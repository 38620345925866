import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";

function WorkflowInfoPopup(props: any) {
  const {
    request,
    setRequest,
    allModules,
    getAllPipelineList,
    pipelineList,
    getTypes,
  } = props;
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")} toggle={props.toggle}>
      <ModalHeader>
        <h6 className="header_text__20">Create New Workflow</h6>
      </ModalHeader>
      <ModalBody>
        <div className="w-100 p-2">
          <div className="">
            <div className="mt-2">
              <InputRuleForm
                inputType="TEXT"
                label={"Workflow Name"}
                placeholder="Enter Workflow Name"
                name={"name"}
                value={getValue(request, `name`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    name: getValue(e, `target.value`, ""),
                  })
                }
                //   validator={simpleValidator}
                // validLeft
                mandatory
                required
              />
            </div>
            <SearchToolTip
              width={450}
              name="Module"
              data={allModules.filter(
                (item: object) =>
                  getValue(item, `api_name`, "") !== "calls" &&
                  getValue(item, `api_name`, "") !== "meetings"
              )}
              label="label"
              selectKey={"id"}
              onChange={(e: any) => {
                getAllPipelineList(getValue(e, `id`, ""));
                setRequest({
                  ...request,
                  moduleId: getValue(e, `id`, ""),
                  moduleName: getValue(e, `api_name`, ""),
                });
              }}
              value={getValue(request, `moduleId`, "")}
            />
            {getTypes(getValue(request, `moduleName`, "")) !== "ACTIVITY" && (
              <SearchToolTip
                width={450}
                name="Pipelines"
                data={pipelineList}
                label="label"
                selectKey={"id"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    pipelineId: getValue(e, `id`, ""),
                  })
                }
                value={getValue(request, `pipelineId`, "")}
              />
            )}

            {/* <div className="mt-2">
              <InputRuleForm
                inputType="TEXTAREA"
                label={"Description"}
                placeholder="Enter Description"
                rows={4}
                name={"description"}
                value={getValue(request, `description`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    description: getValue(e, `target.value`, ""),
                  })
                }
                //   validator={simpleValidator}
              />
            </div> */}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={props.toggle}
            buttonType={"scecondary"}
            buttonText="Cancel"
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={"Submit"}
            onClickHandler={() => props.handleSubmit()}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default WorkflowInfoPopup;
