
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: any;
  label: string;
  validator: any;
  placeholder: string;
  mandatory: boolean;
  maxDate: any;
  minDate: any;
  hideLabel: boolean;
};

export default function InlineTimePicker({
  value,
  onChange,
  validator,
  label,
  placeholder,
  mandatory,
  hideLabel,
}: Props) {
  return (
    <div
      // className={`signup-form-wrapper__form-group ${
      //   validator && validator.current.message(label, value, "required")
      //     ? "signup-form-wrapper__form-group--error"
      //     : ""
      // } form-group mb-3`}
      className=""
    >
      {!hideLabel && (
        <label className="form-label-inline">
          {label} {mandatory && "*"}
        </label>
      )}
      <div>
        <DatePicker
          className={`inline_form_input`}
          selected={value ? moment(value).toDate() : null}
          onChange={onChange}
          placeholderText={placeholder}
          showTimeSelect
          showMonthDropdown
          showYearDropdown
          //   showTimeSelectOnly
          timeIntervals={15}
          dateFormat="d MMM yyyy, p"
          timeCaption="Time"
        />
      </div>
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(label, value, "required")}
        </p>
      )}
    </div>
  );
}
