import { getValue } from "@utils/lodash";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import ActivityLoader from "@components/common/Loader/activity-loader";
import FormBuilder from "@components/Builder/FormBuilder";

function CommonDetailTaskPopup(props: any) {
  const { isRollsPopupVisibile, setRollsPopupVisisbility } = props;
  return (
    <div>
      <SlideFromRightPopup
        isPopupVisible={isRollsPopupVisibile}
        addCustomClass={"rolls-popup"}
        toggle={() => {
          setRollsPopupVisisbility(false);
        }}
        handleCloseButtonClick={() => {
          setRollsPopupVisisbility(!isRollsPopupVisibile);
        }}
        title={getValue(props, `title`, "")}
        width={"580px"}
        submit_text={"Submit"}
        handleSubmitButtonClick={() => props.handleSubmit()}
        isLoading={props.formSubmitLoading}
      >
        {props.formLoading ? (
          <div>
            <ActivityLoader />
          </div>
        ) : (
          <>
            <div className={"task_wrapper"}>
              <FormBuilder
                fields={getValue(props, `form`, [])}
                setFields={props.setForm}
                simpleValidator={props.simpleValidator}
                singular_label={props.singular_label}
                module={"tasks"}
              />
            </div>
            {/* <FormBuilder
              fields={getValue(props, `form`, [])}
              setFields={props.setForm}
              simpleValidator={props.simpleValidator}
              singular_label={props.singular_label}
            /> */}

            <SubmitContainer
              handleBackButton={() => {
                setRollsPopupVisisbility(false);
              }}
              handleSubmit={() => props.handleSubmit()}
              submitLoading={props.formSubmitLoading}
              first_title={"Cancel"}
              second_title={"Save"}
              fromTaskPopup
              disabled={props.disabled}
              disableValidation={props.disableValidation}
            />
          </>
        )}
      </SlideFromRightPopup>
    </div>
  );
}

export default CommonDetailTaskPopup;
