import React, { useEffect, useState } from "react";
import { getValue } from "@utils/lodash";
import {
  createAttachments,
  getAttachments,
} from "@services/attachments.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useParams } from "react-router-dom";
import AttachmentsPopup from "./upload-popup";
import { mediaUpload } from "@services/upload.service";
import { toast } from "sonner";
import CommonDocumentList from "@components/custom/FileUpload/document-list";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { updateModuleCommon } from "@services/common.service";
import TaskPendingLayout from "../../components/TaskPendingLayout/TaskPendingLayout";
import PendingTaskCommonTitle from "../common/PendingTaskCommonTitle/PendingTaskCommonTitle";
import PendingTaskCommonDescription from "../common/PendingTaskCommonDescription/PendingTaskCommonDescription";


function CommonAttachments(props: any) {
  const params = useParams();
  /* --------------------------------------------------------------------- */
  /*                            State Section                              */
  /* --------------------------------------------------------------------- */
  const [isActive2, setIsActive2] = useState(true);
  const handleOpenForm = () => {
    // props.getForm("sales");
    // setRollsPopupVisisbility(true);
  };
  const getKeyName = (name: string) => {
    switch (name) {
      case "contacts":
        return "contact_id";
      case "deals":
        return "deal_id";
      case "companies":
        return "company_id";
      case "tasks":
        return "task_id";
      case "notes":
        return "note_id";
      case "meetings":
        return "meeting_id";
      case "calls":
        return "call_id";
      case "task_comments":
        return "task_comment_id";

      default:
        return null;
    }
  };
  /* --------------------------------------------------------------------- */
  /*                         Upload Section                                */
  /* --------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [documentList, setDocumentList] = useState<any>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  const handleRemoveDocuments = async (i: number) => {
    let list = documentList.filter((_item: any, index: number) => i !== index);
    setDocumentList(list);
  };

  /* --------------------------------------------------------------------- */
  /*                             API Section                               */
  /* --------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let key: any = getKeyName(props.module);
      let payload = {
        files:
          getValue(documentList, `length`, 0) > 0
            ? documentList.map((item: object) => ({
                action: "add",
                file_id: getValue(item, `id`, ""),
              }))
            : [],
        [key]: getValue(params, `id`, ""),
      };
      let resp = await createAttachments(payload);
      if (resp) {
        toast.success("Uploaded successfully");
        setSubmitLoading(false);
        props.getData(false);
        // getAttachmentList();
        handleModal();
        setDocumentList([]);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* --------------------------------------------------------------------- */
  /*                         Delete Section                                */
  /* --------------------------------------------------------------------- */
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const [deleteId, setDeleteId] = useState("");
  const handleDelete = async () => {
    try {
      setSubmitLoading(true);
      let files = getValue(props, `data.attachments`, []).map(
        (item: object) => ({
          // ...item,
          id: getValue(item, `id`, ""),
          file_id: getValue(item, `file.id`, ""),
          action: getValue(item, `id`, "") === deleteId ? "delete" : "update",
        })
      );

      let payload = {
        files: files,
      };
      let resp = await updateModuleCommon(
        props.module,
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        toast.success("Removed successfully");
        setSubmitLoading(false);
        handleModal1();
        setDocumentList([]);
        setDeleteId("");
        props.getData(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <TaskPendingLayout>
      <PendingTaskCommonTitle
        commonTitle="Documents"
        count={
          getValue(props, `data.attachments.length`, 0) < 9 &&
          getValue(props, `data.attachments.length`, 0) !== 0
            ? `${getValue(props, `data.attachments.length`, 0)}`
            : getValue(props, `data.attachments.length`, 0)
        }
        isLinkVisible={true}
        handleToggle={() => {
          setIsActive2(!isActive2);
        }}
        triggerAddPopupSlidingFromRight={() => {
          handleModal();
        }}
      />
      <div
        className={`${
          isActive2 ? "showdiv" : "hidediv"
        } ${"accordian-wrapper"}`}
      >
        <PendingTaskCommonDescription description="See the files attached to your activities or uploaded to this record." />
        <CommonDocumentList
          documentList={getValue(props, `data.attachments`, []).map(
            (item: object) => ({
              id: getValue(item, `id`, ""),
              extension: getValue(item, `file.extension`, ""),
              filename: getValue(item, `file.filename`, ""),
              key: getValue(item, `file.key`, ""),
              size: getValue(item, `file.size`, ""),
              description: getValue(item, `description`, ""),
            })
          )}
          handleRemoveDocuments={handleModal1}
          setDeleteId={setDeleteId}
          isEdit
        />
      </div>
      <AttachmentsPopup
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleSubmit}
        handleUploadDocuments={handleUploadDocuments}
        handleRemoveDocuments={handleRemoveDocuments}
        documentList={documentList}
        submitLoading={submitLoading}
      />
      <DeleteModal
        isOpen={isOpen1}
        handleModal={handleModal1}
        handleSubmit={handleDelete}
        deleteValue={""}
      />
    </TaskPendingLayout>
  );
}

export default CommonAttachments;
