import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";

const CreatePropertyHeader = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const { activeTab, setActiveTab } = props;

  return (
    <div>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(`/${getValue(params, "orgId", "")}/property`)
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">
              {activeTab === 1 && "Property details"}
              {activeTab === 2 && "Property amenities"}
              {activeTab === 3 && "Property images"}
              {activeTab === 4 && "Room types"}
              {activeTab === 5 && "Cancellation policy"}
            </p>
          </div>
          <div className="d-flex align-items-center">
            {activeTab === 1 && (
              <ButtonComponent
                buttonType="primary"
                buttonText={"Next"}
                onClickHandler={() => setActiveTab(2)}
                // disabled={isLoading}
              />
            )}
            {activeTab === 2 && (
              <>
                <ButtonComponent
                  buttonType="secondary"
                  buttonText={"Back"}
                  onClickHandler={() => setActiveTab(1)}
                  // disabled={isLoading}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Next"}
                  onClickHandler={() => setActiveTab(3)}
                  // disabled={isLoading}
                />
              </>
            )}
            {activeTab === 3 && (
              <>
                <ButtonComponent
                  buttonType="secondary"
                  buttonText={"Back"}
                  onClickHandler={() => setActiveTab(2)}
                  // disabled={isLoading}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Next"}
                  onClickHandler={() => setActiveTab(4)}
                  // disabled={isLoading}
                />
              </>
            )}
            {activeTab === 4 && (
              <>
                <ButtonComponent
                  buttonType="secondary"
                  buttonText={"Back"}
                  onClickHandler={() => setActiveTab(3)}
                  // disabled={isLoading}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Next"}
                  onClickHandler={() => setActiveTab(5)}
                  // disabled={isLoading}
                />
              </>
            )}
            {activeTab === 5 && (
              <>
                <ButtonComponent
                  buttonType="secondary"
                  buttonText={"Back"}
                  onClickHandler={() => setActiveTab(4)}
                  // disabled={isLoading}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Create"}
                  onClickHandler={() =>
                    navigate(`/${getValue(params, "orgId", "")}/property`)
                  }
                  // disabled={isLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePropertyHeader;
