import "./index.scss";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import { toast } from "sonner";
import { formatString } from "common/text-helpers";
import {
  activateOrgUser,
  cancelInvOrgUser,
  deactivateOrgUser,
  getOrganisationInvitedUsers,
  getOrganisationUsers,
  resendInvOrgUser,
  sendInvOrgUser,
  updateOrganisationUsers,
} from "@services/organisations.service";
import UserPopup from "./components/user-popup";
import classnames from "classnames";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import CopySvgComponents from "assets/svg/copy";
import { CopyToClipboard } from "common/text-helpers";
import ResendSvgComponent from "assets/svg/resend";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import SimpleReactValidator from "simple-react-validator";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import NotificationBar from "@components/Pages/Filter/NotificationBar";
import Filter from "@components/Pages/Filter/filter";
import CustomDataTable from "@components/custom/Datatable";
import CustomAllCheckbox from "@components/Form/CheckBox/CustomAllCheckbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import Nodata from "@components/common/NoData/NoData";
import PaginationPage from "@components/common/Pagination/pagination";
import ConfirmModal from "@components/Dialogs/Modals/confirmModal";
import UsersNavViews from "./components/nav-views";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import Loader from "@components/common/Loader/loading";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUsersProps {}

const SettingUsers: React.FunctionComponent<IUsersProps> = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  // const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    setSelectedIds([]);
    if (activeTab !== tab) {
      setUsersList([]);
      delete UrlParams.search_text;
      let payload = {
        ...UrlParams,
        active_tab: tab,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  const filterNavigationArray: any = [];
  const subMenuArray: any = [];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      if (activeTab == 1) {
        getActiveUsersData();
      } else {
        getInvitedUsersData();
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (getValue(UrlParams, `search_text`, "")) {
        setSearchInput(UrlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      //   getAllPipelines();
      if (getValue(UrlParams, `active_tab`, "1") == 1) {
        getActiveUsersData();
      } else if (getValue(UrlParams, `active_tab`, "1") == 2) {
        getInvitedUsersData();
      } else {
        getDeactiveUsersData();
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  /* -------------------------  Active Users Section -------------------------- */
  const [headers, setHeaders] = useState([
    { api_name: "name", is_selected: true, label: "Name" },
    { api_name: "email", is_selected: true, label: "Email" },
    { api_name: "phone", is_selected: true, label: "Phone Number" },
    // {api_name:"user_status",is_selected:true,label:"User Status"},
    {
      api_name: "email_verification",
      is_selected: true,
      label: "Email Verification",
    },
  ]);
  const getActiveUsersData = async () => {
    let payload = {
      filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
      page_size: getValue(UrlParams, `limit`, "")
        ? getValue(UrlParams, `limit`, "")
        : limit,
      search: getValue(UrlParams, `search_text`, ""),
      page_no: getValue(UrlParams, `page_no`, "")
        ? getValue(UrlParams, `page_no`, "")
        : page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setLoading(true);
      let resp = await getOrganisationUsers(queryRequest);
      if (resp) {
        setUsersList(
          getValue(resp, `data.length`, 0) > 0 &&
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              email: getValue(item, `email`, ""),
              name:
                getValue(item, `first_name`, "") +
                " " +
                getValue(item, `last_name`, ""),
              phone: getValue(item, `phone`, ""),
              user_status: formatString(getValue(item, `status`, "")),
              email_verification: getValue(item, `is_email_verified`, "")
                ? "Yes"
                : "No",
              phone_verification: getValue(item, `is_phone_verified`, "")
                ? "Yes"
                : "No",
            }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /* ---------------------  DeActivated Users Section -------------------------- */
  const getDeactiveUsersData = async () => {
    let payload = {
      filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
      page_size: getValue(UrlParams, `limit`, "")
        ? getValue(UrlParams, `limit`, "")
        : limit,
      page_no: getValue(UrlParams, `page_no`, "")
        ? getValue(UrlParams, `page_no`, "")
        : page_no,
      status: "INACTIVE",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setLoading(true);
      let resp = await getOrganisationUsers(queryRequest);
      if (resp) {
        setUsersList(
          getValue(resp, `data.length`, 0) > 0 &&
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              email: getValue(item, `email`, ""),
              name:
                getValue(item, `first_name`, "") +
                " " +
                getValue(item, `last_name`, ""),
              phone: getValue(item, `phone`, ""),
              user_status: formatString(getValue(item, `status`, "")),
              email_verification: getValue(item, `is_email_verified`, "")
                ? "Yes"
                : "No",
              phone_verification: getValue(item, `is_phone_verified`, "")
                ? "Yes"
                : "No",
            }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /* -------------------------  Invite Users Section ----------------------- */
  const [InviteHeaders, setInviteHeaders] = useState([
    "email",
    "invite_link",
    "created_at",
    "updated_at",
    "expires_at",
    // "phone_verification",
  ]);
  const getInvitedUsersData = async () => {
    let payload = {
      filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
      page_size: getValue(UrlParams, `limit`, "")
        ? getValue(UrlParams, `limit`, "")
        : limit,
      page_no: getValue(UrlParams, `page_no`, "")
        ? getValue(UrlParams, `page_no`, "")
        : page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setLoading(true);
      let resp = await getOrganisationInvitedUsers(queryRequest);
      if (resp) {
        setUsersList(
          getValue(resp, `data.length`, 0) > 0 &&
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              email: getValue(item, `email`, "")
                ? getValue(item, `email`, "")
                : getValue(item, `identifier`, ""),
              created_at: convertCurrentDate(getValue(item, `created_at`, "")),
              expires_at: convertCurrentDate(getValue(item, `expires_at`, "")),
              updated_at: convertCurrentDate(getValue(item, `updated_at`, "")),
            }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search_text) {
      delete UrlParams.search_text;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      if (activeTab == 1) {
        getActiveUsersData();
      } else {
        getInvitedUsersData();
      }
    }
  };
  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    let payload: any = {
      ...UrlParams,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      if (activeTab == 1) {
        getActiveUsersData();
      } else {
        getInvitedUsersData();
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                    Filter Delete View Section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  //   useEffect(() => {
  //     if (isOpenFilterView || isVisibile) {
  //       document.body.classList.add("modal-open");
  //     } else {
  //       document.body.classList.remove("modal-open");
  //     }
  //   }, [isOpenFilterView, isVisibile]);

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Create User section                          */
  /* -------------------------------------------------------------------------- */
  const [, forceUpdate] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const openEditPopup = () => {
    setIsOpenEditPopup(!isOpenEditPopup);
  };
  const [request, setRequest] = useState({
    identifier_type: "EMAIL",
    identifiers: "",
  });

  const [invLoading, setInvLoading] = useState(false);
  const handleCreateUser = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setInvLoading(true);
        let resp = await sendInvOrgUser({
          identifier_type: "EMAIL",
          identifiers: [request.identifiers],
        });
        if (resp) {
          toast.success("Invitation sent successfully");
          setInvLoading(false);
          openEditPopup();
          setRequest({
            ...request,
            identifier_type: "EMAIL",
            identifiers: "",
          });
          let payload = {
            active_tab: 2,
          };
          let queryRequest = QueryRequestHelper(payload);
          forceUpdate(0);
          simpleValidator.current.hideMessages();
          navigate(`${window.location.pathname}?${queryRequest}`);
        } else {
          setInvLoading(false);
        }
      } catch (error) {
        setInvLoading(false);
      }
    }
  };

  const handleResendInvUser = async (id: string) => {
    try {
      let resp = await resendInvOrgUser(id);
      if (resp) {
        toast.success("Invitation resent successfully");
        if (activeTab == 1) {
          getActiveUsersData();
        } else {
          getInvitedUsersData();
        }
      } else {
      }
    } catch (error) {}
  };

  const handleCancelInvUser = async (id: string) => {
    try {
      let resp = await cancelInvOrgUser(id);
      if (resp) {
        toast.success("Invitation cancelled successfully");
        handleModal();
        setSelectedIds([]);
        if (activeTab == 1) {
          getActiveUsersData();
        } else {
          getInvitedUsersData();
        }
      } else {
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const handleChangeAllCheckbox = (value: any) => {
    if (value === "all_checked") {
      setSelectedIds([]);
    } else {
      let IDs = usersList.map((item: any) => item.id);
      setSelectedIds(IDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           Deactivate User section                          */
  /* -------------------------------------------------------------------------- */
  const handleDeactivateUser = async () => {
    try {
      let resp = await deactivateOrgUser(selectedIds[0]);
      if (resp) {
        toast.success("User deactivated successfully");
        setSelectedIds([]);
        getActiveUsersData();
      } else {
      }
    } catch (error) {}
  };

  const handleActivateUser = async () => {
    try {
      let resp = await activateOrgUser(selectedIds[0]);
      if (resp) {
        toast.success("User activated successfully");
        setSelectedIds([]);
        getInvitedUsersData();
      }
    } catch (error) {}
  };
  const selectedUserInfo: any =
    getValue(usersList, `length`, 0) > 0 &&
    getValue(selectedIds, `length`, 0) === 1 &&
    usersList.filter(
      (item: any) =>
        getValue(item, `id`, "") === getValue(selectedIds, `[${0}]`, "")
    );

  const activeTab = getValue(UrlParams, `active_tab`, "1");

  const updateUserStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateOrganisationUsers(id, { is_admin: status });
      if (resp) {
        toast.success("Updated successfully");
        getActiveUsersData();
      }
    } catch (error) {}
  };
  return (
    <>
      <HomeHeader />
      <CompanyProfileSubHeader />
      <div className={`${"users_container"} w-100`}>
        <CompanyProfileNavigation />
        <div className="w-100">
          <div className={`d-flex`}>
            <div className={`${"manageFieldRight"} w-100 add-overflow-y`}>
              <div className="mt-2 mb-2">
                <div className="px-5 ">
                  <div
                    className={`content ${
                      getValue(selectedIds, `length`, 0) > 0 ? "open" : "closed"
                    }`}
                  >
                    <NotificationBar
                      handleDeactivateUser={handleDeactivateUser}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      // handleOpenFilter={handleOpenFilter}
                      //   handleDeleteTable={handleDeleteTable}
                      hideStage
                      hideOwner
                      hideDelete
                      usersModule
                      userInfo={getValue(selectedUserInfo, `[${0}]`, {})}
                      handleCancelInvUser={handleModal}
                      handleResendInvUser={handleResendInvUser}
                      activateUser={
                        getValue(UrlParams, `active_tab`, "") == 3
                          ? true
                          : false
                      }
                      handleActivateUser={handleActivateUser}
                    />
                  </div>
                  <div
                    className={`other-content subheader_filter_section ${
                      getValue(selectedIds, `length`, 0) === 0
                        ? "open"
                        : "closed"
                    }`}
                  >
                    <Filter
                      isStatusVisible={true}
                      filterNavigationArray={filterNavigationArray}
                      isFilterVisibile={true}
                      isCreateDealVisible={true}
                      SubPiplineTitle={"User"}
                      SubPiplineTitlePrefix={"Invite"}
                      setVisisbility={openEditPopup}
                      //search section
                      searchInput={search_text}
                      showSearchTextbox={showSearchTextbox}
                      toggleSearch={toggleSearch}
                      toggleSearchClose={toggleSearchClose}
                      handleChangeSearch={handleChangeSearch}
                      hideCloseDate
                      hideCreateDate
                      hideStage
                      hideOwner
                      createText={"Invite User"}
                      hideEditColumns
                      hideUpload
                    />
                  </div>
                </div>
                {/* <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Active Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Invited Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Inactive Users
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <UsersNavViews toggle={toggle} activeTab={activeTab} />
                {activeTab == 1 && (
                  <div className="mt-3 px-3">
                    {loading ? (
                      <Loader />
                    ) : getValue(usersList, `length`, 0) > 0 ? (
                      <table className={`common_table w-100 mt-3`}>
                        <thead>
                          <tr>
                            <td style={{ width: "70px" }}>
                              {getValue(selectedIds, `length`, 0) ===
                              getValue(usersList, `length`, 0) ? (
                                <CustomCheckbox
                                  checked={
                                    getValue(selectedIds, `length`, 0) ===
                                    getValue(usersList, `length`, 0)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleChangeAllCheckbox(
                                      getValue(selectedIds, `length`, 0) ===
                                        getValue(usersList, `length`, 0)
                                        ? "all_checked"
                                        : "all_not_checked"
                                    )
                                  }
                                  className={"datatable_table_th_checkbox"}
                                />
                              ) : (
                                <CustomAllCheckbox
                                  checked={
                                    getValue(selectedIds, `length`, 0) > 0
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleChangeAllCheckbox(
                                      getValue(selectedIds, `length`, 0) ===
                                        getValue(usersList, `length`, 0)
                                        ? "all_checked"
                                        : "all_not_checked"
                                    )
                                  }
                                  className={"datatable_table_th_checkbox"}
                                />
                              )}
                            </td>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Phone Number</td>
                            <td>Email Verification</td>
                            <td>Admin</td>
                          </tr>
                        </thead>
                        <tbody>
                          {usersList.map((item: object) => {
                            return (
                              <tr>
                                <td>
                                  <CustomCheckbox
                                    onChange={() =>
                                      handleChangeCheckbox(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                    checked={
                                      selectedIds.includes(
                                        getValue(item, `id`, "")
                                      )
                                        ? true
                                        : false
                                    }
                                    className={"datatable_table_th_checkbox"}
                                  />
                                </td>
                                <td>{getValue(item, `name`, "")}</td>
                                <td>{getValue(item, `email`, "")}</td>
                                <td>{getValue(item, `phone`, "")}</td>
                                <td>
                                  {getValue(item, `email_verification`, "")}
                                </td>
                                <td>
                                  {" "}
                                  <label className={"switch"}>
                                    <input
                                      type="checkbox"
                                      checked={getValue(
                                        item,
                                        `is_admin`,
                                        false
                                      )}
                                      onChange={() =>
                                        updateUserStatus(
                                          getValue(item, `id`, ""),
                                          !getValue(item, `is_admin`, false)
                                        )
                                      }
                                    />
                                    <span
                                      className={`${"slider"} ${`round`} d-flex`}
                                    ></span>
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <FiltersNoDataPage module={"users"} />
                    )}
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="mt-3 px-4">
                    {getValue(usersList, `length`, 0) > 0 ? (
                      <table className={`common_table w-100 mt-3`}>
                        <thead>
                          <tr>
                            <td style={{ width: "70px" }}>
                              {getValue(selectedIds, `length`, 0) ===
                              getValue(usersList, `length`, 0) ? (
                                <CustomCheckbox
                                  checked={
                                    getValue(selectedIds, `length`, 0) ===
                                    getValue(usersList, `length`, 0)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleChangeAllCheckbox(
                                      getValue(selectedIds, `length`, 0) ===
                                        getValue(usersList, `length`, 0)
                                        ? "all_checked"
                                        : "all_not_checked"
                                    )
                                  }
                                  className={"datatable_table_th_checkbox"}
                                />
                              ) : (
                                <CustomAllCheckbox
                                  checked={
                                    getValue(selectedIds, `length`, 0) > 0
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleChangeAllCheckbox(
                                      getValue(selectedIds, `length`, 0) ===
                                        getValue(usersList, `length`, 0)
                                        ? "all_checked"
                                        : "all_not_checked"
                                    )
                                  }
                                  className={"datatable_table_th_checkbox"}
                                />
                              )}
                            </td>
                            <td>Email</td>
                            <td>Created at</td>
                            <td>Updated at</td>
                            <td>More</td>
                          </tr>
                        </thead>
                        <tbody>
                          {usersList.map((item: object) => {
                            return (
                              <tr>
                                <td>
                                  <CustomCheckbox
                                    onChange={() =>
                                      handleChangeCheckbox(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                    checked={
                                      selectedIds.includes(
                                        getValue(item, `id`, "")
                                      )
                                        ? true
                                        : false
                                    }
                                    className={"datatable_table_th_checkbox"}
                                  />
                                </td>
                                <td>{getValue(item, `email`, "")}</td>
                                <td>
                                  {convertCurrentDateWithTime(
                                    getValue(item, `created_at`, "")
                                  )}
                                </td>
                                <td>
                                  {convertCurrentDateWithTime(
                                    getValue(item, `updated_at`, "")
                                  )}
                                </td>

                                <td>
                                  <div className="d-flex align-items-center">
                                    <div
                                      onClick={() =>
                                        CopyToClipboard(
                                          getValue(item, `invite_link`, "")
                                        )
                                      }
                                    >
                                      <CopySvgComponents />
                                    </div>
                                    <div
                                      className="ms-4"
                                      onClick={() =>
                                        handleResendInvUser(
                                          getValue(item, `id`, "")
                                        )
                                      }
                                    >
                                      <ResendSvgComponent color={"#276ef1"} />
                                    </div>
                                    <div
                                      className="ms-4"
                                      onClick={() =>
                                        handleCancelInvUser(
                                          getValue(item, `id`, "")
                                        )
                                      }
                                    >
                                      <RemoveCircleSvgComponent
                                        color={"#d72e38"}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <FiltersNoDataPage module={"users"} />
                    )}
                  </div>
                )}
                {activeTab == 3 && (
                  <div className="mt-3">
                    {/* <CustomDataTable
                      headers={headers}
                      data={usersList}
                      setHeaders={setHeaders}
                      singular_label={"User"}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      isLoading={loading}
                      checkboxKey={"name"}
                      module={"users"}
                      // disableCheckbox
                    /> */}
                    <div className="mt-3 px-3">
                      {loading ? (
                        <Loader />
                      ) : getValue(usersList, `length`, 0) > 0 ? (
                        <table className={`common_table w-100 mt-3`}>
                          <thead>
                            <tr>
                              <td style={{ width: "70px" }}>
                                {getValue(selectedIds, `length`, 0) ===
                                getValue(usersList, `length`, 0) ? (
                                  <CustomCheckbox
                                    checked={
                                      getValue(selectedIds, `length`, 0) ===
                                      getValue(usersList, `length`, 0)
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleChangeAllCheckbox(
                                        getValue(selectedIds, `length`, 0) ===
                                          getValue(usersList, `length`, 0)
                                          ? "all_checked"
                                          : "all_not_checked"
                                      )
                                    }
                                    className={"datatable_table_th_checkbox"}
                                  />
                                ) : (
                                  <CustomAllCheckbox
                                    checked={
                                      getValue(selectedIds, `length`, 0) > 0
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleChangeAllCheckbox(
                                        getValue(selectedIds, `length`, 0) ===
                                          getValue(usersList, `length`, 0)
                                          ? "all_checked"
                                          : "all_not_checked"
                                      )
                                    }
                                    className={"datatable_table_th_checkbox"}
                                  />
                                )}
                              </td>
                              <td>Name</td>
                              <td>Email</td>
                              <td>Phone Number</td>
                              <td>Email Verification</td>
                            </tr>
                          </thead>
                          <tbody>
                            {usersList.map((item: object) => {
                              return (
                                <tr>
                                  <td>
                                    <CustomCheckbox
                                      onChange={() =>
                                        handleChangeCheckbox(
                                          getValue(item, `id`, "")
                                        )
                                      }
                                      checked={
                                        selectedIds.includes(
                                          getValue(item, `id`, "")
                                        )
                                          ? true
                                          : false
                                      }
                                      className={"datatable_table_th_checkbox"}
                                    />
                                  </td>
                                  <td>{getValue(item, `name`, "")}</td>
                                  <td>{getValue(item, `email`, "")}</td>
                                  <td>{getValue(item, `phone`, "")}</td>
                                  <td>
                                    {getValue(item, `email_verification`, "")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <FiltersNoDataPage module={"users"} />
                      )}
                    </div>
                  </div>
                )}

                <div className={"table_footer_container"}>
                  {totalCount > 10 && getValue(usersList, `length`, 0) > 0 ? (
                    <PaginationPage
                      totalCount={totalCount}
                      limit={limit}
                      pageNumber={page_no}
                      handleChangePagination={handleChangePagination}
                      handleChangeLimit={handleChangeLimit}
                    />
                  ) : (
                    <p className="show-rows-count mt-3 d-flex">
                      {/* Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p> */}
                    </p>
                  )}
                </div>
                <ConfirmModal
                  isOpen={isOpen}
                  handleModal={handleModal}
                  handleSubmit={() => handleCancelInvUser(selectedIds[0])}
                />
                <UserPopup
                  isOpen={isOpenEditPopup}
                  handleModal={openEditPopup}
                  request={request}
                  setRequest={setRequest}
                  isLoading={invLoading}
                  handleSubmit={handleCreateUser}
                  simpleValidator={simpleValidator}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingUsers;
