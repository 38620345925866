import { getValue } from "@utils/lodash";
import DocumentFormatPreviews from "./doc-formats-preview";
import DocumentSvgComponent from "@assets/svg/document";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import DocumentFormatReplyPreviews from "./doc-formats-reply-preview";
import AddSvgComponent from "@assets/svg/add";
import { AnimatePresence, motion } from "framer-motion";
import { handleDownloadOnClick } from "@common/downloadfile";
import { config } from "env";
import convertMarkdownToHtml from "@pages/Private/Broadcast/TemplateMessages/useMarkdown";
import { replaceDefaultPlaceholders } from "@common/markdown-helper";

function ChatReceiverSection(props: any) {
  const { item, setSelectedReplyMessage } = props;
  return (
    <div>
      <div className="reciever-details-wrapper">
        <div className="reciever-details">
          <div className="reciever-right-section">
            <p className="reciever-message ">
              <div className="arrow-container">
                <ArrowdownSvgComponent color={"gray"} class="arrow" />
                <div className="whatsapp-more-options">
                  <button
                    onClick={() => setSelectedReplyMessage(item)}
                    className=" small_text__13 w-more-options"
                  >
                    <img
                      src="/images/icons/whatsapp-reply.svg"
                      className="ms-1"
                    />
                    Reply
                  </button>
                  {getValue(item, `type`, "") === "image" ||
                    getValue(item, `type`, "") === "video" ||
                    (getValue(item, `type`, "") === "document" && (
                      <>
                        <div className="add-border my-1"></div>
                        <button
                          className="small_text__13 w-more-options"
                          onClick={() =>
                            handleDownloadOnClick(
                              `https://${config.URL}/${getValue(
                                item,
                                `upload_file.key`,
                                ""
                              )}`,
                              getValue(item, `upload_file.filename`, "")
                            )
                          }
                        >
                          <img
                            src="/images/icons/whatsapp-download.svg"
                            className="download-icon ms-1"
                          />
                          Download
                        </button>
                      </>
                    ))}
                  {/* <div className="add-border"></div>
                  <button className="small_text__13 w-more-options">
                    <AddSvgComponent size="14" color="#212121" />
                    Add to task
                  </button> */}
                </div>
              </div>

              {getValue(item, `replied_message.id`, "") && (
                <AnimatePresence mode="wait">
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "64px" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.8,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <div className="reply_message_container mb-2 reciever-replay w-100">
                      <div className="d-flex align-items-center ms-2 justify-content-between w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <DocumentSvgComponent />
                          {/* <p className="small_text__14 px-2">
                            {getValue(item, `replied_message.media_caption`, "")
                              ? getValue(
                                  item,
                                  `replied_message.media_caption`,
                                  ""
                                )
                              : getValue(item, `replied_message.text_body`, "")}
                          </p> */}
                          <p
                            // dangerouslySetInnerHTML={{
                            //   __html: getValue(
                            //     item,
                            //     `replied_message.media_caption`,
                            //     ""
                            //   )
                            //     ? getValue(
                            //         item,
                            //         `replied_message.media_caption`,
                            //         ""
                            //       )
                            //     : getValue(
                            //         item,
                            //         `replied_message.text_body`,
                            //         ""
                            //       ),
                            // }}
                            className="small_text__14 px-2 py-1"
                          >
                            {replaceDefaultPlaceholders(
                              getValue(
                                item,
                                `replied_message.media_caption`,
                                ""
                              )
                                ? getValue(
                                    item,
                                    `replied_message.media_caption`,
                                    ""
                                  )
                                : getValue(
                                    item,
                                    `replied_message.text_body`,
                                    ""
                                  )
                            )}
                          </p>
                        </div>
                        {getValue(
                          item,
                          `replied_message.upload_file.id`,
                          ""
                        ) && (
                          <DocumentFormatReplyPreviews
                            item={getValue(item, `replied_message`, {})}
                            upload_file={getValue(
                              item,
                              `replied_message.upload_file`,
                              ""
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </motion.section>
                </AnimatePresence>
              )}

              <DocumentFormatPreviews item={item} receiver />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatReceiverSection;
