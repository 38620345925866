import { config } from "../env";
import { get } from "./helpers/http-handler";

export const getDashboardOverview = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/overview?${queryRequest}`);

export const getDashboardModule = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/modules?${queryRequest}`);

export const getDashboardContact = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/modules/contacts?${queryRequest}`);
