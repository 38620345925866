import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import Search from "../Search";
import { useNavigate } from "react-router-dom";

const SearchHeader = (props: any) => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return (
    <section className="add-filter-wrapper add-filter-wrapper--border  d-flex align-items-center justify-content-between">
      <div className="add-filter-wrapper__add d-flex align-items-center ">
        {props.back && (
          <NavLink to={`${props.backUrl}`}>
            <img
              src="/images/back-link.svg"
              className="img-fluid ml-5"
              height={24}
              width={24}
            />
          </NavLink>
        )}
      </div>
      <div className="add-filter-wrapper__filter d-flex align-items-center">
        {props.search !== "false" && (
          <Search
            searchInput={getValue(props, `searchInput`, "")}
            showSearchTextbox={getValue(props, `showSearchTextbox`, "")}
            toggleSearch={props.toggleSearch}
            toggleSearchClose={props.toggleSearchClose}
            handleChangeSearch={props.handleChangeSearch}
            calender={props.calender}
            handleNavigateCalenderView={props.handleNavigateCalenderView}
            searchPlaceholder={props.searchPlaceholder}
          />
        )}
      </div>
    </section>
  );
};

export default SearchHeader;
