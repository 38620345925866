import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React from "react";
// import CompanyProfileSubHeader from "@components/v2/Settings/CompanyProfile/CompanyProfileSubHeader/CompanyProfileSubHeader";

function SettingsLayout({ children }: any) {
  return (
    <div>
      <HomeHeader />
      {/* <CompanyProfileSubHeader /> */}
      <div className="flex-1">{children}</div>
    </div>
  );
}

export default SettingsLayout;
