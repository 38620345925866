import EditSvgComponent from "@assets/svg/edit";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import ActivityLoader from "@components/common/Loader/activity-loader";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function FacebookLeadList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="px-4 pt-4">
      {isLoading ? (
        <ActivityLoader />
      ) : (
        <table className="common_table w-100">
          <thead>
            <tr>
              <td>Created On</td>
              <td>Status</td>
              <td>Form Name</td>
              <td>CRM Integration</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {list.map((item: object) => {
              return (
                <tr
                  onClick={() =>
                    navigate(
                      `/${getValue(
                        params,
                        `orgId`,
                        ""
                      )}/settings/ads/lead-forms/${getValue(
                        params,
                        `id`,
                        ""
                      )}/edit/${getValue(item, `form_id`, "")}`
                    )
                  }
                >
                  <td>
                    {convertCurrentDateWithTime(
                      getValue(item, `created_time`, "")
                    )}
                  </td>
                  <td className="d-flex gap-2 align-items-center">
                    <div
                      className="active-icon"
                      style={{
                        background:
                          getValue(item, `status`, "") === "ACTIVE"
                            ? "#21C9AF"
                            : "#F05555",
                      }}
                    ></div>
                    {formatString(getValue(item, `status`, ""))}
                  </td>
                  <td>{getValue(item, `name`, "")}</td>
                  <td>{getValue(item, `id`, "") ? "Yes" : "No"}</td>
                  <td>
                    <EditSvgComponent />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default FacebookLeadList;
