import BrandingSvgComponent from "@assets/svg/branding";
import PhoneSvgComponent from "@assets/svg/custom/phone";
import DeleteSvgComponent from "@assets/svg/delete";
import EditSvgComponent from "@assets/svg/edit";
import MoneySvgComponent from "@assets/svg/money";
import PersonSvgComponent from "@assets/svg/person";
import StarSvgComponent from "@assets/svg/star";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import { useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import EditPropertyDetails from "./components/edit-property-details";
import EditAmenities from "./components/edit-amenities";

const bedTypes = [
  {
    type: "Single bed",
    occupancy: 1,
    price: 2345,
    amenities: ["Bathtub", "Wardrobe"],
  },
  {
    type: "Double bed",
    occupancy: 2,
    price: 3456,
    amenities: ["Bathtub", "Wardrobe", "Balcony"],
  },
  {
    type: "Single bed",
    occupancy: 1,
    price: 2345,
    amenities: ["Bathtub", "Wardrobe"],
  },
  {
    type: "Double bed",
    occupancy: 2,
    price: 3456,
    amenities: ["Bathtub", "Towel", "Wardrobe", "Balcony", "Kettle"],
  },
];

const PropertySettingsDetails = () => {
  const [openEditDetails, setOpenEditDetails] = useState(false);
  const toggleEditDetails = () => {
    setOpenEditDetails(!openEditDetails);
  };

  const [openEditAmenities, setOpenEditAmenities] = useState(false);
  const toggleEditAmenities = () => {
    setOpenEditAmenities(!openEditAmenities);
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleClick = () => {};
  return (
    <PropertySettingsLayout setActiveTab={setActiveTab}>
      <div className="d-flex gap-2 overflow-x-hidden">
        <img src="/property/7.jpg" className="rounded" />
        <img src="/property/6.jpg" className="rounded" />
        <img src="/property/7.jpg" className="rounded" />
      </div>
      <div className="mt-4 ">
        <div className="d-flex justify-content-between align-items-baseline border-bottom pb-5">
          <div>
            <h3 className="header_text__24 my-3">Aclet meadows</h3>
            <div className="d-flex align-items-center gap-2">
              <BrandingSvgComponent size="20" color="#3c4043" />
              <p>Alpine, TX, USA</p>
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <PhoneSvgComponent size="20" />
              <p>+919999988888</p>
            </div>
            <p
              onClick={toggleEditDetails}
              className="header_blue_text__16 touchable-text mt-4 mb-2 d-flex align-items-center gap-2"
            >
              <EditSvgComponent /> Edit Details
            </p>
            <p
              onClick={toggleEditAmenities}
              className="header_blue_text__16 touchable-text  d-flex align-items-center gap-2"
            >
              <EditSvgComponent /> Edit Amenities
            </p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <StarSvgComponent size="20" />
            <p className="header_text__20">4.6</p>
          </div>
        </div>
        <div className="mt-4 mb-5">
          <div className="d-flex justify-content-between ">
            <p className="header_text__18">Manage room type</p>
            <ButtonComponent
              buttonType="primary"
              buttonText={"Add Room type"}
              onClickHandler={handleClick}
              // disabled={isLoading}
            />
          </div>
          <div className="bed_type_container mt-3">
            {bedTypes.map((bed, index) => (
              <div className="bed_type_details" key={index}>
                <div className="bed_type-background d-flex justify-content-between flex-column gap-4">
                  <div className="d-flex justify-content-between align-items-start gap-4">
                    <p className="small_text__16 text-white">{bed.type}</p>
                    <div className="d-flex gap-2 align-items-center">
                      <EditSvgComponent color="#dddddd" />
                      <DeleteSvgComponent color="#d20f0f" />
                    </div>
                  </div>
                  <div className="d-grid gap-2 mt-3">
                    <div className="d-flex align-items-center gap-2">
                      <p>
                        <MoneySvgComponent size="18" color="#dddddd" />
                      </p>
                      <span className="small_text__14 card-text-white">
                        {bed.price}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <p>
                          <PersonSvgComponent size="18" color="#dddddd" />
                        </p>
                        <span className="small_text__14 card-text-white">
                          Max adults {bed.occupancy}
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <p>
                          <PersonSvgComponent size="18" color="#dddddd" />
                        </p>
                        <span className="small_text__14 card-text-white">
                          Max children {bed.occupancy}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CommonRightPopup
        isPopupVisible={openEditDetails}
        toggle={toggleEditDetails}
        width="750px"
        title="Edit Hotel Details"
        hideNew
        second_title="Save"
      >
        <EditPropertyDetails />
      </CommonRightPopup>
      <CommonRightPopup
        isPopupVisible={openEditAmenities}
        toggle={toggleEditAmenities}
        width="600px"
        title="Edit Amenities"
        hideNew
        second_title="Save"
      >
        <EditAmenities />
      </CommonRightPopup>
    </PropertySettingsLayout>
  );
};

export default PropertySettingsDetails;
