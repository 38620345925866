import { useState } from "react";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";

function CarouselNavViews(props: any) {
  const { activeTab, handleTab } = props;

  return (
    <ul
      className="d-flex align-items-center justify-content-between mt-3 overflow-auto pr-3"
      style={{ borderBottom: "1px solid #efefef" }}
    >
      <div className="d-flex align-items-center justify-content-start ms-4">
        {Array.from({ length: getValue(props, `length`, 0) }).map(
          (_: any, index: number) => {
            return (
              <li
                key={`pending-${index}`}
                className={`${
                  activeTab == index + 1
                    ? "activebar__main-body-list_active"
                    : "activebar__main-body-list"
                }`}
                onClick={() => handleTab(index + 1)}
              >
                <a className={"activebar__main-body-link p-3"}>{index + 1}</a>
              </li>
            );
          }
        )}
      </div>
    </ul>
  );
}

export default CarouselNavViews;
