import Loader from "@components/common/Loader/loading";
import Layout from "@layouts/Layout";
import { oAuthLinkCallback } from "@services/email-config.service";
import { getValue } from "@utils/lodash";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

function EmailAuthCallbackpage(props: any) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    sendCodeToEmailConfig(
      getValue(urlParams, `code`, ""),
      getValue(params, `provider`, "")
    );
  }, []);

  const sendCodeToEmailConfig = async (code: string, provider: string) => {
    let state = getValue(urlParams,`state`,'').split(':::')
    if (code && provider) {
      let payload = {
        provider: `EMAIL_${provider.toUpperCase()}`,
        state:getValue(state,`[${0}]`,''),
        code: decodeURIComponent(code),
      };
      try {
        let resp = await oAuthLinkCallback(
          `EMAIL_${provider.toUpperCase()}`,
          payload,
          getValue(state,`[${1}]`,'')
        );
        if (resp) {
          window.location.href = `/${getValue(
            urlParams,
            `state`,
            ""
          )}/settings/emails/config`;
        }
      } catch (error) {
        toast.error("Something went wrong, Please try after sometime");
        // navigate(`/${getValue(urlParams, `state`, "")}/settings/emails/config`);
      }
    } else {
      toast.error("Something went wrong, Please try after sometime");
      // navigate(`/${getValue(urlParams, `state`, "")}/settings/emails/config`);
    }
  };
  return (
    <Layout>
      <Loader />
    </Layout>
  );
}

export default EmailAuthCallbackpage;
