import InputRuleForm from "@components/InputRuleForm/form";
import ProgressSteps from "./addRoomType/room-progress-bar";
import { useState } from "react";
import InfoSvgComponent from "@assets/svg/info";

const AddRoomType = ({ addBedType, openModal, toggleModal }: any) => {
  const [activeStep, setActiveStep] = useState(1);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const [numberOfRooms, setNumberOfRooms] = useState("12");
  const [isSecondInputEnabled, setIsSecondInputEnabled] = useState(true);

  const handleNumberOfRoomsChange = (e: any) => {
    const value = e.target.value;
    setNumberOfRooms(value);

    if (value && !isNaN(value) && parseInt(value) > 0) {
      setIsSecondInputEnabled(true);
    } else {
      setIsSecondInputEnabled(false);
    }
  };

  const roomInputs = [];
  if (isSecondInputEnabled) {
    for (let i = 0; i < parseInt(numberOfRooms); i++) {
      roomInputs.push(
        <div className="mt-3 col-3 overflow-x-hidden" key={i}>
          <InputRuleForm
            inputType="TEXT"
            name={`room-id-${i}`}
            placeholder={`Id for room ${i + 1}`}
            className="mb-2"
            label={`Room ${i + 1} ID `}
            disabled={!isSecondInputEnabled}
            required
          />
        </div>
      );
    }
  }

  return (
    <div className="">
      {addBedType && (
        <>
          <ProgressSteps
            activeStep={activeStep}
            nextStep={nextStep}
            prevStep={prevStep}
          />
          <div className="mt-4 px-5 pb-5">
            {/* <p className="header_text__18">Configure your rooms!</p> */}
            {activeStep === 1 && (
              <>
                <div className="">
                  <InputRuleForm
                    inputType="TEXT"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter a name"
                    className="mb-2"
                    label="Room type name"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="number"
                    value={numberOfRooms}
                    placeholder="Enter no of rooms"
                    required
                    className="mb-2"
                    label="How many rooms under this room type?"
                    onChange={handleNumberOfRoomsChange} // Add onChange handler
                  />
                </div>
                <div className=" w-100">
                  <div className="row">{roomInputs}</div>
                </div>
              </>
            )}
            {activeStep === 2 && (
              <>
                <div className="">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter default no of travellers"
                    required
                    className="mb-2"
                    label="Default occupancy"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter maximum adult occupancy"
                    required
                    className="mb-2"
                    label="Maximum adult occupancy"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter max no of children"
                    required
                    className="mb-2"
                    label="Maximum children"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter default price"
                    required
                    className="mb-2"
                    label="Default adult price"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter min adult price"
                    required
                    className="mb-2"
                    label="Min adult price"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter extra adult price"
                    required
                    className="mb-2"
                    label="Extra adult price"
                  />
                </div>
                <div className="mt-3">
                  <InputRuleForm
                    inputType="NUMBER"
                    name="name"
                    //   value={getValue(request, `name`, "")}
                    placeholder="Enter child price"
                    required
                    className="mb-2"
                    label="Child price"
                  />
                </div>
              </>
            )}
            {activeStep === 3 && (
              <>
                <div className="pt-3">
                  <section className="">
                    <div className="d-flex gap-3 flex-wrap">
                      <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
                        Children crib
                      </button>
                      <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
                        Clothes rack
                      </button>
                      <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
                        Air conditioning
                      </button>
                      <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
                        Washing machine
                      </button>
                      <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
                        Toilet paper
                      </button>
                      <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
                        Electric kettle
                      </button>
                    </div>
                  </section>
                </div>
              </>
            )}
            {activeStep === 4 && (
              <div>
                <p className="header_text__20">
                  Add photos to enrich user experience!
                </p>
                <p className="small_text__14 color_desc mt-2">
                  Publish at least 1 images to get your property more appealing.
                  You can edit them later in dashboard.
                </p>
                <div className="my-4">
                  <p
                    // htmlFor="file-upload"
                    className="custom-file-upload mb-2 requirement-message"
                  >
                    Choose a{" "}
                    <span className="required-file-format">.jpg or .png</span>{" "}
                    file
                  </p>
                  <input
                    type="file"
                    className="custom-upload-filename"
                    accept={".jpg,.jpeg,.png"}
                  />
                </div>
                <p className="small_text__14 color_desc mt-2 d-flex gap-2 align-items-center">
                  <InfoSvgComponent size="16" />
                  Maximum of 20 images can be uploaded.
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddRoomType;
