import BackSvgComponent from "@assets/svg/back-link";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import DateMonthYearPicker from "@components/custom/Forms/Date";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdditionalGuestDetails from "./guest-details";
import ChildDetails from "./child-details";
import DayLevelAmount from "./day-level-amount";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";

const CreateWalkIN = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [openGuestDetails, setOpenGuestDetails] = useState(false);
  const toggleGuestDetails = () => {
    setOpenGuestDetails(!openGuestDetails);
  };

  const [openChildDetails, setOpenChildDetails] = useState(false);
  const toggleChildDetails = () => {
    setOpenChildDetails(!openChildDetails);
  };

  const [openDayLevelAmount, setOpenDayLevelAmount] = useState(false);
  const toggleDayLevelAmount = () => {
    setOpenDayLevelAmount(!openDayLevelAmount);
  };

  const handleClick = () => {
    
  }

  return (
    <div>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/details/${getValue(params, "id", "")}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">Create walk in</p>
          </div>
          <ButtonComponent
            buttonType="primary"
            buttonText={"Create Reservation"}
            onClickHandler={handleClick}
          />
        </div>
      </div>
      <div className="manageFieldRight w-100 p-5 overflow-y-auto">
        <div className="add-box-shadow">
          <p className="small_text__16">Check in - check out</p>
        </div>
        <div className="d-flex align-items-center gap-4 add-box-shadow">
          <div className="flex-1">
            <p className="form-label">Room info</p>
            <SearchToolTip
              placeholder="Choose room"
              className="pt-2"
              value="Ac double bed"
            />
          </div>
          <div className="flex-1">
            <p className="form-label">Room id</p>
            <SearchToolTip
              placeholder="Choose room id"
              className="pt-2"
              value="Ac double bed"
            />
          </div>
        </div>
        <div className="grid-container gap-3 w-100">
          <div className="add-box-shadow">
            <h3 className="header_text__18">Guest details</h3>
            <div className="grid-container gap-3 my-3">
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter full name"
                label="Customer name"
                required
              />
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter customer email"
                label="Customer email"
                required
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter number"
                required
                label="Customer number"
                marginZero
              />
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Any special request"
                label="Special request"
              />
              <div>
                <p className="form-label">Identity card type</p>
                <SearchToolTip />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter Govt ID card no"
                label="Govt ID number"
              />
              {/* <div>
                <p className="form-label">Upload from </p>
                <SearchToolTip />
              </div> */}
              <div>
                <p className="form-label">Upload front ID</p>
                <input
                  type="file"
                  className="custom-upload-filename"
                  accept={".jpg,.jpeg,.png"}
                />
              </div>
              <div>
                <p className="form-label">Upload back ID </p>
                <input
                  type="file"
                  className="custom-upload-filename"
                  accept={".jpg,.jpeg,.png"}
                />
              </div>
              <div className="grid-full-width">
                <InputRuleForm
                  inputType="TEXT"
                  name="name"
                  //   value={getValue(request, `name`, "")}
                  placeholder="Enter full address"
                  label="Address"
                />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter city"
                label="City"
              />

              <div>
                <p className="form-label">State</p>
                <SearchToolTip />
              </div>
              <div>
                <p className="form-label">Country</p>
                <SearchToolTip />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter zip code"
                label="Zip code"
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter no of adults (Max 3)"
                label="Adults"
                marginZero
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter no of children (Max 1)"
                label="Child"
                marginZero
              />
              <div className="grid-full-width d-flex flex-column gap-3">
                <div>
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={"Add guest 2 details"}
                    onClickHandler={toggleGuestDetails}
                  />
                </div>
                <div className="">
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={"Add child 1 details"}
                    onClickHandler={toggleChildDetails}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 d-flex align-items-center gap-3">
              <h3 className="header_text__18">Optional fields</h3>
              <label className={"switch"}>
                <input type="checkbox" />
                <span className={`${"slider"} ${`round`} d-flex`}></span>
              </label>
            </div>
            <div className="grid-container gap-3 my-3">
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter full address"
                label="Address line 2"
              />
              <div>
                <p className="form-label">Gender</p>
                <SearchToolTip />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter dob"
                label="Date of birth"
              />
              <div>
                <p className="form-label">Nationality</p>
                <SearchToolTip />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter occupation"
                label="Occupation"
              />
            </div>
          </div>
          <div className="add-box-shadow ">
            <h3 className="header_text__18">Price details</h3>
            <div className="d-flex flex-column gap-3 my-3">
              <div>
                <p className="form-label">Rate plan</p>
                <SearchToolTip />
              </div>
              <div>
                <p className="form-label">Segment</p>
                <SearchToolTip />
              </div>
              <div>
                <p className="form-label">Sub segment</p>
                <SearchToolTip />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter Tax exempt ID"
                label="Tax exempt ID"
              />
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter Taxes to exempt"
                label="Taxs to exempt "
              />
              <div>
                <p className="form-label">Coupon code/ offer</p>
                <SearchToolTip />
              </div>
              <InputRuleForm
                inputType="TEXT"
                name="name"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter coupon code"
                label="Coupon code"
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter per night amount"
                required
                label="Per night excluding taxes"
                marginZero
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                //   value={getValue(request, `name`, "")}
                placeholder="Enter total amount"
                required
                label="Total excluding taxes"
                marginZero
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                value="3245"
                placeholder="Enter tax"
                required
                label="Total tax"
                marginZero
              />
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                value="3338"
                placeholder="Enter total with tax"
                required
                label="Total with tax"
                marginZero
              />
              <div className="grid-full-width">
                <ButtonComponent
                  buttonType="secondary"
                  buttonText={"Day level amount"}
                  onClickHandler={toggleDayLevelAmount}
                />
              </div>
              <div>
                <div className=" d-flex align-items-center gap-3">
                  <h3 className="header_text__16">Enquiry</h3>
                  <label className={"switch"}>
                    <input type="checkbox" />
                    <span className={`${"slider"} ${`round`} d-flex`}></span>
                  </label>
                </div>
                <div className="d-flex flex-column gap-1 mt-3">
                  <span>Enquiry expires in</span>
                  <div className=" mx-4 my-2">
                    <InputRuleForm
                      inputType="NUMBER"
                      name="number"
                      //   value={getValue(request, `name`, "")}
                      placeholder="24"
                      value="24"
                      hideFormLabel
                    />
                    <div className="">
                      <p className="form-label">Expiration time</p>
                      <SearchToolTip />
                    </div>
                    <div className="">
                      <p className="form-label">Source</p>
                      <SearchToolTip />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className=" d-flex align-items-center gap-3">
                  <h3 className="header_text__16">Collect payment</h3>
                  <label className={"switch"}>
                    <input type="checkbox" />
                    <span className={`${"slider"} ${`round`} d-flex`}></span>
                  </label>
                </div>
                <div className="d-flex flex-column gap-1 ">
                  <div className=" mx-4 my-2">
                    <div className="">
                      <p className="form-label">Payment method</p>
                      <SearchToolTip label={`Select payment method`} />
                    </div>
                    <InputRuleForm
                      inputType="NUMBER"
                      name="number"
                      //   value={getValue(request, `name`, "")}
                      placeholder="24"
                      value="24"
                      label="Amount"
                    />
                    <InputRuleForm
                      inputType="TEXT"
                      name="name"
                      //   value={getValue(request, `name`, "")}
                      placeholder="Enter remark"
                      label="Remark"
                    />
                  </div>
                </div>
              </div>
              <div className=" mt-4">
                <p className="form-label">Select add ons</p>
                <SearchToolTip />
              </div>
              <table className="datatable_table w-100">
                <thead className="datatable_table_thead position-relative">
                  <tr className="datatable_table_tr">
                    <th className="datatable_table_th ">
                      <h6 className="header_text__12">NAME</h6>
                    </th>
                    <th className="datatable_table_th ">
                      <h6 className="header_text__12">QUANTITY</h6>
                    </th>
                    <th className="datatable_table_th ">
                      <h6 className="header_text__12">PRICE</h6>
                    </th>
                    <th className="datatable_table_th ">
                      <h6 className="header_text__12">TAX %</h6>
                    </th>
                    <th className="datatable_table_th ">
                      <h6 className="header_text__12">PRICE WITH TAX</h6>
                    </th>
                  </tr>
                </thead>
                <tbody className="datatable_table_tbody">
                  <tr className="datatable_table_tr">
                    <td
                      className="datatable_table_td "
                      style={{ display: "table-cell" }}
                    >
                      Swimming
                    </td>
                    <td className="datatable_table_td ">1</td>
                    <td className="datatable_table_td ">999.00</td>
                    <td className="datatable_table_td ">9</td>
                    <td className="datatable_table_td ">1088.91</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CommonRightPopup
        isPopupVisible={openGuestDetails}
        toggle={toggleGuestDetails}
        width="500px"
        title="Add guest 2 details"
        hideNew
        second_title="Save"
      >
        <AdditionalGuestDetails />
      </CommonRightPopup>
      <CommonRightPopup
        isPopupVisible={openChildDetails}
        toggle={toggleChildDetails}
        width="500px"
        title="Add child 1 details"
        hideNew
        second_title="Save"
      >
        <ChildDetails />
      </CommonRightPopup>
      <CommonRightPopup
        isPopupVisible={openDayLevelAmount}
        toggle={toggleDayLevelAmount}
        width="800px"
        title="Day level amount"
        hideNew
        second_title="Save"
      >
        <DayLevelAmount />
      </CommonRightPopup>
    </div>
  );
};

export default CreateWalkIN;
