import DocumentSvgComponent from "@assets/svg/document";
import ButtonComponent from "@components/Form/Button/Button";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import SearchHeader from "@components/custom/SearchHeader";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const detailsCard = [
  {
    count: "1",
    heading: "New booking",
    color: "#96c7c1",
  },
  {
    count: "0",
    heading: "In house",
    color: "#CDF2CA",
  },
  {
    count: "2",
    heading: "Arrivals ",
    color: "#f7d59c",
  },
  {
    count: "0",
    heading: "Departures",
    color: "#6b7aa1",
  },
  {
    count: "0",
    heading: "Cancellations",
    color: "#F29191",
  },
  {
    count: "0",
    heading: "On hold",
    color: "#6E7C7C",
  },
  {
    count: "0",
    heading: "No shows",
    color: "#D3E0DC",
  },
  {
    count: "0",
    heading: "Magic links",
    color: "pink",
  },
];

const ReservationTab = ({ toggleCheckin, toggleMagicLink }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const [openSearch, setOpenSearch] = useState(false);
  const toggleSearch = () => {
    setOpenSearch(!openSearch);
  };

  const [openCard, setOpenCard] = useState(false);
  const toggleCard = () => {
    setOpenCard(!openCard);
  };
  return (
    <div>
      <h3 className="header_text__22">Good morning</h3>
      <p className="mt-1">
        Here is what going on with your property on <b>27 Jun 2024</b>
      </p>
      <div className="reservation-card-container mt-3 border-bottom pb-4">
        {detailsCard.map((details, index) => (
          <div
            className="reserversation-details-card position-relative"
            key={index}
          >
            <div
              className="p-1 position-absolute top-0 w-100 rounded-top-2"
              style={{ background: details.color }}
            ></div>
            <span className="card-count">{details.count}</span>
            <p>{details.heading}</p>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
        <SearchHeader
          toggleSearch={toggleSearch}
          toggleSearchClose={toggleSearch}
        />
        {/* <div className="d-flex justify-content-between align-items-center gap-3"> */}
        <TextHeaderTooltip
          title="Sort by"
          lookup_api={"org_users"}
          // value={getValue(UrlParams, `filter_owner_id`, "")}
          // onChange={(e: any) =>
          //   handleSelectFilter(
          //     "filter_owner_id",
          //     getValue(e, `id`, ""),
          //     ""
          //   )
          // }
          label={`label`}
          width={"270px"}
          showActiveInline
        />
        <DocumentSvgComponent size="24" />
        {/* </div> */}
      </div>
      <div
        className="border mt-4  p-3 rounded-3 cursor-pointer room-box-shadow"
        onClick={toggleCard}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4">
            <div>
              <p className="small_text__14 mb-2">ravi</p>
              <p className="small_text__14">9808909999</p>
            </div>
            <div>
              <p className="small_text__14 mb-2">
                SFBOOKING_28869_12359 - <b>(Standard Plan)</b>
              </p>
              <p className="small_text__14">
                Jun 26, 2024 10:00 AM - Jun 27, 2024 10:00 AM
              </p>
            </div>
          </div>
          <div>
            <p className="small_text__14 mb-2 text-end">
              <b>254A (AC)</b>
            </p>
            <div className="d-flex gap-3 small_text__14">
              <p>CONFIRMED</p>
              <div>
                <p className="d-flex justify-content-between gap-4 mb-1">
                  <span>Total</span>
                  <span>
                    <b>₹ 4,500</b>
                  </span>
                </p>
                <p className="d-flex justify-content-between gap-4">
                  <span>Balance</span>
                  <span>
                    <b>₹ 2,500</b>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {openCard && (
          <div className="d-flex justify-content-end gap-1 mt-3">
            <ButtonComponent
              buttonType="secondary"
              buttonText="Check in"
              onClickHandler={toggleCheckin}
            />
            <ButtonComponent
              buttonType="secondary"
              buttonText="View folio"
              onClickHandler={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/details/1234/folio`
                )
              }
            />
            <ButtonComponent
              buttonType="secondary"
              buttonText="Send magic link"
              onClickHandler={toggleMagicLink}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationTab;
