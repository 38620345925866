import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import { getValue } from "@utils/lodash";

function PhoneInputComponent(props: any) {
  const {
    value,
    onChange,
    onBlur,
    label,
    placeholder,
    hideLabel,
    validator,
    validatoryType,
    mandatory,
    inline,
    showWhatsapp,
  } = props;
  const [isValid, setIsValid] = useState(false);
  const handlePhoneChange = (phone: string) => {
    const valid = isValidPhoneNumber(phone);
    setIsValid(valid);
    onChange(phone);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {!hideLabel && <label className={`form-label`}>{label} </label>}
        {showWhatsapp && isValidPhoneNumber(value) && (
          <img
            src="/images/whatsapp.png"
            height={25}
            width={25}
            onClick={() =>
              isValidPhoneNumber(value)
                ? window.open(
                    `https://api.whatsapp.com/send?text=Hi&phone=+91${value}`
                  )
                : null
            }
          />
        )}
      </div>
      <PhoneInput
        defaultCountry="in"
        value={value}
        onChange={handlePhoneChange}
        onBlur={onBlur}
        placeholder={placeholder}
        style={{
          borderLeft: mandatory ? "2px solid red" : "",
          borderRadius: "4px",
          border: inline && "none",
        }}
      />
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(
            label,
            isValid ? value : "",
            validatoryType ? validatoryType : "required"
          )}
        </p>
      )}
    </div>
  );
}

export default PhoneInputComponent;
