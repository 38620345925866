import ButtonComponent from "@components/Form/Button/Button";
import "./CompanyProfile.scss";
import { getValue } from "@utils/lodash";
import SettingsSvgComponents from "assets/svg/settings";
import AddCircleSvgComponent from "@assets/svg/add-circle";

export default function CompanyProfileSubHeader(props: any) {
  const { isLoading, handleSubmit, submitText } = props;
  return (
    <div className={"company-profile-subheader-wrapper"}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <SettingsSvgComponents color={"#202124"} size={28} />
          <h6
            className={`company-profile-subheader-wrapper__submenu-name mx-2`}
          >
            Settings
          </h6>
        </div>
        {getValue(props, `showSubmit`, false) ? (
          <div className="d-flex align-items-center gap">
            <ButtonComponent
              buttonType="primary"
              buttonText={
                isLoading ? "Please wait..." : submitText ? submitText : "Save"
              }
              onClickHandler={handleSubmit}
              disabled={isLoading}
            />
          </div>
        ) : (
          <></>
        )}

        {getValue(props, `addWorkflow`, false) && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() =>
              // navigate(
              //   `/${getValue(params, `orgId`, "")}/settings/workflows/create`
              // )
              props.toggle()
            }
          >
            <AddCircleSvgComponent />
            <p className="small_text__16 color_success ms-1">Workflow</p>
          </div>
        )}
        {getValue(props, `addForm`, false) && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => props.toggle()}
          >
            <AddCircleSvgComponent />
            <p className="small_text__16 color_success ms-1">Create form</p>
          </div>
        )}
      </div>
    </div>
  );
}
