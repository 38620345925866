import { convertCurrentDate } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import Switch from "@components/Form/Switch/Switch";
import ActivityLoader from "@components/common/Loader/activity-loader";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function FacebookPageList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  list.map((item: object) => {
    console.log(getValue(item, "status", ""));
  });

  return (
    <div className="px-4 pt-4">
      {isLoading ? (
        <ActivityLoader />
      ) : (
        <table className="common_table w-100">
          <thead>
            <tr>
              <td>Page ID</td>
              <td>Name</td>
              <td>Category</td>
              <td>Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {list.map((item: object) => {
              return (
                <tr
                  onClick={() =>
                    getValue(item, `status`, "") === "SUBSCRIBED" &&
                    navigate(
                      `/${getValue(
                        params,
                        `orgId`,
                        ""
                      )}/settings/ads/lead-forms/${getValue(item, `id`, "")}`
                    )
                  }
                >
                  <td className="header_blue_text__14 touchable-text">
                    {getValue(item, `page_id`, "")}
                  </td>
                  <td>{getValue(item, `name`, "")}</td>
                  <td>{getValue(item, `category`, "")}</td>
                  <td className=" ">
                    {formatString(getValue(item, `status`, "")) === "SUBSCRIBED"
                      ? "Subscribed"
                      : "Not Subscribed"}
                  </td>
                  {/* <td className="d-flex align-items-center justify-content-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={getValue(item, "isActive", true)}
                        onChange={() =>
                          props.handleSubscribePage(
                            getValue(item, "status", ""),
                            !getValue(item, "isActive", true)
                          )
                        }
                      />
                      <span className="slider round d-flex"></span>
                    </label>
                  </td> */}
                  <td>
                    <div className="d-flex justify-content-center">
                      <button
                        className={`dashboard-button cursor-pointer ${
                          getValue(item, `status`, "") !== "SUBSCRIBED"
                            ? "create-user-button me-3 py-2"
                            : "me-3"
                        }`}
                        onClick={() => props.handleSubscribePage(item)}
                        disabled={getValue(item, `status`, "") === "SUBSCRIBED"}
                      >
                        Subscribe
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default FacebookPageList;
