import React from "react";
import BotSendMessageComponent from "./send-message";
import { getValue } from "@utils/lodash";
import BotButtonsComponent from "./buttons";
import BotVisitorCompanyNameComponent from "./visitor-company-name";
import BotVisitorPhoneComponent from "./visitor-phone";
import BotVisitorEmailComponent from "./visitor-email";
import BotVisitorNameComponent from "./visitor-name";

function RenderBotForm(props: any) {
  const renderForm = (name: string) => {
    switch (name) {
      case "reply":
        return (
          <BotSendMessageComponent
            request={getValue(props, `request`, {})}
            setRequest={props.setRequest}
          />
        );
      case "suggestions":
        return (
          <BotButtonsComponent
            request={getValue(props, `request`, {})}
            setRequest={props.setRequest}
          />
        );
      case "visitor_name":
        return (
          <BotVisitorNameComponent
            request={getValue(props, `request`, {})}
            setRequest={props.setRequest}
          />
        );
      case "visitor_email":
        return (
          <BotVisitorEmailComponent
            request={getValue(props, `request`, {})}
            setRequest={props.setRequest}
          />
        );
      case "visitor_phone":
        return (
          <BotVisitorPhoneComponent
            request={getValue(props, `request`, {})}
            setRequest={props.setRequest}
          />
        );
      case "company_name":
        return (
          <BotVisitorCompanyNameComponent
            request={getValue(props, `request`, {})}
            setRequest={props.setRequest}
          />
        );

      default:
        return null;
    }
  };
  return <div>{renderForm(getValue(props, `request.component`, ""))}</div>;
}

export default RenderBotForm;
