import { useState } from "react";
import MoreBookingDetails from "./more-booking-details";

const bookingDetails = [
  {
    heading: "Booking Id",
    info: "SFBOOKING_28869_12357",
  },
  {
    heading: "Booking source",
    info: "Walk In",
  },
  {
    heading: "Guest name",
    info: "ravi",
  },
  {
    heading: "Guest phone",
    info: "9999988888",
  },
  {
    heading: "Room type",
    info: "AC",
  },
  {
    heading: "Rate plan",
    info: "Standard Plan",
  },
  {
    heading: "Final amount with tax",
    info: "INR 666.67",
  },
  {
    heading: "Payment made",
    info: "INR 0.00",
  },
  {
    heading: "Check-In",
    info: "Jun 23rd 2024, 5 AM",
  },
  {
    heading: "Check-out",
    info: "Jun 24th 2024, 1 PM",
  },
  {
    heading: "Booking made on",
    info: "Jun 18th 2024, 11:52 PM",
  },
  {
    heading: "Adults / Children / Infant",
    info: "1 / 0 / 0",
  },
  {
    heading: "Booked Room No.(s)",
    info: "254",
  },
  {
    heading: "Room Booking Amount",
    info: "INR 648.67",
  },
  {
    heading: "Room Booking Tax",
    info: "INR 18.00",
  },
  {
    heading: "Addons Amount",
    info: "INR 0.00",
  },
  {
    heading: "Addons Tax",
    info: "INR 0.00",
  },
  {
    heading: "Total Tax",
    info: "INR 18.00",
  },
  {
    heading: "Balance Due",
    info: "INR 666.67",
  },
  {
    heading: "Access code",
    info: "NA",
  },
  {
    heading: "OTA Booking Id",
    info: "NA",
  },
];

const AllBookingInformation = () => {
  const [showAll, setShowAll] = useState(false);

  const handleReadMore = () => {
    setShowAll(!showAll);
  };

  const detailsToShow = showAll ? bookingDetails : bookingDetails.slice(0, 8);

  return (
    <div>
      <div className="grid-container row-gap-3 column-gap-4">
        {detailsToShow.map((details, index) => (
          <div
            key={index}
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">{details.heading}:</p>
            <p className="small_text__16 text-black">{details.info}</p>
          </div>
        ))}
      </div>
      {bookingDetails.length > 8 && (
        <div
          onClick={handleReadMore}
          className="read-more-button my-3 touchable-text header_blue_text__16"
        >
          {showAll ? "Show less" : "Read more"}
        </div>
      )}
      <MoreBookingDetails />
    </div>
  );
};

export default AllBookingInformation;
