import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import { handleDownloadOnClick } from "@common/downloadfile";
import { formatString } from "@common/text-helpers";
import BroadCastNoDataPage from "@components/common/NoData/broadcast-nodata";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function BroadcastHistoryTable(props: any) {
  const { list } = props;
  const navigate = useNavigate();
  return (
    <>
      {list.length > 0 ? (
        <div className="datatable_container">
          <div className=" datatable_table_wrapper">
            <table className="datatable_table w-100">
              <thead className="datatable_table_thead position-relative">
                <tr className="datatable_table_tr">
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">BROADCAST NAME</h6>
                  </th>
                  <th
                    className="datatable_table_th "
                    style={{ width: "120px" }}
                  >
                    <h6 className="header_text__12">SUCCESSFUL</h6>
                  </th>
                  <th
                    className="datatable_table_th "
                    style={{ width: "120px" }}
                  >
                    <h6 className="header_text__12">SENT</h6>
                  </th>
                  <th
                    className="datatable_table_th "
                    style={{ width: "120px" }}
                  >
                    <h6 className="header_text__12">READ</h6>
                  </th>
                  <th
                    className="datatable_table_th "
                    style={{ width: "120px" }}
                  >
                    <h6 className="header_text__12">REPLIED</h6>
                  </th>
                  {/* <th className="datatable_table_th ">
                <h6 className="header_text__12">RECIPIENTS</h6>
              </th> */}
                  <th
                    className="datatable_table_th "
                    style={{ width: "120px" }}
                  >
                    <h6 className="header_text__12">FAILED</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">STATUS</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">TRIGGERED ON</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">CREATED ON</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">ACTIONS</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="datatable_table_tbody">
                {list.map((item: object) => {
                  return (
                    <tr
                      className="datatable_table_tr "
                      onClick={() =>
                        // props.getSpecificBrodcast(getValue(item, `id`, ""))
                        navigate(`${getValue(item, `id`, "")}`)
                      }
                    >
                      <td
                        className="datatable_table_td "
                        style={{ display: "table-cell" }}
                      >
                        <div className="header_blue_text__14 touchable-text">
                          {getValue(item, `name`, "")}
                        </div>
                      </td>
                      <td className="datatable_table_td ">
                        {getValue(item, `overview.overview.delivered.count`, 0)}
                      </td>
                      <td className="datatable_table_td ">
                        {getValue(item, `overview.overview.sent.count`, 0)}
                      </td>
                      <td className="datatable_table_td ">
                        {getValue(item, `overview.overview.read.count`, 0)}
                      </td>
                      <td className="datatable_table_td ">
                        {getValue(item, `overview.overview.replied.count`, 0)}
                      </td>
                      <td className="datatable_table_td ">
                        {getValue(item, `overview.overview.failed.count`, 0)}
                      </td>
                      <td className="datatable_table_td ">
                        {formatString(getValue(item, `status`, ""))}
                      </td>
                      <td className="datatable_table_td ">
                        {convertCurrentDateWithTime(
                          getValue(item, `trigger_at`, "")
                        )}
                      </td>
                      <td className="datatable_table_td ">
                        {convertCurrentDate(getValue(item, `created_at`, ""))}
                      </td>
                      <td
                        className="datatable_table_td"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.handleDownloadBroadcastTemplate(
                            getValue(item, `id`, ""),
                            getValue(item, `contact_file.filename`, "")
                          );
                        }}
                      >
                        {getValue(item, `contact_file.url`, "") && (
                          <h6 className="header_text__14 color_primary">
                            Download
                          </h6>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <BroadCastNoDataPage />
      )}
    </>
  );
}

export default BroadcastHistoryTable;
