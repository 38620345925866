import React from "react";

const CreatePropertyStepTwo = () => {
  return (
    <>
      <p className="header_text__20">Choose your property amenities</p>
      <div className="d-flex gap-3 flex-wrap mt-4">
        <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
          On premise parking
        </button>
        <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
          Gym
        </button>
        <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
          Brekfast
        </button>
        <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
          Laundry
        </button>
        <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
          Valet parking
        </button>
        <button className="dashboard-button  hover-text touchable-text-white px-4 py-2">
          Outdoor dining area
        </button>
      </div>
    </>
  );
};

export default CreatePropertyStepTwo;
