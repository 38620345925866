import React from "react";

const BackButton = ({ className }) => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <button onClick={handleGoBack} className={`${className} dashboard-button p-0`}>
      <img
        src="/images/icons/back-icon.svg"
        width={24}
        height={24}
        alt="Back"
      />
    </button>
  );
};

export default BackButton;
