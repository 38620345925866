import { getValue } from "@utils/lodash";

export const handleChatbotRequest = (
  name: string,
  chatRequest: any,
  setChatRequest: any,
  findSelectedNode: any,
  params: any
) => {
  switch (name) {
    case "reply":
      return setChatRequest({
        ...chatRequest,
        id: getValue(findSelectedNode, `id`, ""),
        flow_id: getValue(findSelectedNode, `flow_id`, ""),
        seq_num: getValue(findSelectedNode, `seq_num`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(findSelectedNode, `action_id`, ""),
        criteria: getValue(findSelectedNode, `criteria`, {}),
        name: getValue(findSelectedNode, `name`, ""),
        position: getValue(findSelectedNode, `position`, {}),
        description: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].text`,
          ""
        ),
        delay_config: {},
        component: "reply",
        type: "reply",
      });

    case "suggestions":
      return setChatRequest({
        ...chatRequest,
        id: getValue(findSelectedNode, `id`, ""),
        flow_id: getValue(findSelectedNode, `flow_id`, ""),
        seq_num: getValue(findSelectedNode, `seq_num`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(findSelectedNode, `action_id`, ""),
        criteria: getValue(findSelectedNode, `criteria`, {}),
        name: getValue(findSelectedNode, `name`, ""),
        position: getValue(findSelectedNode, `position`, {}),
        description: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].text`,
          ""
        ),
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.suggestions`,
          []
        ),
        delay_config: {},
        component: "suggestions",
        type: "reply",
      });

    case "visitor_name":
    case "visitor_email":
    case "visitor_phone":
    case "company_name":
      return setChatRequest({
        ...chatRequest,
        id: getValue(findSelectedNode, `id`, ""),
        flow_id: getValue(findSelectedNode, `flow_id`, ""),
        seq_num: getValue(findSelectedNode, `seq_num`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(findSelectedNode, `action_id`, ""),
        criteria: getValue(findSelectedNode, `criteria`, {}),
        name: getValue(findSelectedNode, `name`, ""),
        position: getValue(findSelectedNode, `position`, {}),
        description: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].text`,
          ""
        ),
        delay_config: {},
        component: name,
        type: "reply",
      });

    default:
      return {};
  }
};

export const getChatbotRequest = (
  name: string,
  findSelectedNode: any,
  params: any
) => {
  switch (name) {
    case "reply":
      return {
        id: getValue(findSelectedNode, `id`, ""),
        flow_id: getValue(findSelectedNode, `flow_id`, ""),
        seq_num: getValue(findSelectedNode, `seq_num`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(findSelectedNode, `action_id`, ""),
        criteria: getValue(findSelectedNode, `criteria`, {}),
        name: getValue(findSelectedNode, `name`, ""),
        position: getValue(findSelectedNode, `position`, {}),
        description: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].text`,
          ""
        ),
        delay_config: {},
        component: "reply",
        type: "reply",
      };

    case "suggestions":
      return {
        id: getValue(findSelectedNode, `id`, ""),
        flow_id: getValue(findSelectedNode, `flow_id`, ""),
        seq_num: getValue(findSelectedNode, `seq_num`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(findSelectedNode, `action_id`, ""),
        criteria: getValue(findSelectedNode, `criteria`, {}),
        name: getValue(findSelectedNode, `name`, ""),
        position: getValue(findSelectedNode, `position`, {}),
        description: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].text`,
          ""
        ),
        suggestions: getValue(
          findSelectedNode,
          `rendering_config.suggestions`,
          []
        ),
        delay_config: {},
        component: "suggestions",
        type: "reply",
      };

    case "visitor_name":
    case "visitor_email":
    case "visitor_phone":
    case "company_name":
      return {
        id: getValue(findSelectedNode, `id`, ""),
        flow_id: getValue(findSelectedNode, `flow_id`, ""),
        seq_num: getValue(findSelectedNode, `seq_num`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(findSelectedNode, `action_id`, ""),
        criteria: getValue(findSelectedNode, `criteria`, {}),
        name: getValue(findSelectedNode, `name`, ""),
        position: getValue(findSelectedNode, `position`, {}),
        description: getValue(
          findSelectedNode,
          `rendering_config.replies[${0}].text`,
          ""
        ),
        delay_config: {},
        component: name,
        type: "reply",
      };

    default:
      return {};
  }
};
