import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useState } from "react";
import "../property.scss";
import CreatePropertyHeader from "../components/create-property-header";
import CreatePropertyStepOne from "../components/create-property-step-1";
import CreatePropertyStepTwo from "../components/create-property-step-2";
import CreatePropertyStepThree from "../components/create-property-step-3";
import CreatePropertyStepFive from "../components/create-property-step-5";
import AddRoomType from "../components/add-room-type";
import PropertyLocation from "../components/property-location";
import CreatePropertyStepFour from "../components/create-property-step-4";

const CreateProperty = () => {
  const [activeTab, setActiveTab] = useState(1);

  const [addBedType, setAddBedType] = useState(false);
  const toggleAddBedType = () => {
    setAddBedType(!addBedType);
  };

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <HomeHeader />
      <CreatePropertyHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      <div
        className={`w-100 grid-container   ${
          activeTab !== 2 && "add-overflow-y"
        }`}
      >
        <div className="w-100 p-5 add-overflow-y manageFieldRight">
          {activeTab === 1 && <CreatePropertyStepOne />}
          {activeTab === 2 && <CreatePropertyStepTwo />}
          {activeTab === 3 && <CreatePropertyStepThree />}
          {activeTab === 4 && (
            <CreatePropertyStepFour toggleAddBedType={toggleAddBedType} />
          )}
          {activeTab === 5 && <CreatePropertyStepFive />}
        </div>
        <div className="w-100 add-overflow-y manageFieldRight">
          {activeTab === 1 && <PropertyLocation />}
          {activeTab === 2 && (
            <div className="p-5">
              <p className="header_text__18">Selected amenities</p>
              <div className="d-flex gap-3 flex-wrap mt-4">
                <button className="dashboard-button   px-4 py-2">Gym</button>
                <button className="dashboard-button   px-4 py-2">
                  Laundry
                </button>
              </div>
            </div>
          )}
          {activeTab === 4 && (
            <AddRoomType
              addBedType={addBedType}
              openModal={openModal}
              toggleModal={toggleModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateProperty;
