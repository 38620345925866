import React from 'react';
import PendingLoanComponent from './components/PendingLoanComponent/PendingLoanComponent';
import PendingDealComponent from './components/PendingDealComponent/PendingDealComponent';
import './PendingTaskLeft.scss'
function CommonModulesDetailLeftPagePage(props:any,) {
    return (
        <div className={`pendingtask-left_container ${props.className}`}>
        <PendingLoanComponent {...props} />
        <PendingDealComponent {...props} />
      </div>
    );
}

export default CommonModulesDetailLeftPagePage;

