import FacebookSvgComponent from "@assets/svg/facebook";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import React, { useState } from "react";
import Popup from "reactjs-popup";

const FacebookSDKComponent = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          props.handleSubmitFacebook(response);
          setIsLoggedIn(true);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "email,ads_management,pages_read_engagement,pages_show_list,pages_manage_metadata,leads_retrieval,pages_manage_ads",
      }
    );
  };

  return (
    <div>
      {/* {isLoggedIn ? (
        <h2>Logged in successfully!</h2>
      ) : ( */}
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div></div>
        <div>
          <Popup
            trigger={(open) => (
              <button className="create-user-button create-user-button--department hover-text touchable-text-white mx-5 py-2">
               <img src="/images/icons/link-white.svg" className="add-image"/> Connect Account
              </button>
            )}
            position="bottom"
            closeOnDocumentClick
            className="me-3"
            mouseEnterDelay={600}
            mouseLeaveDelay={300}
          >
            <div className="filter-select-form-inline">
              <div className="option_container">
                <div className="d-flex flex-column gap-1  option">
                  <div
                    className=" selected_text cursor-pointer  hover-text d-flex gap-2"
                    onClick={handleLogin}
                  >
                    <div className="d-flex gap-1">
                      <img
                        src="/images/icons/facebook.svg"
                        width="20"
                        height="20"
                      />
                      Facebook
                    </div>
                    +
                    <div className="d-flex gap-1">
                      <img
                        src="/images/icons/instagram.svg"
                        width="20"
                        height="20"
                      />
                      Instagram
                    </div>
                  </div>
                </div>
                <div className="option">
                  <div className=" d-flex align-items-center gap-1 module-tab-wrapper__text" >
                    <img
                      src="/images/icons/linkedin.svg"
                      width="20"
                      height="20"
                    />{" "}
                    LinkedIn{" "}
                    <span className="font_size__12 module-tab-wrapper__text">(Coming soon)</span>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default FacebookSDKComponent;
