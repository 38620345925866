import CompanyPipelineDetails from "@components/Pages/Pipeline/Detail/companies";
import BotsPage from "@pages/Private/Bots";
import BotBuilder from "@pages/Private/Bots/BotBuilder";
import BotBuilderStepFour from "@pages/Private/Bots/ReactFlowBuilder";
import Companies from "@pages/Private/Companies";

export const ChatbotRoutes = [
  {
    path: "/:orgId/chatbot",
    name: "Bots",
    component: BotsPage,
  },
  {
    path: "/:orgId/chatbot/builder",
    name: "Bots",
    component: BotBuilder,
  },
  {
    path: "/:orgId/chatbot/builder/create-flow/:id",
    name: "Bots",
    component: BotBuilderStepFour,
  },
];
