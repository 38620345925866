import "./PendingTaskCommonTitle.scss";
import { useState } from "react";

export default function PendingTaskCommonTitle(props: any) {
  const [isActive, setStateActive] = useState(false);
  return (
    <div className={`d-flex align-items-center justify-content-between`}>
      <div className="d-flex align-items-center">
        <img
          src="/images/task/icons/header/drag-indicator.svg"
          className={`img-fluid cursor-pointer`}
        />
        <div className="d-flex align-items-center">
          <img
            src="/images/icons/arrow-down.svg"
            className={`${
              isActive ? "active" : ""
            } cursor-pointer img-fluid ${"pending-wrapper__downarrow-icon"}
            }`}
            onClick={() => {
              props.handleToggle();
              setStateActive(!isActive);
            }}
          />
          <h6 className={"pending-wrapper__main-section-title"}>
            {props.commonTitle}
            <span className={"pending-wrapper__main-section-title-count"}>
              ({props.count})
            </span>
          </h6>
        </div>
      </div>

      {/* one more component */}
      {props.isLinkVisible && (
        <div
          onClick={() => {
            props.triggerAddPopupSlidingFromRight();
          }}
        >
          <div className={"pending-task-commontitle__link touchable-text"}>
            <img
              src="/images/icons/add-blue.svg"
              className={"pending-task-commontitle__link-add"}
            />
          </div>
        </div>
      )}
      {props.isTextVisible && (
        <p className={"pending-task-commontitle__text"}>Hide Blank</p>
      )}
    </div>
  );
}
