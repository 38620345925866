import { useEffect, useState } from "react";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { Nav, NavItem, NavLink } from "reactstrap";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import TemplateMessages from "./TemplateMessages";
import BroadcastHistory from "./BroadcastHistory";
import { useNavigate } from "react-router-dom";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";

function Broadcast(props: any) {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [activeTab, setActiveTab] = useState("1");

  const views = [
    {
      id: "1",
      name: "Broadcast history",
    },
    {
      id: "2",
      name: "Template messages",
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setActiveTab(getValue(UrlParams, `activeTab`, ""));
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleChangeTab = (tab: string) => {
    let payload = {
      ...UrlParams,
      page_no: 1,
      activeTab: tab,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle="Broadcast"
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
      />
      <div className="d-flex">
        <div className="manageFieldRight w-100">
          <Nav tabs>
            {views.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  className={`d-flex justify-content-between align-items-center ${
                    activeTab == item.id ? "active" : ""
                  }`}
                  onClick={() => {
                    handleChangeTab(item.id);
                  }}
                >
                  {item.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          {activeTab === "1" && <BroadcastHistory />}
          {activeTab === "2" && <TemplateMessages />}
        </div>
      </div>
    </div>
  );
}

export default Broadcast;
