import React, { useEffect, useState } from "react";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import CloseSvgComponent from "@assets/svg/close";
import CrossSvgComponent from "@assets/svg/cross";
import InputRuleForm from "@components/InputRuleForm/form";
import ButtonComponent from "@components/Form/Button/Button";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";

function FormCreatorEditFied(props: any) {
  const { editFieldId, droppedFormFields, setDroppedFormFields } = props;

  const [request, setRequest] = useState({
    display_label: "",
    required: false,
    description: "",
  });

  useEffect(() => {
    if (getValue(props, `fieldInfo.label`, "")) {
      setRequest({
        ...request,
        display_label: getValue(props, `fieldInfo.display_label`, ""),
        required: getValue(props, `fieldInfo.system_required`, false)
          ? true
          : getValue(props, `fieldInfo.required`, false),
        description: getValue(props, `fieldInfo.description`, ""),
      });
    } else {
      setRequest({
        ...request,
        display_label: "",
        required: false,
        description: "",
      });
    }
  }, [getValue(props, `fieldInfo`, {})]);

  const handleSubmit = async () => {
    setDroppedFormFields((options: any) => {
      return options.map((x: object, index: number) => {
        if (getValue(x, `id`, "") !== editFieldId) return x;
        return {
          ...x,
          display_label: getValue(request, `display_label`, ""),
          required: getValue(request, `required`, ""),
          description: getValue(request, `description`, ""),
        };
      });
    });
    props.handleModal();
    props.resetEdit();
  };
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")}>
      <div className="d-flex align-items-center justify-content-between w-100 p-2 px-4 py-3 border-bottom">
        <h6 className="header_text__20">Edit Field</h6>
        <div
          onClick={() => {
            props.handleModal();
            props.resetEdit();
          }}
          className="cursor-pointer"
        >
          <CrossSvgComponent />
        </div>
      </div>
      <ModalBody>
        <div>
          <InputRuleForm
            inputType={"TEXT"}
            placeholder={"Enter Label"}
            label={"Display Label"}
            value={getValue(request, `display_label`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                display_label: e.target.value,
              })
            }
          />
        </div>
        <div className="d-flex align-items-center mt-3">
          <p className="small_text__14">SuperCRM Field Name</p>:
          <p className="small_text__14 color_success ms-2">
            {getValue(props, `fieldInfo.label`, "")}
          </p>
        </div>
        <div className="d-flex  align-items-center mt-4">
          <div className="d-flex align-items-center gap-2 mb-2">
            <CustomCheckbox
              onChange={(e: object) =>
                setRequest({
                  ...request,
                  required: !getValue(request, `required`, false),
                })
              }
              checked={getValue(request, `required`, false) ? true : false}
              disabled={
                getValue(props, `fieldInfo.system_required`, false)
                  ? true
                  : false
              }
            />
            <Label> Mark as Required Field </Label>
            {/* <input
              type="checkbox"
              onChange={(e: object) =>
                setRequest({
                  ...request,
                  required: !getValue(request, `required`, false),
                })
              }
              checked={getValue(request, `required`, false) ? true : false}
              disabled={
                getValue(props, `fieldInfo.system_required`, false)
                  ? true
                  : false
              }
            />
            <label className="header_text__14 ms-3">
              Mark as Required Field
            </label> */}
          </div>
        </div>

        <div>
          <InputRuleForm
            inputType={"TEXTAREA"}
            placeholder={"Enter Description"}
            label={"Description"}
            value={getValue(request, `description`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                description: e.target.value,
              })
            }
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            buttonType={"primary"}
            buttonText={"Save"}
            onClickHandler={() => handleSubmit()}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default FormCreatorEditFied;
