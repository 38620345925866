import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import React, { useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { convertDateTime } from "@common/date-helpers";
import FormBuilder from "@components/Builder/FormBuilder";

function ActivityCallPopup(props: any) {
  const { isOpen, setIsOpen, title, width, request, setRequest } = props;
  const [eventTypes] = useState([
    { value: "inbound", label: "Inbound" },
    { value: "outbound", label: "Outbound" },
  ]);
  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        setIsOpen(false);
      }}
      title={title}
      width={width}
    >
      <div className="activity_popup-main">
        {/* <div className="activity_popup-container"> */}
          <FormBuilder
            fields={getValue(props, `fields`, [])}
            setFields={props.setFields}
            simpleValidator={props.simpleValidator}
            singular_label={props.singular_label}
            module={props.module}
          />
        {/* </div> */}
      </div>
      <SubmitContainer
        handleBackButton={() => setIsOpen(!isOpen)}
        handleSubmit={() => props.handleSubmit()}
        submitLoading={props.submitLoading}
        first_title={"Cancel"}
        second_title={"Save"}
      />
    </SlideFromRightPopup>
  );
}

export default ActivityCallPopup;
