import { useEffect } from "react";
import "./SlideFromRightPopup.scss";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";

export default function SlideFromRightPopup({
  isPopupVisible,
  addCustomClass,
  width,
  toggle,
  title,
  moduleName,
  children,
  navigateBack,
  enableBack
}: any) {
  useEffect(() => {
    // Add a CSS class to the body when the popup is visible
    if (isPopupVisible) {
      document.body.classList.add("popup-visible");
    } else {
      // Remove the CSS class when the popup is closed
      document.body.classList.remove("popup-visible");
    }
    // Clean up the effect
    return () => {
      document.body.classList.remove("popup-visible");
    };
  }, [isPopupVisible]);
  return (
    <>
      {isPopupVisible && (
        <div className="overlay">
          <div
            className={`slide-from-right-popup ${
              isPopupVisible && `slide-from-right-popup--active`
            } ${addCustomClass && `slide-from-right-popup--rolls-popup`}
        `}
            style={{ width: width }}
          >
            <CommonPopupHeader
              title={title}
              handleBackButton={toggle}
              moduleName={moduleName}
              navigateBack={navigateBack}
              enableBack={enableBack}
            />
            {children}
          </div>
        </div>
      )}
    </>
  );
}
