import React, { useEffect, useState } from "react";
import "./task-left.scss";
import BackSvgComponent from "assets/svg/back-link";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { updateTask } from "@services/tasks.service";
import {
  convertCurrentDateToTime,
  convertCurrentDateWithTime,
} from "common/date-helpers";
import { concatString, formatString } from "common/text-helpers";
import { toast } from "sonner";
import PersonSvgComponent from "assets/svg/person";
import TaskSvgComponent from "assets/svg/task";
import CheckCircleSvgComponent from "assets/svg/check-circle";
import TaskInfoPopup from "../MiddleSection/components/info-popup";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import VerifiedSvgComponent from "@assets/svg/verified";
import CircleSvgComponent from "@assets/svg/circle";
import { getColorStyle } from "@common/colors";
import DateSvgComponent from "@assets/svg/custom/date";

function TaskDetailLeftSection(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const { list } = props;

  const handleNavigate = () => {
    navigate(`/${getValue(params, `orgId`, "")}/meetings`);
  };

  const handleClick = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/meetings/detail/${id}`);
  };
  return (
    <div className={"task_detail_left-container"}>
      <div
        className={`${"task_detail_left-header_section"} d-flex align-items-center px-3`}
      >
        <div onClick={handleNavigate}>
          <BackSvgComponent />
        </div>
        <h6 className="header_text ms-3">All Meetings</h6>
      </div>
      <div
        className={
          !props.isChanged
            ? "task_detail_left-list-update-section"
            : "task_detail_left-list"
        }
      >
        {getValue(list, `length`, 0) > 0
          ? list.map((item: any) => {
              return (
                <div
                  className={`${`task_detail_left-list_section${
                    getValue(params, `id`, "") === getValue(item, `id`, "")
                      ? "__active"
                      : ""
                  }`} cursor-pointer`}
                  onClick={() => handleClick(getValue(item, `id`, ""))}
                >
                  <div className="d-flex align-items-center">
                    <DateSvgComponent />
                    <div className="d-flex align-items-center ms-2">
                      <TaskInfoPopup
                        header={
                          <p
                            className={`header_text__16 ms-2 ${
                              !getValue(item, `is_completed`, false)
                                ? ""
                                : "task-line-through"
                            }`}
                          >
                            {concatString(getValue(item, `title`, ""), 30)}
                          </p>
                        }
                        text={getValue(item, `title`, "")}
                        minLength={30}
                      />
                    </div>
                  </div>
                  <p className="small_text__14 mt-3">
                      {convertCurrentDateWithTime(
                        getValue(item, `start_datetime`, "")
                      )}{" "}
                      {convertCurrentDateToTime(
                        getValue(item, `end_datetime`, "")
                      ) && (
                        <>
                          {" - "}
                          {convertCurrentDateToTime(
                            getValue(item, `end_datetime`, "")
                          )}
                        </>
                      )}
                  </p>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default TaskDetailLeftSection;
