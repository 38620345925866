import { getValue } from "@utils/lodash";
import { v4 as uuidv4 } from "uuid";
export const chatbotRequestPayload = (
  name: string,
  chatRequest: object,
  params: any,
  allChatSetupInfo: any,
  selectedFlowID: string,
  selectedHandleFlowID: string
) => {
  let nodesLength = getValue(allChatSetupInfo, `length`, 0);
  switch (name) {
    case "reply":
      return {
        id: getValue(chatRequest, `id`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(chatRequest, `action_id`, "")
          ? getValue(chatRequest, `action_id`, "")
          : getValue(params, `id`, ""),
        flow_id: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `flow_id`, "")
          : nodesLength > 0
          ? selectedFlowID
            ? selectedFlowID
            : getValue(
                allChatSetupInfo,
                `[${nodesLength - 1}].component`,
                ""
              ) === "suggestions"
            ? getValue(
                allChatSetupInfo,
                `[${nodesLength - 1}].rendering_config.suggestions[${0}].id`,
                ""
              )
            : getValue(allChatSetupInfo, `[${nodesLength - 1}].id`, "")
          : getValue(params, `id`, ""),
        criteria: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `criteria`, {})
          : {},
        name: getValue(chatRequest, `name`, ""),
        position: getValue(chatRequest, `position`, {}),
        seq_num: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `seq_num`, "")
          : nodesLength + 1,
        component: "reply",
        type: "reply",
        source_handle: selectedHandleFlowID,
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: getValue(chatRequest, `description`, ""),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          version: 1,
          _meta: {
            type: "reply",
          },
          behaviour: "goto_next_action",
        },
      };

    case "suggestions":
      return {
        id: getValue(chatRequest, `id`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(chatRequest, `action_id`, "")
          ? getValue(chatRequest, `action_id`, "")
          : getValue(params, `id`, ""),
        flow_id: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `flow_id`, "")
          : nodesLength > 0
          ? selectedFlowID
            ? selectedFlowID
            : getValue(
                allChatSetupInfo,
                `[${nodesLength - 1}].component`,
                ""
              ) === "suggestions"
            ? getValue(
                allChatSetupInfo,
                `[${nodesLength - 1}].rendering_config.suggestions[${0}].id`,
                ""
              )
            : getValue(allChatSetupInfo, `[${nodesLength - 1}].id`, "")
          : getValue(params, `id`, ""),
        criteria: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `criteria`, {})
          : {},
        position: getValue(chatRequest, `position`, {}),
        name: getValue(chatRequest, `name`, ""),
        seq_num: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `seq_num`, "")
          : nodesLength + 1,
        component: "suggestions",
        source_handle: selectedHandleFlowID,
        type: "reply",
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: getValue(chatRequest, `description`, ""),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          version: 1,
          _meta: {
            type: "reply",
          },
          behaviour: "wait_for_user_input",
          suggestions:
            getValue(chatRequest, `suggestions.length`, 0) > 0
              ? getValue(chatRequest, `suggestions`, []).map(
                  (item: object) => ({
                    ...item,
                    id: getValue(item, `id`, "")
                      ? getValue(item, `id`, "")
                      : uuidv4(),
                  })
                )
              : [],
        },
      };

    case "visitor_name":
    case "visitor_email":
    case "visitor_phone":
    case "company_name":
      return {
        id: getValue(chatRequest, `id`, ""),
        chatbot_config_id: getValue(params, `id`, ""),
        action_id: getValue(chatRequest, `action_id`, "")
          ? getValue(chatRequest, `action_id`, "")
          : getValue(params, `id`, ""),
        flow_id: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `flow_id`, "")
          : nodesLength > 0
          ? selectedFlowID
            ? selectedFlowID
            : getValue(
                allChatSetupInfo,
                `[${nodesLength - 1}].component`,
                ""
              ) === "suggestions"
            ? getValue(
                allChatSetupInfo,
                `[${nodesLength - 1}].rendering_config.suggestions[${0}].id`,
                ""
              )
            : getValue(allChatSetupInfo, `[${nodesLength - 1}].id`, "")
          : getValue(params, `id`, ""),
        criteria: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `criteria`, {})
          : {},
        name: getValue(chatRequest, `name`, ""),
        position: getValue(chatRequest, `position`, {}),
        seq_num: getValue(chatRequest, `id`, "")
          ? getValue(chatRequest, `seq_num`, "")
          : nodesLength + 1,
        component: name,
        type: "reply",
        source_handle: selectedHandleFlowID,
        rendering_config: {
          action: "reply",
          replies: [
            {
              text: getValue(chatRequest, `description`, ""),
              delay_config: getValue(chatRequest, `delay_config`, ""),
            },
          ],
          version: 1,
          _meta: {
            type: "reply",
          },
          behaviour: "wait_for_user_input",
        },
      };

    default:
      return {};
  }
};
