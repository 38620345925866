import * as React from "react";
import Loading from "./loading.json";
import Lottie from "react-lottie";
// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface IListLoaderProps {}

const ListLoader: React.FunctionComponent<IListLoaderProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loader-container-list">
      <div className="loader-wrapper__details">
        <Lottie
          options={defaultOptions}
          height={"350px"}
          width={"350px"}
          style={{marginTop:'65px'}}
        />
      </div>
    </div>
  );
};

export default ListLoader;
