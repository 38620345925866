import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const BotBuilderHeader = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const { activeTab, setActiveTab, isLoading, handleSubmit } = props;
  return (
    <div>
      {activeTab !== 4 && (
        <div className={"company-profile-subheader-wrapper"}>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <div
                onClick={() =>
                  navigate(`/${getValue(params, `orgId`, "")}/chatbot`)
                }
              >
                <BackSvgComponent />
              </div>
              <p className="small_text__16 ms-3">
                {activeTab === 1 && "Bot builder"}
                {activeTab === 2 && "Bot profile"}
                {activeTab === 3 && "Configurations"}
              </p>
            </div>
            <div className="d-flex align-items-center">
              {activeTab === 1 && (
                <ButtonComponent
                  buttonType="primary"
                  buttonText={"Next"}
                  onClickHandler={() => setActiveTab(3)}
                  // disabled={isLoading}
                />
              )}
              {activeTab === 2 && (
                <>
                  <ButtonComponent
                    buttonType="secondary"
                    buttonText={"Back"}
                    onClickHandler={() => setActiveTab(1)}
                    // disabled={isLoading}
                  />
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={"Next"}
                    onClickHandler={() => setActiveTab(3)}
                    // disabled={isLoading}
                  />
                </>
              )}
              {activeTab === 3 && (
                <>
                  <ButtonComponent
                    buttonType="secondary"
                    buttonText={"Back"}
                    onClickHandler={() => setActiveTab(1)}
                    // disabled={isLoading}
                  />
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={isLoading ? "Please wait..." : "Create"}
                    onClickHandler={() => handleSubmit()}
                    // disabled={isLoading}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BotBuilderHeader;
