import { Tooltip } from "react-tooltip";
import "./search.scss";
import { getValue } from "@utils/lodash";
import SearchSvgComponent from "@assets/svg/search";

export default function Search(props: any) {
  return (
    <section className="common-search-wrapper__filter d-flex align-items-center">
      {props.showSearchTextbox && (
        <>
          <div className="common-search-wrapper__filter-form-group form-group m-0 ease-effect mx-1">
            <input
              type="text"
              placeholder={
                getValue(props, `searchPlaceholder`, "")
                  ? getValue(props, `searchPlaceholder`, "")
                  : "Search"
              }
              className="common-search-wrapper__filter-search-input"
              value={getValue(props, `searchInput`, "")}
              onChange={props.handleChangeSearch}
              autoFocus
            />
            <img
              src="/images/search.svg"
              className="img-fluid common-search-wrapper__filter-input-image"
            />

            <img
              src="/images/close-black.svg"
              className="img-fluid common-search-wrapper__filter-image-close"
              onClick={() => {
                props.toggleSearchClose();
              }}
            />
          </div>
        </>
      )}

      <div
        className="common-search-wrapper__filter-container cursor-pointer"
        onClick={() => {
          props.toggleSearchClose();
        }}
      >
        <div className="d-flex search">
          {/* <img
            src="/images/search.svg"
            className="img-fluid common-search-wrapper__filter-image"
          /> */}
          <SearchSvgComponent color={'#1a73e8'} size={20}/>
        </div>
        {/* <h6 className="common-search-wrapper__filter-title">
          {props.searchText || "SEARCH"}
        </h6> */}
      </div>
      {props.calender && (
        <div
          className="common-search-wrapper__filter-container cursor-pointer"
          onClick={() => props.handleNavigateCalenderView()}
        >
          <div className="d-flex">
            <img
              src="/images/calendar_view.svg"
              className="img-fluid common-search-wrapper__filter-image"
            />
          </div>
          <h6
            className="common-search-wrapper__filter-title"
            style={{ color: "#1A73E8", fontSize: "10px" }}
          >
            {"CALENDAR VIEW"}
          </h6>
        </div>
      )}
      <Tooltip anchorSelect={`.search`} className="tooltip">
        {"Search"}
      </Tooltip>
    </section>
  );
}
