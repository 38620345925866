import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllContacts = (queryRequest: string) =>
  get(`${config.API_URL}/records/contacts?${queryRequest}`);

export const getListOfAllContacts = (queryRequest: string) =>
  get(`${config.API_URL}/records/contacts/list?${queryRequest}`);

export const getSpecificContact = (id: string) =>
  get(`${config.API_URL}/records/contacts/${id}`);

export const createContact = (payload: object) =>
  post(`${config.API_URL}/records/contacts`, payload);

export const updateContact = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/contacts/${id}`, payload);

export const deleteContact = (id: string) =>
  Delete(`${config.API_URL}/records/contacts/${id}`);

export const contactsBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/contacts/bulk-update`, payload);
