import React, { useState } from "react";
import ActivityMeetingPopup from "./popup/meeting-popup";
import { generatePayload } from "@components/helpers/request-helper";
import { getValue } from "@utils/lodash";
import { createMeeting } from "@services/meetings.service";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { toast } from "sonner";
import NotFoundDetail from "@components/common/NoData/Not-Found-Detail";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { useNavigate, useParams } from "react-router-dom";
import ActivityNavViews from "../components/nav-views";

function CommonDetailActivitiesMeetings(props: any) {
  const { fields, setFields } = props;
  const pendingLinkArray = [
    {
      title: "Meetings",
      linkUrl: "#",
      status: "active",
    },
    {
      title: "Calls",
      linkUrl: "#",
    },
  ];
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------   Meetings  ------------------------------- */
  const { isEventVisibility, setIsEventVisibility } = props;
  const [eventLoading, setEventLoading] = useState(false);
  const handleSubmitMeeting = async () => {
    try {
      let payload = generatePayload("meetings", fields, {});
      let requestPayload = {
        meeting: payload.meeting,
        associations: payload.associations.concat({
          api_name: props.module,
          record_id: getValue(props, `params.id`, ""),
        }),
      };
      let resp = await createMeeting({
        ...removeNullOrUndefinedProperties(requestPayload),
        // associations: [
        //   {
        //     api_name: props.module,
        //     record_id: getValue(props, `params.id`, ""),
        //   },
        // ],
      });
      if (resp) {
        toast.success("Created successfully");
        navigate(
          `/${getValue(props, `params.orgId`, "")}/${getValue(
            props,
            `module`,
            ""
          )}/detail/${getValue(
            props,
            `params.id`,
            ""
          )}?activeTab=3&activeActivitySubTab=1`
        );
        props.getData(false);
        setIsEventVisibility(false);
        setEventLoading(false);
        setFields([]);
      } else {
        setEventLoading(false);
      }
    } catch (error) {
      setEventLoading(false);
    }
  };
  return (
    <div>
      {getValue(urlParams, `activeTab`, "") == 3 && (
        <div className={`email_card px-3 p-2`}>
          <div className={`email_card_body`}>
            <div className="card_tabs">
              <ActivityNavViews pendingLinkArray={pendingLinkArray} />
            </div>
          </div>
          {(!getValue(urlParams, `activeActivitySubTab`, "") ||
            getValue(urlParams, `activeActivitySubTab`, "") == 1) &&
            (getValue(props, `data.meetings.length`, 0) > 0 ? (
              <div className="table-container">
                <table className={`common_detail_table mt-3`}>
                  <thead>
                    <tr>
                      <td>Title</td>
                      <td>Start Date</td>
                      <td>End Date</td>
                      <td>Outcome</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className={`card_table_body`}>
                    {getValue(props, `data.meetings`, []).map(
                      (item: object) => {
                        return (
                          <tr
                            onClick={() =>
                              navigate(
                                `/${getValue(
                                  params,
                                  `orgId`,
                                  ""
                                )}/meetings/detail/${getValue(item, `id`, "")}`
                              )
                            }
                          >
                            <td>
                              <div className="d-flex align-items-center">
                                <p className="ms-2">
                                  {getValue(item, `title`, "")}
                                </p>
                              </div>
                            </td>

                            <td>
                              <p className={`text`}>
                                {convertCurrentDateWithTime(
                                  getValue(item, `start_datetime`, "")
                                )}
                              </p>
                            </td>
                            <td>
                              <p className={`text`}>
                                {" "}
                                {convertCurrentDateWithTime(
                                  getValue(item, `end_datetime`, "")
                                )}
                              </p>
                            </td>
                            <td>
                              <p className={`text`}>
                                {" "}
                                {formatString(getValue(item, `outcome`, ""))}
                              </p>
                            </td>
                            <td>
                              <p className={`text`}>
                                {getValue(item, `description`, "")}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <NotFoundDetail title={"No meetings have been included."} />
            ))}
        </div>
      )}
      <ActivityMeetingPopup
        isOpen={isEventVisibility}
        setIsOpen={setIsEventVisibility}
        title={"Meetings"}
        width={"600px"}
        fields={fields}
        setFields={setFields}
        submitLoading={eventLoading}
        handleSubmit={handleSubmitMeeting}
      />
    </div>
  );
}

export default CommonDetailActivitiesMeetings;
