import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getValue } from "@utils/lodash";

function ModulesEditFields(props: any) {
  const { handleOnDragEnd, usedFields, unUsedFields } = props;
  return (
    <div className="drag_drop-main">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="container">
          <Droppable droppableId="source">
            {(provided) => (
              <div
                className="source-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <p className={"drag_drop_column_title"}>Selected Fields</p>{" "}
                {usedFields.map((item: any, index: number) => (
                  <Draggable
                    key={getValue(item, `name`, "")}
                    draggableId={getValue(item, `name`, "")}
                    index={index}
                    isDragDisabled={getValue(item, `mandatory`, false)}
                  >
                    {(provided) => (
                      <div
                        className="source-item d-flex justify-content-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          {!getValue(item, `mandatory`, false) ? (
                            <img
                              src="/images/icons/drag_indicator.svg"
                              height={20}
                              width={20}
                              style={{ marginRight: "15px" }}
                            />
                          ) : (
                            <img
                              src="/images/icons/stopdrag.svg"
                              height={20}
                              width={20}
                              style={{ marginRight: "15px" }}
                            />
                          )}
                          {item.label}
                        </div>
                        <button
                          onClick={() => props.handleVisibility(item)}
                          className={"drag_drop_button"}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className={"drag_drop_separator"}></div>
          <Droppable droppableId="target">
            {(provided) => (
              <div
                className="target-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <p className={"drag_drop_column_title"}>Unused Fields</p>
                {unUsedFields.map((item: any, index: number) => (
                  <Draggable
                    key={getValue(item, `name`, "")}
                    draggableId={getValue(item, `name`, "")}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="target-item d-flex justify-content-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          {!getValue(item, `mandatory`, false) ? (
                            <img
                              src="/images/icons/drag_indicator.svg"
                              height={20}
                              width={20}
                              style={{ marginRight: "15px" }}
                            />
                          ) : (
                            <img
                              src="/images/icons/stopdrag.svg"
                              height={20}
                              width={20}
                              style={{ marginRight: "15px" }}
                            />
                          )}
                          {item.label}
                        </div>
                        <button
                          onClick={() => props.handleVisibility(item)}
                          className={"drag_drop_button"}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
}

export default ModulesEditFields;
