import AddSvgComponent from "@assets/svg/add";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import DoneSvgComponent from "@assets/svg/done";
import EditSvgComponent from "@assets/svg/edit";
import PenSvgComponent from "@assets/svg/pen";
import TagSvgComponent from "@assets/svg/tag";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import MultiSearchToolTip from "@components/custom/Dropdown/MultiSearchTooltip_ReactjsPopup";
import TagsDetailList from "@components/custom/Dropdown/tags/tags-detail-list";
import TagsMultiDropdown from "@components/custom/TagsDropdown";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

function TagPopup(props) {
  const POSITION_TYPES = [
    "bottom center",
    "top center",
    // "center center",
    // "bottom right",
    // "bottom center",
    // "left top",
    // "left bottom",
    // "right top",
    // "top left",
    // "top center",
    // "right center",
    // "right bottom",
    // "bottom left",
    // "left center",
    // "center center",
  ];

  const popupRef = useRef();
  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };
  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      <div className="d-flex align-items-center mb-1 justify-content-between w-100">
        <div className="d-flex align-items-center">
          <TagSvgComponent size={20} />
          <p className="ms-2 header_text__14 tag_text">Tags</p>
        </div>
        <div className="d-flex notes_action_container mx-1">
          <div className="image_hover_background">
            <EditSvgComponent color={"#7890a1"} />
          </div>
        </div>
      </div>
    </div>
  ));
  useEffect(() => {
    if (props.clicked) {
      handleClose();
      props.setClicked(false);
    }
  }, [props.clicked]);

  const [colors] = useState([
    "#D5F5E3",
    "#F2D7D5",
    "#FADBD8",
    "#EBDEF0",
    "#D4E6F1",
    "#D1F2EB",
    "#A9DFBF",
    "#EC7063",
    "#F9E79F",
    "#FAD7A0",
    "#F5CBA7",
    "#EDBB99",
    "#E5E7E9",
    "#CCD1D1",
    "#D5D8DC",
    "#A3E4D7",
  ]);

  const [isActive, setIsActive] = useState(false);
  return (
    // <Popup
    //   trigger={(open) => <CustomButtons open={open} header={props.header} />}
    //   position={POSITION_TYPES}
    //   closeOnDocumentClick
    //   contentStyle={{
    //     width: "400px",
    //     minHeight: "120px",
    //     zIndex: 9999,
    //     background: "white",
    //     paddingTop: "20px",
    //     boxShadow:
    //       "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
    //   }}
    //   arrow
    //   nested
    //   ref={popupRef}
    //   onOpen={handleOpen}
    // >
    <div>
      <div className="d-flex justify-content-between align-items-center cursor-pointer">
        <div className="d-flex align-items-center mb-1 justify-content-between w-100">
          <div className="d-flex align-items-center">
            <TagSvgComponent size={20} />
            <p className="ms-2 header_text__14 tag_text">Tags</p>
          </div>
        </div>
      </div>
      {isActive ? (
        <div
          className="tag-popup-container position-relative"
          style={{ width: props.width }}
        >
          <TagsMultiDropdown
            // width={"340px"}
            label="label"
            values={props.values}
            onChange={props.handleSelectChange}
            data={props.tags}
            setData={props.setAllTags}
            handleSubmitTags={props.handleSubmitTags}
            handleAddTags={props.handleAddTags}
            obj={props.obj}
            popupRef={popupRef}
          />
          <div className="tag-popup-button-container pb-3 pt-3">
            <button
              className="main_button__secondary"
              onClick={() => setIsActive(!isActive)}
            >
              Cancel
            </button>
            <button
              className="main_button__primary"
              // disabled={getValue(props, `obj.label`, "") ? true : false}
              onClick={() => {
                props.handleUpdateTags();
                setIsActive(!isActive);
              }}
            >
              {props.submitLoading ? "Please wait..." : "Update"}
            </button>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setIsActive(!isActive)}
          className=" border-radius__5 p-2 cursor-pointer"
        >
          {getValue(props, `tagsList.length`, 0) > 0 ? (
            <div className="d-flex align-items-center">
              <TagsDetailList tags={getValue(props, `tagsList`, [])} />
              <div className="mb-1">
              <AddCircleSvgComponent color={"lightgray"} size={20}/>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <AddCircleSvgComponent size={20} color={"#7890a1"} />
              <p className="ms-2 header_text__14 tag_text">Add Tags</p>
            </div>
          )}
        </div>
      )}
    </div>
    // </Popup>
  );
}

export default TagPopup;
