
import Organisations from '@pages/Private/Organisations';
import CreateOrganisations from '@pages/Private/Organisations/CreateOrgnisation';

export const OrganizationRoutes = [
	{
		path: '/',
		name: 'Organisations',
		component: Organisations,
	},
	{
		path: '/organisations',
		name: 'Organisations',
		component: Organisations,
	},
  {
		path: '/organisations/create',
		name: 'Organisations',
		component: CreateOrganisations,
	},
]