import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";

const PropertyNotes = () => {
  const [buttonTypeEnum] = useState([
    { value: "Booking_notes", label: "Booking notes" },
    { value: "Customer_notes", label: "Customer notes" },
  ]);

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const toggleNotesModal = () => {
    setOpenNotesModal(!openNotesModal);
  };

  return (
    <div>
      <div className="d-flex  align-items-center  radio_button_container justify-content-center border-bottom pb-4">
        {buttonTypeEnum.map((item: object) => {
          return (
            <div
              // onClick={() =>
              //   setButtonType(getValue(item, `value`, ""))
              // }
              className="d-flex align-items-center mx-2 "
            >
              <input
                type="radio"
                className="input-radio"
                //   checked={
                //     selectedType === getValue(item, `value`, "")
                //       ? true
                //       : false
                //   }
              />
              <div className=" ms-1 small_text__14">
                <p>{getValue(item, `label`, "")}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="d-flex justify-content-between mb-3 mt-4">
          <h3 className="header_text__18">Booking Notes</h3>
          <ButtonComponent
            buttonType={"primary"}
            buttonText={"Add"}
            onClickHandler={toggleNotesModal}
          />
        </div>
        <p className="color_desc small_text__14">No booking notes available</p>
      </div>

      <Modal
        isOpen={openNotesModal}
        toggle={toggleNotesModal}
        style={{ zIndex: "1101" }}
      >
        <section className="add-popup-wrapper">
          <ModalHeader>
            <h6 className="header_text__16">Confirm</h6>
          </ModalHeader>
          <div className="">
            <div className="">
              <p className="header_text__14 mt-3 mb-4 text-left px-3">
                Are you certain you wish to proceed with saving as a draft?
              </p>
            </div>
          </div>
          <ModalFooter>
            <div className="popup-submit-container">
              <ButtonComponent
                //   onClickHandler={props.handleCancel}
                buttonType={"scecondary"}
                buttonText="Cancel"
              />
              &nbsp;&nbsp;&nbsp;
              <ButtonComponent
                buttonType={"primary"}
                buttonText={"Save as Draft"}
                //   onClickHandler={() => props.handleSubmit()}
              />
            </div>
          </ModalFooter>
        </section>
      </Modal>
    </div>
  );
};

export default PropertyNotes;
