import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { getValue } from "@utils/lodash";
import { concatString, formatString } from "common/text-helpers";
import Popup from "reactjs-popup";
import { QueryRequestHelper } from "common/query-request-helper";
import { motion } from "framer-motion";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getLookupAPIs } from "../../helpers/lookup-apis";

const CountrySearchToolTip = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    width,
    selectKey,
    name,
    data,
    isLoading,
    disabled,
    mandatory,
    required,
    errorLabel,
    concatLength,
    hideLabel,
    module_id,
  } = props;
  const ref = useRef();
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
  }, [getValue(props, `lookup_api`, "")]);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (popupRef.current) {
        popupRef.current.close();
      }
    } else {
    }
  };
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const handleClickOption = (option) => {
    if (!getValue(props, `disabled`, false)) {
      onChange && onChange(option);
    }
    setInputChange(false);
    setQuery("");
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  const onClickAdd = () => {
    props.handleOpenPopup();
  };
  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const [dataInfo, setData] = useState([]);
  const [isLoadingInfo, setIsLoading] = useState(true);
  const handleFetchData = async (search_text) => {
    if (search_text)
      try {
        let payload = {
          search: search_text,
          page_no: 1,
          page_size: 10,
          forModule: props.forModule,
          module_id: props.module_id,
        };
        setIsLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getLookupAPIs(
          getValue(props, `lookup_api`, ""),
          queryRequest
        );
        if (resp) {
          setData(resp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    else onChange(null);
  };
  const fetchDefaultData = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 10,
        forModule: props.forModule,
        module_id: props.module_id,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getLookupAPIs(
        getValue(props, `lookup_api`, ""),
        queryRequest
      );
      if (resp) {
        setData(resp);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const filter = (options) => {
    if (!options || options.length === 0) {
      return [];
    }
    return options.filter((option) => {
      const value = option["name"] + option["dial_code"] + option["label"];
      if (!value) {
        return false;
      }
      return value.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });
  };
  const findSelectedValue = (id) => {
    let list =
      getValue(data, `length`, 0) > 0
        ? data.find(
            (item) =>
              getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
          )
        : [];
    return getValue(list, `label`, "")
      ? `${getValue(list, `flag`, "")}${" "}${" "}${getValue(
          list,
          `label`,
          ""
        )}`
      : "";
  };
  const valueLabel = useMemo(() => findSelectedValue(value), [
    value,
    data,
    dataInfo,
    getValue(data, `length`, 0) > 0,
    getValue(dataInfo, `length`, 0) > 0,
    getValue(props, `custom_type`, ""),
  ]);

  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
    if (popupRef.current) {
      popupRef.current.close();
    }
    document.body.style.overflow = "auto";
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
    setClick(true);
    document.body.style.overflow = "hidden";
  };
  const POSITION_TYPES = [
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "bottom right",
    "left center",
    "center center",
  ];
  /* -------------------------------------------------------------------------- */
  /*                                Scroll section                              */
  /* -------------------------------------------------------------------------- */
  const selectedOptionRef = useRef(null);
  const [click, setClick] = useState(false);
  // Scroll to the selected option when the component updates
  useEffect(() => {
    if (selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ behavior: "instant" });
      setClick(false);
    }
  }, [click]);
  /* -------------------------------------------------------------------------- */
  /*                           Dynamic Width section                            */
  /* -------------------------------------------------------------------------- */
  const [parentWidth, setParentWidth] = useState(null);
  const div = useCallback((node) => {
    if (node !== null) {
      setParentWidth(node.getBoundingClientRect().width);
    }
  });
  let defaultKey =
    getValue(props, `name_key`, "") &&
    getValue(props, `name_key`, "").split("_")[0];
  return (
    <>
      <div className={"dropdown-select-form"} ref={div}>
        {!hideLabel && name && <label className="form-label">{name} </label>}
        <Popup
          trigger={(open) => (
            <div
              className={`${
                props.editInline
                  ? "popup-selected-value-box__inline"
                  : "popup-selected-value-box"
              } ${open && !disabled ? "active" : ""}`}
              style={
                props.validator && getValue(props, `required`, false) && !value
                  ? {
                      width: width,
                      marginLeft: props.add && "10px",
                      background: disabled ? "#F8F9F9" : "",
                      borderLeft:
                        getValue(props, `required`, false) ||
                        getValue(props, `hasRequired`, false)
                          ? "2px solid red"
                          : "",
                    }
                  : {
                      width: width,
                      marginLeft: props.add && "10px",
                      background: disabled ? "#F8F9F9" : "",
                      borderLeft:
                        getValue(props, `required`, false) ||
                        getValue(props, `hasRequired`, false)
                          ? "2px solid red"
                          : "",
                    }
              }
            >
              <div>
                {valueLabel ? (
                  <p className="value">
                    {concatString(valueLabel, concatLength ? concatLength : 40)}
                  </p>
                ) : (
                  <p className="placeholder_text">
                    {getValue(props, `placeholder`, "")
                      ? getValue(props, `placeholder`, "")
                      : "Select"}
                  </p>
                )}
              </div>
              {!getValue(props, `phoneInline`, false) && (
                <div className="right_container">
                  <div className="arrow_image_container">
                    <img
                      src={
                        open && !disabled
                          ? "/dropdown/arrow_up.svg"
                          : "/dropdown/arrow_down.svg"
                      }
                      className="arrow_image"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          position={POSITION_TYPES}
          ref={popupRef}
          contentStyle={{
            width: "280px",
            marginTop: "5px",
            padding: "0",
            zIndex: 9999,
            display: disabled && "none",
          }}
          onOpen={handleOpen}
          on={"click"}
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          onClose={() => popupRef.current.close()}
          lockScroll
          type="tooltip"
          arrow={props.arrow ? props.arrow : false}
        >
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className={`dropdown-select-form options ${isOpen ? "open" : ""}`}
              style={{}}
            >
              <div className="popup_search_container">
                <img src={"/dropdown/search.svg"} className="search_image" />
                <input
                  className="search_input"
                  ref={inputRef}
                  autoFocus
                  type="text"
                  value={query}
                  name="searchTerm"
                  autoComplete="off"
                  placeholder={getValue(props, `placeholder`, "search here...")}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    handleFetchData(e.target.value);
                  }}
                  disabled={disabled}
                />
                {query && (
                  <div className="input_close_image_container">
                    <img
                      src={"/dropdown/close.svg"}
                      className="input_close_image"
                      onClick={(e) => {
                        setQuery("");
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="popup_option_container">
                  {isLoading ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <img src={`/dropdown/loader1.gif`} className="loader" />
                    </div>
                  ) : filter(data).length > 0 ? (
                    filter(data).map((option, index) => {
                      return (
                        <div
                          onClick={() => {
                            handleClickOption(option);
                          }}
                          className={`popup_options ${
                            getValue(
                              option,
                              `[${selectKey ? selectKey : "id"}]`,
                              ""
                            ) == value
                              ? "selected"
                              : ""
                          }`}
                          key={`${id}-${index}`}
                          ref={
                            getValue(
                              option,
                              `[${selectKey ? selectKey : "id"}]`,
                              ""
                            ) === value
                              ? selectedOptionRef
                              : null
                          }
                        >
                          <div className="selected_text hover-text">
                            {getValue(option, `flag`, "")}
                            <span className="ms-2">
                              {getValue(option, `name`, "")}
                            </span>
                            <span className="ms-2">
                              {"( "}
                              {getValue(option, `dial_code`, "")}
                              {" )"}
                            </span>
                          </div>
                          {getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value && (
                            <img
                              src="/dropdown/done.svg"
                              className="done_image"
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="nodata">No Data Found</div>
                  )}
                </div>
              </div>
              {getValue(props, `add`, "") ? (
                <div
                  className="button_container touchable-text"
                  onClick={(e) => {
                    handleClose(e);
                    onClickAdd();
                  }}
                >
                  <img src={"/dropdown/add.svg"} className="button_image" />
                  <p className="button_input">
                    {getValue(props, `button_title`, "")}
                  </p>
                </div>
              ) : null}
            </div>
          </motion.div>
        </Popup>
      </div>
      {props.validator && (
        <p className={"form-error"}>
          {props.validator.current.message(
            errorLabel ? errorLabel : getValue(props, `name`, ""),
            props.value,
            "required"
          )}
        </p>
      )}
    </>
  );
};

export default CountrySearchToolTip;
