import BackSvgComponent from "@assets/svg/back-link";
import FormBuilder from "@components/Builder/FormBuilder";
import ButtonComponent from "@components/Form/Button/Button";
import { useState } from "react";
import ".././index.scss";
import { Tooltip } from "react-tooltip";

const devices = [
  {
    width: 46,
    backgroundWidth: 100,
    icon: "/images/icons/desktop.png",
    className: "desktop-device",
    label: "Desktop",
  },
  {
    width: 90,
    backgroundWidth: 50,
    icon: "/images/icons/tablet.png",
    className: "tablet-device",
    label: "Tablet",
  },
  {
    width: 90,
    backgroundWidth: 30,
    icon: "/images/icons/smartphone.png",
    className: "mobile-device",
    label: "Mobile",
  },
];

function PreviewScreen(props: any) {
  const handleSubmit = () => {};
  const [width, setWidth] = useState(46);
  const [backgroundWidth, setBackgroundWidth] = useState(100);

  const handleDeviceChange = (newWidth: number, newBackgroundWidth: number) => {
    setWidth(newWidth);
    setBackgroundWidth(newBackgroundWidth);
  };
  return (
    <div>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center justify-content-center">
            <div onClick={() => props.setActiveTab(1)}>
              <BackSvgComponent />
            </div>
            <p
              className={`small_text__16 ms-3`}
            >
              Preview
            </p>
          </div>
          <div className="my-4 d-flex gap-3 justify-content-center">
            {devices.map((device, index) => (
              <button
                key={index}
                onClick={() =>
                  handleDeviceChange(device.width, device.backgroundWidth)
                }
                className={`responsive-devices ${device.className}`}
              >
                <img src={device.icon} alt={device.label} />
                <Tooltip
                  className="tooltip"
                  anchorSelect={`.${device.className}`}
                  place="bottom"
                >
                  {device.label}
                </Tooltip>
              </button>
            ))}
          </div>
          <div></div>
        </div>
      </div>

      <div
        className="manageFieldRight w-100 d-flex justify-content-start flex-column align-items-center add-overflow-y"
      >
        <div
          style={{ width: `${backgroundWidth}%` }}
          className="responsive-animation"
        >
          <div
            style={{ background: "#eaeef2" }}
            className="w-100 d-flex justify-content-center"
          >
            <div className="pb-4 d-flex flex-column pt-4" style={{ width: `${width}%` }}>
              <FormBuilder
                fields={props.formFields}
                setFields={props.setFormFields}
              />
              <div className="preview-b-radius  pb-5 pt-3 bg-white d-flex justify-content-center">
                <div className="desktop-size">
                  <ButtonComponent
                    buttonType={"primary"}
                    buttonText={"Save"}
                    onClickHandler={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewScreen;
