import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import React from "react";

const BotBuilderStepThree = (props: any) => {
  const { request, setRequest } = props;
  const intervelTimeList = [
    { value: 1, label: "1 Second" },
    { value: 2, label: "2 Seconds" },
    { value: 3, label: "3 Seconds" },
  ];
  return (
    <div>
      {/* <div className="mb-5">
        <p className="header_text__18">Choose bot audience</p>
        <p className="pending-task-commondescription mt-1">
          Select the type of visitor to initiate proactive conversation.
        </p>
        <div className="d-flex gap-3 align-items-center mt-2">
          <ImportCheckbox
            value="All visitors"
            label="All visitors"
            description="The bot will assist all visitors who land on your website."
            // iconPath={"/images/icons/code-64.png"}
            selectedOption={selectedVisitor}
            // onChange={handleChange}
          />
          <ImportCheckbox
            value="Custom visitors"
            label="Custom visitors"
            description="The bot assist visitors if they match the set condition and criteria."
            // iconPath={"/images/icons/code-64.png"}
            selectedOption={selectedVisitor}
            // onChange={handleChange}
          />
        </div>
      </div> */}
      {/* <div className="mt-5">
        <p className="header_text__18">When should the bot initiate chat?</p>
        <p className="pending-task-commondescription mt-1">
          Choose channels to deploy your bot
        </p>
        <div className="d-flex gap-2 align-items-center">
          <CustomCheckbox />
          <label>When visitors land on the site</label>
        </div>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox />
          <label>When visitors click the chat widgetobile</label>
        </div>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox />
          <label>When visitors perform the custom action</label>
        </div>
      </div> */}
      <div className="">
        <p className="header_text__18">Response interval</p>
        <p className="pending-task-commondescription mt-1">
          Add an interval to your bot's response time during interactive chats
          for a more natural conversational flow.
        </p>
        <SearchToolTip
          placeholder="Choose a time"
          data={intervelTimeList}
          label="label"
          value={getValue(request, `response_interval`, "")}
          selectKey={"value"}
          name=''
          required
          onChange={(e: any) =>
            setRequest({
              ...request,
              response_interval: getValue(e, `value`, ""),
            })
          }
        />
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <p className="header_text__18">Idle chat handling</p>
          <label className={"switch"}>
            <input
              type="checkbox"
              checked={getValue(request, `initiate_chat_on_site_land`, false)}
              onChange={() =>
                setRequest({
                  ...request,
                  initiate_chat_on_site_land: !getValue(
                    request,
                    `initiate_chat_on_site_land`,
                    false
                  ),
                })
              }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
        <p className="pending-task-commondescription mt-1">
          Configure follow-up actions if the bot did not receive a reply for the
          specified duration.
        </p>
      </div>
      {/* <div className="mb-5">
        <p className="header_text__18">Reminder</p>
        <p className="pending-task-commondescription mt-1">
          Select the type of visitor to initiate proactive conversation.
        </p>
        <p className="pending-task-commondescription mt-4">
          Send first message in:
        </p>
        <SearchToolTip placeholder="Choose a time" />
        <p className="pending-task-commondescription mt-4">Language:</p>
        <SearchToolTip placeholder="Choose language" />
        <p className="pending-task-commondescription mt-4">Message:</p>
        <textarea
          className="tasks_notes-textarea w-100"
          placeholder="Enter notes"
          name="comment"
          // onChange={(e) => props.setComment(e.target.value)}
          // value={props.comment}
          autoFocus
          rows={5}
          value={`{
                    "replies": [
                      {
                        "text": "Is there anything else I might help you with today"
                      }
                    ]
                  }`}
        />
        <p className="touchable-text header_blue_text__16 mt-3">
          Add another message
        </p>
      </div>
      <div className="mb-5">
        <p className="header_text__18">Chat timeout</p>
        <p className="pending-task-commondescription mt-1">
          Select an action for the bot to initiate when the user fails to
          respond to the reminders.
        </p>
        <p className="pending-task-commondescription mt-4">End chat in:</p>
        <SearchToolTip placeholder="Choose a time" />
        <p className="pending-task-commondescription mt-4">Language:</p>
        <SearchToolTip placeholder="Choose language" />
        <p className="pending-task-commondescription mt-4">Message:</p>
        <textarea
          className="tasks_notes-textarea w-100"
          placeholder="Enter notes"
          name="comment"
          // onChange={(e) => props.setComment(e.target.value)}
          // value={props.comment}
          autoFocus
          rows={5}
          value={`{
	"replies": [
		{
			"text": "Is there anything else I might help you with today"
		}
	]
}`}
        />
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <p className="header_text__18">Allow hand off to operators</p>
          <label className={"switch"}>
            <input
              type="checkbox"
              checked
              //   onChange={() =>
              //     updateUserStatus(
              //       getValue(item, `id`, ""),
              //       !getValue(item, `is_admin`, false)
              //     )
              //   }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
        <p className="pending-task-commondescription mt-2">
          Let visitors opt to connect with human operators during an ongoing bot
          conversation.
        </p>
        <InputRuleForm
          //   label="Name your Bot"
          inputType="TEXT"
          name="name"
          placeholder="Enter a Name"
          className="pb-2"
          // onChange={(e: any) =>
          //   setRequest({
          //     ...request,
          //     name: e.target.value,
          //   })
          // }
          value="Connect with a human?"
        />
        <div className="mt-3">
          <SearchToolTip placeholder="Choose " className="pt-3" />
        </div>
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <p className="header_text__18">Forward message</p>
          <label className={"switch"}>
            <input
              type="checkbox"
              //   onChange={() =>
              //     updateUserStatus(
              //       getValue(item, `id`, ""),
              //       !getValue(item, `is_admin`, false)
              //     )
              //   }
            />
            <span className={`${"slider"} ${`round`} d-flex`}></span>
          </label>
        </div>
        <p className="pending-task-commondescription mt-2">
          Display a message right after the chat is forwarded to a human
          operator
        </p>
        <div className="mt-3">
          <SearchToolTip placeholder="Choose " className="pt-3" />
        </div>
        <p className="pending-task-commondescription mt-4">Message:</p>
        <textarea
          className="tasks_notes-textarea w-100"
          placeholder="Enter notes"
          name="comment"
          // onChange={(e) => props.setComment(e.target.value)}
          // value={props.comment}
          autoFocus
          rows={5}
          value={`Enter your forward message here`}
        />
        <p className="pending-task-commondescription mt-4">
          Type % for dynamic text suggestions
        </p>
      </div>
      <div className="mt-5">
        <p className="header_text__18">Bot conversation actions</p>
        <p className="pending-task-commondescription mt-1">
          Allow visitors to go back to the previous message in the bot
          conversation or restart the conversation if required.
        </p>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox />
          <label>Rollback to the previous message</label>
        </div>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox />
          <label>Restart conversation</label>
        </div>
      </div> */}
    </div>
  );
};

export default BotBuilderStepThree;
