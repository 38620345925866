import Select from "react-select";
import "./form.scss";
import { getValue } from "@utils/lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import { convertDateTime } from "common/date-helpers";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: any;
  label: string;
  validator: any;
  placeholder: string;
  mandatory: boolean;
  maxDate: any;
  minDate: any;
  hideLabel: boolean;
  inline: boolean;
};

export default function MainTimePicker({
  value,
  onChange,
  validator,
  label,
  placeholder,
  mandatory,
  hideLabel,
  inline,
}: Props) {
  return (
    <div
      className={
        inline
          ? ""
          : `signup-form-wrapper__form-group ${
              validator && validator.current.message(label, value, "required")
                ? "signup-form-wrapper__form-group--error"
                : ""
            } form-group mb-3`
      }
    >
      {!hideLabel && (
        <label className="form-label ">
          {label}{" "}
          {/* {mandatory && <span className="color_danger small_text__16">*</span>} */}
        </label>
      )}
      <div>
        <DatePicker
          className={
            mandatory ? `custom-form-control__required` : `custom-form-control`
          }
          placeholderText={placeholder}
          selected={value ? moment(value).toDate() : null}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15} // Set the time interval (in minutes)
          dateFormat="h:mm aa" // Display format for the time
        />
      </div>
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(label, value, "required")}
        </p>
      )}
    </div>
  );
}
