import { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import CustomNode from "./components/CustomNode";
import "reactflow/dist/style.css";
import "./index.scss";
import {
  deleteChatbotSetup,
  getSpecificChatbot,
} from "@services/chatbot.service";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getAllChatbotSetup } from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { createChatbotSetup } from "@services/chatbot.service";
import {
  tempButtonRequest,
  tempCompanyNameRequest,
  tempEmailRequest,
  tempNameRequest,
  tempPhoneRequest,
  tempSendMessageRequest,
} from "./helpers/flow-payload";
import { sortJSONObjectArray } from "@common/text-helpers";
import {
  getChatbotRequest,
  handleChatbotRequest,
} from "./helpers/request-helper";
import { chatbotRequestPayload } from "./helpers/payload-helper";
import _ from "lodash";
import { toast } from "sonner";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import ButtonEdge from "./components/ButtonEdge";
import ModifyNodePopup from "./popup/modify-node-popup";
import AddNodePopup from "./popup/add-node-popup";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { edgeRequestHelper } from "./helpers/edge-helper";
import { nodeRequestHelper } from "./helpers/node-helper";
import RocketSvgComponent from "@assets/svg/rocket";
const nodeTypes = {
  customNode: (props: any) => <CustomNode {...props} />,
};
const edgeTypes = {
  buttonedge: ButtonEdge,
};

export default function ChatbotBuilder() {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [nodes, setNodes] = useNodesState<any>([]);
  const [edges, setEdges] = useEdgesState<any>([]);
  const [selectedNode, setSelectedNode] = useState(null);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getData();
    getChatbotSetupInfo();
  }, []);

  const [botInfo, setBotInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificChatbot(getValue(params, `id`, ""));
      if (resp) {
        setBotInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* ----------------------------  Chatbot Setup  --------------------------- */

  const [allChatSetupInfo, setAllChatSetupInfo] = useState([]);
  const [chatRequest, setChatRequest] = useState<any>(null);
  const getChatbotSetupInfo = async () => {
    try {
      let payload = {
        chatbot_config_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbotSetup(queryRequest);
      if (resp) {
        let list = sortJSONObjectArray(getValue(resp, `data`, []), "seq_num");
        setAllChatSetupInfo(list);
        if (getValue(resp, `data.length`, 0) > 0) {
          let edges = edgeRequestHelper(list, handleDeleteEdge);
          let nodes = nodeRequestHelper(
            list,
            handleDeleteNode,
            edges,
            addNodeByID
          );
          setNodes(nodes);
          setEdges(edges);
        }
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                         Reactflow Helper Section                           */
  /* -------------------------------------------------------------------------- */

  const onEdgesChange = (changes: any) => {
    const filteredData = _.filter(changes, (item) => item.type !== "remove");
    if (getValue(filteredData, `length`, 0) > 0) {
      setEdges((eds) => {
        const newEdges = applyEdgeChanges(changes, eds);
        return newEdges;
      });
    }
  };

  const onNodesChange = (changes: any) => {
    const filteredData = _.filter(changes, (item) => item.type !== "remove");
    if (getValue(filteredData, `length`, 0) > 0) {
      setNodes((nds) => {
        const newNodes = applyNodeChanges(changes, nds);
        return newNodes;
      });
    }
  };

  const onConnect = useCallback(
    async (connectParams: any) => {
      let selectedEdgeInfo: any = allChatSetupInfo.find(
        (item: object) =>
          getValue(item, `id`, "") === getValue(connectParams, `target`, "")
      );
      let obj = getChatbotRequest(
        getValue(selectedEdgeInfo, `component`, ""),
        selectedEdgeInfo,
        params
      );
      let payload: any = chatbotRequestPayload(
        getValue(obj, `component`, ""),
        obj,
        params,
        allChatSetupInfo,
        selectedFlowID,
        selectedHandleFlowID
      );
      payload["flow_id"] = getValue(connectParams, `source`, "");
      payload["source_handle"] = getValue(connectParams, `sourceHandle`, "");
      console.log(connectParams);
      console.log(edges);
      let selectedEdge: any =
        getValue(edges, `length`, 0) > 0
          ? edges.find((item: object) =>
              getValue(connectParams, `sourceHandle`, "")
                ? getValue(item, `sourceHandle`, "") ===
                  getValue(connectParams, `sourceHandle`, "")
                : getValue(item, `source`, "") ===
                  getValue(connectParams, `source`, "")
            )
          : {};
      if (!getValue(selectedEdge, `id`, "")) {
        let resp = await createChatbotSetup(payload);
        if (resp) {
          getChatbotSetupInfo();
          toast.success("Updated sucessfully");
        }
      } else {
        toast.error("Multiple connections will not be supported");
      }
    },
    [setEdges, nodes]
  );

  let proOptions = { hideAttribution: true };
  const defaultViewport = { x: 100, y: 80, zoom: 0.8 };

  /* -------------------------------------------------------------------------- */
  /*                                Modal Section                               */
  /* -------------------------------------------------------------------------- */
  const [openNodeModal, setOpenNodeModal] = useState(false);
  const handleNodeModal = () => {
    setOpenNodeModal(!openNodeModal);
  };

  const [openAddNodeModal, setOpenAddNodeModal] = useState(false);
  const handleAddNodeModal = () => {
    setOpenAddNodeModal(!openAddNodeModal);
  };

  const navigateBack = () => {
    setOpenNodeModal(false);
    setOpenAddNodeModal(true);
  };
  /* -------------------------------------------------------------------------- */
  /*                               OnChange Section                             */
  /* -------------------------------------------------------------------------- */
  const addNode = (name: string) => {
    switch (name) {
      case "send_message":
        setChatRequest(tempSendMessageRequest);
        break;
      // case "share_link":
      //   setChatRequest(tempSendLinkRequest);
      //   break;
      case "suggestions":
        setChatRequest(tempButtonRequest);
        break;

      case "visitor_name":
        setChatRequest(tempNameRequest);
        break;
      case "visitor_email":
        setChatRequest(tempEmailRequest);
        break;
      case "visitor_phone":
        setChatRequest(tempPhoneRequest);
        break;
      case "company_name":
        setChatRequest(tempCompanyNameRequest);
        break;

      default:
        setChatRequest({});
    }
    setOpenAddNodeModal(false);
    setOpenNodeModal(true);
  };

  const onNodeClick = (event: any, node: any) => {
    setSelectedNode(node);
    let findSelectedNode: any = allChatSetupInfo.find(
      (item: object) => getValue(item, `id`, "") === getValue(node, `id`, "")
    );
    setSelectedFlowID(getValue(node, `flow_id`, ""));
    setSelectedHandleFlowID(getValue(node, `source_handle`, ""));
    handleChatbotRequest(
      getValue(findSelectedNode, `component`, ""),
      chatRequest,
      setChatRequest,
      findSelectedNode,
      params
    );
    handleNodeModal();
  };

  const closeSettingsPanel = () => {
    setChatRequest(null);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Section                               */
  /* -------------------------------------------------------------------------- */

  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteNode = (id: string) => {
    setDeleteId(id);
    setIsOpen(true);
  };
  const handleDelete = async () => {
    try {
      let resp = await deleteChatbotSetup(deleteId);
      if (resp) {
        toast.success("Deleted sucessfully");
        getChatbotSetupInfo();
        handleModal();
      }
    } catch (error) {}
  };

  const [deleteId1, setDeleteId1] = useState("");
  const [deleteValue1, setDeleteValue1] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteEdge = (id: string) => {
    setDeleteId1(id);
    setIsOpen1(true);
  };

  const handleDeleteEdges = async () => {
    let selectedEdgeInfo: any = allChatSetupInfo.find(
      (item: object) => getValue(item, `id`, "") === deleteId1
    );
    let obj = getChatbotRequest(
      getValue(selectedEdgeInfo, `component`, ""),
      selectedEdgeInfo,
      params
    );
    let payload = chatbotRequestPayload(
      getValue(obj, `component`, ""),
      obj,
      params,
      allChatSetupInfo,
      selectedFlowID,
      selectedHandleFlowID
    );
    payload["flow_id"] = "";
    let resp = await createChatbotSetup(payload);
    if (resp) {
      setChatRequest(null);
      getChatbotSetupInfo();
      toast.success("Updated sucessfully");
      handleModal1();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Submit Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitNode = async () => {
    try {
      setSubmitLoading(true);
      let payload = chatbotRequestPayload(
        getValue(chatRequest, `component`, ""),
        chatRequest,
        params,
        allChatSetupInfo,
        selectedFlowID,
        selectedHandleFlowID
      );
      let resp = await createChatbotSetup(payload);
      if (resp) {
        setChatRequest(null);
        getChatbotSetupInfo();
        setSubmitLoading(false);
        toast.success("Updated sucessfully");
        setSelectedFlowID("");
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [selectedFlowID, setSelectedFlowID] = useState("");
  const [selectedHandleFlowID, setSelectedHandleFlowID] = useState("");

  const addNodeByID = (id: string, handleId: string) => {
    setSelectedFlowID(id);
    setSelectedHandleFlowID(handleId);
    handleAddNodeModal();
  };

  return (
    <>
      <HomeHeader />
      <div className="app-container">
        <div
          className="ms-4 mt-4 flow_add_button cursor-pointer d-flex align-items-center border px-3 py-2"
          onClick={handleAddNodeModal}
        >
          <RocketSvgComponent size={18} color={"#4285f4"} />
          <h6 className=" header_text__14 color_primary mx-2">Start</h6>
        </div>
        <div className="flow-container">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            elementsSelectable={true}
            // fitView
            proOptions={proOptions}
            edgeTypes={edgeTypes}
            defaultViewport={defaultViewport}
          >
            <MiniMap pannable zoomable />
            <Controls />
            <Background />
            <button className="reactflow_save_button">Save Flow</button>
          </ReactFlow>
        </div>
        {openAddNodeModal && (
          <AddNodePopup
            selectedNode={selectedNode}
            isOpen={openAddNodeModal}
            setIsOpen={setOpenAddNodeModal}
            handleModal={handleAddNodeModal}
            addNode={addNode}
          />
        )}
        {getValue(chatRequest, `component`, "") && openNodeModal && (
          <ModifyNodePopup
            isOpen={openNodeModal}
            setIsOpen={setOpenNodeModal}
            handleModal={handleNodeModal}
            request={chatRequest}
            setRequest={setChatRequest}
            handleSubmit={handleSubmitNode}
            handleCancel={closeSettingsPanel}
            submitLoading={submitLoading}
            closePanel={closeSettingsPanel}
            navigateBack={navigateBack}
          />
        )}
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDelete}
          deleteValue={deleteValue}
        />
        <DeleteModal
          isOpen={isOpen1}
          handleModal={handleModal1}
          handleSubmit={handleDeleteEdges}
          deleteValue={deleteValue1}
        />
      </div>
    </>
  );
}
