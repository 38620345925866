import "./ManagefieldsLeft.scss";

export default function StagingLeftMenu() {
  return (
    <div className={"manage-field__navigation px-3 "}>
      <h6 className="mb-2 pt-3">Stages</h6>
      <p className={"manage-field__text"}>
        You can manage and create new stages here. Also, you can select
        colors for all the stages to view Gantt bars in specified colors.
      </p>
    </div>
  );
}
