import { getValue } from "@utils/lodash";
import  "./stage.scss";

function EditManageStages(props: any) {
  const {
    request,
    editStage,
  } = props;

  return (
    <div className={`container`}>
      <div className={"column"}>
        <div className={"title-container"}>
          <h5 className={"title"}>Open Stages</h5>
          <img
            src="/images/icons/add-circle.svg"
            className={"add-image custom-image cursor-pointer"}
            // onClick={() => addStage("openStages")}
          />
        </div>
        {getValue(request, `openStages`, []).map(
          (obj: object, index: number) => {
            return (
              <div key={index} className={"box-container"}>
                <div className={"box"}>
                  <div className={"hide-container"}>
                    <p className={"text-title"}>
                      {getValue(obj, `name`, "")}
                    </p>
                    <div className={"buttons"}>
                      <button
                        className={"edit-button"}
                        onClick={() => editStage(obj)}
                      >
                        Edit
                      </button>
                      {/* <button className={"delete-button"}>
                        Delete
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className={"column"}>
        <div className={"title-container"}>
          <h5 className={"title"}>Closed Won Stages</h5>
          <img
            src="/images/icons/add-circle.svg"
            className={"add-image custom-image cursor-pointer"}
            // onClick={() => addStage("openStages")}
          />
        </div>
        {getValue(request, `closedWonStages`, []).map(
          (obj: object, index: number) => {
            return (
              <div key={index} className={"box-container"}>
                <div className={"box"}>
                  <div className={"hide-container"}>
                    <p className={"text-title"}>
                      {getValue(obj, `name`, "")}
                    </p>
                    <div className={"buttons"}>
                      <button
                        className={"edit-button"}
                        onClick={() => editStage(obj)}
                      >
                        Edit
                      </button>
                      {/* <button className={"delete-button"}>
                        Delete
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className={"column"}>
        <div className={"title-container"}>
          <h5 className={"title"}>Closed Lost Stages</h5>
          <img
            src="/images/icons/add-circle.svg"
            className={"add-image custom-image cursor-pointer"}
            // onClick={() => addStage("openStages")}
          />
        </div>
        {getValue(request, `closedLostStages`, []).map(
          (obj: object, index: number) => {
            return (
              <div key={index} className={"box-container"}>
                <div className={"box"}>
                  <div className={"hide-container"}>
                    <p className={"text-title"}>
                      {getValue(obj, `name`, "")}
                    </p>
                    <div className={"buttons"}>
                      <button
                        className={"edit-button"}
                        onClick={() => editStage(obj)}
                      >
                        Edit
                      </button>
                      {/* <button className={ClassNames["delete-button"}>
                        Delete
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>

      {/* <p className="mb-2">Opened Stages</p>
      {getValue(request, `openStages`, []).map((obj: object, index: number) => {
        return (
          <div key={index} className="d-flex mt-1">
            <InputForms
              inputType="TEXT"
              placeholder="Enter Open Stage"
              value={getValue(obj, `name`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextArrayChange(index, e, "openStages", setRequest)
              }
              name={"name"}
              label={"Open Stage"}
              validator={activeStep === 2 && simpleValidator}
              validLeft
              hideLabel
              mandatory
            />
            <div className="d-flex mt-1">
              <img
                src="/images/icons/add-circle.svg"
                className={"add-image custom-image cursor-pointer"}
                onClick={() => addStage("openStages")}
              />
              {index !== 0 && (
                <img
                  src="/images/icons/cancel-circle.svg"
                  className={"add-image custom-image cursor-pointer"}
                  onClick={() => removeStage(index, "openStages")}
                />
              )}
            </div>
          </div>
        );
      })}

      <p className="mt-4 mb-2">Closed Won Stages</p>
      {getValue(request, `closedWonStages`, []).map(
        (obj: object, index: number) => {
          return (
            <div key={index} className="d-flex mt-1">
              <div className="inline-input mt-1">
                <InputForms
                  inputType="TEXT"
                  placeholder="Enter Closed Stage"
                  value={getValue(obj, `name`, "")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextArrayChange(
                      index,
                      e,
                      "closedWonStages",
                      setRequest
                    )
                  }
                  name={"name"}
                  validator={activeStep === 2 && simpleValidator}
                  validLeft
                  hideLabel
                  mandatory
                  label={"Closed Won Stage"}
                />
                <img src="/images/icons/thumb_up.svg" />
              </div>

              <div className="d-flex mt-2">
                <img
                  src="/images/icons/add-circle.svg"
                  className={"add-image custom-image cursor-pointer"}
                  onClick={() => addStage("closedWonStages")}
                />
                {index !== 0 && (
                  <img
                    src="/images/icons/cancel-circle.svg"
                    className={"add-image custom-image cursor-pointer"}
                    onClick={() => removeStage(index, "closedWonStages")}
                  />
                )}
              </div>
            </div>
          );
        }
      )}

      <p className="mt-4 mb-2">Closed Lost Stages</p>
      {getValue(request, `closedLostStages`, []).map(
        (obj: object, index: number) => {
          return (
            <div key={index} className="d-flex mt-1">
              <div className="inline-input">
                <InputForms
                  inputType="TEXT"
                  placeholder="Enter Closed Stage"
                  value={getValue(obj, `name`, "")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextArrayChange(
                      index,
                      e,
                      "closedLostStages",
                      setRequest
                    )
                  }
                  name={"name"}
                  validator={activeStep === 2 && simpleValidator}
                  validLeft
                  hideLabel
                  mandatory
                  label={"Closed Lost Stage"}
                />
                <img src="/images/icons/thumb_down.svg" />
              </div>
              <div className="d-flex mt-1">
                <img
                  src="/images/icons/add-circle.svg"
                  className={"add-image custom-image cursor-pointer"}
                  onClick={() => addStage("closedLostStages")}
                />
                {index !== 0 && (
                  <img
                    src="/images/icons/cancel-circle.svg"
                    className={"add-image custom-image cursor-pointer"}
                    onClick={() => removeStage(index, "closedLostStages")}
                  />
                )}
              </div>
            </div>
          );
        }
      )} */}
    </div>
  );
}

export default EditManageStages;
