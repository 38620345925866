import CloseMainSvgComponent from "@assets/svg/close-main";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import WhatsappIconViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon";
import { getValue } from "@utils/lodash";
import { config } from "../../../../../env";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function MessageOptOutPopup(props: any) {
  const {
    request,
    setRequest,
    templateList,
    messageTypeEnum,
    submitLoading,
    handleFileChange,
  } = props;
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      // toggle={props.handleModal}
      size="lg"
    >
      <div className="d-flex align-items-center justify-content-between p-3 px-3 py-3 border-bottom">
        <div>
          <h6 className="header_text__16">Configure Message</h6>
          <p className="small_text__14 mt-3">
            Send template message from one of your pre approved templates. You
            can also opt to send regular message to active users.
          </p>
        </div>
        <div onClick={props.handleModal}>
          <CloseMainSvgComponent size={24} />
        </div>
      </div>
      <ModalBody>
        <div className="px-3">
          <div
            className="d-flex mb-3 mt-3 cursor-pointer"
            // onClick={() => setMessageType("preapproved")}
            onClick={() =>
              setRequest({
                ...request,
                responseType: "template",
              })
            }
          >
            <input
              className="common_radio"
              type="radio"
              checked={
                getValue(request, `responseType`, "") === "template"
                  ? true
                  : false
              }
            />
            <p className="small_text__14 ms-3">Pre-approved template message</p>
          </div>
          <div
            className="d-flex mb-3 mt-3 cursor-pointer"
            onClick={() =>
              setRequest({
                ...request,
                responseType: "message",
              })
            }
          >
            <input
              className="common_radio"
              type="radio"
              checked={
                getValue(request, `responseType`, "") === "message"
                  ? true
                  : false
              }
            />
            <p className="small_text__14 ms-3">Regular Message</p>
          </div>
          <div className="mt-3">
            {getValue(request, `responseType`, "") === "template" && (
              <div className="d-flex align-items-start justify-content-between w-100">
                <div className="w-50">
                  <h6 className="header_text__16">Template Name</h6>
                  <p className="small_text__14">
                    Please choose a WhatsApp template message from your approved
                    list
                  </p>
                  <div className="mt-3">
                    <SearchToolTip
                      label="label"
                      data={templateList}
                      selectKey={"value"}
                      value={getValue(request, `templateId`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          templateId: getValue(e, `value`, ""),
                        })
                      }
                      name="Select template message"
                      placeholder="Choose template message"
                    />
                  </div>
                </div>
                {getValue(request, `templateId`, "") && (
                  <div className="template-wrapper">
                    <div className=" template-container">
                      <WhatsappIconViewPage
                        request={templateList.find(
                          (item: object) =>
                            getValue(item, `id`, "") ===
                            getValue(request, `templateId`, "")
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {getValue(request, `responseType`, "") === "message" && (
              <div className="d-flex">
                <div className="w-50">
                  <div className="">
                    <h6 className="header_text__16">
                      Message Type{" "}
                      {getValue(
                        request,
                        `autoResponseMsgType`,
                        ""
                      ).toUpperCase()}
                    </h6>
                    <p className="small_text__14">
                      Select one of available message types
                    </p>
                    <div className="mt-3">
                      <SearchToolTip
                        label="label"
                        data={messageTypeEnum}
                        selectKey={"value"}
                        value={getValue(request, `autoResponseMsgType`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            autoResponseMsgType: getValue(e, `value`, ""),
                          })
                        }
                        name="Select one of available message types"
                        placeholder="Choose message type"
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    {getValue(request, `autoResponseMsgType`, "") ===
                      "text" && (
                      <>
                        <h6 className="header_text__16">Message</h6>
                        <p className="small_text__14">
                          Your message can be upto 4096 characters long.
                        </p>
                        <div>
                          <InputRuleForm
                            inputType="TEXTAREA"
                            maxLength={4096}
                            placeholder="Your message goes here"
                            value={getValue(request, `autoResponseMsgTxt`, "")}
                            onChange={(e: any) =>
                              setRequest({
                                ...request,
                                autoResponseMsgTxt: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    )}

                    {getValue(request, `autoResponseMsgType`, "") ===
                      "image" && (
                      <div>
                        <p
                          // htmlFor="file-upload"
                          className="custom-file-upload mb-2 requirement-message"
                        >
                          Choose a{" "}
                          <span className="required-file-format">
                            .jpg or .png
                          </span>{" "}
                          file
                        </p>
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, "image")}
                          className="custom-upload-filename"
                          accept={".jpg,.jpeg,.png"}
                        />
                      </div>
                    )}

                    {getValue(request, `autoResponseMsgType`, "") ===
                      "video" && (
                      <div>
                        <p
                          // htmlFor="file-upload"
                          className="custom-file-upload mb-2 requirement-message"
                        >
                          Choose a{" "}
                          <span className="required-file-format">.mp4</span>{" "}
                          file
                        </p>
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, "video")}
                          className="custom-upload-filename"
                          accept={".mp4"}
                        />
                      </div>
                    )}

                    {getValue(request, `autoResponseMsgType`, "") ===
                      "document" && (
                      <div>
                        <p
                          // htmlFor="file-upload"
                          className="custom-file-upload mb-2 requirement-message"
                        >
                          Choose a{" "}
                          <span className="required-file-format">.pdf</span>{" "}
                          file
                        </p>
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, "document")}
                          className="custom-upload-filename"
                          accept={".pdf"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-50 ms-3">
                  {getValue(request, `msg_type`, "") == "image" && (
                    <div className="">
                      <img
                        src={
                          getValue(request, `mediaId.key`, "") ||
                          getValue(request, `mediaId.url`, "")
                            ? `https://${config.URL}/${
                                getValue(request, `mediaId.key`, "")
                                  ? getValue(request, `mediaId.key`, "")
                                  : getValue(request, `mediaId.url`, "")
                              }`
                            : `https://${config.URL}/${
                                getValue(request, `mediaId.key`, "")
                                  ? getValue(request, `mediaId.key`, "")
                                  : getValue(request, `mediaId.url`, "")
                              }`
                        }
                        alt="Selected"
                        style={{
                          height: "250px",
                          width: "250px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={props.handleModal}
            buttonType={"scecondary"}
            buttonText="Cancel"
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={submitLoading ? "Please wait..." : "Save"}
            onClickHandler={() => props.handleSubmit()}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default MessageOptOutPopup;
