import CloseMainSvgComponent from "@assets/svg/close-main";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

const OTP_INPUTS_PER_GROUP = 3; // Number of OTP inputs per group
function RegisterPhonePopup(props: any) {
  const { submitLoading } = props;
  const inputRefs = useRef<any>([]); // Ref to store input references
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Array to store OTP digits
  const handleOtpInputChange = (groupIdx: any, digitIdx: any, value: any) => {
    const newOtp = [...otp];
    const globalDigitIdx = groupIdx * OTP_INPUTS_PER_GROUP + digitIdx;
    newOtp[globalDigitIdx] = value;
    setOtp(newOtp);

    const moveFocus = (moveIndex: any) => {
      if (moveIndex >= 0 && moveIndex < otp.length) {
        inputRefs.current[moveIndex].focus();
      }
    };

    if (value) {
      moveFocus(
        globalDigitIdx < otp.length - 1 ? globalDigitIdx + 1 : globalDigitIdx
      );
    } else {
      moveFocus(globalDigitIdx > 0 ? globalDigitIdx - 1 : globalDigitIdx);
    }
  };
  // useEffect(() => {
  //   if (inputRefs.current) {
  //     inputRefs.current[0].focus();
  //   }
  // }, []);

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      const clipboardData =
        event.clipboardData || getValue(window, `clipboardData`, {});
      const pastedData = clipboardData.getData("text");
      if (pastedData) {
        const otpArray = pastedData
          .split("")
          .filter((char) => /\d/.test(char))
          .slice(0, otp.length);

        const newOtp = otpArray.map((digit, idx) => digit || "");
        setOtp(newOtp);
        // Set focus on the last input
        const lastInputIndex = newOtp.length - 1;
        if (inputRefs.current[lastInputIndex]) {
          inputRefs.current[lastInputIndex].focus();
        }
      } else {
        console.error("No text found in clipboard.");
      }
    } catch (error) {
      console.error("Error reading clipboard:", error);
    }
  };

  return (
    <Modal isOpen={getValue(props, `isOpen`, "")} size="lg">
      <div className="d-flex align-items-center justify-content-between p-3 px-3 py-3 border-bottom">
        <div>
          <h6 className="header_text__16">Register Mobile</h6>
          <p className="small_text__12 mt-1">
            Please set a 6-digit PIN you wish to use for two-step verification
            for this mobile number.
          </p>
        </div>
        <div onClick={props.handleModal}>
          <CloseMainSvgComponent size={24} />
        </div>
      </div>
      <ModalBody>
        <div className=" p-3 px-3 py-3">
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex justify-content-between">
              {[...Array(otp.length)].map((_, idx) => {
                const groupIdx = Math.floor(idx / OTP_INPUTS_PER_GROUP);
                const localIdx = idx % OTP_INPUTS_PER_GROUP;
                const isLastInGroup = localIdx === OTP_INPUTS_PER_GROUP - 1;
                const isFirstInGroup = localIdx === 0 && idx !== 0;
                return (
                  <React.Fragment key={idx}>
                    <input
                      type="text"
                      inputMode="numeric"
                      autoComplete="one-time-code"
                      pattern="\d{1}"
                      name={`otp-${idx}`}
                      onChange={(e) =>
                        handleOtpInputChange(groupIdx, localIdx, e.target.value)
                      }
                      onPaste={handlePaste}
                      maxLength={1}
                      className={`otp-input ${
                        localIdx === 0 ? "first-input" : ""
                      } ${isLastInGroup ? "last-input" : ""}`}
                      value={otp[idx]}
                      ref={(input) => (inputRefs.current[idx] = input)}
                    />
                    {isLastInGroup && idx < otp.length - 1 && (
                      <span
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        className="mt-2"
                      >
                        _
                      </span>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={() => {
              props.handleModal();
              setOtp(["", "", "", "", "", ""]);
            }}
            buttonType={"scecondary"}
            buttonText="Cancel"
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={submitLoading ? "Please wait..." : "Save"}
            onClickHandler={() => {
              props.registerPhone(otp.join(""));
              setOtp(["", "", "", "", "", ""]);
            }}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default RegisterPhonePopup;
