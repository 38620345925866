import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useEffect, useRef, useState } from "react";
import "./messages.scss";
import "reactjs-popup/dist/index.css";
import {
  SpecificWhatsappConversationRead,
  getSpecificWhatsappConversation,
  getWhatsappConversations,
  sendWhatsappConversation,
} from "@services/fb.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue, setValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";
import InboxContacts from "./components/contacts";
import ChatFooter from "./components/chat-footer";
import ChatContactInfo from "./components/contact-info";
import ChatLeftHeader from "./components/chat-left-header";
import ChatRightHeader from "./components/chat-right-header";
import ChatDocumentPreview from "./components/chat-doc-preview";
import { mediaUpload } from "@services/upload.service";
import DocumentSvgComponent from "@assets/svg/document";
import CrossSvgComponent from "@assets/svg/cross";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import {
  getSpecificContact,
} from "@services/contacts.service";
import { motion } from "framer-motion";
import SearchSvgComponent from "@assets/svg/search";
import {
  allPipelines,
  getSpecificPipeline,
  getSpecificPipelineColumns,
} from "@services/pipeline.service";
import { useStateContext } from "@context/profileProvider";
import {
  appendObjectValuesToArray,
  removeDuplicatesById,
} from "@components/helpers/request-helper";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import _ from "lodash";
import { replacePlaceholders } from "@common/markdown-helper";
import { useParams } from "react-router-dom";
import ConversationMessages from "./components/conversation-messages";

function Messages(props: any) {
  const params = useParams();
  const [expanded, setExpanded] = useState(false);
  const [docExpanded, setDocExpanded] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleToggleWidth = () => {
    setExpanded(!expanded);
  };
  const {
    selectedModuleId,
    userId,
    newNotification,
    setNewNotification,
    newStatus,
    setNewStatus,
  } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                                 Socket Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (newNotification) {
      getData(false, 1, "", "");
      setNewNotification(false);
    }
  }, [newNotification]);
  const [msgIndex, setMsgIndex] = useState<any>(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const msgId = getValue(newStatus, `msg.id`, "");
    const msgStatus = getValue(newStatus, `msg.status`, "");
    const conversationId = getValue(newStatus, `conversation.id`, "");
    if (msgId) {
      const elementPos = conversation.findIndex((x) => x.id === msgId);
      setMsgIndex(elementPos);

      if (elementPos !== -1) {
        setValue(conversation, `[${elementPos}].status`, msgStatus);
        setConversation([...conversation]);
      }

      setNewStatus({});
      setMsgIndex(null);

      if (
        (msgStatus !== "is_read" || count < 2) &&
        msgStatus !== "is_delivered"
      ) {
        getSpecificConversation(false, conversationId);
        if (count > 1) {
          setCount(0);
        }
      }
      if (msgStatus === "is_read") {
        setCount(count + 1);
      }
    }
  }, [getValue(newStatus, `msg.id`, ""), msgIndex]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [messages, setMessages] = useState<any>([]);
  useEffect(() => {
    getData(true, page_no, "", query);
    getAllPipelines();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  /* ----------------------------  Whatsapp Contacts List  --------------------- */
  const [conversationId, setconversationId] = useState("");
  const [conversations, setConversations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page_no, setPageNo] = useState(1);
  const [page_size, setPageSize] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePagination = (page_no: number) => {
    setPageNo(page_no);
    getData(true, page_no, selectedConversation, query);
  };

  const sortByUpdatedAt = (data: any) => {
    return _.orderBy(data, ["updated_at"], ["desc"]);
  };

  const getData = async (
    status: boolean,
    page_no: number,
    conversationId: string,
    searchText: string
  ) => {
    try {
      setIsLoading(status);
      let payload = {
        page_no: page_no,
        page_size: page_size,
        search: searchText,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappConversations(queryRequest);
      if (resp) {
        let result = getValue(resp, `data.unread`, []).concat(
          getValue(resp, `data.conversations`, [])
        );
        setConversations(result);
        // let data = sortByUpdatedAt(getValue(resp, `data.conversations`, []));
        // setConversations(getValue(resp, `data.conversations`, []));
        // setconversationId(
        //   conversationId
        //     ? conversationId
        //     : getValue(resp, `data.unread[${0}].id`, "")
        //     ? getValue(resp, `data.unread[${0}].id`, "")
        //     : getValue(resp, `data.conversations[${0}].id`, "")
        // );
        // setSelectedConversation(
        //   conversationId
        //     ? conversationId
        //     : getValue(resp, `data.unread[${0}].id`, "")
        //     ? getValue(resp, `data.unread[${0}].id`, "")
        //     : getValue(resp, `data.conversations[${0}].id`, "")
        // );
        if (!newNotification) {
          getSpecificConversation(
            true,
            conversationId
              ? conversationId
              : getValue(resp, `data.unread[${0}].id`, "")
              ? getValue(resp, `data.unread[${0}].id`, "")
              : getValue(resp, `data.conversations[${0}].id`, "")
          );
        }
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* ----------------------------  Whatsapp Specific Contactinfo data  --------------------- */

  const [conversationLoading, setConversationLoading] = useState(false);
  const [conversation, setConversation] = useState<any[]>([]);
  const [selectedContact, setSelectedContact] = useState<any>({});
  const getSpecificConversation = async (status: boolean, id: string) => {
    try {
      setConversationLoading(status);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificWhatsappConversation(id, queryRequest);
      if (resp) {
        setConversation(getValue(resp, `data.msgs`, []));
        // setSelectedContact(
        //   conversations.find((item: object) => getValue(item, `id`, "") === id)
        // );
        setConversationLoading(false);
      } else {
        setConversationLoading(false);
      }
    } catch (error) {
      setConversationLoading(false);
    }
  };

  const [selectedConversation, setSelectedConversation] = useState("");
  const [selectedConversationInfo, setSelectedConversationInfo] = useState({});
  const handleSelectConvesation = (item: any) => {
    setSelectedConversationInfo({
      ...item,
      last_name: getValue(item, `wa_profile_name`, ""),
      mobile_number: getValue(item, `wa_id`, ""),
    });
    if (
      getValue(item, `unreadCount`, "") > 0 &&
      getValue(item, `last_msg_id`, "")
    ) {
      handleReadMessage(
        item,
        getValue(item, `id`, ""),
        getValue(item, `last_msg_id`, "")
      );
    } else {
      if (selectedConversation !== getValue(item, `id`, "")) {
        setSelectedConversation(getValue(item, `id`, ""));
        getSpecificConversation(true, getValue(item, `id`, ""));
        getSpecificContactInfo(getValue(item, `contact_id`, ""));
      }
    }
  };
  const handleSendMessages = async () => {
    if (message) {
      try {
        let resp = await sendWhatsappConversation(
          selectedConversation,
          removeNullOrUndefinedProperties({
            msgType: "text",
            textBody: replacePlaceholders(message),
            replyMsgId: getValue(selectedReplyMessage, `id`, ""),
          })
        );
        if (resp) {
          getData(false, page_no, selectedConversation, query);
          setMessage("");
          setSelectedReplyMessage(null);
        }
      } catch (error) {}
    }
  };
  const findType = (type: string) => {
    switch (type) {
      case ".png":
      case ".jpg":
      case ".jpeg":
      case ".svg":
        return "image";
      case ".mp4":
        return "video";
      case ".mp3":
        return "audio";
      case ".docx":
      case ".pdf":
        return "document";
      default:
        return "image";
    }
  };
  const handleSendDocumentMessages = async () => {
    documentRequest.map((item: object, index: number) => {
      return triggerAPI(
        {
          msgType: findType(getValue(item, `fileId.extension`, "")),
          textBody: getValue(item, `textBody`, ""),
          fileId: getValue(item, `fileId.id`, ""),
        },
        index
      );
    });
  };
  const triggerAPI = async (obj: object, index: number) => {
    try {
      let resp = await sendWhatsappConversation(selectedConversation, obj);
      if (resp) {
        if (index == 0) {
          setDocumentRequest([]);
          setDocExpanded(false);
          getSpecificConversation(true, selectedConversation);
          setSelectedReplyMessage(null);
        }
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const emojiPickerRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event: any) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    // Attach the event listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const ref = useRef<any>();
  const [message, setMessage] = useState("");
  const handleEmojiClick = (event: any, emojiObject: any) => {
    if (ref.current) {
      const cursor = ref.current.selectionStart;
      const newText =
        message.slice(0, cursor) + event.emoji + message.slice(cursor);
      setMessage(newText);
      toggleEmojiPicker();
    }
  };

  const [documentRequest, setDocumentRequest] = useState<any>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocument = (e: any) => {
    Array.from(e.target.files).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocExpanded(true);
          documentRequest.push({
            fileId: getValue(resp, `data`, {}),
          });
          setDocumentRequest([...documentRequest]);
          setUploadLoading(false);
        } else {
          setUploadLoading(false);
        }
      } catch (error) {
        setUploadLoading(false);
      }
    });
  };

  const [selectedReplyMessage, setSelectedReplyMessage] = useState<any>(null);
  const handleReadMessage = async (
    item: any,
    conversationId: string,
    messageId: string
  ) => {
    try {
      let resp = await SpecificWhatsappConversationRead(
        conversationId
        // messageId
      );
      if (resp) {
        setSelectedConversation(getValue(item, `id`, ""));
        getSpecificConversation(true, getValue(item, `id`, ""));
        if (getValue(item, `contact_id`, "")) {
          getSpecificContactInfo(getValue(item, `contact_id`, ""));
        } else {
          setContactInfo({});
          setColumnsList([]);
          setColumnLabelInfo({});
        }
        getData(false, page_no, getValue(item, `id`, ""), query);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contact Section                           */
  /* -------------------------------------------------------------------------- */

  const [contactInfo, setContactInfo] = useState({});
  const [columnList, setColumnsList] = useState([]);
  const [columnLabelInfo, setColumnLabelInfo] = useState({});
  const getSpecificContactInfo = async (id: string) => {
    if (id)
      try {
        let resp = await getSpecificContact(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("contacts"),
              getValue(resp, `data.pipeline_id`, "")
            );
            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
          }
        }
      } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                            Form Creation Section                           */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = useState<any>({
    pipeline_id: "",
    stage: "",
  });
  const [options, setOptions] = useState([]);
  const getAllPipelines = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_name: "contacts",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];

        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [stages, setStages] = useState([]);
  const [formFields, setFormFields] = useState<any>([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const getFormFields = async (id: string) => {
    try {
      setIsFormLoading(true);
      let resp = await getSpecificPipeline(
        await selectedModuleId("contacts"),
        id
      );
      if (resp) {
        let stages =
          getValue(resp, `data.stages.length`, 0) > 0
            ? getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStaticFieldRequest({
          ...staticFieldRequest,
          pipeline_id: getValue(resp, `data.id`, ""),
          stage: getValue(stages, `[${0}].id`, ""),
        });
        setStages(stages);

        let fields =
          getValue(resp, `data.form_fields.length`, 0) > 0
            ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                ...getValue(item, `module_field`, {}),
                name: getValue(item, `api_name`, ""),
                quick_add: getValue(item, `quick_add`, ""),
                required: getValue(item, `required`, ""),
                seq_num: getValue(item, `seq_num`, ""),
              }))
            : [];
        for (const item of fields) {
          if (item.api_name === "owner_id") {
            item.value = userId;
          }
        }
        let array: any = appendObjectValuesToArray(
          fields,
          selectedConversationInfo
        );

        setFormFields(removeDuplicatesById(array));

        setIsFormLoading(false);
      } else {
        setIsFormLoading(false);
      }
    } catch (error) {
      setIsFormLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              bottom chat Scroll                            */
  /* -------------------------------------------------------------------------- */
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "end" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [getValue(conversation, `msgs`, [])]);

  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e: any) => {
    setQuery(e.target.value);
    getData(false, page_no, selectedConversation, e.target.value);
  };

  const maxLength = 260;

  const [userDetails, setUserDetails] = useState(true);
  const toggleUserDetails = () => {
    setUserDetails(!userDetails);
  };

  const handleAddContact = () => {
    handleToggleWidth();
    setUserDetails(false);
  };
  console.log(selectedConversation);
  return (
    <div>
      <HomeHeader />
      <div className="chatbox-wrapper">
        <div className="chatbox-container">
          <div className="chatbox-left-container">
            <ChatLeftHeader />
            <div className="contact-search-wrapper">
              <div className="message_search">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="contact-search"
                  onChange={(e) => handleChangeSearch(e)}
                  placeholder="Search user"
                />
                <div className="search-icon">
                  <SearchSvgComponent color={"#8696a0"} size={18} />
                </div>
              </div>
            </div>
            <InboxContacts
              isLoading={isLoading}
              conversations={conversations}
              selectedConversation={selectedConversation}
              handleSelectConvesation={handleSelectConvesation}
              query={query}
            />
            {totalCount > 100 && (
              <Pagination
                totalCount={totalCount}
                limit={page_size}
                page_no={page_no}
                handleChangePagination={handleChangePagination}
                showLimit={"false"}
                // handleChangeLimit={handleChangeLimit}
              />
            )}
          </div>
          <div className="chatbox-center-container">
            {selectedConversation ? (
              <div className="conversation-wrapper">
                <div className="conversation-section">
                  <ChatRightHeader
                    handleToggleWidth={handleToggleWidth}
                    selectedContact={selectedContact}
                    selectedConversationInfo={selectedConversationInfo}
                  />
                  <div className="message-section">
                    {conversationLoading ? (
                      <Loader small />
                    ) : (
                      <>
                        <div className="chat-section">
                          {!getValue(contactInfo, `id`, "") && (
                            <div className="d-flex justify-content-center">
                              <div className="new-contact-preview">
                                <AccountCircleSvgComponent
                                  color={"#dfe5e7"}
                                  size={100}
                                />
                                <p className="small_text__18 color_black text-center mb-1 mt-2">
                                  {getValue(selectedContact, "wa_id", "")}
                                </p>
                                {getValue(
                                  selectedContact,
                                  `contact.first_name`,
                                  ""
                                ) ||
                                getValue(
                                  selectedContact,
                                  `contact.last_name`,
                                  ""
                                ) ? (
                                  <p
                                    className="small_text__14 text-center"
                                    style={{ textAlign: "left" }}
                                  >
                                    ~
                                    {formatString(
                                      getValue(
                                        selectedContact,
                                        `contact.first_name`,
                                        ""
                                      )
                                    )}{" "}
                                    {formatString(
                                      getValue(
                                        selectedContact,
                                        `contact.last_name`,
                                        ""
                                      )
                                    )}
                                  </p>
                                ) : (
                                  <p
                                    className="small_text__14 text-center"
                                    style={{ textAlign: "left" }}
                                  >
                                    ~
                                    {formatString(
                                      getValue(
                                        selectedContact,
                                        `wa_profile_name`,
                                        ""
                                      )
                                    )}
                                  </p>
                                )}
                                <p className="small_text__13 text-center mb-1 mt-2">
                                  Not a contact- No groups in common
                                </p>
                                <button
                                  onClick={handleAddContact}
                                  className="mt-3 create-user-button create-user-button--department hover-text touchable-text-white px-4 py-2"
                                >
                                  Add to Contact
                                </button>
                              </div>
                            </div>
                          )}
                          <ConversationMessages
                            conversation={conversation}
                            setSelectedReplyMessage={setSelectedReplyMessage}
                          />

                          <div ref={messagesEndRef} />
                        </div>
                      </>
                    )}
                    {selectedReplyMessage && (
                      <motion.div
                        initial={{ y: 0, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        // exit={{
                        //   y: 50,
                        //   opacity: 0,
                        //   transition: { duration: 0.5, ease: "easeInOut" },
                        // }}
                      >
                        <div className="d-flex  align-items-center replay-message-wrapper">
                          <div className="reply_message_container d-flex justify-content-between align-items-center px-4 py-2 mx-4 mt-2 w-100 ">
                            <div className="d-flex align-items-center ms-2">
                              <DocumentSvgComponent />
                              <p className="small_text__14 ms-2">
                                {getValue(
                                  selectedReplyMessage,
                                  `media_caption`,
                                  ""
                                )
                                  ? getValue(
                                      selectedReplyMessage,
                                      `media_caption`,
                                      ""
                                    ).length > maxLength
                                    ? getValue(
                                        selectedReplyMessage,
                                        `media_caption`,
                                        ""
                                      ).substring(0, maxLength) + "..."
                                    : getValue(
                                        selectedReplyMessage,
                                        `media_caption`,
                                        ""
                                      )
                                  : getValue(
                                      selectedReplyMessage,
                                      `text_body`,
                                      ""
                                    )
                                  ? getValue(
                                      selectedReplyMessage,
                                      `text_body`,
                                      ""
                                    ).length > maxLength
                                    ? getValue(
                                        selectedReplyMessage,
                                        `text_body`,
                                        ""
                                      ).substring(0, maxLength) + "..."
                                    : getValue(
                                        selectedReplyMessage,
                                        `text_body`,
                                        ""
                                      )
                                  : getValue(
                                      selectedReplyMessage,
                                      `upload_file.filename`,
                                      ""
                                    ).length > maxLength
                                  ? getValue(
                                      selectedReplyMessage,
                                      `upload_file.filename`,
                                      ""
                                    ).substring(0, maxLength) + "..."
                                  : getValue(
                                      selectedReplyMessage,
                                      `upload_file.filename`,
                                      ""
                                    )
                                  ? getValue(
                                      selectedReplyMessage,
                                      `template.body`,
                                      ""
                                    ).substring(0, maxLength) + "..."
                                  : getValue(
                                      selectedReplyMessage,
                                      `template.body`,
                                      ""
                                    )}
                              </p>
                            </div>
                          </div>
                          <div
                            onClick={() => setSelectedReplyMessage(null)}
                            className="cursor-pointer pe-3"
                          >
                            <CrossSvgComponent />
                          </div>
                        </div>
                      </motion.div>
                    )}
                    <ChatFooter
                      showEmojiPicker={showEmojiPicker}
                      handleEmojiClick={handleEmojiClick}
                      toggleEmojiPicker={toggleEmojiPicker}
                      emojiPickerRef={emojiPickerRef}
                      message={message}
                      setMessage={setMessage}
                      reference={ref}
                      handleSendMessages={handleSendMessages}
                      handleUploadDocument={handleUploadDocument}
                    />
                  </div>
                </div>

                <div
                  className={`document-review-section  ${
                    docExpanded ? "expanded" : ""
                  }`}
                >
                  <div className={`${docExpanded ? "show" : "hidden"}`}>
                    <ChatDocumentPreview
                      showEmojiPicker={showEmojiPicker}
                      handleEmojiClick={handleEmojiClick}
                      toggleEmojiPicker={toggleEmojiPicker}
                      message={message}
                      setMessage={setMessage}
                      reference={ref}
                      handleSendMessages={handleSendMessages}
                      setDocExpanded={setDocExpanded}
                      docExpanded={docExpanded}
                      handleUploadDocument={handleUploadDocument}
                      documentRequest={documentRequest}
                      setDocumentRequest={setDocumentRequest}
                      handleSendDocumentMessages={handleSendDocumentMessages}
                    />
                  </div>
                </div>
                <motion.div
                  key="otherComponent"
                  initial={{ x: "100%", opacity: 1 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: "-100%", opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className={`profile-section ${expanded ? "expanded" : ""}`}
                >
                  <div className={`${expanded ? "show" : "hidden"}`}>
                    <ChatContactInfo
                      handleToggleWidth={handleToggleWidth}
                      contactInfo={contactInfo}
                      columnList={columnList}
                      columnLabelInfo={columnLabelInfo}
                      allPipelines={options}
                      stages={stages}
                      fields={formFields}
                      selectedConversation={getValue(
                        selectedConversationInfo,
                        `id`,
                        ""
                      )}
                      setFields={setFormFields}
                      getFormFields={getFormFields}
                      isFormLoading={isFormLoading}
                      staticFieldRequest={staticFieldRequest}
                      setStaticFieldRequest={setStaticFieldRequest}
                      selectedContact={selectedContact}
                      userDetails={userDetails}
                      toggleUserDetails={toggleUserDetails}
                    />
                  </div>
                </motion.div>
              </div>
            ) : (
              <div className="no-conversation">
                <img src="/nodata/messages.svg" className="whatsapp_no" />
                <h4 className="mt-3">No Conversation Selected</h4>
                <p>Select a conversation to read</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messages;
