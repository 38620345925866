import React from "react";

const ImportCheckbox = ({
  label,
  value,
  iconPath,
  description,
  onChange,
  checked,
  selectedOption,
  disabled,
}: any) => {
  const inputId = `importOption_${value}`;
  return (
    <div className="radio-wrapper">
      <input
        type="radio"
        id={inputId}
        name="importOption"
        value={value}
        checked={selectedOption === value}
        onChange={() => onChange(value)}
        disabled={disabled}
      />
      <label className="radio-label" htmlFor={inputId}>
        <div className="d-flex gap-3 align-items-center">
          {iconPath && (
            <img
              src={iconPath}
              className="img-fluid company-logo"
              alt={`Icon for ${label}`}
            />
          )}
          {label}
        </div>
        {description && (
          <p className="small_text__14 mt-2 mb-3 ">{description}</p>
        )}
      </label>
    </div>
  );
};

export default ImportCheckbox;
