import React, { useState } from "react";
import CommonDetailTaskPopup from "./popup/task-popup";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import MainBodyTasks from "./task-list";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { FormRequestHelper } from "@components/helpers/request-helper";
import { createTask, deleteTask, updateTask } from "@services/tasks.service";
import { toast } from "sonner";
import { handleFormValidation } from "@components/custom/validation";
import { useNavigate } from "react-router-dom";

function CommonDetailTasks(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                             Task Popup Section                             */
  /* -------------------------------------------------------------------------- */
  const {
    isRollsPopupVisibile,
    setRollsPopupVisisbility,
    handleOpenForm,
  } = props;
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const [taskSubmitLoading, setTaskSubmitLoading] = React.useState(false);
  const handleSubmitTask = async (val: string) => {
    // const formValid = simpleValidator.current.allValid();
    // if (!formValid) {
    //   simpleValidator.current.showMessages();
    //   forceUpdate(1);
    // } else {
    //----- task section -----//
    let fields = JSON.parse(JSON.stringify(props.form));
    let payload = FormRequestHelper(fields);
    let requestPayload: any = payload;
    try {
      setTaskSubmitLoading(true);
      let resp = await createTask({
        task: requestPayload,
        associations: [
          {
            api_name: props.module,
            record_id: getValue(props, `params.id`, ""),
          },
        ],
      });
      if (resp) {
        toast.success("Created Successfully");
        setTaskSubmitLoading(false);
        props.getData(false);
        props.getForm("tasks");
        navigate(
          `/${getValue(props, `params.orgId`, "")}/${getValue(
            props,
            `module`,
            ""
          )}/detail/${getValue(props, `params.id`, "")}?activeTab=2`
        );
        simpleValidator.current.hideMessages();
        forceUpdate(0);
        setRollsPopupVisisbility(!isRollsPopupVisibile);
      } else {
        setTaskSubmitLoading(false);
      }
    } catch (error) {
      setTaskSubmitLoading(false);
    }
    // }
  };
  const handleTaskUpdate = async (id: string, status: boolean) => {
    try {
      let resp = await updateTask(id, {
        is_completed: status,
      });
      if (resp) {
        toast.success("Updated successfully");
        props.getData(false);
      }
    } catch (error) {}
  };

  //**  Delete section  */
  const [deleteTaskId, setDeleteTaskId] = useState("");
  const [deleteTaskValue, setDeleteTaskValue] = useState("");
  const [isTaskOpen, setTaskIsOpen] = useState(false);
  const handleTaskModal = () => {
    setTaskIsOpen(!isTaskOpen);
  };
  const handleTaskDeleteView = (id: string, value: string) => {
    setDeleteTaskId(id);
    setDeleteTaskValue(value);
    handleTaskModal();
  };

  const handleDeleteTask = async () => {
    try {
      let resp = await deleteTask(deleteTaskId);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        handleTaskModal();
        props.getData(false);
      }
    } catch (error) {}
  };
  const errors = handleFormValidation(props.form);
  const hasErrors = errors.length > 0;
  return (
    <div>
      {getValue(urlParams, `activeTab`, "") == 2 && (
        <MainBodyTasks
          tasks={getValue(props, `data.tasks`, [])}
          handleTaskUpdate={handleTaskUpdate}
          handleDelete={handleTaskDeleteView}
        />
      )}
      {/* ----------------------------------- Task Module ----------------------------------- */}
      <CommonDetailTaskPopup
        isRollsPopupVisibile={isRollsPopupVisibile}
        setRollsPopupVisisbility={setRollsPopupVisisbility}
        form={props.form}
        setForm={props.setForm}
        formLoading={props.formLoading}
        handleSubmit={handleSubmitTask}
        isLoading={taskSubmitLoading}
        simpleValidator={simpleValidator}
        handleAssociate={props.handleAssociate}
        title={"Tasks"}
        module={"tasks"}
        disabled={hasErrors ? true : false}
        disableValidation
      />
      <DeleteModal
        isOpen={isTaskOpen}
        handleModal={handleTaskModal}
        handleSubmit={handleDeleteTask}
        deleteValue={deleteTaskValue}
      />
    </div>
  );
}

export default CommonDetailTasks;
