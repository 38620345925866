import ContactPipelineDetails from '@components/Pages/Pipeline/Detail/contacts';
import Contacts from '@pages/Private/Contacts';

export const ContactsRoutes = [
  {
    path: '/:orgId/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: "/:orgId/contacts/detail/:id",
    name: "Contacts",
    component: ContactPipelineDetails,
  },
];
