import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

const BotBuilderStepOne = (props: any) => {
  const { selectedOption, request, setRequest } = props;
  return (
    <div>
      <div className="">
        <p className="form-label">Name your Bot</p>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, `name`, "")}
          placeholder="Enter a Name"
          required
          className="mb-2"
          onChange={(e: any) =>
            setRequest({
              ...request,
              name: e.target.value,
            })
          }
        />
      </div>
      <div className="mt-3">
        <p className="form-label">Describe your bot</p>
        <InputRuleForm
          inputType="TEXTAREA"
          name="description"
          value={getValue(request, `description`, "")}
          placeholder="Enter a Description"
          required
          onChange={(e: any) =>
            setRequest({
              ...request,
              description: e.target.value,
            })
          }
        />
      </div>
      {/* <div className="mt-3">
        <p className="form-label">Platform</p>
        <InputRuleForm
          inputType="TEXT"
          name="platform"
          value={getValue(request, `platform`, "")}
          placeholder="Enter a Platform"
          required
          onChange={(e: any) =>
            setRequest({
              ...request,
              platform: e.target.value,
            })
          }
        />
      </div> */}
      {/* <div className="mt-4">
        <p className="header_text__16">Choose a platform</p>
        <button className="main_button__primary mt-3">
          Sales IQ Platforms
        </button>
        <p className="pending-task-commondescription mt-3">
          Build a compelling chatbot with our in-house bot-building platforms to
          suit your business needs.
        </p>
        <ImportCheckbox
          value="Codeless"
          label="Codeless bot builder"
          description="A no coding bot builder platform. Just drag and drop and build a powerful bot to assist your website visitors."
          iconPath={"/images/icons/code-64.png"}
          selectedOption={selectedOption}
          // onChange={handleChange}
        />
      </div> */}
    </div>
  );
};

export default BotBuilderStepOne;
