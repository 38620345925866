import { formatString } from "@common/text-helpers";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { useRef, useState } from "react";
import { toast } from "sonner";
import { whatsappRequestObj } from "../workflow-request";
import { handleObjectValidation } from "@components/custom/validation";
import SimpleReactValidator from "simple-react-validator";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import AddSvgComponent from "@assets/svg/add";
import { handleValueArrayChange } from "@common/handleChange";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import DialcodeInlinePhoneInputComponent from "@components/custom/Forms/DialcodeInlineInput";
import DialcodePhoneInputComponent from "@components/custom/Forms/DialcodeInput";
import DialcodePhoneInputCommon from "@components/custom/Forms/DialCodeInputCommon";

function WorkflowWhatsappPopup(props: any) {
  const {
    isOpen,
    title,
    toggle,
    request,
    setRequest,
    submitLoading,
    mainRequest,
    setMainRequest,
    editIndex,
    setEditIndex,
  } = props;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const handleAddEmailToList = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex) {
        let index = editIndex - 1;
        const updatedData = [...mainRequest.actions.whatsapp];
        updatedData[index] = request;
        setMainRequest((prevState: any) => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            whatsapp: updatedData,
          },
        }));
        toggle();
        setRequest({ ...whatsappRequestObj });
        setEditIndex(null);
      } else {
        if (getValue(request, `name`, "")) {
          mainRequest.actions.whatsapp.push(request);
          setMainRequest({ ...mainRequest });
          setRequest({ ...whatsappRequestObj });
          toggle();
        } else {
          toast.error("Please input required fields");
        }
      }
      hideErrorMessages();
    }
  };
  const hideErrorMessages = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  const [dates] = useState([
    {
      value: "workflow_triggered_at",
      label: formatString("workflow_triggered_at"),
    },
    { value: "record_created_at", label: formatString("record_created_at") },
  ]);
  const errors = handleObjectValidation(request, [
    "name",
    "owner",
    "dueDate",
    "dueDateDays",
    "priority",
  ]);
  const hasErrors = errors.length > 0;

  const handleRemovePhoneNumbers = (index: number) => {
    let number = getValue(request, `alternateNumbers`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      alternateNumbers: number,
    });
  };

  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      title={title}
      toggle={() => {
        toggle();
        setRequest({ ...whatsappRequestObj });
        setEditIndex(null);
        hideErrorMessages();
      }}
      width={"40%"}
    >
      <div className="activity_popup-main px-4">
        <div className="mt-4">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Enter Name"
            name="name"
            label={"Name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
            required
          />
        </div>
        <div className="">
          <SearchToolTip
            label="label"
            lookup_api={"templates"}
            selectKey={"id"}
            value={getValue(request, `templateId`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                templateId: getValue(e, `id`, ""),
              })
            }
            name="Templates"
            placeholder="Choose Template"
            required
            validator={simpleValidator}
          />
        </div>
        <div className="mt-1">
          <SearchToolTip
            label="label"
            data={[
              { value: "owner", label: "Owner" },
              { value: "creator", label: "Creator" },
              { value: "user_mobile", label: "User Mobile" },
              { value: "user_phone", label: "User Phone" },
            ]}
            selectKey={"value"}
            value={getValue(request, `userPreference`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                userPreference: getValue(e, `value`, ""),
              })
            }
            name="User Preference"
            placeholder="Choose User Preference"
            required
            validator={simpleValidator}
          />
        </div>
        <>
          <label className="form-label">Alternative Phone Number(s)</label>
          {getValue(request, `alternateNumbers`, []).map(
            (item: object, index: number) => {
              return (
                <div className="mb-2 d-flex align-items-start">
                  <div className="w-100">
                    {/* <PhoneInputComponent
                      placeholder="Enter phone number (+91...)"
                      value={getValue(item, `value`, "")}
                      onChange={(e: any) => {
                        handleValueArrayChange(
                          index,
                          e,
                          "value",
                          "alternateNumbers",
                          setRequest
                        );
                      }}
                      hideLabel
                      label={"Phone Number"}
                      // mandatory
                      // validator={simpleValidator}
                    /> */}
                    <DialcodePhoneInputCommon
                      placeholder="Enter phone number (+91...)"
                      value={getValue(item, `value`, "")}
                      onChange={(e: any) => {
                        handleValueArrayChange(
                          index,
                          e,
                          "value",
                          "alternateNumbers",
                          setRequest
                        );
                      }}
                      hideLabel
                      label={"Phone Number"}
                    />
                  </div>
                  <div
                    className="ms-2"
                    onClick={() => handleRemovePhoneNumbers(index)}
                  >
                    <RemoveCircleSvgComponent />
                  </div>
                </div>
              );
            }
          )}
        </>
        <div className="d-flex justify-content-between mt-3 cursor-pointer">
          <div></div>
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => {
              getValue(request, `alternateNumbers`, []).push({ value: "" });
              setRequest({ ...request });
            }}
          >
            <AddSvgComponent color={"#408dfb"} />
            <h6 className="header_text__16 color_primary ms-1">
              Add Alternative Number
            </h6>
          </div>
        </div>
      </div>

      <SubmitContainer
        handleBackButton={() => {
          toggle();
          setRequest({ ...whatsappRequestObj });
          setEditIndex(null);
          hideErrorMessages();
        }}
        handleSubmit={() => handleAddEmailToList()}
        submitLoading={submitLoading}
        first_title={"Cancel"}
        second_title={editIndex ? "Update" : "Save"}
        disableValidation
        // disabled={hasErrors ? true : false}
      />
    </SlideFromRightPopup>
  );
}

export default WorkflowWhatsappPopup;
