import InputRuleForm from "@components/InputRuleForm/form";
import React from "react";

const DayLevelAmount = () => {
  return (
    <div className="subpipeline-wrapper px-5 py-5">
      <table className="datatable_table w-100">
        <thead className="datatable_table_thead position-relative">
          <tr className="datatable_table_tr">
            <th className="datatable_table_th ">
              <h6 className="header_text__12">DATE</h6>
            </th>
            <th className="datatable_table_th ">
              <h6 className="header_text__12">AMOUNT BEFORE TAX (INR)</h6>
            </th>
            <th className="datatable_table_th ">
              <h6 className="header_text__12">TAX AMOUNT (INR)</h6>
            </th>
            <th className="datatable_table_th ">
              <h6 className="header_text__12">TOTAL WITH TAX (INR)</h6>
            </th>
          </tr>
        </thead>
        <tbody className="datatable_table_tbody">
          <tr className="datatable_table_tr">
            <td
              className="datatable_table_td "
              style={{ display: "table-cell" }}
            >
              Jun 25th 2024
            </td>
            <td className="datatable_table_td ">
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                value="3114.00"
                placeholder="Enter total with tax"
                marginZero
                hideFormLabel
              />
            </td>
            <td className="datatable_table_td ">9.00</td>
            <td className="datatable_table_td ">
              <InputRuleForm
                inputType="NUMBER"
                name="number"
                value="3123.00"
                placeholder="Enter total with tax"
                marginZero
                hideFormLabel
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DayLevelAmount;
