import AddSvgComponent from "@assets/svg/add";
import DeleteSvgComponent from "@assets/svg/delete";
import EditSvgComponent from "@assets/svg/edit";
import MoneySvgComponent from "@assets/svg/money";
import PersonSvgComponent from "@assets/svg/person";
import RoomPreferencesSvgComponent from "@assets/svg/room";

const bedTypes = [
  {
    type: "Single bed",
    occupancy: 1,
    price: 2345,
    amenities: ["Bathtub", "Wardrobe"],
  },
  {
    type: "Double bed",
    occupancy: 2,
    price: 3456,
    amenities: ["Bathtub", "Wardrobe", "Balcony"],
  },
  {
    type: "Single bed",
    occupancy: 1,
    price: 2345,
    amenities: ["Bathtub", "Wardrobe"],
  },
  {
    type: "Double bed",
    occupancy: 2,
    price: 3456,
    amenities: ["Bathtub", "Towel", "Wardrobe", "Balcony", "Kettle"],
  },
];

const CreatePropertyStepFour = ({ toggleAddBedType }: any) => {
  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <p className="header_text__20">Add all the room types</p>
      </div>
      <div className=" bed_type_container">
        <button
          className="dashboard-button--secondary header_text__18 dashboard-button  hover-text touchable-text px-4 py-2"
          onClick={toggleAddBedType}
        >
          <AddSvgComponent />
          Add room type
        </button>
        {bedTypes.map((bed, index) => (
          <div className="bed_type_details" key={index}>
            <div className="bed_type-background d-flex justify-content-between flex-column gap-4">
              <div className="d-flex justify-content-between align-items-start gap-4">
                <p className="small_text__16 text-white">{bed.type}</p>
                <div className="d-flex gap-2 align-items-center">
                  <EditSvgComponent color="#dddddd" />
                  <DeleteSvgComponent color="#d20f0f" />
                </div>
              </div>
              <div className="d-grid gap-2 mt-3">
                <div className="d-flex align-items-center gap-2">
                  <p>
                    <PersonSvgComponent size="18" color="#dddddd" />
                  </p>
                  <span className="small_text__14 card-text-white">
                    {bed.occupancy}
                  </span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p>
                    <MoneySvgComponent size="18" color="#dddddd" />
                  </p>
                  <span className="small_text__14 card-text-white">
                    {bed.price}
                  </span>
                </div>
                <div className="d-flex align-items-start gap-2">
                  <p>
                    <RoomPreferencesSvgComponent size="18" color="#dddddd" />
                  </p>
                  <div className="d-flex  row-gap-1 flex-wrap">
                    {bed.amenities.slice(0, 2).map((amenity, i) => (
                      <span
                        className="small_text__14 me-2 card-text-white"
                        key={i}
                      >
                        {amenity}
                        {i < 1 ? ", " : ""}
                      </span>
                    ))}
                    {bed.amenities.length > 2 && (
                      <span className="small_text__14 me-2 card-text-white">
                        ...
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatePropertyStepFour;
