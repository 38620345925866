import ModulesAndFieldsSvgComponent from "@assets/svg/modules-fields";
import PenSvgComponent from "@assets/svg/pen";
import WorkflowSvgComponent from "@assets/svg/workflow";
import { formatString } from "@common/text-helpers";
import Switch from "@components/Form/Switch/Switch";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function WorkflowTable(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className={"admin-wrapper mt-3"}>
      <table className={`${"admin-wrapper__table"} w-100`}>
        <thead>
          <tr>
            <td style={{ width: "70px" }}>{/* <CustomCheckbox /> */}</td>
            <td>
              <div className="d-flex align-items-center w-100">
                <h6 className="header_text__15 ms-4">Workflow Name</h6>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center w-100">
                <h6 className="header_text__15 ms-4"> Module Name</h6>
              </div>
            </td>

            <td>
              <div className="d-flex align-items-center w-100">
                <h6 className="header_text__15 ms-4">Execution Type</h6>
              </div>
            </td>

            <td>
              <div className="d-flex align-items-center w-100">
                <h6 className="header_text__15 ms-4">Status</h6>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center w-100">
                <h6 className="header_text__15 ms-4">Action</h6>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {getValue(props, `list`, []).map((item: object, index: number) => {
            return (
              <tr
                key={index}
                onClick={() =>
                  navigate(
                    `/${getValue(
                      params,
                      `orgId`,
                      ""
                    )}/settings/workflows/edit/${getValue(item, `id`, "")}?${
                      props.mainQueryRequest
                    }`
                  )
                }
                className="p-3"
              >
                <td className="p-3">
                  <WorkflowSvgComponent color={"lightgray"} />
                </td>
                <td className="p-3 d-flex align-items-center justify-content-between hide-hover">
                  <p className="small_text__14">{getValue(item, `name`, "")}</p>
                </td>
                <td className="p-3">
                  <p className="small_text__14">
                    {formatString(getValue(item, `moduleName`, ""))}
                  </p>
                </td>
                <td>
                  <p className="small_text__14">
                    {formatString(getValue(item, `executionType`, ""))}
                  </p>
                </td>
                <td className="p-3">
                  <p className="small_text__14">
                    {getValue(item, `isActive`, false) ? "Active" : "Inactive"}
                  </p>
                </td>
                <td className="p-3" onClick={(e) => e.stopPropagation()}>
                  <label className={"switch"}>
                    <input
                      type="checkbox"
                      checked={getValue(item, `isActive`, false)}
                      onChange={() =>
                        props.handleChangeWorkflowStatus(
                          getValue(item, `id`, ""),
                          !getValue(item, `isActive`, false)
                        )
                      }
                    />
                    <span className={`${"slider"} ${`round`} d-flex`}></span>
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* )} */}
    </div>
  );
}

export default WorkflowTable;
