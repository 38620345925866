import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { Nav, NavItem, NavLink } from "reactstrap";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import { useStateContext } from "@context/profileProvider";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import {
  addAddManagerFormFields,
  getFbAdManagerLeadFormsByID,
} from "@services/admanager.service";
import {
  formHandleSelectArrayChangeByName,
  handleSelectArrayChange,
} from "@common/handleChange";
import { formHandleSelectArrayChange } from "@common/handleChange";
import { updateAddManagerFormFields } from "@services/admanager.service";
import { toast } from "sonner";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import Navbar from "../../common/navbar";
import BackButton from "@components/custom/BrowserBack";

function AdLeadInfoDetailPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const { selectedModuleId } = useStateContext();
  const views = [
    {
      id: "1",
      name: "Lead Forms",
    },
  ];

  const [request, setRequest] = useState({
    form_id: "",
    created_time: "",
    locale: "",
    name: "",
    status: "",
    owner_id: "",
    pipeline_id: "",
    pipeline_stage_id: "",
    form_field: [
      // {
      //   key: "",
      //   label: "",
      //   type: "",
      //   id: "",
      //   crm_key: "",
      // },
    ],
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */
  const [info, setInfo] = useState({});
  const [fbFields, setFbFields] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getFbAdManagerLeadFormsByID(getValue(params, `id`, ""));
      if (resp) {
        let inf = getValue(resp, `data`, []).find(
          (item: object) =>
            getValue(item, `form_id`, "") === getValue(params, `formId`, "")
        );
        setInfo(inf);
        setFbFields(getValue(inf, `form_field`, []));
        setRequest({
          ...request,
          pipeline_id: getValue(inf, `pipeline_id`, ""),
          pipeline_stage_id: getValue(inf, `pipeline_stage_id`, ""),
          owner_id: getValue(inf, `owner_id`, ""),
        });
        getAllPipelines(getValue(inf, `pipeline_id`, ""));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [stages, setStages] = useState([]);
  const getAllPipelines = async (id: string) => {
    try {
      setIsLoading(true);
      let payload = {
        module_name: "companies",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];

        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        if (id) {
          // setRequest({
          //   ...request,
          //   pipeline_id: id,
          // });
          getFormFields(id);
        } else {
          setRequest({
            ...request,
            pipeline_id: getValue(list, `[${0}].id`, ""),
          });
          getFormFields(getValue(list, `[${0}].id`, ""));
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [formFields, setFormFields] = useState([]);
  const getFormFields = async (id: string) => {
    try {
      let resp = await getSpecificPipeline(
        await selectedModuleId("contacts"),
        id
      );
      if (resp) {
        let stages =
          getValue(resp, `data.stages.length`, 0) > 0
            ? getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStages(stages);
        setFormFields(
          getValue(resp, `data.form_fields`, []).map((item: object) => ({
            value: getValue(item, `module_field.api_name`, ""),
            label: getValue(item, `module_field.label`, ""),
            id: getValue(item, `module_field.api_name`, ""),
          }))
        );
      }
    } catch (error) {}
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp;
      let payload = removeNullOrUndefinedProperties({
        form_field: fbFields,
        form_id: getValue(info, `form_id`, ""),
        locale: getValue(info, `locale`, ""),
        name: getValue(info, `name`, ""),
        status: getValue(info, `status`, ""),
        created_time: getValue(info, `created_time`, ""),
        pipeline_id: getValue(request, `pipeline_id`, ""),
        pipeline_stage_id: getValue(request, `pipeline_stage_id`, ""),
        owner_id: getValue(request, `owner_id`, ""),
      });
      if (getValue(info, `id`, "")) {
        resp = await updateAddManagerFormFields(
          getValue(info, `id`, ""),
          payload
        );
      } else {
        resp = await addAddManagerFormFields(payload);
      }
      if (resp) {
        toast.success(
          `${getValue(info, `id`, "") ? "Updated" : "Created"} Successfully`
        );
        getData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <div>
      <HomeHeader />
      <CompanyProfileSubHeader
        showSubmit
        handleSubmit={handleSubmit}
        isLoading={submitLoading}
        submitText={getValue(info, `id`, "") ? "Update" : "Save"}
      />
      <div className="d-flex manageFieldRight w-100">
        <CompanyProfileNavigation />
        <div className="w-100 overflow-auto">
          <div className="d-flex gap-1 align-items-center">
            <BackButton className="ms-4 mt-3" />
            <Navbar
              views={views}
              route={`/settings/ads/${getValue(params, `id`, "")}/edit`}
            />
          </div>
          <div className="ms-5 mt-3">
            <div className="w-30">
              <SearchToolTip
                placeholder={`Choose Pipeline`}
                label={`label`}
                data={options}
                value={getValue(request, `pipeline_id`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    pipeline_id: getValue(e, `id`, ""),
                  })
                }
                name={"Contact Pipelines"}
                // selectKey={"value"}
                // name={getValue(item, `label`, "")}
                // mandatory={required}
                // validator={required && simpleValidator}
              />
            </div>
            <div className="w-30 mt-2">
              <SearchToolTip
                placeholder={`Choose Pipeline Stage`}
                label={`label`}
                data={stages}
                value={getValue(request, `pipeline_stage_id`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    pipeline_stage_id: getValue(e, `id`, ""),
                  })
                }
                name={"Contact Pipeline Stage"}
                // selectKey={"value"}
                // name={getValue(item, `label`, "")}
                // mandatory={required}
                // validator={required && simpleValidator}
              />
            </div>
            <div className="w-30 mt-2">
              <SearchToolTip
                placeholder={`Choose Owner`}
                label={`label`}
                lookup_api={"org_user"}
                value={getValue(request, `owner_id`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    owner_id: getValue(e, `id`, ""),
                  })
                }
                name={"Contact Owner"}
                // selectKey={"value"}
                // name={getValue(item, `label`, "")}
                // mandatory={required}
                // validator={required && simpleValidator}
              />
            </div>
          </div>
          <div className="mt-3 w-100 d-flex align-items-center px-5 mb-5">
            <div className="w-100">
              {/* <h6 className="header_text__16">FIELDS IN THE LEAD FORM</h6> */}
              <div>
                {getValue(fbFields, `length`, 0) > 0 &&
                  fbFields.map((item: object, index: number) => {
                    return (
                      <div className="d-flex align-items-center">
                        <div className="mt-2 mb-2 w-25">
                          <p className="small_text__16">
                            {index + 1}. {getValue(item, `label`, "")}
                          </p>
                        </div>
                        <div className="w-30 mb-2 mt-2">
                          <SearchToolTip
                            label="label"
                            data={
                              // getValue(formFields, `length`, 0) > 0
                              //   ? formFields.filter(
                              //       (fil: object) =>
                              //         !getMappedFields.includes(fil)
                              //     )
                              //   : []
                              formFields
                            }
                            selectKey={"value"}
                            value={getValue(item, `crm_key`, "")}
                            onChange={(e: object) => {
                              formHandleSelectArrayChangeByName(
                                index,
                                e,
                                "crm_key",
                                setFbFields
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdLeadInfoDetailPage;
