import { getValue } from "@utils/lodash";
import "../broadcastTemplate.scss";
import { Modal, ModalBody } from "reactstrap";
import CloseSvgComponent from "@assets/svg/close";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";

function AddWhatsappVariable(props: any) {
  const {
    isOpen,
    handleModal,
    handleAddTags,
    allTags,
    tagsByPipelineID,
    allPipelinesList,
    pipelineId,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={handleModal}>
      <div className="px-3 py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="header_text__18">Select attribute</h6>
          </div>
          <div onClick={handleModal}>
            <CloseSvgComponent size={16} />
          </div>
        </div>
        <p className="sample--template-text mt-1">
          Use variable as placeholders. Variables are filled in automatically
          when they are sent
        </p>
      </div>
      <div className="border-bottom"></div>
      <ModalBody>
        <div className="p-3">
          <SearchToolTip
            label="label"
            data={allPipelinesList}
            selectKey="value"
            value={pipelineId}
            onChange={(e: any) => tagsByPipelineID(getValue(e, "id", ""))}
            name="Contact Pipelines"
            placeholder="Choose Pipeline"
          />
          <div className="d-flex gap-3 flex-wrap mt-4">
            {getValue(allTags, `length`, 0) > 0 &&
              allTags.map((item: any, index: number) => (
                <button
                  key={index}
                  className="dashboard-button  hover-text touchable-text-white px-4 py-2"
                  onClick={() => {
                    handleAddTags(item);
                    handleModal();
                  }}
                >
                  {getValue(item, `label`, "")}
                </button>
              ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AddWhatsappVariable;
