import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "context/profileProvider";
import { getValue } from "@utils/lodash";
import { getFirstLetterOfWord } from "@common/text-helpers";
import "./SignInDropdown.scss";
import EditSvgComponent from "@assets/svg/edit";
import Signout from "@assets/svg/logout";
import ButtonOnHover from "@components/common/Tooltip/button-on-hover";
import FacebookLogin from "social/facebook-login";
export default function SignInDropdownOrg(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/signin`;
  };
  const { userInfo } = useStateContext();
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle>
          <div className={"signin"}>
            <span className={"signin-name"}>
              {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <div className="position-relative">
            <div className={"dropdown-account-wrapper"}>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className={"dropdown-account"}>Account</h3>
                {/* <div
                  className="image_hover_background"
                  onClick={() =>
                    navigate(`/${getValue(params, `orgId`, "")}/profile`)
                  }
                >
                  <EditSvgComponent />
                </div> */}
                <div
                  className="d-flex align-items-center cursor-pointer border p-1 border-radius__5"
                  onClick={handleLogout}
                >
                  <span className="small_text__14 mx-1 mx-1">Signout</span>
                  {/* <Signout size="16" />{" "} */}
                </div>
              </div>
              <div className="d-flex gap align-items-center">
                <div className={"signin--initial"}>
                  <span className={"signin-name--initial"}>
                    {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
                  </span>
                </div>
                <div>
                  <h6 className={"signin__name"}>
                    {getValue(userInfo, `first_name`, "")}{" "}
                    {getValue(userInfo, `last_name`, "")}
                  </h6>
                  <p className={"signin__text"}>
                    {getValue(userInfo, `email`, "")}
                  </p>
                  {/* <p className={`signin__text mb-0`}>
                    Status - <span className={"signin__text-span"}>Active</span>
                  </p> */}
                </div>
              </div>
            </div>

            <div className={"dropdown-button-cta mt-2 mb-2"}>
              {/* <Link to="/signin"> */}
              {getValue(params, `orgId`, "") ? (
                <button
                  className={"dropdown-button"}
                  onClick={() =>
                    navigate(`/${getValue(params, `orgId`, "")}/profile`)
                  }
                >
                  My Profile
                </button>
              ) : (
                <ButtonOnHover
                  header={
                    <button className={"dropdown-button"} disabled>
                      My Profile
                    </button>
                  }
                  text={
                    "Kindly choose the organization to access your profile."
                  }
                  width={"200px"}
                  height={"50px"}
                  hover
                />
              )}

              {/* </Link> */}
            </div>
            <div className={"dropdown-privary-policy-wrapper"}>
              <ul className="d-flex justify-content-center">
                <li className={"dropdown-privary-policy"}>
                  <a target="_blank" href="https://www.supercrm.ai/policy">
                    Privacy Policy
                  </a>
                </li>
                <li className="mx-2">-</li>
                <li className={"dropdown-privary-policy"}>
                  <a target="_blank" href="https://www.supercrm.ai/terms">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>

            <div className={`dropdown-playstore`}>
              <ul className="d-flex align-items-center">
                <li>
                  <img
                    src="/images/googleplay.png"
                    className={`playstore-icons img-fluid`}
                  />
                </li>
                <li>
                  <img
                    src="/images/apple-store.webp"
                    className={`playstore-icons img-fluid`}
                  />
                </li>
                {/* <li>
                  <FacebookLogin />
                </li> */}
              </ul>
            </div>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
