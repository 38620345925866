import InfoSvgComponent from "@assets/svg/info";
import AttachmentsPopup from "@components/Pages/Pipeline/Detail/right-container/Attachments/upload-popup";
import { useState } from "react";

const CreatePropertyStepThree = () => {
  const [isOpen, setIsOpen] = useState(true);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <p className="header_text__20">Add photos to enrich user experience!</p>
      <p className="small_text__14 color_desc mt-2">
        Publish at least 1 images to get your property more appealing. You can
        edit them later in dashboard.
      </p>
      <div className="my-4">
        <p
          // htmlFor="file-upload"
          className="custom-file-upload mb-2 requirement-message"
        >
          Choose a <span className="required-file-format">.jpg or .png</span>{" "}
          file
        </p>
        <input
          type="file"
          className="custom-upload-filename"
          accept={".jpg,.jpeg,.png"}
        />
      </div>
      <p className="small_text__14 color_desc mt-2 d-flex gap-2 align-items-center">
        <InfoSvgComponent size="16" />
        Maximum of 20 images can be uploaded.
      </p>
    </div>
  );
};

export default CreatePropertyStepThree;
