import { useState } from "react";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";

function UsersNavViews(props: any) {
  const { toggle, activeTab } = props;
  const [tabs] = useState([
    {
      value: "Active Users",
    },
    {
      value: "Invited Users",
    },
    {
      value: "Inactive Users",
    },
  ]);
  return (
    <ul
      className="d-flex align-items-center justify-content-between mt-3"
      style={{ borderBottom: "1px solid #efefef" }}
    >
      <div className="d-flex align-items-center justify-content-start ms-4">
        {tabs.map((linkObj: object, index: number) => {
          return (
            <li
              key={`pending-${index}`}
              className={`${
                activeTab == index + 1
                  ? "activebar__main-body-list_active"
                  : "activebar__main-body-list"
              }`}
              onClick={() => toggle(index + 1)}
            >
              <a className={"activebar__main-body-link p-3"}>
                {formatString(getValue(linkObj, `value`, ""))}
              </a>
            </li>
          );
        })}
      </div>
    </ul>
  );
}

export default UsersNavViews;
