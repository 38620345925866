import React from "react";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import ActivityLoader from "@components/common/Loader/activity-loader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import FormNoDataPage from "@components/common/NoData/form-nodata";

function FormCreatorTable(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();
  return (
    <>
      {isLoading ? (
        <ActivityLoader />
      ) : (
        <>
          {list.length > 0 ? (
            <div className="px-4 pt-4 pb-4">
              <table className="common_table w-100">
                <thead>
                  <tr>
                    <td>Title</td>
                    <td>Module</td>
                    {/* <td>Status</td> */}
                    <td>Created by</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item: object) => {
                    return (
                      <tr>
                        <td
                          className="header_blue_text__14 touchable-text"
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          {getValue(item, `title`, "")}
                        </td>
                        <td
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          {getValue(item, `module.label`, "")}
                        </td>
                        {/* <td
                          className="d-flex gap-2 align-items-center"
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          <div
                            className="active-icon"
                            style={{
                              background: getValue(item, `is_active`, false)
                                ? "#21C9AF"
                                : "#F05555",
                            }}
                          ></div>
                          <div>
                            {getValue(item, `is_active`, false)
                              ? "Active"
                              : "Inactive"}
                          </div>
                        </td> */}
                        <td
                          onClick={() =>
                            navigate(
                              `/${getValue(
                                params,
                                `orgId`,
                                ""
                              )}/settings/forms/edit/${getValue(
                                item,
                                `id`,
                                ""
                              )}`
                            )
                          }
                        >
                          {getValue(item, `created_by.first_name`, "")}{" "}
                          {getValue(item, `created_by.last_name`, "")}
                          <p className="small_text__12">
                            {convertCurrentDateWithTime(
                              getValue(item, `created_at`, "")
                            )}
                          </p>
                        </td>
                        <td>
                          <label className={"switch"}>
                            <input
                              type="checkbox"
                              checked={getValue(item, `is_active`, false)}
                              onChange={() =>
                                props.handleChangeStatus(
                                  getValue(item, `id`, ""),
                                  !getValue(item, `is_active`, false)
                                )
                              }
                            />
                            <span
                              className={`${"slider"} ${`round`} d-flex`}
                            ></span>
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="pt-5">
              <FormNoDataPage />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FormCreatorTable;
