import ManageMandatory from "./ManageMandatory";
import "./ManageTable.scss";
import { getValue } from "@utils/lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getImages } from "@common/image-helper";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import AddCircleSvgComponent from "@assets/svg/add-circle";
export default function ManageTable(props: any) {
  const { handleOnDragEnd } = props;
  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    // patching the existing style
    return {
      ...style,
      //   transition: `all 3s ease`,
      //   backgroundColor: "blue"
    };
  }
  //------------------------- Search Section -------------------------//
  return (
    <>
      <div className={"managetable-wrapper"}>
        <div
          className={`${"managetable-wrapper__header"} d-flex align-items-center w-100`}
        >
          <div className={"managetable-wrapper__icon-image"}>
            {/* <img
              src="/images/task/icons/header/drag-indicator.svg"
              className={`img-fluid cursor-pointer m-auto`}
            /> */}
          </div>
          {/* <div className={"managetable-wrapper__field-1"}>
            <h6 className={"managetable-wrapper__title"}>
              Field Name
            </h6>
          </div> */}
          <div className={"managetable-wrapper__field-1"}>
            <h6 className={"managetable-wrapper__title"}>Display Name</h6>
          </div>
          <div className={"managetable-wrapper__field-2"}>
            {/* <ManageMandatory /> */}
          </div>
          <div
            className=" position-absolute d-flex align-items-center border border-radius__5 p-2 bg-white"
            style={{ right: 10 }}
            onClick={props.handleFieldVisibility}
          >
            <AddCircleSvgComponent color={"#1a73e8"} />
            <p className="header_blue_text__16 ms-2">Add Custom Field</p>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="source" direction="vertical">
            {(provided) => (
              <div
                className="m-2"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {getValue(props, `usedFields.length`, 0) > 0
                  ? getValue(props, `usedFields`, []).map(
                      (item: object, index: number) => (
                        <Draggable
                          key={getValue(item, `api_name`, "")}
                          draggableId={getValue(item, `api_name`, "")}
                          index={index}
                          // isDragDisabled={index === 0 ? true : false}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`${`managetable-wrapper__body`}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getStyle(
                                provided.draggableProps.style,
                                snapshot
                              )}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className={
                                    "managetable-wrapper__icon-image d-flex justify-content-center"
                                  }
                                >
                                  <DragIndicatorSvgComponent
                                    color={"lightgray"}
                                  />
                                </div>

                                <div
                                  className={`${"managetable-wrapper__field-1"} d-flex align-items-center justify-content-between`}
                                >
                                  <div className="d-flex align-items-center">
                                    {getImages({
                                      name: getValue(item, `input_type`, ""),
                                      size: 24,
                                      color: "",
                                    })}
                                    <h6 className="managetable-wrapper__field-title ms-3">
                                      {getValue(item, `label`, "")}
                                    </h6>
                                  </div>
                                </div>
                                <div
                                  className={
                                    "managetable-wrapper__field-2 cursor-pointer"
                                  }
                                >
                                  <ManageMandatory
                                    item={item}
                                    handleVisibility={props.handleVisibility}
                                    handleEditFields={props.handleEditFields}
                                    selectedPipeline={props.selectedPipeline}
                                    index={index}
                                    getData={props.getData}
                                    handleEditCustomFields={
                                      props.handleEditCustomFields
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}
