import { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import AllBookingInformation from "./all-booking-information";
import CusotmerInformation from "./customer-information";
import PropertyNotes from "./property-notes";

const BookingInformation = () => {
  const [activeTab, setActiveTab] = useState("1");

  const views = [
    {
      id: "1",
      name: "Booking information",
    },
    {
      id: "2",
      name: "Customer information",
    },
    {
      id: "3",
      name: "Notes",
    },
  ];

  return (
    <div className="subpipeline-wrapper p-0">
      <div className="position-sticky top-0 bg-white pb-3">
        <Nav tabs>
          {views.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                className={`d-flex justify-content-between align-items-center ${
                  activeTab == item.id ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(item.id);
                }}
              >
                {item.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="px-5 pt-4 pb-5">
        {activeTab === "1" && <AllBookingInformation />}
        {activeTab === "2" && <CusotmerInformation />}
        {activeTab === "3" && <PropertyNotes />}
      </div>
    </div>
  );
};

export default BookingInformation;
