import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import Lottie from "react-lottie";
import bin from "./delete.json";
import "./addpopup.scss";
import ButtonComponent from "@components/Form/Button/Button";
interface IConfirmModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const ConfirmModal: React.FunctionComponent<IConfirmModalProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      toggle={getValue(props, `isOpen`, "")}
    >
      <section className="">
        <div className="">
          <div className="m-3 p-3">
            <img src="/images/info-delete.png" className="delete-icon" />
            <p className="delete_text mt-1">Are you sure ?</p>
            <p className="small_text__16 mt-2">
              {/* You are about to delete{" "} */}
              you want to continue 
              <b>{getValue(props, `deleteValue`, "")}</b>{" "}
            </p>
          </div>
        </div>
        <ModalFooter>
          <div className="popup-submit-container">
            <ButtonComponent
              onClickHandler={props.handleModal}
              buttonType={"scecondary"}
              buttonText="No"
            />
            <ButtonComponent
              buttonType={"delete"}
              buttonText={"Yes"}
              onClickHandler={() => props.handleSubmit()}
            />
          </div>
        </ModalFooter>
      </section>
    </Modal>
  );
};

export default ConfirmModal;
