import InlineCheckbox from "@components/Builder/InlineFormBuilder/Components/InlineCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import Checkbox from "@components/custom/Forms/Checkbox";
import React from "react";

const AdditionalGuestDetails = () => {
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="d-flex flex-column gap-3 mb-4">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter first name"
          label="Guest first name"
          required
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter last name"
          label="Guest last name"
          required
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter number"
          required
          label="Customer number"
          marginZero
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter customer email"
          label="Customer email"
        />
        <div>
          <p className="form-label">Gender</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter dob"
          label="Date of birth"
        />
        <div className="email-checkbox  align-items-center">
          <label className="checkbox-container">
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
          <p className="form-label">Address same as primary guest</p>
        </div>
        <div>
          <p className="form-label">Identity card type</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          placeholder="Enter Govt ID card no"
          label="Govt ID number"
        />
        <div>
          <p className="form-label">Upload front ID</p>
          <input
            type="file"
            className="custom-upload-filename"
            accept={".jpg,.jpeg,.png"}
          />
        </div>
        <div>
          <p className="form-label">Upload back ID </p>
          <input
            type="file"
            className="custom-upload-filename"
            accept={".jpg,.jpeg,.png"}
          />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter address line 1"
          label="Address line 1"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter address line 2"
          label="Address line 2"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter city"
          label="City"
        />

        <div>
          <p className="form-label">State</p>
          <SearchToolTip />
        </div>
        <div>
          <p className="form-label">Country</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter zip code"
          label="Zip code"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter occupation"
          label="Occupation"
        />
      </div>
    </div>
  );
};

export default AdditionalGuestDetails;
