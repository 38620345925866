import ButtonComponent from "@components/Form/Button/Button";
import React from "react";

const EditAmenities = () => {
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="d-flex align-items-center  justify-content-between border-bottom pb-3">
        <p>On premise parking</p>
        <div className="d-flex align-items-center gap-3">
          <ButtonComponent
            buttonType="secondary"
            buttonText={"Remove"}
            disabled
          />
          <ButtonComponent buttonType="primary" buttonText={"Add"} />
        </div>
      </div>
      <div className="d-flex align-items-center  justify-content-between border-bottom py-3">
        <p>Gym</p>
        <div className="d-flex align-items-center gap-3">
          <ButtonComponent buttonType="secondary" buttonText={"Remove"} />
          <ButtonComponent buttonType="primary" buttonText={"Add"} />
        </div>
      </div>
      <div className="d-flex align-items-center  justify-content-between border-bottom py-3">
        <p>Brekfast</p>
        <div className="d-flex align-items-center gap-3">
          <ButtonComponent buttonType="secondary" buttonText={"Remove"} />
          <ButtonComponent buttonType="primary" buttonText={"Add"} />
        </div>
      </div>
    </div>
  );
};

export default EditAmenities;
