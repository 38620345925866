import React, { useEffect, useState } from "react";
import "./task-left.scss";
import BackSvgComponent from "assets/svg/back-link";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { updateTask } from "@services/tasks.service";
import { convertCurrentDateWithTime } from "common/date-helpers";
import { concatString, formatString } from "common/text-helpers";
import { toast } from "sonner";
import PersonSvgComponent from "assets/svg/person";
import TaskSvgComponent from "assets/svg/task";
import CheckCircleSvgComponent from "assets/svg/check-circle";
import TaskInfoPopup from "../MiddleSection/components/info-popup";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import VerifiedSvgComponent from "@assets/svg/verified";
import CircleSvgComponent from "@assets/svg/circle";
import { getColorStyle } from "@common/colors";

function TaskDetailLeftSection(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const { list } = props;

  const handleNavigate = () => {
    navigate(`/${getValue(params, `orgId`, "")}/tasks`);
  };

  const handleClick = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/tasks/detail/${id}`);
  };
  return (
    <div className={"task_detail_left-container"}>
      <div
        className={`${"task_detail_left-header_section"} d-flex align-items-center px-3`}
      >
        <div onClick={handleNavigate}>
          <BackSvgComponent />
        </div>
        <h6 className="header_text ms-3">All Tasks</h6>
      </div>
      <div
        className={
          !props.isChanged
            ? "task_detail_left-list-update-section"
            : "task_detail_left-list"
        }
      >
        {getValue(list, `length`, 0) > 0
          ? list.map((item: any) => {
              return (
                <div
                  className={`${`task_detail_left-list_section${
                    getValue(params, `id`, "") === getValue(item, `id`, "")
                      ? "__active"
                      : ""
                  }`} cursor-pointer`}
                  onClick={() => handleClick(getValue(item, `id`, ""))}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <p className="header_text__14 ms-2">
                      {concatString(getValue(item, `title`, ""), 40)}
                    </p> */}
                    <div className="d-flex align-items-center ms-2">
                      {/* <TaskSvgComponent size={22} color={'lightgray'}/> */}
                      <TaskInfoPopup
                        header={
                          <p
                            className={`header_text__16 ms-2 ${
                              !getValue(item, `is_completed`, false)
                                ? ""
                                : "task-line-through"
                            }`}
                          >
                            {concatString(getValue(item, `title`, ""), 30)}
                          </p>
                        }
                        text={getValue(item, `title`, "")}
                        minLength={30}
                      />
                    </div>

                    <TooltipOnHover
                      header={
                        <div
                          className="filter-image-container"
                          onClick={() =>
                            props.handleTaskUpdate(
                              getValue(item, `id`, ""),
                              !getValue(item, `is_completed`, false)
                            )
                          }
                        >
                          {getValue(item, `is_completed`, false) ? (
                            <CheckCircleSvgComponent
                              size={30}
                              color={"#4cc793"}
                            />
                          ) : (
                            <CircleSvgComponent color={"lightgray"} size={30} />
                          )}
                        </div>
                      }
                      text={
                        getValue(item, `is_completed`, false)
                          ? "Mark as Open"
                          : "Mark as Completed"
                      }
                      show
                      height={"40px"}
                      width={"150px"}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1 ms-2">
                    <div className="d-flex align-items-center">
                      <AccountCircleSvgComponent
                        size={22}
                        color={"lightgray"}
                      />
                      <p className="small_text__14 ms-1">
                        {getValue(item, `owner.first_name`, "")}{" "}
                        {getValue(item, `owner.last_name`, "")}
                      </p>
                    </div>
                    <p className="small_text__12  text-secondary">
                      {convertCurrentDateWithTime(
                        getValue(item, `created_at`, "")
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mt-1 justify-content-between ms-2">
                    <div
                      className="d-flex align-items-center"
                      style={getColorStyle(
                        getValue(item, `is_completed`, false)
                          ? "completed"
                          : "not-completed"
                      )}
                    >
                      {/* <VerifiedSvgComponent
                        size={18}
                        color={
                          getValue(item, `is_completed`, false)
                            ? "#198754"
                            : "#dc3545"
                        }
                      /> */}
                      <p
                        className={`small_text__12 ms-1 ${
                          getValue(item, `is_completed`, false)
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {getValue(item, `is_completed`, false)
                          ? "Completed"
                          : "Not Completed"}
                      </p>
                    </div>
                    <p
                      className={`small_text__14 ${
                        getValue(item, `priority`, "") === "High"
                          ? "text-danger"
                          : getValue(item, `priority`, "") === "Medium"
                          ? "text-warning"
                          : "text-secondary"
                      }`}
                    >
                      {formatString(getValue(item, `priority`, ""))}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default TaskDetailLeftSection;
