import { getValue } from "@utils/lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

function Navbar(props: any) {
  const { views, route } = props;
  const location = useLocation();
  const params = useParams();
  return (
    <div className="w-100">
      <Nav tabs>
        {views.map((item: object, index: number) => (
          <NavItem key={index}>
            <NavLink
              className={`d-flex justify-content-between align-items-center ${
                location.pathname ===
                `/${getValue(params, `orgId`, "")}${route}`
                  ? "active"
                  : ""
              }`}
            >
              {getValue(item, `name`, "")}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  );
}

export default Navbar;
