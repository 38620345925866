import React from "react";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import BackSvgComponent from "@assets/svg/back-link";
import { useNavigate, useParams } from "react-router-dom";

function FormCreatorHeader(props: any) {
  const { isLoading, handleSubmit, submitText, activeTab, fieldCount } = props;
  const navigate = useNavigate();
  const params = useParams();

  const actionText = () => {
    switch (activeTab) {
      case 1:
        return "Save & Next";
      case 2:
        return !getValue(params, `id`, "") ? "Save" : "Update";
      default:
        return "Save";
    }
  };
  return (
    <div>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div onClick={() => navigate(`${getValue(props, `route`, "")}`)}>
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3"> Forms</p>
          </div>

          <div className="d-flex align-items-center">
            {getValue(props, `showPreview`, false) && (
              // <ButtonComponent
              //   buttonType="primary"
              //   buttonText={"Preview"}
              //   onClickHandler={() => props.setActiveTab(0)}
              //   disabled={isLoading}
              // />
              <button
                className="d-flex gap-2 dashboard-button dashboard-button--secondary px-4 mx-3  d-flex justify-content-center align-items-center"
                disabled={isLoading}
                onClick={() => props.setActiveTab(0)}
              >
                <img src="/images/icons/eye.png" width="16" />
                Preview
              </button>
            )}
            {getValue(props, `showSubmit`, false) ? (
              <div className="d-flex align-items-center gap">
                {(activeTab == 2 || activeTab == 3) && (
                  <ButtonComponent
                    buttonType="secondary"
                    buttonText={
                      isLoading
                        ? "Please wait..."
                        : submitText
                        ? submitText
                        : "Previous"
                    }
                    onClickHandler={() => handleSubmit(activeTab - 1)}
                    disabled={isLoading}
                  />
                )}
                {activeTab == 3 && (
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={"Done"}
                    onClickHandler={() => props.handleDone()}
                    disabled={isLoading}
                  />
                )}

                {activeTab != 3 && (
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={
                      isLoading
                        ? "Please wait..."
                        : submitText
                        ? actionText()
                        : actionText()
                    }
                    onClickHandler={() => handleSubmit(activeTab + 1)}
                    disabled={
                      isLoading || getValue(props, `showPreview`, "") === false
                    }
                  />
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormCreatorHeader;
