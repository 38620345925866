import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";

function MessageLayout({ children }: any) {
  return (
    <>
      <HomeHeader />
      <CompanyProfileSubHeader />
      <div className={`${"email_container"} w-100`}>
        <CompanyProfileNavigation />
        {children}
      </div>
    </>
  );
}

export default MessageLayout;
