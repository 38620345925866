import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import ChatReceiverSection from "./chat-receiver";
import ChatSenderSection from "./chat-sender";

function ConversationMessages(props: any) {
  const { conversation, setSelectedReplyMessage } = props;
  return (
    <div>
      {getValue(conversation, `length`, 0) > 0
        ? 
        conversation.map((item: object) => {
            return (
              <>
                {(getValue(item, `text_body`, "") ||
                  getValue(item, `upload_file.key`, "") ||
                  getValue(item, `broadcast_id`, "")) &&
                  (!getValue(item, `business_initiated`, false) ? (
                    <ChatReceiverSection
                      item={item}
                      setSelectedReplyMessage={setSelectedReplyMessage}
                    />
                  ) : (
                    <ChatSenderSection
                      item={item}
                      setSelectedReplyMessage={setSelectedReplyMessage}
                    />
                  ))}
              </>
            );
          })
        : ""}
    </div>
  );
}

export default ConversationMessages;
