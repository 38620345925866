import ComfirmOrganization from "@pages/Private/Auth/confirm-org";
import ProfilePage from "@pages/Private/Profile";
import EmailAuthCallbackpage from "@pages/Private/Settings/email/components/auth";

//------------- Email configure -----------//
export const AuthRoutes = [
  {
    path: "/oauth/:provider/callback",
    name: "Contacts",
    component: EmailAuthCallbackpage,
  },
  {
    path: "/organisations/join",
    name: "Contacts",
    component: ComfirmOrganization,
  },
  {
    path: "/:orgId/profile",
    name: "Contacts",
    component: ProfilePage,
  },
  
];
