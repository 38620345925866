import { getValue } from "@utils/lodash";
import { Link } from "react-router-dom";
import "./CreateHeader.scss";
import { concatString } from "common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";

export default function CreateHeader(props: any) {
  const { title } = props;
  return (
    <section className={`create-header`}>
      <div className="container-fluid-padding d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Link
            to={
              getValue(props, `backUrl`, "")
                ? getValue(props, `backUrl`, "")
                : "/more/organization/users/user-information"
            }
          >
            <img
              src="/images/icons/close.svg"
              className={`cursor-pointer create-header__cancel-button`}
            />
          </Link>
          <h6 className={`create-header__title"]} normal-font`}>
            {title ? concatString(title, 20) : ""}
          </h6>
        </div>
        {!getValue(props, `hideSubmit`, "") ? (
          <div className="d-flex justify-content-center align-items-center">
            <ButtonComponent
              buttonText={"Cancel"}
              buttonType="secondary"
              onClickHandler={() => {
                props.onCancel();
              }}
            />
            <ButtonComponent
              buttonText={
                getValue(props, `isLoading`, false)
                  ? "Please wait..."
                  : "Submit"
              }
              buttonType="primary"
              onClickHandler={() => {
                props.onSubmit();
              }}
              disabled={getValue(props, `isLoading`, false) ? true : false}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
}
