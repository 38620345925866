import React, { useEffect, useState } from "react";
// import "./messages.scss";
import { Link, useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  getWhatsappManagerConfig,
  whatsappManagerAccess,
  whatsappRegisterPhone,
  whatsappWebhookSubscribe,
} from "@services/fb.service";
import { getValue } from "@utils/lodash";
import FacebookLogin from "social/facebook-login";
import Loader from "@components/common/Loader/loading";
import InputRuleForm from "@components/InputRuleForm/form";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import RegisterPhonePopup from "../components/register-phone-popup";

function Messages() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const [available, setAvailable] = useState(false);
  const toggleAvailable = () => {
    setAvailable(!available);
  };

  useEffect(() => {
    getFbConfig();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [fbConfig, setFbConfig] = useState({});
  const getFbConfig = async () => {
    try {
      let resp = await getWhatsappManagerConfig();
      if (resp) {
        setFbConfig(getValue(resp, `data`, ""));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (accessToken: string) => {
    if (accessToken)
      try {
        let payload = {
          accessCode: accessToken,
        };
        let resp = await whatsappManagerAccess(payload);
        if (resp) {
          toast.success("Subscribed successfully");
          getFbConfig();
        }
      } catch (error) {}
  };

  const handleDeactivateFb = async () => {
    try {
    } catch (error) {}
  };

  const activeTab = getValue(UrlParams, `active_tab`, "1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      delete UrlParams.search_text;
      let payload = {
        ...UrlParams,
        active_tab: tab,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = async () => {
    setIsOpen(!isOpen);
  };

  const [otpLoading, setOtpLoading] = useState(false);
  const registerPhone = async (otp: string) => {
    try {
      let payload = {
        pinCode: otp,
      };
      setOtpLoading(true);
      let resp = await whatsappRegisterPhone(payload);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleModal();
        setOtpLoading(false);
      } else {
        setOtpLoading(false);
      }
    } catch (error) {
      setOtpLoading(false);
      handleModal();
    }
  };

  const webhookSubscribe = async () => {
    try {
      let resp = await whatsappWebhookSubscribe();
      if (resp) {
        toast.success("Subscribe successfully");
      }
    } catch (error) {}
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-100">
          {!getValue(fbConfig, `accountLinked`, false) ? (
            <div className="chatbox-wrapper align-items-center justify-content-center d-flex w-100">
              <div className="chatbox-container">
                <div className="chatbox-center-container">
                  <div className="connect-account">
                    <h5 className="integration-heading">
                      Connect your WhatsApp Business Account to SuperCRM
                    </h5>
                    <div className="icons-wrapper">
                      <img
                        src="/logo.svg"
                        alt="supercrm-logo"
                        className="supercrm-logo"
                      ></img>
                      <img
                        src="/images/icons/add-blue.svg"
                        alt="add-icon"
                      ></img>
                      <img
                        src="/images/whatsapp-business.svg"
                        alt="whatsapp-business-icon"
                      ></img>
                    </div>
                    <h6 className="integration-subheading">
                      WhatsApp Business
                    </h6>
                    <p className="integration-description">
                      Link and authorize your WhatsApp Business Account with
                      SuperCRM to engage your <br />
                      customers via the WhatsApp Business Platform{" "}
                      <Link
                        to="#"
                        className="message-blue-text"
                        target="_blank"
                      >
                        Learn more
                      </Link>
                    </p>
                    {/* <ConfirmIntegration handleSubmit={handleSubmit} /> */}
                    <FacebookLogin handleSubmit={handleSubmit} />
                    <div className="note-section">
                      <h6 className="note-heading">Requirements</h6>
                      <ul className="note-points-wrapper">
                        <li className="note-points">
                          You must have a Facebook Account
                        </li>
                        <li className="note-points">
                          You should have a valid phone number for WhatsApp. The
                          phone number must meet
                          <br /> the criteria mentioned{" "}
                          <Link
                            to="https://developers.facebook.com/docs/whatsapp/cloud-api/phone-numbers"
                            className="message-blue-text"
                            target="__blank"
                          >
                            here
                          </Link>
                          .
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="header_container d-flex align-items-center justify-content-between border-bottom p-3">
                <div className="d-flex align-items-center">
                  <img src={`/images/whatsapp_b.png`} />
                  <h6 className="header_text__16 ms-2">WhatsApp Business</h6>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  {!getValue(fbConfig, `details.phoneRegistered`, false) && (
                    <button
                      className="dashboard-button px-4"
                      onClick={handleModal}
                    >
                      Phone Register
                    </button>
                  )}
                  {!getValue(fbConfig, `details.webhookSubscribed`, false) && (
                    <button
                      className="dashboard-button px-4"
                      onClick={webhookSubscribe}
                    >
                      Webhook Subscribe
                    </button>
                  )}

                  <FacebookLogin handleSubmit={handleSubmit} edit />
                </div>
              </div>
              <div className={`organisations-wrapper_company`}>
                <div className={"organisations-wrapper__main-section_create"}>
                  <div className={`form_wrapper`}>
                    <InputRuleForm
                      inputType="TEXT"
                      label={"Display Name"}
                      placeholder="Enter Name"
                      name={"name"}
                      value={getValue(fbConfig, `details.verifiedName`, "")}
                      style={{ height: "40px" }}
                      disabled
                    />
                    <InputRuleForm
                      inputType="TEXT"
                      label={"Phone Number"}
                      placeholder="Enter Name"
                      name={"name"}
                      value={getValue(
                        fbConfig,
                        `details.displayPhoneNumber`,
                        ""
                      )}
                      style={{ height: "40px" }}
                      disabled
                    />
                  </div>
                </div>
                <div className="p-5">
                  <h6 className="header_text__16 text-center">
                    Account Details
                  </h6>
                  <div className="d-flex justify-content-center">
                    <div className="gap-5 mt-3">
                      <div className="d-flex ms-3 align-items-center gap-3 my-3 ">
                        <p className="small_text__14 ">Display Name</p> :{" "}
                        <p className=" header_text__16 ">
                          {getValue(fbConfig, `details.verifiedName`, "")}
                        </p>
                      </div>
                      <div className="d-flex ms-3 align-items-center gap-3 my-3">
                        <p className="small_text__14 ">Phone Number</p>:
                        <p className="header_text__16">
                          {getValue(fbConfig, `details.displayPhoneNumber`, "")}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <RegisterPhonePopup
        isOpen={isOpen}
        handleModal={handleModal}
        registerPhone={registerPhone}
        submitLoading={otpLoading}
      />
    </>
  );
}

export default Messages;
