import EditSvgComponent from "@assets/svg/edit";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import TemplateNoDataPage from "@components/common/NoData/template-nodata";
import { getValue } from "@utils/lodash";
import React from "react";
import { Tooltip } from "react-tooltip";

function TemplateTable(props: any) {
  const { templateList } = props;

  return (
    <>
      {templateList.length > 0 ? (
        <div className="datatable_container">
          <div className=" datatable_table_wrapper">
            <table className="datatable_table w-100">
              <thead className="datatable_table_thead position-relative">
                <tr className="datatable_table_tr">
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">TEMPLATE NAME</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">CATEGORY</h6>
                  </th>
                  {/* <th className="datatable_table_th ">
                <h6 className="header_text__12">LANGUAGE</h6>
              </th> */}
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">LAST UPDATED</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">STATUS</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">REJECT REASON</h6>
                  </th>
                  {/* <th className="datatable_table_th ">
                <h6 className="header_text__12">ACTIONS</h6>
              </th> */}
                </tr>
              </thead>
              <tbody className="datatable_table_tbody">
                {templateList.map((item: object, index: any) => {
                  return (
                    <tr
                      className="datatable_table_tr touchable-text"
                      onClick={() =>
                        // props.handleTemplate(getValue(item, `id`, ""))
                        props.handlePreview(getValue(item, `id`, ""))
                      }
                    >
                      <td
                        className="datatable_table_td "
                        style={{ display: "table-cell" }}
                      >
                        <div className="header_blue_text__14 ">
                          {getValue(item, `name`, "")}
                        </div>
                      </td>
                      <td className="datatable_table_td ">
                        {getValue(item, `category`, "")}
                      </td>
                      <td className="datatable_table_td ">
                        {convertCurrentDateWithTime(
                          getValue(item, `updated_at`, "")
                        )}
                      </td>
                      <td className="datatable_table_td ">
                        <p
                          className="header_blue_text__14"
                          style={{
                            color:
                              getValue(item, "status", "") === "REJECTED"
                                ? "#F05555"
                                : "#21C9AF",
                          }}
                        >
                          {formatString(getValue(item, "status", ""))}
                        </p>
                      </td>
                      <td className={`datatable_table_td `}>
                        <p
                          className={`small_text__14 my-template-${index}`}
                          style={{ whiteSpace: "normal" }}
                        >
                          {getValue(item, "error_reason_message", "")
                            ? getValue(item, "error_reason_message", "")
                                .length > 70
                              ? getValue(
                                  item,
                                  "error_reason_message",
                                  ""
                                ).slice(0, 70) + "..."
                              : getValue(item, "error_reason_message", "")
                            : ""}
                          <Tooltip
                            className="tooltip"
                            anchorSelect={`.my-template-${index}`}
                          >
                            {getValue(item, `error_reason_message`, "")}
                          </Tooltip>
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <TemplateNoDataPage />
      )}
    </>
  );
}

export default TemplateTable;
