import AccountCircleSvgComponent from "@assets/svg/account-circle";
import MoreVerticalSvgComponent from "@assets/svg/more-vertical";
import React from "react";

function ChatLeftHeader(props: any) {
  return (
    <div className="filter-section-wrapper">
      <div className="filter-section">
        <div>
          <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
        </div>
        <div className="cursor-pointer">
          <MoreVerticalSvgComponent />
        </div>
      </div>
    </div>
  );
}

export default ChatLeftHeader;
