import { CopyToClipboard } from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import CopySvgComponents from "assets/svg/copy";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function ConfigPopup(props: any) {
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      toggle={props.handleModal}
      size="lg"
    >
      <ModalHeader>
        <div className="d-flex align-items-center">
          <h6 className="header_text__18">Authenticate</h6>
          <p className="small_text__14 ms-2 color_primary">
            {getValue(props, `configInfo.domain`, "")}
          </p>
        </div>
      </ModalHeader>
      <ModalBody className="m-2">
        <p className="small_text__14 mb-3">
          Verify your domain{" "}
          <span className="small_text__14 mx-1 color_primary">
            {getValue(props, `configInfo.domain`, "")}
          </span>
          to send messages with email addresses from this domain name. You will
          need admin access to log into your domain provider (e.g. GoDaddy,
          Cloudflare) and create the following records in your DNS settings, by
          copying and pasting the below values.
        </p>
        <div className="mb-3">
          <h6 className="header_text__18">Mail CNAME</h6>
          <p className="small_text__14 mt-2">
            Add the below record to your domain's DNS settings. If the Mail
            CNAME record exists already, add include:one.zoho.com alone to it
          </p>
          <div className="mt-3 d-flex align-items-center ">
            <p className="small_text__16 border__dashed w-75 p-2 word__break">
              {getValue(props, `info.mail_cname.data`, "")
                ? getValue(props, `info.mail_cname.data`, "")
                : getValue(props, `info.mail_cname.reason`, "")}
            </p>
            <div
              className="ms-3 cursor-pointer"
              onClick={() =>
                CopyToClipboard(
                  getValue(props, `info.mail_cname.data`, "")
                    ? getValue(props, `info.mail_cname.data`, "")
                    : getValue(props, `info.mail_cname.reason`, "")
                )
              }
            >
              <CopySvgComponents />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <h6 className="header_text__18">DKIM 1</h6>
          <p className="small_text__14 mt-2">
            Add the public key below to the subdomain in your domain's DNS
            settings
          </p>
          <div className="mt-3 d-flex align-items-center ">
            <p className="small_text__16 border__dashed w-75 p-2 word__break">
              {getValue(props, `info.dkim1.data`, "")
                ? getValue(props, `info.dkim1.data`, "")
                : getValue(props, `info.dkim1.reason`, "")}
            </p>
            <div
              className="ms-3 cursor-pointer"
              onClick={() =>
                CopyToClipboard(
                  getValue(props, `info.dkim1.data`, "")
                    ? getValue(props, `info.dkim1.data`, "")
                    : getValue(props, `info.dkim1.reason`, "")
                )
              }
            >
              <CopySvgComponents />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <h6 className="header_text__18">DKIM 2</h6>
          <p className="small_text__14 mt-2">
            Add the public key below to the subdomain in your domain's DNS
            settings
          </p>
          <div className="mt-3 d-flex align-items-center">
            <p className="small_text__16 border__dashed w-75 p-2 word__break">
              {getValue(props, `info.dkim2.data`, "")
                ? getValue(props, `info.dkim2.data`, "")
                : getValue(props, `info.dkim2.reason`, "")}
            </p>
            <div
              className="ms-3 cursor-pointer"
              onClick={() =>
                CopyToClipboard(
                  getValue(props, `info.dkim2.data`, "")
                    ? getValue(props, `info.dkim2.data`, "")
                    : getValue(props, `info.dkim2.reason`, "")
                )
              }
            >
              <CopySvgComponents />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            buttonText={"Cancel"}
            buttonType="secondary"
            onClickHandler={props.handleModal}
          />
          <ButtonComponent
            buttonText={props.isLoading ? "Validating..." : "Validate Records"}
            buttonType="primary"
            onClickHandler={props.handleSubmit}
            disabled={props.isLoading}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ConfigPopup;
