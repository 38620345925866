import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getUsers = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users/list?${queryRequest}`);

export const getUsersEmail = (queryRequest: string) =>
  get(`${config.API_URL}/settings/org/users?${queryRequest}`);

export const getOrgUsers = () => get(`${config.API_URL}/settings/org/users/me`);
