import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function ChatRightHeader(props: any) {
  const { handleToggleWidth, selectedContact, selectedConversationInfo } =
    props;
  return (
    <div
      className="conversation-header  cursor-pointer"
      onClick={handleToggleWidth}
    >
      <div className="header-right-section">
        <button className="header-name">
          <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
          <div>
            {getValue(selectedContact, `contact.first_name`, "") ||
            getValue(selectedContact, `contact.last_name`, "") ? (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedContact, `contact.first_name`, "")
                )}{" "}
                {formatString(
                  getValue(selectedContact, `contact.last_name`, "")
                )}
              </p>
            ) : (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(getValue(selectedContact, `wa_profile_name`, ""))}
              </p>
            )}

            <p className="small_text__12 mt-1 color_desc">
              Click here for contact info
            </p>
          </div>
        </button>
      </div>
    </div>
  );
}

export default ChatRightHeader;
