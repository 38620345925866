import React from "react";
import "./nodata.scss";
function CallsNoDataPage(props: any) {
  return (
    <div className="n_container">
      <div className="nodata_container w-40">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Review past conversations in SuperCRM.
            </h6>
            <p className="nodata_container_desc mt-2">
              Calls will appear here if you call through SuperCRM, log
              call/meeting activities, receive an inbound call with a voicemail,
              or record calls from third parties.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/calls.svg"}
            alt="SVG Image"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default CallsNoDataPage;
