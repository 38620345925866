import convertMarkdownToHtml from "@pages/Private/Broadcast/TemplateMessages/useMarkdown";
import parse from "html-to-md";

export const replacePlaceholders = (text: any) => {
  if (text) {
    let replacedText = parse(text);
    let escapedMatch = String(replacedText).replace(/\\/g, "");
    return escapedMatch;
  } else {
    return null;
  }
  // return replacedText;
};

export const replaceDefaultPlaceholders = (text: any) => {
  let replacedText = convertMarkdownToHtml(text);
  return replacedText;
};
