import ButtonComponent from "@components/Form/Button/Button";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import React from "react";

const CusotmerInformation = () => {
  return (
    <div>
      <div className="border-bottom pb-4">
        <p className="form-label">All customer(s)</p>
        <SearchToolTip placeholder="Choose customer" />
      </div>
      <div className="grid-container row-gap-3 column-gap-4 mt-3">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value="ravi"
          placeholder="Enter customer name"
          label="Customer name"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value="test@gmail.com"
          placeholder="Enter email"
          label="Customer email"
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="9999988888"
          placeholder="Enter contact number"
          label="Customer number"
          marginZero
        />
        <div>
          <p className="form-label">Gender</p>
          <SearchToolTip placeholder="Choose gender" />
        </div>
        <div>
          <p className="form-label">Guest category</p>
          <SearchToolTip placeholder="Choose guest category" />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter dob"
          label="Date of birth"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter address line 1"
          label="Address line 1"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter address line 2"
          label="Address line 2"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter city"
          label="City"
        />

        <div>
          <p className="form-label">State</p>
          <SearchToolTip />
        </div>
        <div>
          <p className="form-label">Country</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter zip code"
          label="Zip code"
        />
        <div>
          <p className="form-label">Upload front ID</p>
          <input
            type="file"
            className="custom-upload-filename"
            accept={".jpg,.jpeg,.png"}
          />
        </div>
        <div>
          <p className="form-label">Upload back ID </p>
          <input
            type="file"
            className="custom-upload-filename"
            accept={".jpg,.jpeg,.png"}
          />
        </div>
        <div className="d-flex align-items-center gap-2">
          <CustomCheckbox />
          <p className="form-label">
            Update customer information for all the rooms{" "}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <ButtonComponent
          //   onClickHandler={props.handleCancel}
          buttonType={"primary"}
          buttonText="Update ravi's details"
        />
      </div>
    </div>
  );
};

export default CusotmerInformation;
