import { getValue } from "@utils/lodash";
import BotReplyOption from "./Options/reply";
import BotsuggestionOption from "./Options/suggestion";
import BotVisitorPhoneOption from "./Options/visitor-phone";
import BotVisitorNameOption from "./Options/visitor-name";
import BotVisitorEmailOption from "./Options/visitor-email";
import BotVisitorCompanyNameOption from "./Options/visitor-company-name";

function CustomNodeOptions(props: any) {
  const { data, handleDelete } = props;
  const handleStyle = {
    height: "10px",
    width: "10px",
    border: "1px solid gray",
    borderRadius: "5px",
    background: "white",
  };

  const renderTypes = (data: object) => {
    switch (getValue(data, `component`, "")) {
      case "reply":
        return (
          <BotReplyOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "suggestions":
        return (
          <BotsuggestionOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_name":
        return (
          <BotVisitorNameOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_email":
        return (
          <BotVisitorEmailOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "visitor_phone":
        return (
          <BotVisitorPhoneOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      case "company_name":
        return (
          <BotVisitorCompanyNameOption
            handleStyle={handleStyle}
            data={data}
            handleDelete={handleDelete}
          />
        );

      default:
        return null;
    }
  };
  return <div>{renderTypes(data)}</div>;
}

export default CustomNodeOptions;
