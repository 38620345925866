import React from "react";
import ConfirmImportPopup from "./popup/confirm-import";
import { getValue } from "@utils/lodash";

function ImportSubmitButton(props: any) {
  const {
    currentStep,
    setCurrentStep,
    handleClose,
    setShowConfirmation,
    showConfirmation,
    handleSubmitData,
    submitLoading,
    info,
  } = props;
  const handleNext = () => {
    // if (getValue(info, `column_mapping.length`, 0) > 0) {}
    setCurrentStep((prevStep: any) => prevStep + 1);
  };
  const handleGoBack = () => {
    setCurrentStep((prevStep: any) => Math.max(1, prevStep - 1));
  };

  const handleGoBackWithConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleConfirmGoBack = () => {
    handleGoBack();
    setShowConfirmation(false);
  };
  const handleCancelGoBack = () => {
    setShowConfirmation(false);
  };

  switch (currentStep) {
    case 1:
      return (
        <>
          <button onClick={handleClose} className="cancel-button">
            Cancel
          </button>
          <button onClick={handleNext} className="next-button">
            Next
          </button>
        </>
      );
    // case 2:
    //   return (
    //     <>
    //       <button onClick={handleGoBack} className="cancel-button">
    //         Go back
    //       </button>
    //       <button onClick={handleNext} className="next-button">
    //         Next
    //       </button>
    //     </>
    //   );

    case 2:
      return (
        <>
          <button onClick={handleGoBack} className="cancel-button">
            Go back
          </button>
          <button
            onClick={handleNext}
            className="next-button"
            disabled={
              getValue(info, `column_mapping.length`, 0) === 0 ? true : false
            }
          >
            Next
          </button>
        </>
      );
    case 3:
      return (
        <>
          <button
            onClick={handleGoBackWithConfirmation}
            className="cancel-button"
          >
            Go back
          </button>
          <button onClick={handleNext} className="next-button">
            Next
          </button>
          <ConfirmImportPopup
            open={showConfirmation}
            onClose={handleCancelGoBack}
            onConfirm={() => {
              handleConfirmGoBack();
              props.setInfo({});
            }}
          />
        </>
      );
    case 4:
      return (
        <>
          <button onClick={handleGoBack} className="cancel-button">
            Go back
          </button>
          <button onClick={handleSubmitData} className="next-button">
            {submitLoading ? "Importing..." : "Import Now"}
          </button>
        </>
      );
    default:
      return null;
  }
}

export default ImportSubmitButton;
