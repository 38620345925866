import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { getValue } from "@utils/lodash";
import countries from "@json/dialcodes.json";
import Input from "./Input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CountrySearchToolTip from "../Dropdown/CountrySearchTooltip";
import { formHandlePhoneArrayChange } from "@common/handleChange";
import { getLastTenDigits } from "@common/text-helpers";
import { getDigitsExceptLastTen } from "@common/text-helpers";
import { handleChangeNumber } from "@common/handleChange";
import { QueryRequestHelper } from "@common/query-request-helper";
import { checkModuleDuplicates } from "@services/common.service";

function DialcodePhoneInputComponent(props: any) {
  const {
    value,
    label,
    placeholder,
    hideLabel,
    validator,
    showWhatsapp,
    index,
    setFields,
    mandatory,
    api_name,
    phoneInline
  } = props;

  const [countryCode, setCountryCode] = useState("+91");
  const [nationalNumber, setNationalNumber] = useState("");
  const handleInputChange = (input: any) => {
    if (input) {
      if (
        getValue(input, `length`, 0) === 10 ||
        getValue(input, `length`, 0) < 10
      ) {
        setNationalNumber(input);
      } else if (getValue(input, `length`, 0) > 10) {
        let lastTenDigits = getLastTenDigits(input);
        let digitsExceptLastTen = getDigitsExceptLastTen(input);
        setCountryCode(digitsExceptLastTen);
        setNationalNumber(lastTenDigits);
      } else {
        const parsedNumber = parsePhoneNumberFromString(input);
        if (parsedNumber) {
          setNationalNumber(parsedNumber.nationalNumber);
        } else {
          setCountryCode("");
          setNationalNumber("");
        }
      }
    }
  };
  useEffect(() => {
    handleInputChange(value);
  }, []);

  const getPhoneNumber = (input: any) => {
    if (input) {
      if (input.startsWith("+")) {
        const parsedNumber = parsePhoneNumberFromString(input);
        if (parsedNumber) {
          setNationalNumber(parsedNumber.nationalNumber);
        } else {
          return null;
        }
      } else {
        return setNationalNumber(input);
      }
    } else {
      return null;
    }
  };

  const handleChangeCountryCode = (obj: object) => {
    let input = getValue(obj, `value`, "");
    setCountryCode(getValue(obj, `value`, ""));
    formHandlePhoneArrayChange(index, input + nationalNumber, setFields);
  };

  const handleChangePhoneNumber = (e: any) => {
    let input = handleChangeNumber(e);
    setNationalNumber(input);
    formHandlePhoneArrayChange(index, countryCode + input, setFields);
  };

  const handleBlur = async (e: any) => {
    const value = countryCode + nationalNumber;
    let outputString = value.replace(/[^+\d]/g, "").trim();
    if (api_name && outputString) {
      let payload = {
        [api_name]: encodeURIComponent(outputString),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await checkModuleDuplicates(props.module, queryRequest);
      if (resp) {
        if (getValue(resp, `data.id`, "")) {
          if (!props.duplicateAPINames.includes(api_name)) {
            props.duplicateAPINames.push(api_name);
            props.setDuplicateAPINames([...props.duplicateAPINames]);
          }
        } else {
          let filtered = props.duplicateAPINames.filter(
            (item: string) => api_name !== item
          );
          props.setDuplicateAPINames(filtered);
        }
      }
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {!hideLabel && <label className={`form-label`}>{label} </label>}
        {showWhatsapp && isValidPhoneNumber(value) && (
          <img
            src="/images/whatsapp.png"
            height={25}
            width={25}
            onClick={() =>
              isValidPhoneNumber(value)
                ? window.open(
                    `https://api.whatsapp.com/send?text=Hi&phone=+91${value}`
                  )
                : null
            }
          />
        )}
      </div>
      <div className="d-flex">
        <div className="w-20">
          <CountrySearchToolTip
            label={"label"}
            data={countries.map((item: object) => ({
              ...item,
              label: getValue(item, "dial_code", ""),
              value: getValue(item, "dial_code", ""),
            }))}
            selectKey="dial_code"
            value={countryCode}
            onChange={handleChangeCountryCode}
            required={mandatory}
            phoneInline={phoneInline}
          />
        </div>
        <div className="w-80">
          <Input
            {...props}
            hideLabel
            value={nationalNumber}
            onChange={handleChangePhoneNumber}
            placeholder={placeholder}
            mandatory={false}
            validator={null}
            onBlur={handleBlur}
          />
        </div>
      </div>
      {validator && (
        <p className={"form-error"}>
          {value && !isValidPhoneNumber(countryCode + nationalNumber)
            ? `Please enter valid ${label}`
            : validator.current.message(
                label,
                isValidPhoneNumber(countryCode + nationalNumber)
                  ? nationalNumber
                  : "",
                "required"
              )}
        </p>
      )}
    </div>
  );
}

export default DialcodePhoneInputComponent;
