import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import { useState } from "react";
import { Modal } from "reactstrap";
// import "./edit-popup.scss";

function UserPopup(props: any) {
  const { request, setRequest, simpleValidator } = props;
  const [editOptions] = useState([
    { value: "owner_id", label: "Owner" },
    { value: "stage_id", label: "Stage" },
  ]);
  return (
    <div>
      <Modal isOpen={getValue(props, `isOpen`, "")} toggle={props.handleModal}>
        <CommonPopupHeader
          title={"User"}
          handleBackButton={props.handleModal}
          height={"60px"}
        />
        <section className="edit-popup-wrapper">
          <div className="">
            <InputRuleForm
              inputType="EMAIL"
              label="Email"
              placeholder={"Enter Email"}
              name="identifiers"
              value={getValue(request, `identifiers`, "")}
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
              validator={simpleValidator}
              validLeft
            />
            <div className="edit-footer-wrapper">
              <ButtonComponent
                onClickHandler={props.handleModal}
                buttonType={"scecondary"}
                buttonText="Cancel"
              />
              <ButtonComponent
                buttonType={"primary"}
                buttonText={props.isLoading ? "Please wait..." : "Submit"}
                disable={props.isLoading}
                onClickHandler={() => props.handleSubmit()}
              />
            </div>
          </div>
        </section>
      </Modal>
    </div>
  );
}

export default UserPopup;
