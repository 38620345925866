import InfoSvgComponent from "@assets/svg/info";
import { handleBooleanChange } from "@common/handleChange";
import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import TextArea from "@components/custom/Forms/textarea";
import React from "react";

const BotBuilderStepTwo = (props: any) => {
  return (
    <div className="mb-5">
      {/* <div className="">
        <p className="header_text__18">Choose a brand</p>
        <p className="pending-task-commondescription mt-1">
          The bot you build will be deployed and assist visitors on the website
          where the chosen brand is configured.
        </p>
        <ImportCheckbox
          value="Randoo"
          label="Randoo"
          iconPath={"/images/icons/code-64.png"}
          selectedOption={selectedBrand}
          // onChange={handleChange}
        />
      </div>
      <div className="mt-5">
        <p className="header_text__18">Associate departments</p>
        <p className="pending-task-commondescription mt-1">
          The bot will communicate only to visitors from the departments
          selected here.
        </p>
        <SearchToolTip placeholder="Choose a department" />
        <p className="pending-task-commondescription my-y d-flex align-items-center mt-3">
          <InfoSvgComponent size={18} color="red" /> All existing departments
          associated with the bot!
        </p>
        <p className="pending-task-commondescription mt-1">
          CHOSEN DEPARTMENTS (1)
        </p>
      </div>
      <div className="mt-5">
        <p className="header_text__18">
          Channels<span className="text-danger">*</span>
        </p>
        <p className="pending-task-commondescription mt-1">
          Choose channels to deploy your bot
        </p>
        <div className="d-flex gap-2 align-items-center">
          <CustomCheckbox checked />
          <label>Webiste</label>
        </div>
        <div className="d-flex gap-2 align-items-center mt-2">
          <CustomCheckbox checked />
          <label>Mobile</label>
        </div>
      </div>
      <div className="mt-5">
        <p className="header_text__18">Supported languages</p>
        <p className="pending-task-commondescription mt-1">
          Select the languages for your bot to converse.
        </p>
      </div> */}
    </div>
  );
};

export default BotBuilderStepTwo;
