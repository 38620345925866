import { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { getValue } from "@utils/lodash";
import CloseMainSvgComponent from "@assets/svg/close-main";

const MultiSearchTooltip = (props) => {
  const { label, id, onChange, value, data, name } = props;

  const inputRef = useRef(null);
  const ref = useRef();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const colorCodes = [
    "#82E0AA",
    "#fea7a7",
    "#fdd399",
    "#fff1a3",
    "#a8e8ad",
    "#b1dbfd",
    "#d4c9fd",
    "#beeae8",
    "#e9bfed",
    "#bbd2ef",
    "#d0d9e2",
  ];
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);
  /* -------------------------------------------------------------------------- */
  /*                      Hadleclick Outside Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const toggleBox = (e) => {
    setIsOpen(!isOpen);
  };
  const handleClickOption = (option) => {
    onChange && onChange(option);
    setInputChange(false);
    setQuery("");
  };
  const handleRemoveOption = (option) => {
    onChange(option);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
  };
  const filter = (options) => {
    let filtered =
      getValue(options, `length`, 0) > 0
        ? options.filter((item) => !value.includes(getValue(item, `name`, "")))
        : [];
    return getValue(filtered, `length`, 0) > 0
      ? filtered.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedPipeline = (id) => {
    let list =
      getValue(data, `length`, 0) > 0
        ? data.filter((item) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(() => findSelectedPipeline(value), [
    value,
    getValue(data, `length`, 0) > 0,
  ]);

  const valuesSet = new Set(value);
  const selectedData = useMemo(() => {
    return data.filter((item) => valuesSet.has(getValue(item, "value", "")));
  }, [data, valuesSet, value, query]);

  return (
    <>
      {name && (
        <label className="form-label">
          {name}{" "}
          {/* {(getValue(props, `required`, false) ||
            getValue(props, `hasRequired`, false)) && (
            <span className="color_danger small_text__16">*</span>
          )} */}
        </label>
      )}

      <div className={"tag-dropdownselect-form"} ref={ref}>
        <div
          className={`tag-multidropdown-box_main position-relative ${
            isOpen ? "active" : ""
          }`}
          onClick={toggleBox}
          style={{ width: props.width,
            borderLeft:
            getValue(props, `required`, false) ||
            getValue(props, `hasRequired`, false)
              ? "2px solid red"
              : "",
          }} /* add custom width here */
        >
          <div className="tag-search_container">
            {selectedData.map((item, index) => {
              if (item)
                return (
                  <div
                    className="d-flex align-items-center tag_container px-3 border bg-white"
                    key={index}
                    style={{ background: getValue(item, `color_code`, "") }}
                  >
                    <p className="tag-text">{getValue(item, `label`, "")}</p>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveOption(item);
                      }}
                      className="tag-item-close"
                    >
                      <CloseMainSvgComponent size={17} color={"red"} />
                    </div>
                  </div>
                );
            })}
            <input
              className="tag-search_input"
              ref={inputRef}
              type="text"
              value={
                inputChange
                  ? query
                    ? query
                    : valueLabel
                  : valueLabel
                  ? valueLabel
                  : query
              }
              name="searchTerm"
              autoComplete="off"
              placeholder={getValue(
                props,
                `placeholder`,
                "Please Add/Search here..."
              )}
              onChange={(e) => {
                !e.target.value && onChange(null);
                setQuery(e.target.value);
                e.target.value.length === 1 && setIsOpen(true);
              }}
            />
          </div>
          <div className="right_container">
            {valueLabel && (
              <div className="close_image_container">
                <img
                  src={"/dropdown/close.svg"}
                  className="close_image"
                  onClick={(e) => handleClose(e)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`tag-options ${isOpen ? "open" : ""}`}
          style={{ width: props.width }} /* add custom width here */
        >
          <div>
            <div className="tag-option_container pb-3">
              {filter(getValue(props, `data`, [])).length > 0 &&
              getValue(value, `length`, 0) !==
                filter(getValue(props, `data`, [])).length ? (
                filter(getValue(props, `data`, [])).map((option, index) => {
                  if (!value.includes(getValue(option, `value`, "")))
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                          // setIsOpen(false);
                        }}
                        className={`tag_option ${
                          option.id === value ? "selected" : ""
                        }`}
                        key={`${id}-${index}`}
                      >
                        <div
                          className="selected_tag_text px-3 p-1"
                          style={{
                            background: getValue(option, `color_code`, ""),
                          }}
                        >
                          {option[label]}
                        </div>
                      </div>
                    );
                })
              ) : (
                <p className="small_text__14 text-center">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.validator && (
        <p className={"error-text"}>
          {props.validator.current.message(
            getValue(props, `name`, ""),
            props.value,
            "required"
          )}
        </p>
      )}

      
    </>
  );
};

export default MultiSearchTooltip;
