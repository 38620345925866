/* -------------------------------------------------------------------------- */
/*                          Template Request section                          */
/* -------------------------------------------------------------------------- */

//response cards
export const tempSendMessageRequest = {
  chatbot_config_id: "",
  action_id: "",
  criteria: {},
  name: "",
  description: "",
  delay_config: {
    enabled: false,
    info_messages: [""],
    time: null,
    type: "random",
  },
  component: "reply",
  type: "reply",
  flow_id: null,
  seq_num: null,
  position: {},
};

export const tempSendLinkRequest = {
  chatbot_config_id: "",
  action_id: "",
  criteria: {},
  name: "",
  component: "links",
  type: "reply",
  description: "",
  links: [
    {
      url: "https://test.com",
      text: "Link 1",
      target: "_blank",
    },
    {
      url: "https://test.com",
      text: "Link 2",
      target: "_blank",
    },
  ],
  position: {},
};

//input cards

export const tempButtonRequest = {
  chatbot_config_id: "",
  action_id: "",
  name: "",
  component: "suggestions",
  type: "reply",
  description: "",
  suggestions: [
    {
      text: "",
    },
  ],
  criteria: {},
  position: {},
};

//visitor cards

export const tempNameRequest = {
  name: "",
  component: "visitor_name",
  type: "reply",
  description: "",
};

export const tempEmailRequest = {
  name: "",
  component: "visitor_email",
  type: "reply",
  description: "",
};

export const tempPhoneRequest = {
  name: "",
  component: "visitor_phone",
  type: "reply",
  description: "",
};

export const tempCompanyNameRequest = {
  name: "",
  component: "company_name",
  type: "reply",
  description: "",
};

/* -------------------------------------------------------------------------- */
/*                         Example Template section                           */
/* -------------------------------------------------------------------------- */

export const sendMessageRequest = {
  id: "",
  chatbot_config_id: "",
  action_id: "",
  criteria: {},
  name: "",
  component: "reply",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        text: "",
        delay_config: {
          enabled: false,
          info_messages: [""],
          time: null,
          type: "random",
        },
      },
    ],
    version: 1,
    _meta: {
      type: "reply",
    },
    behaviour: "goto_next_action",
  },
};

export const button_text = {
  chatbot_config_id: "",
  action_id: "",
  name: "Button Card 4",
  component: "suggestions",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        text: "Hey there! Looking out for the best and economic loan plans? I can help.",
      },
    ],
    version: 1,
    _meta: {
      type: "suggestions",
    },
    behaviour: "wait_for_user_input",
    suggestions: [
      {
        text: "I want to buy",
        id: "6b49bpxkri",
      },
      {
        text: "Have a query?",
        id: "kilt8xmkpp",
      },
      {
        text: "Deals of the day",
        id: "1jpvf7qjwu",
      },
    ],
  },
  criteria: {
    rules: [
      {
        and_criteria: [
          {
            or_criteria: [
              {
                comparator: "is_equal_to",
                field_name: "bot_contexts",
                sub_field: "Button Card 4",
                values: ["6b49bpxkri"],
              },
            ],
          },
        ],
        title: "",
        type: "rule",
      },
      {
        and_criteria: [
          {
            or_criteria: [
              {
                comparator: "is_equal_to",
                field_name: "bot_contexts",
                sub_field: "Button Card 4",
                values: ["kilt8xmkpp"],
              },
            ],
          },
        ],
        title: "",
        type: "rule",
      },
      {
        and_criteria: [
          {
            or_criteria: [
              {
                comparator: "is_equal_to",
                field_name: "bot_contexts",
                sub_field: "Button Card 4",
                values: ["1jpvf7qjwu"],
              },
            ],
          },
        ],
        title: "",
        type: "rule",
      },
    ],
  },
};

export const multiSelect = {
  chatbot_config_id: "",
  action_id: "",
  name: "Multi Choice Card 10",
  component: "multiselect",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        text: "Hey there! Looking out for the best courses for your educational qualification? I can help! ",
      },
    ],
    version: 1,
    _meta: {
      type: "multiple-select",
    },
    behaviour: "wait_for_user_input",
    input: {
      type: "multiple-select",
      options: [
        {
          text: "Have a query?",
          id: "gx2wmau7yt",
        },
        {
          text: "I want to buy",
          id: "dys9yuyist",
        },
        {
          text: "I would like to schedule",
          id: "7n03u1xp6e",
        },
      ],
      min_selection: "1",
      max_selection: "3",
    },
  },
};

export const send_link = {
  chatbot_config_id: "",
  action_id: "",
  criteria: {},
  name: "Share Link(s) Card 3",
  component: "links",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        links: [
          {
            url: "https://test.com",
            text: "Link 1",
            target: "_blank",
          },
          {
            url: "https://test.com",
            text: "Link 2",
            target: "_blank",
          },
        ],
        type: "links",
        text: "Hey there! Looking forward to meet executives of our org? I can schedule a meeting for you. (or) In need of a quick demo of our product? I can help!",
      },
    ],
    version: 1,
    _meta: {
      type: "links",
      apply_dynamic_values: false,
    },
    behaviour: "goto_next_action",
  },
};

export const single_select = {
  chatbot_config_id: "",
  action_id: "",
  criteria: {},
  name: "Single Choice Card 9",
  component: "singleselect",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        text: "Hey there! Looking out for the best and economic loan plans? I can help.",
      },
    ],
    version: 1,
    _meta: {
      type: "select",
    },
    behaviour: "wait_for_user_input",
    input: {
      type: "select",
      options: [
        {
          text: "Need help with purchasing",
          id: "k145inrbc6",
        },
        {
          text: "Have a query?",
          id: "v9cdj7icv7",
        },
        {
          text: "I need assistance",
          id: "ozc8zpmvth",
        },
      ],
    },
  },
};

export const name = {
  name: "",
  component: "visitor_name",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        field_name: "siq_name",
        text: "What's your name?",
      },
    ],
    _meta: {
      type: "visitor_name",
    },
    behaviour: "wait_for_user_input",
    input: {
      type: "name",
      value: "%visitor.name%",
      placeholder: "Enter your name",
      error: ["Enter a valid name"],
    },
  },
  unique_name: "visitor.name",
};

export const email = {
  name: "Email Card 3",
  component: "visitor_email",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        text: "What's your email?",
        field_name: "siq_email",
      },
    ],
    version: 1,
    _meta: {
      type: "visitor_email",
    },
    behaviour: "wait_for_user_input",
    skippable: false,
    allow_typing: false,
    input: {
      type: "email",
      value: "%visitor.email%",
      placeholder: "Enter your Email address",
      error: ["Enter a valid email"],
    },
  },
  unique_name: "visitor.email",
};

export const phone = {
  name: "Phone Card 4",
  component: "visitor_phone",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        text: "What's your phone number?",
        field_name: "siq_phone",
      },
    ],
    version: 1,
    _meta: {
      type: "visitor_phone",
    },
    behaviour: "wait_for_user_input",
    skippable: false,
    allow_typing: false,
    input: {
      type: "tel",
      country_code: "+1",
      value: "%visitor.phone%",
      placeholder: "Enter your phone number",
      error: ["Enter a valid phone number"],
    },
  },
  unique_name: "visitor.phone",
};

export const company_name = {
  name: "Company Card 5",
  component: "company_name",
  type: "reply",
  rendering_config: {
    action: "reply",
    replies: [
      {
        field_name: "siq_company_name",
        text: "What's your company name?",
      },
    ],
    version: 1,
    _meta: {
      type: "company_name",
    },
    behaviour: "wait_for_user_input",
    skippable: false,
    allow_typing: true,
  },
  unique_name: "company.name",
};
