import { Link, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { useStateContext } from "context/profileProvider";
import SignInDropdownOrg from "./SignInDropdownOrg";
import SettingsSvgComponents from "assets/svg/settings";
import OrganizationListPopup from "./OrgListPopup";
import ArrowRightSvgComponent from "assets/svg/arrow-right";
import { useState } from "react";
import NotificationCenter from "@common/notification-center";
import { concatString } from "@common/text-helpers";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";

export default function HeaderSignin() {
  const params = useParams();
  const { orgInfo, userId, profileInfo } = useStateContext();
  const [clicked, setClicked] = useState(false);
  return (
    <div>
      <ul className="d-flex align-items-center">
        {/* <li>
          <Link to="#0">
            <img
              src="/images/header/image-3.svg"
              className="img-fluid cursor-pointer"
            />
          </Link>
        </li> */}
        {getValue(profileInfo, `is_admin`, false) && (
          <li className="mx-2">
            <Link
              to={`/${getValue(params, `orgId`, "")}/settings/company-profile`}
            >
              <SettingsSvgComponents size={28} />
            </Link>
          </li>
        )}
        <li>
          <NotificationCenter userId={userId} />
        </li>
        <li
          className="vr"
          style={{ marginLeft: "5px", marginRight: "10px" }}
        ></li>
        <li>
          <OrganizationListPopup
            header={
              <TooltipOnHover
                header={
                  <div className="d-flex align-items-center">
                    <p className="org_name">
                      {concatString(getValue(orgInfo, `name`, ""), 18)}
                    </p>
                    <ArrowRightSvgComponent size={24} color={"white"} />
                  </div>
                }
                text={getValue(orgInfo, `name`, "")}
              />
              // <div className="d-flex align-items-center">
              //   <p className="org_name">{concatString(getValue(orgInfo, `name`, ""),18)}</p>
              //   <ArrowRightSvgComponent size={24} color={"white"} />
              // </div>
            }
            clicked={clicked}
            setClicked={setClicked}
          />
        </li>
        <li className="vr mx-1"></li>
        <li onClick={() => setClicked(true)}>
          <SignInDropdownOrg />
        </li>
      </ul>
    </div>
  );
}
