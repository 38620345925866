import AccountCircleSvgComponent from "@assets/svg/account-circle";
import {
  convertCurrentDateToTime,
  formatDatetoDay,
} from "@common/date-helpers";
import { replaceDefaultPlaceholders } from "@common/markdown-helper";
import { concatString, formatString } from "@common/text-helpers";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import React from "react";

function InboxContacts(props: any) {
  const {
    isLoading,
    conversations,
    selectedConversation,
    handleSelectConvesation,
  } = props;
  return (
    <div>
      {isLoading ? (
        <Loader small />
      ) : getValue(conversations, `length`, 0) > 0 ? (
        conversations.map((item: object) => {
          return (
            <div
              className={`chat-list cursor-pointer ${
                selectedConversation === getValue(item, `id`, "")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSelectConvesation(
                  item
                  // getValue(item, `id`, ""),
                  // getValue(item, `contact_id`, "")
                )
              }
            >
              <div className="nonselected-profile-image">
                <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
              </div>

              <div className="w-100 d-flex flex-column gap-1">
                <div className="d-flex justify-content-between align-items-center">
                  {getValue(item, `contact.first_name`, "") ||
                  getValue(item, `contact.last_name`, "") ? (
                    <p className="small_text__16">
                      {formatString(getValue(item, `contact.first_name`, ""))}{" "}
                      {formatString(getValue(item, `contact.last_name`, ""))}
                    </p>
                  ) : (
                    <p className="small_text__16">
                      {formatString(getValue(item, `wa_profile_name`, ""))}
                    </p>
                  )}

                  <span className="time-noti">
                    {/* {convertCurrentDateToTime(getValue(item, `updated_at`, ""))} */}
                    {formatDatetoDay(getValue(item, `updated_at`, ""))}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    {/* +{getValue(item, `wa_id`, "")} */}
                    {getValue(item, `last_msg.text_body`, "") ? (
                      // <p className="desc_text small_text__12">
                      //   {concatString(
                      //     getValue(item, `last_msg.text_body`, ""),
                      //     30
                      //   )}

                      // </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceDefaultPlaceholders(
                            concatString(
                              getValue(item, `last_msg.text_body`, ""),
                              30
                            )
                          ),
                        }}
                        className="desc_text small_text__12"
                      ></p>
                    ) : (
                      <p className="desc_text small_text__12 color_gray">
                        Hey there! I am using WhatsApp
                      </p>
                    )}
                  </p>
                  {getValue(item, `unreadCount`, 0) > 0 && (
                    <div className="d-flex justify-content-center align-items-center whatsappcount">
                      {getValue(item, `unreadCount`, "")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="header_text__16 d-flex justify-content-center mt-5">
          No Contacts Found
        </p>
      )}
    </div>
  );
}

export default InboxContacts;
