import { useEffect, useState } from "react";
import ModuleSettingNavViews from "../components/nav-views";
import {
  getInboxDomains,
  validateInboxDomain,
} from "@services/email-config.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import SenderSvgComponent from "assets/svg/sender";
import SettingsLayout from "@layouts/Layout/settings-layout";
import { useNavigate, useParams } from "react-router-dom";
import ListLoader from "@components/common/Loader";
import ConfigPopup from "./config-popup";
import { toast } from "sonner";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import EditSvgComponent from "@assets/svg/edit";
import DeleteSvgComponent from "@assets/svg/delete";
import EmailssNoDataPage from "@components/common/NoData/email-nodata";
function SettingsEmailDeliverability(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [configInfo, setConfigInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let payload = {};
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getInboxDomains(queryRequest);
      if (resp) {
        setDomainList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getItemInfo = (item: object) => {
    let config = getValue(item, `config`, "");
    if (config) {
      setConfigInfo({
        ...item,
        config: JSON.parse(config),
      });
      handleModalOpen();
    } else {
      toast.error("No config generated");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleNavigate = () => {
    navigate(
      `/${getValue(params, `orgId`, "")}/settings/emails/deliverability/add`
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [validateLoading, setValidateLoading] = useState(false);
  const handleValidate = async () => {
    try {
      setValidateLoading(true);
      let resp = await validateInboxDomain(getValue(configInfo, `id`, ""));
      if (resp) {
        setValidateLoading(false);
        if (getValue(resp, `data.isVerified`, false)) {
          toast.success("Verified successfully");
        } else {
          toast.error("Not verified");
        }
      } else {
        setValidateLoading(false);
      }
    } catch (error) {
      setValidateLoading(false);
    }
  };
  return (
    <SettingsLayout>
      <CompanyProfileSubHeader />
      <div className={`${"email_container"} w-100`}>
        <CompanyProfileNavigation />
        <div className={`${"email_body_container"} w-100`}>
          <ModuleSettingNavViews />

          <div className="mt-4 d-flex align-items-center">
            <p className="small_text__14 w-50">
              When you send messages using SuperCRM, the Sender is the "from"
              email address that is displayed. You can add your own email
              address(es) as the sender.
            </p>
            <button
              className={`${"sender_button"} px-3`}
              onClick={handleNavigate}
            >
              New Sender
            </button>
          </div>
          {isLoading ? (
            <ListLoader />
          ) : getValue(domainList, `length`, 0) > 0 ? (
            <table className={`common_table w-100 mt-4`}>
              <thead>
                <tr>
                  <td>Domain</td>
                  <td>Sub Domain</td>
                  <td>Verified</td>
                  <td>Default</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {domainList.map((item: object) => {
                  return (
                    <tr onClick={() => getItemInfo(item)}>
                      <td>{getValue(item, `domain`, "")}</td>
                      <td>{getValue(item, `subDomain`, "")}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <p className="small_text__14">
                            {getValue(item, `isVerified`, false)
                              ? "Verified"
                              : "Unverified"}
                          </p>
                          <p className="small_text__14 color_primary ms-2">
                            Verify
                          </p>
                        </div>
                      </td>
                      <td>
                        {getValue(item, `isDefault`, false) ? "Yes" : "No"}
                      </td>
                      <td>
                        <div className="d-flex align-items-center ">
                          <div className="image_hover_background">
                            <EditSvgComponent />
                          </div>
                          <div
                            className="image_hover_background"
                            onClick={(e: any) => e.stopPropagation()}
                          >
                            <DeleteSvgComponent />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="mt-3">
            <EmailssNoDataPage />
            </div>
          )}
        </div>
      </div>
      <ConfigPopup
        isOpen={isModalOpen}
        handleModal={handleModalOpen}
        info={getValue(configInfo, `config`, {})}
        configInfo={configInfo}
        handleSubmit={handleValidate}
        isLoading={validateLoading}
      />
    </SettingsLayout>
  );
}

export default SettingsEmailDeliverability;
