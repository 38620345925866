import {
  capitalizeAfterSpace,
  capitalizeFirstLetter,
  formatString,
} from "common/text-helpers";
import "./CommonPopupHeader.scss";
import { getValue } from "@utils/lodash";
import BackSvgComponent from "@assets/svg/back-link";

export default function CommonPopupHeader(props: any) {
  return (
    <div
      className={`add-more-rolls-wrapper__header d-flex align-items-center justify-content-between`}
      style={{ height: props.height }}
    >
      <div className="d-flex align-items-center" onClick={props.navigateBack}>
        {getValue(props, `enableBack`, false) && (
          <div className="mx-2">
            <BackSvgComponent color={"white"} />
          </div>
        )}
        <h2 className={"add-more-rolls-wrapper__header-title"}>
          {capitalizeAfterSpace(getValue(props, `title`, ""))}
        </h2>
        {getValue(props, `moduleName`, "") && (
          <div
            className={`add-more-rolls-wrapper__header-container ms-3 px-3 p-2`}
          >
            <p>{formatString(getValue(props, `moduleName`, ""))}</p>
          </div>
        )}
      </div>
      <div
        className={"add-more-rolls-wrapper__close-btn"}
        onClick={() => {
          props.handleBackButton();
        }}
      >
        <img
          src="/images/icons/close-white.svg"
          className={`add-more-rolls-wrapper__close-icon img-fluid cursor-pointer`}
        />
      </div>
    </div>
  );
}
