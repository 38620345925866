import Layout from "@layouts/Layout";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { handleSelectChange, handleTextChange } from "common/handleChange";
import timezones from "@common/json/timezone.json";
import SimpleReactValidator from "simple-react-validator";
import { createOrganisations } from "@services/organisations.service";
import currencies from "@common/json/currency.json";
import { StylesConfig } from "react-select";
import moment from "moment";
import Lottie from "react-lottie";
// import bin from "./form.json";
import bin from "./gear.json";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import CreateHeader from "@components/common/Header/HeaderToolbar/CreateHeader";
import "./createOrg.scss";
import { getStartOfMonthDate } from "@common/date-helpers";

interface ICreateOrganisationsProps {}

const CreateOrganisations: React.FunctionComponent<ICreateOrganisationsProps> = (
  props
) => {
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  /* -------------------------------------------------------------------------- */
  /*                             Use State Section                              */
  /* -------------------------------------------------------------------------- */
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    currency: "INR",
    time_zone: "Asia/Kolkata",
    date_format: "MM/DD/YYYY",
  });

  const [date_formats] = useState([
    {
      value: "MM/DD/YYYY",
      label:
        "MM/DD/YYYY" + " [ " + moment(new Date()).format("MM/DD/YYYY") + " ] ",
    },
    {
      value: "DD/MM/YYYY",
      label:
        "DD/MM/YYYY" + " [ " + moment(new Date()).format("DD/MM/YYYY") + " ] ",
    },
    {
      value: "YYYY/MM/DD",
      label:
        "YYYY/MM/DD" + " [ " + moment(new Date()).format("YYYY/MM/DD") + " ] ",
    },
    {
      value: "DD MMM YYYY",
      label:
        "DD MMM YYYY" +
        " [ " +
        moment(new Date()).format("DD MMM YYYY") +
        " ] ",
    },
  ]);

  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    let codes: any = currencies.map((item: object) => ({
      ...item,
      label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
      value: `${getValue(item, `cc`, "")}`,
    }));
    setCurrencyList(codes);
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async () => {
    let date_range = getStartOfMonthDate();
    try {
      setIsLoading(true);
      let resp = await createOrganisations(request);
      if (resp) {
        // localStorage.setItem("X-Org-Code", getValue(resp, `data.code`, ""));
        // navigate(`/${getValue(resp, `data.code`, "")}/contacts`);
        window.location.href = `/${getValue(
          resp,
          `data.code`,
          ""
        )}/dashboard?date_range=${date_range}`;
        // setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    navigate(`/organisations`);
  };

  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 40,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      // marginTop:'6px',
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Layout>
      {isLoading ? (
        // <>
        //   <div className="d-flex justify-content-center align-items-center">
        //     <div style={{ width: "20%", height: "100%", marginTop: "8%" }}>
        //       <Lottie options={defaultOptions} />
        //       <p className="text-center header_blue_text__16">
        //         Thank you for your patience as we prepare and configure your
        //         organization
        //       </p>
        //     </div>
        //   </div>
        // </>
        <div className="d-flex justify-content-center align-items-center create-org-loader-wrapper ">
          <div className="container row">
            <div className="col-md-6">
              <img
                src="/images/multiple-activity-loading.png"
                alt="Person doing multiple activity"
                className="create-org-loader-image w-100"
              />
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center">
              <div>
                <Lottie options={defaultOptions} />
              </div>
              <div>
                <p>Please wait it may take a while to complete.</p>
              </div>
              <div className="create-org-loader-box">
                <span className="text-center">
                  You are few steps away from...
                </span>
                <p className="text-center">
                  Transform sales across all channels. <br />
                  Never miss a sales opportunity.
                </p>
                <div className="dummy-dots-wrapper ">
                  <div className="dots"></div>
                  <div className="dots"></div>
                  <div className="dots"></div>
                  <div className="dots"></div>
                  <div className="dots"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <CreateHeader
            title={"Create Organisation"}
            backUrl={`/organisations`}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <div className={"organisations-wrapper__main-section_create"}>
            <div className={`form_wrapper`}>
              <InputRuleForm
                inputType="TEXT"
                label={"Name"}
                placeholder="Enter Name"
                name={"name"}
                value={getValue(request, `name`, "")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTextChange(e, request, setRequest)
                }
                validator={simpleValidator}
                style={{ height: "40px" }}
              />
              {/* <InputForms
              inputType="REACT_SELECT"
              label={"Currency"}
              placeholder="Enter Currency"
              name={"currency"}
              options={currencyList}
              value={currencyList.filter(
                (item: object) =>
                  getValue(item, `value`, "") ===
                  getValue(request, `currency`, "")
              )}
              onChange={(e: object) =>
                handleSelectChange(e, "currency", request, setRequest)
              }
              validator={simpleValidator}
              style={customStyles}
            /> */}

              <SearchToolTip
                placeholder={`Enter Currency`}
                onChange={(e: object) =>
                  handleSelectChange(e, "currency", request, setRequest)
                }
                label={`label`}
                width={"365px"}
                data={currencyList}
                value={getValue(request, `currency`, "")}
                selectKey={"value"}
                name={"Currency"}
              />

              <SearchToolTip
                placeholder={`Enter Time Zone`}
                onChange={(e: object) =>
                  handleSelectChange(e, "time_zone", request, setRequest)
                }
                label={`label`}
                width={"365px"}
                data={timezones}
                value={getValue(request, `time_zone`, "")}
                selectKey={"value"}
                name={"Time Zone"}
              />

              <SearchToolTip
                placeholder={`Enter Date Format`}
                onChange={(e: object) =>
                  handleSelectChange(e, "date_format", request, setRequest)
                }
                label={`label`}
                width={"365px"}
                data={date_formats}
                value={getValue(request, `date_format`, "")}
                selectKey={"value"}
                name={"Date Format"}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default CreateOrganisations;
