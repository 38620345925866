import Property from "@pages/Private/Property";
import Bookingfolio from "@pages/Private/Property/BookingFolio";
import CreateProperty from "@pages/Private/Property/CreateProperty";
import PropertyDetails from "@pages/Private/Property/PropertyDetails";
import PropertySettingsDetails from "@pages/Private/Property/PropertySettings";
import CreateWalkIN from "@pages/Private/Property/PropertySettings/components/create-walk-in";

export const PropertyRoutes = [
  {
    path: "/:orgId/property",
    name: "Property",
    component: Property,
  },
  {
    path: "/:orgId/property/create",
    name: "Property",
    component: CreateProperty,
  },
  {
    path: "/:orgId/property/details/:id",
    name: "Property",
    component: PropertyDetails,
  },
  {
    path: "/:orgId/property/details/calender/:id",
    name: "Property",
    component: PropertyDetails,
  },
  {
    path: "/:orgId/property/details/settings/:id",
    name: "Property",
    component: PropertySettingsDetails,
  },
  {
    path: "/:orgId/property/details/:id/create/walk-in",
    name: "Property",
    component: CreateWalkIN,
  },
  {
    path: "/:orgId/property/details/:id/folio",
    name: "Property",
    component: Bookingfolio,
  },
];
