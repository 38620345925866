import { Link, useParams } from "react-router-dom";
// import Logo from "@components/Dashboard/v2/Header/Logo/Logo";
import "./Header.scss";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { useStateContext } from "context/profileProvider";
import HeaderSignin from "../HeaderSignin/HeaderSignin";
import Logo from "@components/common/Logo/Logo";

export default function HomeHeader() {
  const params = useParams();
  let { modules, handleChangeModule } = useStateContext();
  const [navigationArray, setNavigationArray] = useState([
    {
      linkText: "Dashboard",
      linkUrl: `/${getValue(params, `orgId`, "")}/dashboard`,
      activeRoute: "dashboard",
    },
    {
      linkText: "Contacts",
      linkUrl: `/${getValue(params, `orgId`, "")}/contacts`,
      activeRoute: "contacts",
    },
    {
      linkText: "Companies",
      linkUrl: `/${getValue(params, `orgId`, "")}/companies`,
      activeRoute: "companies",
    },
    {
      linkText: "Deals",
      linkUrl: `/${getValue(params, `orgId`, "")}/deals`,
      activeRoute: "deals",
    },
    {
      linkText: "Tasks",
      linkUrl: `/${getValue(params, `orgId`, "")}/tasks`,
      activeRoute: "tasks",
    },
  ]);
  let activeRoute = window.location.pathname.split("/")[2];
  useEffect(() => {
    if (getValue(modules, `length`, 0) === 0) {
      handleChangeModule();
    }
  }, [getValue(modules, `length`, 0) === 0]);
  return (
    <header className={"header-wrapper"}>
      <div className={`d-flex align-items-center`}>
        <Logo />
        <nav className={"header-wrapper__navigation"}>
          <ul className={"header-wrapper__navigation-list"}>
            {modules.map((navObj: any, index: number) => {
              const { subMenu } = navObj;
              return (
                <li
                  className={"header-wrapper__navigation-list-items"}
                  key={`navigation-${index}`}
                >
                  <Link to={navObj.linkUrl}>
                    <div
                      className={
                        getValue(navObj, `activeRoute`, "") == activeRoute
                          ? "header-wrapper__navigation-title_active"
                          : "header-wrapper__navigation-title"
                      }
                    >
                      <p>{navObj.linkText}</p>
                      <img
                        src={navObj.image}
                        className={"header-wrapper__navigation-arrow"}
                      />
                      <div className={"header-wrapper__submenu"}>
                        <ul>
                          {subMenu?.map((subMenuObj: any, index: number) => {
                            return (
                              <li
                                className={"header-wrapper__submenu-list-items"}
                                key={`menu-${index}`}
                              >
                                <Link to={subMenuObj.linkUrl}>
                                  <a
                                    className={
                                      "header-wrapper__submenu-list-title"
                                    }
                                  >
                                    {subMenuObj.linkText}
                                    <img
                                      src={subMenuObj.image}
                                      className={`submenu-icon"]} img-fluid`}
                                    />
                                    {subMenuObj.terciaryMenu?.length >= 1 && (
                                      <div
                                        className={
                                          "header-wrapper__terciary-submenu"
                                        }
                                      >
                                        <ul>
                                          {subMenuObj.terciaryMenu?.map(
                                            (menuobj: any, index: number) => {
                                              return (
                                                <li
                                                  key={`terciary-${index}`}
                                                  className={
                                                    "header-wrapper__terciary-submenu-list-items"
                                                  }
                                                >
                                                  <Link to={menuobj.linkUrl}>
                                                    <div
                                                      className={
                                                        "header-wrapper__terciary-submenu-list-title"
                                                      }
                                                    >
                                                      {menuobj.linkText}
                                                    </div>
                                                  </Link>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </a>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
            {/* <Link to='/messages' className="header-wrapper__submenu-list-items">Messages</Link> */}
          </ul>
        </nav>
      </div>
      <HeaderSignin />
    </header>
  );
}
