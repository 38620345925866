import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCompanies = (queryRequest: string) =>
  get(`${config.API_URL}/records/companies?${queryRequest}`);

export const getListOfAllCompanies = (queryRequest: string) =>
  get(`${config.API_URL}/records/companies/list?${queryRequest}`);

export const getSpecificCompany = (id: string) =>
  get(`${config.API_URL}/records/companies/${id}`);

export const createCompany = (payload: object) =>
  post(`${config.API_URL}/records/companies`, payload);

export const updateCompany = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/companies/${id}`, payload);

export const deleteCompany = (id: string) =>
  Delete(`${config.API_URL}/records/companies/${id}`);

  export const companiesBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/companies/bulk-update`, payload);