import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React, { useEffect, useRef, useState } from "react";
import WhatsappIconViewPage from "../components/common/whatsapp-icon";
import { getValue } from "@utils/lodash";
import DeleteSvgComponent from "@assets/svg/delete";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import InputRuleForm from "@components/InputRuleForm/form";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import {
  handlePhoneNestedRequestArrayChange,
  handleSelectNestedRequestArrayChange,
  handleSelectRequestArrayChange,
  handleTextArrayChange,
  handleTextNestedRequestArrayChange,
  handleTextRequestArrayChange,
  handleValueArrayChange,
} from "@common/handleChange";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import AddSvgComponent from "@assets/svg/add";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import {
  createWhatsappTemplate,
  getSpecificWhatsappTemplate,
  getWhatsappTemplate,
} from "@services/fb.service";
import { toast } from "sonner";
import WarningSvgComponent from "@assets/svg/warning";
import { useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useStateContext } from "@context/profileProvider";
import { QueryRequestHelper } from "@common/query-request-helper";
import { listAllPipelines } from "@services/pipeline.service";
import { getAllAvailableTemplateTags } from "@services/email-config.service";
import { mediaUpload } from "@services/upload.service";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { parse } from "libphonenumber-js";
import convertMarkdownToHtml from "../TemplateMessages/useMarkdown";
import { languageEnum } from "@common/enum";
import CarouselNavViews from "../components/template-messages/components/carousel-nav-views";
import { config } from "env";
import draftToHtmlPuri from "draftjs-to-html";
import AddWhatsappVariable from "../components/template-messages/add-variable-popup";
import BackSvgComponent from "@assets/svg/back-link";
import { emojisList } from "@common/emojis";

const CreateTemplateMessage = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const [editId, setEditId] = useState(getValue(params, `id`, ""));
  const navigate = useNavigate();
  const intialState = {
    id: "",
    moduleId: "",
    pipelineId: "",
    name: "",
    category: "MARKETING",
    language: "en",
    header: {
      type: "",
      text: "",
      link: "",
    },
    body: "",
    footer: "",
    buttonType: "",
    customTags: [],
    buttons: [],
    status: "",
    isActive: true,
    carousel: [
      {
        header: {
          type: "IMAGE",
          link: "",
        },
        body: "",
        buttons: [{ action_type: "" }, { action_type: "" }],
      },
    ],
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState<any>(intialState);
  const resetState = () => {
    setEditId("");
    setErrorMessage("");
    setRequest(intialState);
    // setRequest({ ...request });
  };
  const [templateData, settemplateData] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                             UseEffect Section                              */
  /* -------------------------------------------------------------------------- */

  const { selectedModuleId } = useStateContext();
  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (!editId) {
      resetState();
    } else {
      getData();
    }
  }, [editId]);

  // useEffect(() => {
  //   if (editId && templateData.length > 0) {
  //     handlePreview(editId);
  //   }
  // }, [templateData]);

  // useEffect(() => {
  //   if (templateData.length > 0) {
  //     handlePreview(editId);
  //   }
  // }, [templateData, editId]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificWhatsappTemplate(getValue(params, `id`, ""));
      if (resp) {
        let buttons =
          getValue(resp, `data.buttons.length`, 0) > 0
            ? getValue(resp, `data.buttons`, []).map((item: object) => ({
                ...item,
                type: getValue(item, `action_type`, ""),
                action_type: getValue(item, `type`, ""),
              }))
            : [];
        let customTagsList = getValue(resp, `data.tags`, []).map(
          (item: object) => ({
            type: getValue(item, `type`, ""),
            sample: getValue(item, `sample`, ""),
            fieldId: getValue(item, `module_field_id`, ""),
            placeholder: `[[${getValue(item, `module_field.api_name`, "")}]]`,
          })
        );
        settemplateData(getValue(resp, `data`, ""));
        setRequest({
          ...request,
          id: getValue(resp, `data.id`, ""),
          moduleId: getValue(resp, `data.module_id`, ""),
          pipelineId: getValue(resp, `data.pipeline_id`, ""),
          name: getValue(resp, `data.name`, ""),
          category:
            getValue(resp, `data.carousels.length`, 0) > 0
              ? "CAROUSEL"
              : getValue(resp, `data.category`, ""),
          language: getValue(resp, `data.language`, ""),
          header: {
            type: getValue(resp, `data.header_type`, ""),
            text: getValue(resp, `data.header_link`, ""),
            link: getValue(resp, `data.header_file`, ""),
          },
          body: replaceDefaultPlaceholders(
            getValue(resp, `data.body`, ""),
            allTags
          ),
          footer: getValue(resp, `data.footer`, ""),
          customTags: customTagsList,
          buttons: buttons,
          // isActive: true,
          status: getValue(resp, `data.status`, ""),
          carousel: getCarouselPreview(getValue(resp, `data.carousels`, "")),
        });
        setIsLoading(false);
        // handlePreview(editId);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [allTags, setAllTags] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [pipelineId, setPipelineId] = useState("");
  const [allPipelinesList, setAllPipelineList] = useState([]);
  const getTags = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      setModuleId(await selectedModuleId("contacts"));
      setPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelineList(list);
      tagsByPipelineID(getValue(allPipelines, `data.[${0}].id`, ""));
    } catch (error) {}
  };

  const tagsByPipelineID = async (id: string) => {
    setPipelineId(id);
    try {
      let payload = {
        pipelineId: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAvailableTemplateTags(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        setAllTags(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest({
        ...request,
        header: {
          ...request.header,
          link: getValue(resp, `data`, {}),
        },
      });
    }

    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  const ConvertedComponent = (data: any) => {
    if (getValue(data, `length`, 0) > 0) {
      const convertedData = data.map((item: any, index: number) => {
        if (item.action_type === "CALL_TO_ACTION") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "URL") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "QUICK_REPLY") {
          return {
            type: "QUICK_REPLY",
            quickReply: [
              {
                text: item.text,
                index: index,
              },
            ],
          };
        }
      });
      return convertedData.filter((item: object) => item);
    } else {
      return [];
    }
  };

  const handleNavigateHome = async () => {
    let payload: any = {
      ...UrlParams,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const newfun = (arr1: any, arr2: any, arr3: any) => {
    const arr3Dict = arr3.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
    const newResult = arr1
      .map((item: any) => {
        const placeholderName = item.placeholder
          .replace("[[", "")
          .replace("]]", "");

        if (arr3Dict[placeholderName]) {
          const arr2Item = arr2.find(
            (x: any) => x.placeholder === item.placeholder
          );
          const fieldId = arr2Item ? arr2Item.fieldId : "";
          const sample = arr2Item ? arr2Item.sample : "";
          const sampleInfo = getValue(findInfo, `tags`, []).find(
            (item: object) => getValue(item, `module_field_id`, "") === fieldId
          );
          return {
            type: item.type,
            label: arr3Dict[placeholderName].label,
            name: arr3Dict[placeholderName].name,
            sample: sample ? sample : getValue(sampleInfo, `sample`, ""),
            placeholder: item.placeholder,
            fieldId: fieldId
              ? fieldId
              : arr3Dict[placeholderName].fieldId
              ? arr3Dict[placeholderName].fieldId
              : getValue(sampleInfo, `module_field_id`, ""),
          };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    return newResult;
  };

  const handleAddTypeButton = (name: string, type: String) => {
    const buttonArray = getValue(request, `buttons`, []);
    if (buttonArray.length < 3) {
      if (name === "QUICK_REPLY") {
        let obj = { text: "", action_type: name };
        buttonArray.push(obj);
        setRequest({ ...request });
      } else {
        let obj = {
          action_type: name,
          type: type,
          text: "",
          url: "",
          phone: "",
        };
        buttonArray.push(obj);
        setRequest({ ...request });
      }
    } else {
      toast.error("you have reached maximum limits");
    }
  };

  const handleRemoveTypeButton = (index: number) => {
    let filtered = getValue(request, `buttons`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      buttons: filtered,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                          Template Modal section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const handleOpenTempalte = () => {
    setIsOpenTemplate(!isOpenTemplate);
  };
  /* -------------------------------------------------------------------------- */
  /*                            Variable Modal section                          */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const editorRef = useRef<any>();

  const handleAddTags = (item: any) => {
    const currentBody = getValue(request, `body`, ""); // Get current body content
    const name = getValue(item, `name`, ""); // Get the name from the item
    const updatedBody = currentBody.endsWith("\n")
      ? currentBody.slice(0, -1)
      : currentBody;

    let text: any = insertText(`[[${name}]]`);
    let finalText =
      text && draftToHtmlPuri(convertToRaw(text.getCurrentContent()));
    setRequest({
      ...request,
      body: finalText,
    });
    request.customTags.push({
      type: "body",
      sample: "",
      placeholder: `[[${getValue(item, `name`, "")}]]`,
      fieldId: getValue(item, `fieldId`, ""),
    });
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  ); // Default state is an empty editor state
  const insertText = (value: any) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      value
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    return newEditorState;
  };

  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    // Assuming you're using a state variable called "editor" to store the editor state
    setEditorState(editorState);
    setRequest({
      ...request,
      body: value,
    });

    // Extract custom tags from the editor text
    const regex = /\[\[(.*?)\]\]/g;
    const matches = value.match(regex);
    if (matches) {
      const newCustomTags = matches.map((match: any) => ({
        type: "body",
        sample: "",
        placeholder: match,
        fieldId: "",
      }));
      let tags = newfun(newCustomTags, request.customTags, allTags);
      setRequest({
        ...request,
        body: value,
        customTags: tags,
      });
    } else {
      setRequest({
        ...request,
        body: value,
        customTags: [],
      });
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Helper section                             */
  /* -------------------------------------------------------------------------- */

  const replacePlaceholders = (text: any, data: any) => {
    if (text) {
      let replacedText = parse(text);
      let escapedMatch = String(replacedText).replace(/\\/g, "");
      data.forEach((item: any) => {
        escapedMatch = escapedMatch.replace(
          `[[${item.name}]]`,
          `[[${item.label}]]`
        );
      });
      return escapedMatch;
    } else {
      return null;
    }
    // return replacedText;
  };
  const replaceDefaultPlaceholders = (text: any, data: any) => {
    let replacedText = convertMarkdownToHtml(text);
    data.forEach((item: any) => {
      replacedText = replacedText.replace(item.placeHolder, `[[${item.name}]]`);
    });
    return replacedText;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Preview section                            */
  /* -------------------------------------------------------------------------- */

  const [errorMessage, setErrorMessage] = useState("");
  const [findInfo, setFindInfo] = useState({});

  const handlePreview = async () => {
    setFindInfo(templateData);
    let buttons =
      getValue(findInfo, `buttons.length`, 0) > 0
        ? getValue(findInfo, `buttons`, []).map((item: object) => ({
            ...item,
            type: getValue(item, `action_type`, ""),
            action_type: getValue(item, `type`, ""),
          }))
        : [];
    let customTagsList = getValue(findInfo, `tags`, []).map((item: object) => ({
      type: getValue(item, `type`, ""),
      sample: getValue(item, `sample`, ""),
      fieldId: getValue(item, `module_field_id`, ""),
      placeholder: `[[${getValue(item, `module_field.api_name`, "")}]]`,
    }));
    setRequest({
      ...request,
      id: getValue(findInfo, `id`, ""),
      moduleId: getValue(findInfo, `module_id`, ""),
      pipelineId: getValue(findInfo, `pipeline_id`, ""),
      name: getValue(findInfo, `name`, ""),
      category:
        getValue(findInfo, `carousels.length`, 0) > 0
          ? "CAROUSEL"
          : getValue(findInfo, `category`, ""),
      language: getValue(findInfo, `language`, ""),
      header: {
        type: getValue(findInfo, `header_type`, ""),
        text: getValue(findInfo, `header_link`, ""),
        link: getValue(findInfo, `header_file`, ""),
      },
      body: replaceDefaultPlaceholders(getValue(findInfo, `body`, ""), allTags),
      footer: getValue(findInfo, `footer`, ""),
      customTags: customTagsList,
      buttons: buttons,
      isActive: true,
      status: getValue(findInfo, `status`, ""),
      carousel: getCarouselPreview(getValue(findInfo, `carousels`, "")),
    });
  };

  const getCarouselPreview = (carousels: any) => {
    let carousel =
      getValue(carousels, `length`, 0) > 0
        ? carousels.map((item: object) => ({
            header: {
              type: getValue(item, `header_type`, ""),
              link: getValue(item, `header_file.key`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: convertCarouselButtons(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };

  const convertCarouselButtons = (buttons: any) => {
    let button =
      getValue(buttons, `length`, 0) > 0
        ? buttons.map((item: object) => ({
            ...item,
            action_type: getValue(item, `type`, ""),
            type: getValue(item, `phone`, "") ? "call" : "url",
          }))
        : [];
    return button;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */
  const getCarouselPayload = () => {
    let carousel =
      getValue(request, `carousel.length`, 0) > 0
        ? getValue(request, `carousel`, []).map((item: object) => ({
            header: {
              type: getValue(item, `header.type`, ""),
              text: getValue(item, `header.text`, ""),
              link: getValue(item, `header.link.id`, "")
                ? getValue(item, `header.link.id`, "")
                : getValue(item, `header.link`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: ConvertedComponent(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };
  const convertPayload = () => {
    let buttons = ConvertedComponent(getValue(request, `buttons`, []));
    let payload = JSON.parse(JSON.stringify(request));
    let obj;
    if (getValue(request, `category`, "") === "CAROUSEL") {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: "MARKETING",
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(
          getValue(payload, `body`, ""),
          payload.customTags
        ),
        isActive: true,
        carousel: getCarouselPayload(),
      };
    } else {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: getValue(payload, `category`, ""),
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(
          getValue(payload, `body`, ""),
          payload.customTags
        ),
        isActive: true,
        header: {
          type: getValue(payload, `header.type`, ""),
          text: getValue(payload, `header.text`, ""),
          link: getValue(payload, `header.link.id`, "")
            ? getValue(payload, `header.link.id`, "")
            : getValue(payload, `header.link`, ""),
        },
        footer: getValue(payload, `footer`, ""),
        buttons: buttons,
      };
    }
    return removeNullOrUndefinedProperties(obj);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await createWhatsappTemplate(
          removeNullOrUndefinedProperties(convertPayload())
        );
        if (resp) {
          handleOpenTempalte();
          resetState();
          getData();
          toast.success("Submitted successfully");
          handleNavigateHome();
          // navigate(
          //   `/${getValue(params, `orgId`, "")}/broadcast/?activeTab=2&page_no=1`
          // );

          hideValidations();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const hideValidations = () => {
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [categoryEnum] = useState([
    {
      value: "MARKETING",
      label: "Marketing",
      icon: "/images/icons/marketing-speaker.svg",
      tooltip:
        "Send promotions or informations about your products, servcie or business.",
    },
    {
      value: "UTILITY",
      label: "Utility",
      icon: "/images/icons/bell.svg",
      tooltip: "Send messages about existing order or account.",
    },
    {
      value: "CAROUSEL",
      label: "Carousel",
      icon: "/images/icons/bell.svg",
      tooltip: "Send carousel messages about existing order or account.",
    },
  ]);
  const [headerEnum] = useState([
    { value: "TEXT", label: "Text" },
    { value: "IMAGE", label: "Image" },
    { value: "VIDEO", label: "Video" },
    { value: "DOCUMENT", label: "Document" },
  ]);
  const [carouselHeaderEnum] = useState([
    { value: "IMAGE", label: "Image" },
    { value: "VIDEO", label: "Video" },
  ]);
  const [buttonTypeEnum] = useState([
    { value: "QUICK_REPLY", label: "Quick Reply" },
    { value: "CALL_TO_ACTION", label: "Call to Action" },
    { value: "URL", label: "URL" },
  ]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [selectedType, setButtonType] = useState("QUICK_REPLY");

  const handlChangeType = (
    i: number,
    childIndex: number,
    e: any,
    key: string,
    name: string,
    subName: string,
    setObject: any,
    type: string
  ) => {
    setObject((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            [name]: x[name].map((y: any, p_index: number) => {
              if (childIndex !== p_index) return y;
              return {
                ...y,
                [subName]: type,
              };
            }),
          };
        }),
      };
    });
  };

  const [activeCarouselTab, setActiveCarouselTab] = useState(1);
  const handleCarouselTab = (tab: number) => {
    setActiveCarouselTab(tab);
  };

  /* -------------------------------------------------------------------------- */
  /*                        Carousel Template Section                           */
  /* -------------------------------------------------------------------------- */
  const handleAddCarousel = () => {
    let obj = {
      header: {
        type: "IMAGE",
        link: "",
      },
      body: "",
      buttons: [{ action_type: "" }, { action_type: "" }],
    };
    getValue(request, `carousel`, []).push(obj);
    setRequest({ ...request });
  };

  const handleRemoveCarousel = (index: number) => {
    let filtered = getValue(request, `carousel`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      carousel: filtered,
    });
  };
  const handleCarouseFileChange = async (e: any, i: number) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest((object: any) => {
        return {
          ...object,
          ["carousel"]: object["carousel"].map((x: any, index: number) => {
            if (index !== i) return x;
            return {
              ...x,
              header: {
                ...x.header,
                link: getValue(resp, "data", {}),
              },
            };
          }),
        };
      });
    }
    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  return (
    <div>
      <HomeHeader />
      <div className="px-3 py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    `orgId`,
                    ""
                  )}/broadcast?activeTab=2&page_no=1`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">
              {editId ? "Edit" : "Create"} template message
            </p>
          </div>
          <div>
            {getValue(request, `status`, "") === "APPROVED" ||
            getValue(request, `status`, "") === "REJECTED" ||
            getValue(request, `status`, "") === "PAUSED" ? (
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                  onClick={handleSubmit}
                  disabled={submitLoading}
                >
                  {submitLoading ? "Please wait..." : "Submit"}
                </button>
              </div>
            ) : !editId ? (
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                  onClick={handleSubmit}
                  disabled={submitLoading}
                >
                  {submitLoading ? "Please wait..." : "Submit"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="template-wrapper">
        <div className="template-container">
          <div className="template-form-container">
            <div className="template-editor-wrapper">
              <div className="d-flex justify-content-center flex-column align-items-center">
                {errorMessage && (
                  <div className="warning-container">
                    <WarningSvgComponent size="16" />
                    <p>{errorMessage}</p>
                  </div>
                )}
              </div>
              <div className=" border-bottom">
                <div className="">
                  <InputRuleForm
                    label="Template Name"
                    inputType="TEXT"
                    name="name"
                    placeholder="Enter Template Name"
                    required
                    className="mb-2"
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        name: e.target.value,
                      })
                    }
                    value={getValue(request, `name`, "")}
                    validator={simpleValidator}
                    disabled={editId ? true : false}
                    validLeft
                  />
                </div>
                <div className="mb-2 d-grid gap-1 ">
                  <div>
                    <SearchToolTip
                      label="label"
                      data={categoryEnum}
                      selectKey={"value"}
                      value={getValue(request, `category`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          category: getValue(e, `value`, ""),
                        })
                      }
                      name="Category"
                      placeholder="Choose Langauge"
                      disabled={editId ? true : false}
                      validator={simpleValidator}
                      required
                    />
                  </div>
                  <div>
                    <SearchToolTip
                      label="label"
                      data={languageEnum}
                      selectKey={"value"}
                      value={getValue(request, `language`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          language: getValue(e, `value`, ""),
                        })
                      }
                      name="Language"
                      placeholder="Choose Langauge"
                      disabled={editId ? true : false}
                      validator={simpleValidator}
                      required
                    />
                  </div>
                </div>
              </div>
              {getValue(request, `category`, "") !== "CAROUSEL" && (
                <div className="border-bottom">
                  <h6 className="mt-3">
                    Broadcast title{" "}
                    <span className="optional-text">(Optional)</span>
                  </h6>
                  <p className="my-2 greyed-description">
                    Highlight your brand here, use images or videos to stand
                    out.
                  </p>
                  <div className="mb-3">
                    <SearchToolTip
                      label="label"
                      data={headerEnum}
                      selectKey={"value"}
                      value={getValue(request, `header.type`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          header: {
                            ...request.header,
                            type: getValue(e, `value`, ""),
                          },
                        })
                      }
                      name="Type"
                      placeholder="Choose type"
                    />
                  </div>{" "}
                  {getValue(request, `header.type`, "") === "TEXT" && (
                    <div className=" input-with-length">
                      <InputRuleForm
                        inputType="TEXT"
                        id="text_input"
                        name="text_input"
                        value={getValue(request, `header.text`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            header: {
                              ...request.header,
                              text: e.target.value,
                            },
                          })
                        }
                        maxLength={60}
                        placeholder="Enter text"
                        className=""
                      />
                      <span className="input-length">
                        {getValue(request, `header.text.length`, "")}/60
                      </span>
                    </div>
                  )}
                  {getValue(request, `header.type`, "") === "IMAGE" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">
                          .jpg or .png
                        </span>{" "}
                        file
                      </p>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="custom-upload-filename"
                        accept={".jpg,.jpeg,.png"}
                      />
                    </div>
                  )}
                  {getValue(request, `header.type`, "") === "VIDEO" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">.mp4</span> file
                      </p>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="custom-upload-filename"
                        accept={".mp4"}
                      />
                    </div>
                  )}
                  {getValue(request, `header.type`, "") === "DOCUMENT" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">.pdf</span> file
                      </p>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="custom-upload-filename"
                        accept={".pdf"}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="border-bottom">
                <h6 className="mt-3">Body</h6>
                <p className="my-2 greyed-description">
                  Make your messages personal using variables like {`{{name}}`}{" "}
                  and get more replies.
                </p>
                <div className="w-100 mt-3 input-with-length">
                  <button
                    onClick={() => {
                      handleModal();
                    }}
                    className="dashboard-button--primary dashboard-button  hover-text touchable-text-white px-4 py-2"
                  >
                    Add variable
                  </button>

                  <div className="pt-3">
                    <DraftJSRefEditor
                      editorState={getValue(request, `body`, "")}
                      handleChangeEditor={handleChangeEditor}
                      name="content"
                      placeholder="Enter here"
                      editorStyle={{
                        background: "white",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        height: "30vh",
                      }}
                      focusColor={"#3fd1a9"}
                      toolbar={{
                        options: ["inline", "emoji"],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                        },
                        emoji: {
                          emojis: emojisList,
                        },
                      }}
                      focus
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              {getValue(request, `customTags.length`, 0) > 0 && (
                <div className="border-bottom">
                  <h6 className="mt-3">Sample column</h6>
                  <p className="my-2 greyed-description">
                    Your fields will appear here once an attribute is added
                  </p>
                  <div className="input-with-length">
                    {getValue(request, `customTags`, []).map(
                      (item: any, index: number) => {
                        return (
                          <InputRuleForm
                            inputType="TEXT"
                            placeholder={`Enter sample for ${getValue(
                              item,
                              `placeholder`,
                              ""
                            )}`}
                            name="sample"
                            onChange={(e: any) =>
                              handleTextArrayChange(
                                index,
                                e,
                                "customTags",
                                setRequest
                              )
                            }
                            value={getValue(item, `sample`, "")}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {getValue(request, `category`, "") === "CAROUSEL" ? (
                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <div></div>
                    <div className="d-flex align-items-center">
                      {getValue(request, `carousel.length`, 0) > 0 && (
                        <div
                          className="d-flex cursor-pointer "
                          onClick={() => {
                            handleAddCarousel();
                            setActiveCarouselTab(
                              getValue(request, `carousel.length`, 0)
                            );
                          }}
                        >
                          <AddSvgComponent color={"#1a73e8"} />
                          <h6 className="header_blue_text__16">Add more</h6>
                        </div>
                      )}
                      {getValue(request, `carousel.length`, 0) > 1 && (
                        <div
                          className="d-flex cursor-pointer  ms-4"
                          onClick={() => {
                            handleRemoveCarousel(activeCarouselTab - 1);
                            setActiveCarouselTab(
                              activeCarouselTab === 1
                                ? 1
                                : activeCarouselTab - 1
                            );
                          }}
                        >
                          <RemoveCircleSvgComponent color={"red"} />
                          <h6 className="header_blue_text__16 color_danger">
                            Remove
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                  <CarouselNavViews
                    length={getValue(request, `carousel.length`, 0)}
                    activeTab={activeCarouselTab}
                    handleTab={handleCarouselTab}
                  />
                  {getValue(request, `carousel`, []).map(
                    (carousel: object, index: number) => {
                      if (activeCarouselTab === index + 1)
                        return (
                          <>
                            <div
                              key={index}
                              className=" gap-2 mt-2 carousal-buttons-wrapper"
                            >
                              <div className="carousal-button">
                                <SearchToolTip
                                  name="Header"
                                  data={carouselHeaderEnum}
                                  label="label"
                                  value={getValue(carousel, `header.type`, "")}
                                  selectKey={"value"}
                                  onChange={(e: any) =>
                                    handleSelectRequestArrayChange(
                                      index,
                                      e,
                                      "carousel",
                                      "header",
                                      "type",
                                      setRequest
                                    )
                                  }
                                  hideClose
                                />
                              </div>
                              <div className="carousal-button">
                                <SearchToolTip
                                  name="Button 1"
                                  data={buttonTypeEnum}
                                  label="label"
                                  value={getValue(
                                    carousel,
                                    `buttons[${0}].action_type`,
                                    ""
                                  )}
                                  selectKey={"value"}
                                  onChange={(e: any) => {
                                    handleSelectNestedRequestArrayChange(
                                      index,
                                      0,
                                      e,
                                      "carousel",
                                      "buttons",
                                      "action_type",
                                      setRequest
                                    );
                                    handlChangeType(
                                      index,
                                      0,
                                      getValue(e, `action_type`, ""),
                                      "carousel",
                                      "buttons",
                                      "type",
                                      setRequest,
                                      getValue(e, `value`, "") === "URL"
                                        ? "url"
                                        : getValue(e, `value`, "") ===
                                          "CALL_TO_ACTION"
                                        ? "call"
                                        : ""
                                    );
                                  }}
                                />
                              </div>
                              <div className="carousal-button">
                                <SearchToolTip
                                  name="Button 2"
                                  data={buttonTypeEnum}
                                  label="label"
                                  value={getValue(
                                    carousel,
                                    `buttons[${1}].action_type`,
                                    ""
                                  )}
                                  selectKey={"value"}
                                  onChange={(e: any) => {
                                    handleSelectNestedRequestArrayChange(
                                      index,
                                      1,
                                      e,
                                      "carousel",
                                      "buttons",
                                      "action_type",
                                      setRequest
                                    );
                                    handlChangeType(
                                      index,
                                      1,
                                      getValue(e, `action_type`, ""),
                                      "carousel",
                                      "buttons",
                                      "type",
                                      setRequest,
                                      getValue(e, `value`, "") === "URL"
                                        ? "url"
                                        : getValue(e, `value`, "") ===
                                          "CALL_TO_ACTION"
                                        ? "call"
                                        : ""
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-start mt-4 gap-2">
                              <div className="w-30 mt-3">
                                <p className="small_text__14">
                                  {formatString(
                                    getValue(carousel, `header.type`, "")
                                  )}
                                </p>
                                <div className="mt-2">
                                  {getValue(carousel, `header.type`, "") ===
                                    "IMAGE" && (
                                    <div>
                                      <p className="custom-file-upload mb-2 requirement-message">
                                        Choose a{" "}
                                        <span className="required-file-format">
                                          .jpg or .png
                                        </span>{" "}
                                        file
                                      </p>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          handleCarouseFileChange(e, index)
                                        }
                                        className="custom-upload-filename"
                                        accept={".jpg,.jpeg,.png"}
                                      />
                                    </div>
                                  )}
                                  {getValue(carousel, `header.type`, "") ===
                                    "VIDEO" && (
                                    <div>
                                      <p
                                        // htmlFor="file-upload"
                                        className="custom-file-upload mb-2 requirement-message"
                                      >
                                        Choose a{" "}
                                        <span className="required-file-format">
                                          .mp4
                                        </span>{" "}
                                        file
                                      </p>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          handleCarouseFileChange(e, index)
                                        }
                                        className="custom-upload-filename"
                                        accept={".mp4"}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="carousel_media_container mt-3">
                                  {(getValue(carousel, `header.type`, "") &&
                                  getValue(carousel, `header.link.key`, "")
                                    ? getValue(carousel, `header.link.key`, "")
                                    : getValue(
                                        carousel,
                                        `header.link`,
                                        ""
                                      )) && (
                                    <>
                                      {getValue(carousel, `header.type`, "") ===
                                      "VIDEO" ? (
                                        <video
                                          controls
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                        >
                                          <source
                                            src={`https://${config.URL}/${
                                              getValue(
                                                carousel,
                                                `header.link.key`,
                                                ""
                                              )
                                                ? getValue(
                                                    carousel,
                                                    `header.link.key`,
                                                    ""
                                                  )
                                                : getValue(
                                                    carousel,
                                                    `header.link`,
                                                    ""
                                                  )
                                            }`}
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <img
                                          src={
                                            getValue(
                                              carousel,
                                              `header.link.key`,
                                              ""
                                            )
                                              ? `https://${
                                                  config.URL
                                                }/${getValue(
                                                  carousel,
                                                  `header.link.key`,
                                                  ""
                                                )}`
                                              : `https://${
                                                  config.URL
                                                }/${getValue(
                                                  carousel,
                                                  `header.link`,
                                                  ""
                                                )}`
                                          }
                                          alt="Selected"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="w-70">
                                <div className="px-2 mt-2">
                                  {getValue(carousel, `buttons`, []).map(
                                    (item: object, aIndex: number) => {
                                      if (getValue(item, `action_type`, ""))
                                        return (
                                          <div className="mt-2">
                                            <p className="small_text__14 mt-3">
                                              {formatString(
                                                getValue(
                                                  item,
                                                  `action_type`,
                                                  ""
                                                )
                                              )}
                                            </p>
                                            {getValue(
                                              item,
                                              `action_type`,
                                              ""
                                            ) === "QUICK_REPLY" ? (
                                              <InputRuleForm
                                                inputType="TEXT"
                                                onChange={(e: any) =>
                                                  handleTextNestedRequestArrayChange(
                                                    index,
                                                    aIndex,
                                                    e,
                                                    "carousel",
                                                    "buttons",
                                                    "text",
                                                    setRequest
                                                  )
                                                }
                                                name="text"
                                                value={getValue(
                                                  item,
                                                  "text",
                                                  ""
                                                )}
                                                placeholder="Enter here"
                                                maxLength={25}
                                                showCount
                                              />
                                            ) : (
                                              <div className="d-flex align-items-center justify-content-between">
                                                <div className="w-50 ">
                                                  <InputRuleForm
                                                    inputType="TEXT"
                                                    onChange={(e: any) =>
                                                      handleTextNestedRequestArrayChange(
                                                        index,
                                                        aIndex,
                                                        e,
                                                        "carousel",
                                                        "buttons",
                                                        "text",
                                                        setRequest
                                                      )
                                                    }
                                                    name={"text"}
                                                    value={getValue(
                                                      item,
                                                      "text",
                                                      ""
                                                    )}
                                                    placeholder={`Enter Title`}
                                                    label={"Title"}
                                                    maxLength={20}
                                                    // showCount
                                                  />
                                                </div>
                                                <div className="w-50 ps-2">
                                                  {getValue(
                                                    item,
                                                    "type",
                                                    ""
                                                  ) === "call" ? (
                                                    <PhoneInputComponent
                                                      placeholder="Enter phone number (+91...)"
                                                      value={getValue(
                                                        item,
                                                        "phone",
                                                        ""
                                                      )}
                                                      onChange={(e: any) => {
                                                        handlePhoneNestedRequestArrayChange(
                                                          index,
                                                          aIndex,
                                                          e,
                                                          "carousel",
                                                          "buttons",
                                                          "phone",
                                                          setRequest
                                                        );
                                                      }}
                                                      label={"Phone Number"}
                                                    />
                                                  ) : (
                                                    <InputRuleForm
                                                      inputType="TEXT"
                                                      onChange={(e: any) =>
                                                        handleTextNestedRequestArrayChange(
                                                          index,
                                                          aIndex,
                                                          e,
                                                          "carousel",
                                                          "buttons",
                                                          getValue(
                                                            item,
                                                            "type",
                                                            ""
                                                          ) === "url"
                                                            ? "url"
                                                            : "phone",
                                                          setRequest
                                                        )
                                                      }
                                                      name={"url"}
                                                      value={getValue(
                                                        item,
                                                        "url",
                                                        ""
                                                      )}
                                                      placeholder={`Enter ${
                                                        getValue(
                                                          item,
                                                          "type",
                                                          ""
                                                        ) === "url"
                                                          ? "url"
                                                          : "Phone Number"
                                                      }`}
                                                      label={
                                                        getValue(
                                                          item,
                                                          "type",
                                                          ""
                                                        ) === "url"
                                                          ? "URL"
                                                          : "Phone"
                                                      }
                                                      // maxLength={20}
                                                      // showCount
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                    }
                                  )}
                                </div>
                                <div className="px-2 mt-2">
                                  <InputRuleForm
                                    inputType="TEXTAREA"
                                    label="Body"
                                    value={getValue(carousel, `body`, "")}
                                    name="body"
                                    onChange={(e: any) =>
                                      handleTextRequestArrayChange(
                                        index,
                                        e,
                                        "carousel",
                                        setRequest
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                    }
                  )}
                </div>
              ) : (
                <>
                  <div className="border-bottom">
                    <h6 className="mt-3">
                      Footer <span className="optional-text">(Optional)</span>
                    </h6>
                    <p className="my-2 greyed-description">
                      Footers are great to add any disclaimers or to add a
                      thoughtful PS
                    </p>
                    <div className="input-with-length">
                      <InputRuleForm
                        inputType="TEXT"
                        id="footer"
                        name="footer"
                        value={getValue(request, `footer`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            footer: e.target.value,
                          })
                        }
                        maxLength={60}
                        placeholder="Enter Footer Text"
                      />
                      <span className="input-length">
                        {getValue(request, `footer.length`, "")}/60
                      </span>
                    </div>
                  </div>
                  <div className="border-bottom">
                    <h6 className="mt-3">
                      Buttons <span className="optional-text">(Optional)</span>
                    </h6>
                    <p className="my-2 greyed-description">
                      Create upto 2 buttons that let customers respons to your
                      messages or take action.
                    </p>

                    <div className="d-flex  align-items-center mb-2 mt-3 radio_button_container">
                      {buttonTypeEnum.map((item: object) => {
                        return (
                          <div
                            onClick={() =>
                              setButtonType(getValue(item, `value`, ""))
                            }
                            className="d-flex align-items-center mx-2 "
                          >
                            <input
                              type="radio"
                              className="input-radio"
                              checked={
                                selectedType === getValue(item, `value`, "")
                                  ? true
                                  : false
                              }
                            />
                            <div className=" ms-1 small_text__14">
                              <p>{getValue(item, `label`, "")}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {getValue(request, "buttons.length", 0) < 3 && (
                      <div className="d-flex align-items-center cursor-pointer">
                        <AddSvgComponent />
                        <p
                          className="add_text ms-2"
                          onClick={() =>
                            handleAddTypeButton(
                              selectedType,
                              selectedType === "URL" ? "url" : "call"
                            )
                          }
                        >
                          Add
                        </p>
                      </div>
                    )}
                    {getValue(request, "buttons.length", 0) > 0 && (
                      <div className="mt-4 template_main_button_container">
                        {getValue(request, "buttons", []).map(
                          (item: any, index: number) => {
                            const buttonType = getValue(
                              item,
                              `action_type`,
                              ""
                            );
                            return (
                              <div className="template_main_button_container_item position-relative">
                                <h6 className="header_text__14 mt-2">
                                  {getValue(item, `type`, "")
                                    ? `${formatString(
                                        getValue(item, `type`, "")
                                      )}`
                                    : formatString(
                                        getValue(item, `action_type`, "")
                                      )}
                                </h6>
                                <div
                                  key={index}
                                  className="d-flex align-items-center"
                                >
                                  {buttonType === "QUICK_REPLY" ? (
                                    <InputRuleForm
                                      inputType="TEXT"
                                      onChange={(e: any) =>
                                        handleTextArrayChange(
                                          index,
                                          e,
                                          "buttons",
                                          setRequest
                                        )
                                      }
                                      name="text"
                                      value={getValue(item, "text", "")}
                                      placeholder="Enter here"
                                      maxLength={20}
                                      showCount
                                    />
                                  ) : (
                                    <div className="flex-wrap d-flex">
                                      <div className="w-45 ms-1">
                                        <InputRuleForm
                                          inputType="TEXT"
                                          onChange={(e: any) =>
                                            handleTextArrayChange(
                                              index,
                                              e,
                                              "buttons",
                                              setRequest
                                            )
                                          }
                                          name={"text"}
                                          value={getValue(item, "text", "")}
                                          placeholder={`Enter Title`}
                                          label={"Title"}
                                          maxLength={20}
                                          // showCount
                                        />
                                      </div>
                                      <div className="w-45 ms-1">
                                        {getValue(item, "type", "") ===
                                        "call" ? (
                                          <PhoneInputComponent
                                            placeholder="Enter phone number (+91...)"
                                            value={getValue(
                                              item,
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              ""
                                            )}
                                            onChange={(e: any) => {
                                              handleValueArrayChange(
                                                index,
                                                e,
                                                "phone",
                                                "buttons",
                                                setRequest
                                              );
                                            }}
                                            label={"Phone Number"}
                                          />
                                        ) : (
                                          <InputRuleForm
                                            inputType="TEXT"
                                            onChange={(e: any) =>
                                              handleTextArrayChange(
                                                index,
                                                e,
                                                "buttons",
                                                setRequest
                                              )
                                            }
                                            name={
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "url"
                                                : "phone"
                                            }
                                            value={getValue(
                                              item,
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              ""
                                            )}
                                            placeholder={`Enter ${
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "url"
                                                : "Phone Number"
                                            }`}
                                            label={
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "URL"
                                                : "Phone"
                                            }
                                            // maxLength={20}
                                            // showCount
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    onClick={() =>
                                      handleRemoveTypeButton(index)
                                    }
                                    className="mt-3 mx-1 template_delete"
                                  >
                                    <DeleteSvgComponent size={26} />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <WhatsappIconViewPage request={request} />
        </div>
      </div>
      <AddWhatsappVariable
        isOpen={isOpen}
        handleModal={handleModal}
        handleAddTags={handleAddTags}
        allTags={allTags}
        tagsByPipelineID={tagsByPipelineID}
        allPipelinesList={allPipelinesList}
        pipelineId={pipelineId}
      />
    </div>
  );
};

export default CreateTemplateMessage;
