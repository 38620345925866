import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function PropertyLocation() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <div className="property-location-wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCdc5BWI6M8hboAhcY1eOV5btQkXuY8s-s" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent lat={60.955413} lng={30.337844} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
}
