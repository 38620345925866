import { Link } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { handleEmailChange, handleTextChange } from "common/handleChange";
import React from "react";
import InputFormField from "../../common/inputForm";
// import classNames from "@pages/Public/Auth/Signin/support-login.module.scss";

export default function SignupForm(props: any) {
  const { request, setRequest, validator, handleSubmit, submitLoading } = props;
  const [visibility, setVisisbility] = React.useState(false);

  return (
    <div className={"signup-form-wrapper"}>
      <h2 className={`signup-title text-center mt-4`}>
        Sign up for a 30 days free trail
      </h2>
      <div className={"signup-form-details"}>
        <InputFormField
          inputType="TEXT"
          validator={validator}
          placeholder="Enter First Name"
          label="First Name"
          name="first_name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, `first_name`, "")}
        />
        <InputFormField
          inputType="TEXT"
          validator={validator}
          placeholder="Enter Last Name"
          label="Last Name"
          name="last_name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          value={getValue(request, `last_name`, "")}
        />
        <InputFormField
          inputType="EMAIL"
          validator={validator}
          icon={"email"}
          placeholder="Enter Email"
          label="Email"
          name="identifier"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleEmailChange(e, request, setRequest)
          }
          value={getValue(request, `identifier`, "")}
        />
        <div className="position-relative">
          <InputFormField
            inputType={visibility ? "TEXT" : "PASSWORD"}
            validator={validator}
            icon={visibility ? "key" : "eye"}
            placeholder="Enter Password"
            label="Password"
            name="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            value={getValue(request, `password`, "")}
          />
          <img
            src={
              !visibility
                ? `/images/visibility_off.svg`
                : `/images/visibility.svg`
            }
            className={"visbility_icon"}
            onClick={() => setVisisbility(!visibility)}
          />
        </div>
      </div>

      {/* <Link to="/thankyou"> */}
      <button
        className="superteams-button__primary-button"
        onClick={handleSubmit}
        disabled={submitLoading}
      >
        {submitLoading ? "Please wait..." : "Sign up for Free"}
      </button>
      {/* </Link> */}
      <p className="mt-3 ms-1">
        Already Registered?{" "}
        <Link to="/signin">
          <span className="header_text">Sign-in</span>
        </Link>{" "}
        here
      </p>
      <p className={`signup-text text-center`}>
        By Clicking on “SIGNUP FOR FREE” you agree to our terms and acknowledge
        reading our <span>privacy notice</span>
      </p>
    </div>
  );
}
