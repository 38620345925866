import { convertCurrentDate } from "@common/date-helpers";
import ActivityLoader from "@components/common/Loader/activity-loader";
import AdNoDataPage from "@components/common/NoData/ad-nodata";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function FacebookList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();
  return (
    <>
      {isLoading ? (
        <ActivityLoader />
      ) : (
        <>
          {list.length > 0 ? (
            <div className="px-4 pt-2">
              <table className="common_table w-100">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Provider</td>
                    <td>Status</td>
                    <td>Added on</td>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item: object) => {
                    return (
                      <tr
                        onClick={() =>
                          navigate(
                            `/${getValue(
                              params,
                              `orgId`,
                              ""
                            )}/settings/ads/${getValue(item, `id`, "")}`
                          )
                        }
                      >
                        <td className="header_blue_text__14 touchable-text">
                          {getValue(item, `name`, "")}
                        </td>
                        <td>{getValue(item, `email`, "")}</td>
                        <td>
                          {getValue(item, `provider`, "") === "FACEBOOK"
                            ? "Facebook"
                            : ""}
                        </td>
                        <td className="d-flex gap-2 align-items-center">
                          <div
                            className="active-icon"
                            style={{
                              background:
                                getValue(item, `status`, "") === "ACTIVE"
                                  ? "#21C9AF"
                                  : "#F05555",
                            }}
                          ></div>
                          <div>
                            {getValue(item, `status`, "") === "ACTIVE"
                              ? "Active"
                              : "Not active"}
                          </div>
                        </td>
                        <td>
                          {convertCurrentDate(getValue(item, `created_at`, ""))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="pt-4">
              <AdNoDataPage />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FacebookList;
