import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import React from "react";

const ChildDetails = () => {
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="d-flex flex-column gap-3 mb-4">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter first name"
          label="Guest first name"
          required
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter last name"
          label="Guest last name"
          required
        />
        <div>
          <p className="form-label">Gender</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter dob"
          label="Date of birth"
        />
        <div>
          <p className="form-label">Nationality</p>
          <SearchToolTip />
        </div>
        <div>
          <p className="form-label">Country</p>
          <SearchToolTip />
        </div>
        <div>
          <p className="form-label">Realtionship with primary</p>
          <SearchToolTip />
        </div>
      </div>
    </div>
  );
};

export default ChildDetails;
