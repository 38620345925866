import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
// import Lottie from "react-lottie";
import bin from "./delete.json";
import "./addpopup.scss";
import ButtonComponent from "@components/Form/Button/Button";
import DeleteSvgComponent from "@assets/svg/delete";
// import DeleteSvgComponent from "@assets/svg/delete";
interface IDeleteModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
  deleteValue: string;
}
const DeleteModal: React.FunctionComponent<IDeleteModalProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")} toggle={props.handleModal}>
      {/* <ModalHeader>
        <h6 className="header_text__20 delete_text">Delete</h6>
      </ModalHeader> */}
      <section className="m-3">
        {/* <ModalHeader>
          <h6 className="header_text__16">DELETE</h6>
        </ModalHeader> */}
        <div className="">
          {/* <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{ width: "150px", height: "150px", alignSelf: "center" }}
            >
              <Lottie options={defaultOptions} />
            </div>
          </div> */}
          {/* <DeleteSvgComponent size={26} color={'red'}/> */}
          <div className="m-2 p-2">
            {/* <img src="/images/info-delete.png"  className="delete-icon"/> */}
            <p className=" small_text__22 delete_text mb-4">Confirm delete </p>
            <p className="small_text__15">
              Are you sure you want to delete ?
              <b>{getValue(props, `deleteValue`, "")}</b>{" "}
            </p>
          </div>
        </div>
        {/* <ModalFooter> */}
        <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={props.handleModal}
            buttonType={"scecondary"}
            buttonText="No keep it"
          />
          <ButtonComponent
            buttonType={"delete"}
            buttonText={"Yes, delete it"}
            onClickHandler={() => props.handleSubmit()}
          />
        </div>
        {/* </ModalFooter> */}
      </section>
    </Modal>
  );
};

export default DeleteModal;
