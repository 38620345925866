import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import CommonDocumentList from "@components/custom/FileUpload/document-list";

const AttachmentsPopup = (props: any) => {
  const {
    handleUploadDocuments,
    submitLoading,
    handleRemoveDocuments,
    documentList,
  } = props;
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      toggle={getValue(props, `isOpen`, "")}
    >
      <ModalHeader>
        <h6 className="header_text__18">Attachments</h6>
      </ModalHeader>
      <ModalBody>
        <section className="m-4">
          <DragAndDropFileComponent uploadFile={handleUploadDocuments} />
          <CommonDocumentList
            documentList={documentList}
            handleRemoveDocuments={handleRemoveDocuments}
          />
        </section>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={props.handleModal}
            buttonType={"scecondary"}
            buttonText="Cancel"
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={"Upload"}
            onClickHandler={() => props.handleSubmit()}
            disabled={submitLoading}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AttachmentsPopup;
