import { getValue } from "@utils/lodash";
import DocumentFormatPreviews from "./doc-formats-preview";
import DocumentSvgComponent from "@assets/svg/document";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import DocumentFormatReplyPreviews from "./doc-formats-reply-preview";
import AddSvgComponent from "@assets/svg/add";
import { handleDownloadOnClick } from "@common/downloadfile";
import { config } from "env";
import { replaceDefaultPlaceholders } from "@common/markdown-helper";
import WhatsappIconViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon";
import WhatsappIconChatViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon-chat";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";

function ChatSenderSection(props) {
  const { item, setSelectedReplyMessage } = props;
  const statusColor =
    getValue(item, "status", "") === "failed" ? "#ffc8c8" : "#d9fdd4";

  return (
    <div>
      <div className="sender-details-wrapper">
        <div className="sender-details">
          <div className="sender-left-section">
            <p
              className="sender-message"
              style={{ "--status-color": statusColor }}
            >
              <div className="arrow-container">
                <ArrowdownSvgComponent color={"gray"} class="arrow" />
                <div className="whatsapp-more-options">
                  <button
                    onClick={() => setSelectedReplyMessage(item)}
                    className=" small_text__13 w-more-options"
                  >
                    <img
                      src="/images/icons/whatsapp-reply.svg"
                      className="ms-1"
                    />
                    Reply
                  </button>
                  {getValue(item, `type`, "") === "image" && (
                    <>
                      <div className="add-border my-1"></div>
                      <button
                        className="small_text__13 w-more-options"
                        onClick={() =>
                          handleDownloadOnClick(
                            `https://${config.URL}/${getValue(
                              item,
                              `upload_file.key`,
                              ""
                            )}`,
                            getValue(item, `upload_file.filename`, "")
                          )
                        }
                      >
                        <img
                          src="/images/icons/whatsapp-download.svg"
                          className="download-icon ms-1"
                        />
                        Download
                      </button>
                    </>
                  )}
                </div>
              </div>
              {getValue(item, `replied_message.id`, "") && (
                <div className="reply_message_container mb-2">
                  <div className="d-flex align-items-center ps-2 justify-content-between replayed-background w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <DocumentSvgComponent />
                      {/* <p className="small_text__14 mx-2">
                        {getValue(item, `replied_message.media_caption`, "")
                          ? getValue(item, `replied_message.media_caption`, "")
                          : getValue(item, `replied_message.text_body`, "")}
                      </p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceDefaultPlaceholders(
                            getValue(item, `replied_message.media_caption`, "")
                              ? getValue(
                                  item,
                                  `replied_message.media_caption`,
                                  ""
                                )
                              : getValue(item, `replied_message.text_body`, "")
                          ),
                        }}
                        className="small_text__14 px-2"
                      ></p>
                    </div>
                    {getValue(item, `replied_message.upload_file.id`, "") && (
                      <DocumentFormatReplyPreviews
                        item={getValue(item, `replied_message`, {})}
                        upload_file={getValue(
                          item,
                          `replied_message.upload_file`,
                          ""
                        )}
                      />
                    )}
                  </div>
                </div>
              )}
              {getValue(item, `broadcast_id`, "") && (
                <div>
                  <WhatsappIconChatViewPage
                    request={getValue(item, `template`, {})}
                  />
                </div>
              )}

              <DocumentFormatPreviews item={item} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatSenderSection;
