import { useEffect, useState } from "react";
import Tab2 from "../components/tab2";
import Tab3 from "../components/tab3";
import { getEmailConfig } from "@services/email-config.service";
import { getValue } from "@utils/lodash";
import { getOAuthLink } from "@services/email-config.service";
import Loader from "@components/common/Loader/loading";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import ProfileNavViews from "@pages/Private/Profile/components/nav-views";
import { QueryRequestHelper } from "@common/query-request-helper";

function SettingsUserEmails(props: any) {
  const [activeTab, setActiveTab] = useState(1);
  const handleActiveTab = (tab: any) => {
    setActiveTab(tab);
    // setActiveTab(3);
  };
  const [type, setType] = useState("");
  const handleType = (type: string) => {
    setType(type);
    handleActiveTab(2);
  };

  const [list] = useState([
    {
      value: "EMAIL_GMAIL",
      label: "Gmail",
      url: "/images/gmail.svg",
    },
    {
      value: "EMAIL_OUTLOOK",
      label: "Outlook",
      url: "/images/outlook.png",
    },
    // {
    //   value: "EMAIL_SMTP",
    //   label: "SMTP",
    //   url: "/images/smtp.png",
    // },
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               useEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getConfig();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                           Configure Section                                */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [configInfo, setConfigInfo] = useState({});
  const getConfig = async () => {
    try {
      setIsLoading(true);
      let resp = await getEmailConfig();
      if (resp) {
        setConfigInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getConfigList = async (provider: string) => {
    try {
      let payload = {
        isUser: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getOAuthLink(provider, queryRequest);
      if (resp) {
        window.open(getValue(resp, `data.link`, ""));
      }
    } catch (error) {}
  };
  return (
    <div>
      <HomeHeader />
      <ProfileNavViews />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mt-3 ms-5">
          {/* {activeTab == 1 && (
                <Tab1
                  handleActiveTab={handleActiveTab}
                  configInfo={configInfo}
                />
                
              )} */}
          {activeTab == 1 && (
            <Tab2
              setType={handleType}
              handleActiveTab={handleActiveTab}
              configInfo={configInfo}
              list={list}
            />
          )}
          {activeTab == 2 && (
            <Tab3
              type={type}
              handleActiveTab={handleActiveTab}
              getConfigList={getConfigList}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default SettingsUserEmails;
