import React, { useEffect, useMemo, useRef, useState } from "react";
import "./editfields.scss";
import { getValue, setValue } from "@utils/lodash";
import {
  handleBooleanArrayChange,
  handleTextChange,
} from "common/handleChange";
import AddSvgComponent from "assets/svg/add";
import AddCircleSvgComponent from "assets/svg/add-circle";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorSvgComponent from "assets/svg/drag-indicator";
import useDraggableInPortal from "@common/useDraggableInPortal";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import SwitchMain from "@components/Form/Switch/SwitchComponent";
import InputRuleForm from "@components/InputRuleForm/form";
import ImageSearchToolTip from "@components/custom/Dropdown/ImageSearchTooltip";
import { camelToSnake } from "@common/text-helpers";
// import SwitchMain from "@components/CommonComponents/Switch/SwitchComponent";

function CustomFieldPopup(props: any) {
  const {
    isVisibile,
    handleVisibility,
    handleSubmit,
    submitLoading,
    moduleName,
    request,
    setRequest,
    fieldList,
    subTypes,
  } = props;

  const findStatus = (type: string) => {
    let list = fieldList.filter(
      (item: object) => getValue(item, `value`, "") === type
    );
    return getValue(list, `length`, 0) > 0 ? getValue(list, `[${0}]`, {}) : {};
  };
  const additionalInfo = useMemo(
    () => findStatus(getValue(request, `main_type`, "")),
    [getValue(request, `main_type`, "")]
  );
  const addChoice = () => {
    let obj = {
      value: "",
      label: "",
    };
    getValue(request, `dropdown_options`, []).push(obj);
    setRequest({ ...request });
    addDefaultOptions("");
  };
  const removeChoice = (i: number) => {
    let filtered = getValue(request, `dropdown_options`, []).filter(
      (_item: object, index: number) => i !== index
    );
    setRequest({
      ...request,
      dropdown_options: filtered,
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Drag Section                              */
  /* -------------------------------------------------------------------------- */
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const sourceOpenArrayCopy: any = [
          ...getValue(request, `dropdown_options`, []),
        ];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        setValue(request, `dropdown_options`, sourceOpenArrayCopy);
        setRequest({ ...request });
      }
    }
  };
  const renderDraggable = useDraggableInPortal();

  /* -------------------------------------------------------------------------- */
  /*                                   Ref Section                              */
  /* -------------------------------------------------------------------------- */
  const addDefaultOptions = (name: string) => {
    // let defaultOptions: { value: string; label: string }[] = [];

    // if (name === "select-boolean") {
    //   defaultOptions = [
    //     { value: "", label: "None" },
    //     { value: "yes", label: "Yes" },
    //     { value: "no", label: "No" },
    //   ];
    // } else {
    //   const copy = [...getValue(props, "request.dropdown_options", [])];
    //   const filteredOptions = copy.filter((item: any) => item.value);
    //   defaultOptions = [{ value: "", label: "None" }, ...filteredOptions];
    // }
    // props.defaultOptions.current = defaultOptions;

    let defaultOptions: any = [];
    if (
      name === "boolean_select" ||
      name === "boolean_checkbox" ||
      name === "boolean_radio"
    ) {
      defaultOptions = [
        { value: null, label: "None" },
        { value: true, label: "Yes" },
        { value: false, label: "No" },
        // ...getValue(props, "request.dropdown_options", []), // Adding existing options if needed
      ];
    } else {
      const filteredOptions = getValue(
        props,
        "request.dropdown_options",
        []
      ).filter((item: any) => item.value);
      defaultOptions = [{ value: "", label: "None" }, ...filteredOptions];
    }
    props.defaultOptions.current = defaultOptions;
  };
  useEffect(() => {
    addDefaultOptions("");
  }, [props.defaultOptions]);
  return (
    <CommonRightPopup
      isPopupVisible={isVisibile}
      addCustomClass={"rolls-popup"}
      title={`${
        getValue(props, `editId`, "") ? "Modify" : "Create Custom"
      }  Field`}
      toggle={() => {
        handleVisibility();
      }}
      first_title={"Cancel"}
      second_title={getValue(props, `editId`, "") ? "Update" : "Submit"}
      handleSubmit={handleSubmit}
      submitLoading={submitLoading}
      width={"550px"}
      hideNew
      moduleName={moduleName}
    >
      <div className={`custom_field_container`}>
        <div className="mb-3">
          <div className="mt-3">
            <InputRuleForm
              inputType="TEXT"
              label="Field Label"
              value={getValue(request, `label`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              name="label"
              placeholder="Enter Field Label"
              style={{ height: "40px", fontSize: "16px" }}
              required
            />
          </div>
          {/* {getValue(request, `main_type`, "") !== "lookup" && ( */}
          <>
            <div className="w-100">
              <label className="form-label">Field Type *</label>
              <ImageSearchToolTip
                width="473px"
                label="label"
                data={
                  !props.editId
                    ? fieldList.filter(
                        (item: object) =>
                          getValue(item, `value`, "") !== "lookup"
                      )
                    : fieldList
                }
                value={getValue(request, `main_type`, "")}
                onChange={(e: object) => {
                  props.resetCustomField();
                  setRequest({
                    ...request,
                    main_type: getValue(e, `value`, ""),
                  });
                }}
                selectKey={"value"}
                disabled={props.editId ? true : false}
                required
              />
            </div>
            {getValue(additionalInfo, `sub_type`, false) && (
              <div className="w-100 mt-3">
                <label className="form-label">Sub Field Type *</label>
                <SearchToolTip
                  width="473px"
                  label="label"
                  data={getValue(
                    subTypes,
                    `[${getValue(request, `main_type`, "")}]`,
                    []
                  )}
                  value={getValue(request, `input_type`, "")}
                  onChange={(e: object) => {
                    setRequest({
                      ...request,
                      input_type: getValue(e, `value`, ""),
                    });
                    addDefaultOptions(getValue(e, `value`, ""));
                  }}
                  selectKey={"value"}
                  custom_type={getValue(request, `main_type`, "")}
                  disabled={props.editId ? true : false}
                />
              </div>
            )}
            {getValue(additionalInfo, `value`, "") === "select" && (
              <div>
                <div className="mt-4">
                  <h6 className="header_blue_text__17">Dropdown Options</h6>
                </div>
                <div className="mt-2 position-relative">
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="source">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          // id="myInput"
                        >
                          {getValue(props, `request.dropdown_options`, []).map(
                            (item: object, index: number) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={`${index}open`}
                                  index={index}
                                  // isDragDisabled={!index}
                                >
                                  {renderDraggable((provided: any) => (
                                    <div
                                      className={`d-flex align-items-center mb-1`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="">
                                        <DragIndicatorSvgComponent
                                          color={"lightgray"}
                                        />
                                      </div>
                                      <div className="inline-input-drag ms-2">
                                        <InputRuleForm
                                          id="myInput"
                                          inputType="TEXT"
                                          label="Choice"
                                          hideLabel
                                          value={getValue(item, `label`, "")}
                                          onChange={(e: any) => {
                                            setValue(
                                              request,
                                              `dropdown_options[${index}].value`,
                                              camelToSnake(e.target.value)
                                            );
                                            setValue(
                                              request,
                                              `dropdown_options[${index}].label`,
                                              e.target.value
                                            );
                                            setRequest({ ...request });
                                            addDefaultOptions("");
                                          }}
                                          name="label"
                                          placeholder="Specify option name"
                                          style={{
                                            height: "40px",
                                            marginTop: "8px",
                                            fontSize: "16px",
                                          }}
                                        />
                                      </div>
                                      <div className="d-flex ms-2">
                                        {props.editId &&
                                        getValue(item, `id`, "") ? (
                                          <SwitchMain
                                            checked={
                                              !getValue(
                                                request,
                                                `dropdown_options[${index}].archived`,
                                                false
                                              )
                                            }
                                            onChange={() => {
                                              handleBooleanArrayChange(
                                                index,
                                                !!!getValue(
                                                  request,
                                                  `dropdown_options[${index}].archived`,
                                                  false
                                                ),
                                                "archived",
                                                "dropdown_options",
                                                setRequest
                                              );
                                            }}
                                          />
                                        ) : (
                                          <div className="d-flex align-items-center">
                                            <div
                                              className="cursor-pointer"
                                              onClick={() => {
                                                addChoice();
                                                addDefaultOptions("");
                                              }}
                                            >
                                              <AddCircleSvgComponent
                                                color={"green"}
                                              />
                                            </div>
                                            {index > 1 ? (
                                              <div
                                                className="ms-2 cursor-pointer"
                                                onClick={() => {
                                                  removeChoice(index);
                                                  addDefaultOptions("");
                                                }}
                                              >
                                                <RemoveCircleSvgComponent
                                                  color={"#ea4335"}
                                                />
                                              </div>
                                            ) : (
                                              <div
                                                className="ms-2"
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                              >
                                                <RemoveCircleSvgComponent
                                                  color={"#ff7c80"}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div
                    className={`${"button_container"} mt-4 d-flex align-items-center justify-content-center bg-white`}
                    onClick={() => addChoice()}
                  >
                    <AddSvgComponent color={"#1a73e8"} />
                    <p className="header_blue_text__16">Add Option</p>
                  </div>
                </div>
                <div className="mt-4">
                  <h6 className="header_text__16 mb-2">Set Default Value</h6>
                  <SearchToolTip
                    width="473px"
                    label="label"
                    data={getValue(props, `defaultOptions.current`, [])}
                    // value={getValue(request, `attributes.default_value`, "")}
                    value={getValue(request, `default_value`, "")}
                    onChange={(e: object) =>
                      setRequest({
                        ...request,
                        default_value: getValue(e, `value`, ""),
                        // attributes: {
                        //   ...request.attributes,
                        //   default_value: getValue(e, `value`, ""),
                        // },
                      })
                    }
                    selectKey={"value"}
                    custom_type={getValue(request, `main_type`, "")}
                    // disabled={props.editId ? true : false}
                  />
                </div>
              </div>
            )}
            {getValue(request, `main_type`, "") === "boolean_choice" && (
              <div className="mt-4">
                <h6 className="form-label mb-2">Set Default Value</h6>
                <SearchToolTip
                  width="473px"
                  label="label"
                  data={getValue(props, `defaultOptions.current`, [])}
                  // value={getValue(request, `attributes.default_value`, null)}
                  value={getValue(request, `default_value`, null)}
                  onChange={(e: object) => {
                    return setRequest({
                      ...request,
                      default_value: getValue(e, `value`, null)
                        ? getValue(e, `value`, null)
                        : getValue(e, `value`, false) === false
                        ? false
                        : null,
                      // attributes: {
                      //   ...request.attributes,
                      //   default_value: getValue(e, `value`, null)
                      //     ? getValue(e, `value`, null)
                      //     : getValue(e, `value`, false) === false
                      //     ? false
                      //     : null,
                      // },
                    });
                  }}
                  selectKey={"value"}
                  custom_type={getValue(request, `main_type`, "")}
                  // disabled={props.editId ? true : false}
                />
              </div>
            )}
            {!props.editId && (
              <div className="d-flex align-items-center mt-4">
                <input
                  type="checkbox"
                  className="common_height_width"
                  checked={getValue(request, `required`, false)}
                  onChange={(e: object) =>
                    setRequest({
                      ...request,
                      required: !getValue(request, `required`, false),
                    })
                  }
                />
                <p
                  className="ms-3 small_text__16 cursor-pointer"
                  onClick={(e: object) =>
                    setRequest({
                      ...request,
                      required: !getValue(request, `required`, false),
                    })
                  }
                >
                  Mandatory Field
                </p>
              </div>
            )}
            {(getValue(request, `main_type`, "") === "tel" ||
              getValue(request, `main_type`, "") === "text" ||
              getValue(request, `main_type`, "") === "numarical") && (
              <div className="d-flex align-items-center mt-4">
                <input
                  type="checkbox"
                  className="common_height_width"
                  checked={getValue(request, `unique`, false)}
                  onChange={(e: object) =>
                    setRequest({
                      ...request,
                      unique: !getValue(request, `unique`, false),
                    })
                  }
                />
                <p
                  className="ms-3 small_text__16 cursor-pointer"
                  onClick={(e: object) =>
                    setRequest({
                      ...request,
                      required: !getValue(request, `required`, false),
                    })
                  }
                >
                  Unique
                </p>
              </div>
            )}
          </>
          {/* )} */}
        </div>
      </div>
    </CommonRightPopup>
  );
}

export default CustomFieldPopup;
