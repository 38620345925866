import { useEffect, useRef, useState } from "react";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import WorkflowDetailHeader from "./components/header";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import WorkflowDetailStep2 from "./components/step2";
import WorkflowDetailStep3 from "./components/step3";
import {
  changeWorkflowStatus,
  createWorkflow,
  getAllWorkflowConditionFields,
  getAvailableEmails,
  getSpecificWorkflow,
} from "@services/workflow.service";
import InputRuleForm from "@components/InputRuleForm/form";
import WorkflowDetailStep4 from "./components/step4";
import {
  sendEmailRequestObj,
  tasksRequestObj,
  updatesRequestObj,
  whatsappRequestObj,
} from "./components/workflow-request";
import { toast } from "sonner";
import {
  convertNumbersToSquareBrackets,
  formatString,
  removeEmptyValues,
  removeNullValuesFromObjectArray,
  sortJSONObjectArray,
} from "@common/text-helpers";
import WorkflowTasksPopup from "./components/popup/tasks-popup";
import { getUsers } from "@services/users.service";
import { listAllModules } from "@services/modules.service";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import SendEmailPopup from "./components/popup/send-email-popup";
import WorkflowUpdatesPopup from "./components/popup/updates-popup";
import { useNavigate, useParams } from "react-router-dom";
import AddSvgComponent from "@assets/svg/add";
import PenSvgComponent from "@assets/svg/pen";
import { useStateContext } from "@context/profileProvider";
import { getAllModuleFields } from "@services/module-fields.service";
import WorkflowWhatsappPopup from "./components/popup/whatsapp-popup";
import { isValidPhoneNumber } from "libphonenumber-js";

function SettingsWorkflowDetail(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();
  const { userId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                              UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [allModules, setAllModules] = useState([]);
  const [request, setRequest] = useState({
    workflowId: "",
    moduleId: "",
    pipelineId: "",
    name: "",
    description: "",
    pattern: "",
    executionType: "CREATED",
    repeat: true,
    isActive: false,
    conditions: [
      // {
      //   action: "add",
      //   fieldId: "",
      //   operator: "",
      //   value: [{ key: "" }],
      //   pattern: "WHEN",
      // },
    ],
    moduleName: "",
    actions: {
      emails: [],
      updates: [],
      tasks: [],
      whatsapp: [],
    },
  });
  const [allConditions, setAllConditions] = useState(false);
  const [editIndex, setEditIndex] = useState<any>(null);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
    }
    getEmails();
  }, []);

  const [fromMails, setFromMails] = useState([]);
  const [orgEmails, setOrgEmails] = useState([]);
  const [listInfo, setListInfo] = useState({});
  const getEmails = async () => {
    try {
      let resp = await getAvailableEmails();
      if (resp) {
        // let obj = {
        //   default: "Superteams<system@superteamonline.com>",
        //   orgEmails: {
        //     "1": "dev@dev.com",
        //     "2": "sales@dev.com",
        //   },
        // };
        let obj = getValue(resp, `data`, {});
        setListInfo(obj);
        let transformedArray: any = Object.entries(obj.orgEmails).map(
          ([id, label]) => {
            if (id === "default") {
              return { label, id: label, name: label, value: label };
            } else {
              return { label, id: id, name: label, value: id };
            }
          }
        );

        let transformedArray1: any = Object.entries(obj.orgEmails).map(
          ([id, label]) => {
            if (id !== "default") {
              return { label, id: label, name: label, value: label };
            }
          }
        );

        transformedArray.unshift({
          label: obj.default,
          id: obj.default,
          name: obj.default,
          value: obj.default,
        });

        setFromMails(transformedArray);
        setOrgEmails(transformedArray1);
        setSendEmailRequest({
          ...sendEmailRequest,
          from: getValue(obj, `default`, ""),
          fromEmailType: "default",
        });
      }
    } catch (error) {}
  };

  const selectedModuleId = async (name: string) => {
    let resp = await listAllModules("");
    setAllModules(sortJSONObjectArray(getValue(resp, `data`, []), "seq_num"));
    let moduleInfo = getValue(resp, `data`, []).find(
      (item: object) => getValue(item, `api_name`, "") === name
    );
    return getValue(moduleInfo, `id`, "");
  };

  useEffect(() => {
    if (
      getValue(urlParams, `moduleName`, "") ||
      getValue(urlParams, `pipelineId`, "")
    ) {
      getConditions(
        getValue(urlParams, `moduleName`, ""),
        getValue(urlParams, `pipelineId`, "")
      );
      getOrgUsers();
      getModules(getValue(urlParams, `moduleName`, ""));
      setRequest({
        ...request,
        name: getValue(urlParams, `name`, ""),
        moduleId: getValue(urlParams, `moduleId`, ""),
        pipelineId: getValue(urlParams, `pipelineId`, ""),
      });
    }
  }, [
    getValue(urlParams, `moduleName`, ""),
    getValue(urlParams, `pipelineId`, ""),
  ]);
  /* -------------------------------------------------------------------------- */
  /*                                 API Section                                */
  /* -------------------------------------------------------------------------- */
  function convertExpression(input: string) {
    return input
      .replace(/\[/g, "")
      .replace(/\]/g, "")
      .replace(/\sAND\s/g, " ")
      .replace(/\(/g, "( ")
      .replace(/\)/g, " )");
  }

  function extractOperators(input: string) {
    const operators = [];
    operators.push("WHEN"); // Add WHEN as the first element
    const keywords = input.match(/[A-Z]+/g); // Extract uppercase words
    if (keywords) {
      keywords.forEach((word) => {
        operators.push(word);
      });
    }
    return operators;
  }

  const [info, setInfo] = useState({});
  const getData = async () => {
    try {
      let resp = await getSpecificWorkflow(getValue(params, `id`, ""));
      if (resp) {
        getOrgUsers();
        setInfo(getValue(resp, `data`, {}));
        getModules(getValue(resp, `data.module.api_name`, ""));
        getConditions(
          getValue(resp, `data.module.api_name`, ""),
          getValue(resp, `data.pipeline_id`, "")
        );
        let conditionsTypes = extractOperators(
          getValue(resp, `data.pattern`, "")
        );
        let conditions =
          getValue(resp, `data.conditions.length`, 0) > 0
            ? sortJSONObjectArray(
                getValue(resp, `data.conditions`, []),
                "index"
              ).map((item: object, index: number) => ({
                pattern:
                  getValue(conditionsTypes, `length`, 0) > 0
                    ? conditionsTypes[index]
                    : "",
                value: [{ key: getValue(item, `value`, "") }],
                fieldId: getValue(item, `module_field_id`, "")
                  ? getValue(item, `module_field_id`, "")
                  : "pipeline_stage_id",
                operator: getValue(item, `operator`, ""),
                id: getValue(item, `id`, ""),
                action: "update",
                index: getValue(item, `index`, null),
              }))
            : [];
        let emailActions =
          getValue(resp, `data.email_actions.length`, 0) > 0
            ? getValue(resp, `data.email_actions`, []).map((item: object) => ({
                action: "update",
                id: getValue(item, `id`, ""),
                from: getValue(item, `from`, ""),
                moduleId: getValue(item, `module_id`, ""),
                name: getValue(item, `name`, ""),
                replyToMail: getValue(item, `reply_to_email`, ""),
                templateId: getValue(item, `template_id`, ""),
                recipients: getValue(item, `recipient_emails`, ""),
                displayName: getValue(item, `display_name`, ""),
                emailUsers:
                  getValue(item, `email_action_users.length`, 0) > 0
                    ? getValue(
                        item,
                        `email_action_users`,
                        []
                      ).map((item: object) =>
                        getValue(item, `org_user_id`, "")
                          ? getValue(item, `org_user_id`, "")
                          : getValue(item, `user_type`, "")
                      )
                    : [],
              }))
            : [];
        let taskActions =
          getValue(resp, `data.task_actions.length`, 0) > 0
            ? getValue(resp, `data.task_actions`, []).map((item: object) => ({
                action: "update",
                id: getValue(item, `id`, ""),
                dueDate: getValue(item, `due_date`, ""),
                dueDateDays: getValue(item, `due_date_days`, ""),
                name: getValue(item, `name`, ""),
                owner: getValue(item, `owner_id`, ""),
                reminderDate: getValue(item, `reminder_date`, ""),
                moduleId: getValue(item, `module_id`, ""),
                priority: getValue(item, `priority`, ""),
              }))
            : [];

        let whatsappActions =
          getValue(resp, `data.whatsapp_actions.length`, 0) > 0
            ? getValue(resp, `data.whatsapp_actions`, []).map(
                (item: object) => ({
                  action: "update",
                  id: getValue(item, `id`, ""),
                  name: getValue(item, `name`, ""),
                  templateId: getValue(item, `template_id`, ""),
                  userPreference: getValue(item, `user_preference`, ""),
                  alternateNumbers:
                    getValue(item, `alternate_numbers.length`, 0) > 0
                      ? getValue(
                          item,
                          `alternate_numbers`,
                          []
                        ).map((item: object) => ({ value: item }))
                      : [],
                })
              )
            : [];
        let updateActions =
          getValue(resp, `data.update_actions.length`, 0) > 0
            ? getValue(resp, `data.update_actions`, []).map((item: object) => ({
                action: "update",
                id: getValue(item, `id`, ""),
                name: getValue(item, `name`, ""),
                dueDateDays: getValue(item, `due_date_days`, ""),
                moduleFieldId: getValue(item, `module_field_id`, ""),
                valueToUse: getValue(item, `value`, ""),
                moduleId: getValue(item, `module_id`, ""),
              }))
            : [];
        setAllConditions(
          getValue(resp, `data.conditions.length`, 0) > 0 ? true : false
        );
        setRequest({
          ...request,
          workflowId: getValue(resp, `data.id`, ""),
          name: getValue(resp, `data.name`, ""),
          moduleId: getValue(resp, `data.module_id`, ""),
          moduleName: getValue(resp, `data.module.api_name`, ""),
          pipelineId: getValue(resp, `data.pipeline_id`, ""),
          description: getValue(resp, `data.description`, ""),
          pattern: convertExpression(getValue(resp, `data.pattern`, "")),
          executionType: getValue(resp, `data.execution_type`, ""),
          repeat: getValue(resp, `data.repeat`, false),
          isActive: getValue(resp, `data.is_active`, false),
          conditions: conditions,
          actions: {
            emails: emailActions,
            updates: updateActions,
            tasks: taskActions,
            whatsapp: whatsappActions,
          },
        });
      }
    } catch (error) {}
  };

  const [workflowConditions, setWorkflowCondtions] = useState([]);
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const getConditions = async (module: string, pipelineId: string) => {
    let payload = {
      module: module,
      pipelineId: pipelineId,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getAllWorkflowConditionFields(queryRequest);
      if (resp) {
        setWorkflowOptions(
          getValue(resp, `data`, []).map((item: object) => ({
            id: getValue(item, `fieldId`, ""),
            label: getValue(item, `fieldLabel`, ""),
            inputType: getValue(item, `fieldType`, ""),
            value: getValue(item, `fieldId`, ""),
          }))
        );
        setWorkflowCondtions(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                        Email Users List section                            */
  /* -------------------------------------------------------------------------- */
  const [emailUsersList, setEmailUsersList] = useState([]);
  const getOrgUsers = async () => {
    try {
      let resp = await getUsers("");
      if (resp) {
        let staticOptions: any = [
          {
            value: "owner",
            id: "owner",
            label: `${formatString(
              getValue(urlParams, `moduleName`, "")
            )} Owner`,
            name: `${formatString(
              getValue(urlParams, `moduleName`, "")
            )} Owner`,
          },
          {
            value: "creator",
            id: "creator",
            label: `${formatString(
              getValue(urlParams, `moduleName`, "")
            )} Creator`,
            name: `${formatString(
              getValue(urlParams, `moduleName`, "")
            )} Owner`,
          },
        ];
        let list = getValue(resp, `data`, []).map((item: object) => ({
          value: getValue(item, `id`, ""),
          label:
            getValue(item, `first_name`, "") +
            " " +
            getValue(item, `last_name`, ""),
          name:
            getValue(item, `first_name`, "") +
            " " +
            getValue(item, `last_name`, ""),
        }));
        setEmailUsersList(staticOptions.concat(list));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                             Send Email section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpenSendEmail, setIsOpenSendEmail] = useState(false);
  const toggleSendEmail = () => {
    setIsOpenSendEmail(!isOpenSendEmail);
  };
  const [sendEmailRequest, setSendEmailRequest] = useState(sendEmailRequestObj);
  const handleEditSendEmailPopup = (obj: object, index: number) => {
    setEditIndex(index);
    setSendEmailRequest({
      ...sendEmailRequest,
      ...obj,
    });
    toggleSendEmail();
  };
  /* -------------------------------------------------------------------------- */
  /*                             Update Popup section                           */
  /* -------------------------------------------------------------------------- */
  const [isOpenUpdates, setIsOpenUpdates] = useState(false);
  const toggleUpdates = () => {
    setIsOpenUpdates(!isOpenUpdates);
  };
  const [updateRequest, setUpdateRequest] = useState(updatesRequestObj);
  const handleEditUpdatesPopup = (obj: object, index: number) => {
    setEditIndex(index);
    setUpdateRequest({
      ...updatesRequestObj,
      ...obj,
    });
    toggleUpdates();
  };

  /* -------------------------------------------------------------------------- */
  /*                             Task Popup section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpenTasks, setIsOpenTasks] = useState(false);
  const toggleTasks = () => {
    setIsOpenTasks(!isOpenTasks);
  };
  const [tasksRequest, setTasksRequest] = useState(tasksRequestObj);
  const handleEditTasksPopup = (obj: object, index: number) => {
    setEditIndex(index);
    setTasksRequest({
      ...tasksRequestObj,
      ...obj,
    });
    toggleTasks();
  };

  /* -------------------------------------------------------------------------- */
  /*                         Whatsapp Popup section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpenWhatsapp, setIsOpenWhatsapp] = useState(false);
  const toggleWhatsapp = () => {
    setIsOpenWhatsapp(!isOpenWhatsapp);
  };
  const [whatsappRequest, setWhatsappRequest] = useState(whatsappRequestObj);
  const handleEditWhatsappPopup = (obj: object, index: number) => {
    setEditIndex(index);
    setWhatsappRequest({
      ...whatsappRequest,
      ...obj,
    });
    toggleWhatsapp();
  };

  /* -------------------------------------------------------------------------- */
  /*                                Submit section                              */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    let conditions: any = getValue(request, `conditions`, []).map(
      (item: object, index: number) => ({
        fieldId: getValue(item, `fieldId`, ""),
        id: getValue(item, `id`, ""),
        operator: getValue(item, `operator`, ""),
        value: getValue(item, `value`, "").map((ite: object) =>
          getValue(ite, `key`, "")
        ),
        index: getValue(item, `index`, null)
          ? getValue(item, `index`, null)
          : index + 1,
        action: getValue(item, `action`, ""),
      })
    );
    let removedConidtions = conditions.filter(
      (item: object, _index: number) =>
        getValue(item, `action`, "") === "remove"
    );

    let mainConidtions = conditions.filter(
      (item: object, _index: number) =>
        getValue(item, `action`, "") !== "remove"
    );

    let allConidtions = mainConidtions.map((item: object, index: number) => ({
      ...item,
      index: index + 1,
    }));
    let modifiedConditions = removedConidtions.concat(allConidtions);

    const getFormattedEmailUsers = (ids: any) => {
      if (getValue(ids, `length`, 0) > 0) {
        return ids.map((item: string) => ({
          type: item === "owner" || item === "creator" ? item : "owner",
          isOrgUser: item === "owner" || item === "creator" ? false : true,
          userId: item === "owner" || item === "creator" ? "" : item,
        }));
      } else {
        return [];
      }
    };
    function getRecipientArray(item: any) {
      const recipientsValue = getValue(item, "recipients", ""); // Getting the value of recipients
      if (
        typeof recipientsValue === "string" &&
        recipientsValue.trim() !== ""
      ) {
        return recipientsValue.trim().split(","); // Splitting recipientsValue if it's not empty
      } else {
        return []; // Returning an empty array if recipientsValue is not a non-empty string
      }
    }

    const removeSpaces = (emails: any) => {
      const cleanedRecipients =
        getValue(emails, `length`, 0) > 0
          ? emails.map((email: any) => email.trim())
          : [];
      return cleanedRecipients;
    };
    let modifiedEmails = getValue(request, `actions.emails`, []).map(
      (item: object) => ({
        id: getValue(item, `id`, ""),
        action: getValue(item, `action`, ""),
        name: getValue(item, `name`, ""),
        moduleId: getValue(urlParams, `moduleId`, "")
          ? getValue(urlParams, `moduleId`, "")
          : getValue(request, `moduleId`, ""),
        templateId: getValue(item, `templateId`, ""),
        recipients: item ? removeSpaces(getRecipientArray(item)) : "",
        from: getValue(item, `from`, ""),
        fromEmailType:
          getValue(listInfo, `default`, "") === getValue(item, `from`, "")
            ? "default"
            : "org-email",
        replyToMail: {
          type:
            getValue(item, `replyToMail`, "") === "owner"
              ? "owner"
              : getValue(item, `replyToMail`, "") === "creator"
              ? "creator"
              : getValue(item, `replyToMail`, ""),
          isOrgUser:
            getValue(item, `replyToMail`, "") === "owner"
              ? false
              : getValue(item, `replyToMail`, "") === "creator"
              ? false
              : true,
          userId: getValue(item, `replyToMail`, ""),
        },
        displayName: getValue(item, `displayName`, ""),
        emailUsers: getFormattedEmailUsers(getValue(item, `emailUsers`, [])),
      })
    );
    let modifiedUpdates = getValue(request, `actions.updates`, []).map(
      (item: object) => ({
        id: getValue(item, `id`, ""),
        action: getValue(item, `action`, ""),
        name: getValue(item, `name`, ""),
        moduleId: getValue(urlParams, `moduleId`, "")
          ? getValue(urlParams, `moduleId`, "")
          : getValue(request, `moduleId`, ""),
        moduleFieldId: getValue(item, `moduleFieldId`, ""),
        valueToUse: getValue(item, `valueToUse`, ""),
        isEmpty: getValue(item, `isEmpty`, false),
      })
    );
    let modifiedTasks = getValue(request, `actions.tasks`, []).map(
      (item: object) => ({
        id: getValue(item, `id`, ""),
        action: getValue(item, `action`, ""),
        name: getValue(item, `name`, ""),
        owner: {
          type: "owner",
          isOrgUser: true,
          userId: getValue(item, `owner`, ""),
        },
        dueDate: getValue(item, `dueDate`, ""),
        dueDateDays: parseFloat(getValue(item, `dueDateDays`, "")),
        reminderDate: getValue(item, `reminderDate`, ""),
        priority: getValue(item, `priority`, ""),
      })
    );
    let modifiedWhatsapp = getValue(request, `actions.whatsapp`, []).map(
      (item: object) => ({
        id: getValue(item, `id`, ""),
        action: getValue(item, `action`, ""),
        name: getValue(item, `name`, ""),
        templateId: getValue(item, `templateId`, ""),
        userPreference: getValue(item, `userPreference`, ""),
        alternateNumbers:
          getValue(item, `alternateNumbers.length`, 0) > 0
            ? getValue(item, `alternateNumbers`, [])
                .map((item: object) =>
                  isValidPhoneNumber(getValue(item, `value`, ""))
                    ? getValue(item, `value`, "")
                    : ""
                )
                .filter((num: string) => num) // filter out empty values
            : [],
      })
    );
    let removeConditions = getValue(
      request,
      `conditions`,
      []
    ).filter((item: object) => getValue(item, `id`, ""));
    let removeConditionsMain =
      getValue(removeConditions, `length`, 0) > 0
        ? removeConditions.map((item: object, _index: number) => ({
            ...item,
            action: "remove",
          }))
        : [];
    try {
      let payload = {
        workflowId: getValue(request, `workflowId`, ""),
        isActive: !getValue(params, `id`, "")
          ? true
          : getValue(request, `isActive`, false),
        moduleId: getValue(urlParams, `moduleId`, "")
          ? getValue(urlParams, `moduleId`, "")
          : getValue(request, `moduleId`, ""),
        pipelineId: getValue(urlParams, `pipelineId`, "")
          ? getValue(urlParams, `pipelineId`, "")
          : getValue(request, `pipelineId`, ""),
        name: getValue(urlParams, `name`, "")
          ? getValue(urlParams, `name`, "")
          : getValue(request, `name`, ""),
        description: getValue(request, `description`, ""),
        pattern: !allConditions
          ? `[1]`
          : convertNumbersToSquareBrackets(getValue(request, `pattern`, "")),
        executionType: getValue(request, `executionType`, ""),
        repeat: getValue(request, `repeat`, ""),
        conditions: !allConditions
          ? removeConditionsMain
          : removeNullValuesFromObjectArray(modifiedConditions),
        actions: {
          emails: removeNullValuesFromObjectArray(modifiedEmails),
          updates: removeNullValuesFromObjectArray(modifiedUpdates),
          tasks: removeNullValuesFromObjectArray(modifiedTasks),
          whatsapp: removeNullValuesFromObjectArray(modifiedWhatsapp),
        },
      };
      if (
        getValue(payload, `actions.emails.length`, 0) > 0 ||
        getValue(payload, `actions.updates.length`, 0) > 0 ||
        getValue(payload, `actions.tasks.length`, 0) > 0 ||
        getValue(payload, `actions.whatsapp.length`, 0) > 0
      ) {
        let resp = await createWorkflow(removeEmptyValues(payload));
        if (resp) {
          toast.success(
            `${
              getValue(params, `orgId`, "") ? "Updated" : "Added"
            } successfully`
          );
          navigate(`/${getValue(params, `orgId`, "")}/settings/workflows`);
        }
      } else {
        toast.error("Please revise any single action.");
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                              Pipeline section                              */
  /* -------------------------------------------------------------------------- */
  const [fileds, setAllFields] = useState([]);
  const getModules = async (module_name: string) => {
    if (
      module_name !== "calls" &&
      module_name !== "meetings" &&
      module_name !== "tasks"
    ) {
      try {
        let payload = {
          module_name: module_name,
        };

        let queryRequest = QueryRequestHelper(payload);
        let resp = await allPipelines(
          await selectedModuleId(module_name),
          queryRequest
        );
        if (resp) {
          let response = await getSpecificPipeline(
            await selectedModuleId(module_name),
            getValue(resp, `data[${0}].id`, "")
          );
          if (response) {
            setAllFields(
              getValue(response, `data.form_fields.length`, 0) > 0
                ? sortJSONObjectArray(
                    getValue(response, `data.form_fields`, []),
                    "seq_num"
                  ).map((item: object) => ({
                    ...getValue(item, `module_field`, {}),
                    name: getValue(item, `name`, ""),
                  }))
                : []
            );
          }
        }
      } catch (error) {}
    } else {
      try {
        let allModules = await listAllModules("");
        let code: any =
          getValue(allModules, `data.length`, 0) > 0
            ? getValue(allModules, `data`, []).find(
                (item: object) => getValue(item, `api_name`, "") == module_name
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setAllFields(fields);
        }
      } catch (error) {}
    }
  };

  const [showComments, setShowComments] = useState(false);
  const ref = useRef<HTMLDivElement>(null); // Typed ref for the <div> element
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowComments(!showComments);
    }
  };
  return (
    <>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <WorkflowDetailHeader
          handleSubmit={handleSubmit}
          submitLoading={submitLoading}
          request={request}
          setRequest={setRequest}
          info={info}
          {...params}
        />
      </div>
      <div className="workflow-create-container pb-4">
        <div className="workflow-container">
          <div className="mt-2 w-50">
            {showComments ? (
              <div className="mb-3">
                <InputRuleForm
                  inputType="TEXTAREA"
                  label={"Description"}
                  placeholder="Enter Description"
                  rows={4}
                  name={"description"}
                  autoFocus
                  value={getValue(request, `description`, "")}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setRequest({
                      ...request,
                      description: getValue(e, `target.value`, ""),
                    })
                  }
                  //   validator={simpleValidator}
                />
                <div className="d-flex justify-content-end">
                  <button
                    className="main_button__primary "
                    onClick={() => setShowComments(!showComments)}
                  >
                    Done
                  </button>
                </div>
              </div>
            ) : getValue(request, `description`, "") ? (
              <div className="mt-4 mb-4">
                <label className="form-label">Description</label>
                <div
                  className=" border border-radius__5 cursor-pointer actions_list_container d-flex justify-content-between align-items-center px-3"
                  onClick={() => setShowComments(!showComments)}
                >
                  <p className="small_text__20 p-2">
                    {getValue(request, `description`, "")}
                  </p>

                  <div className=" d-flex justify-content-between align-items-center action_more_container mx-3">
                    <div className="image_hover_background">
                      <PenSvgComponent size={20} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="d-flex align-items-center mt-4 mb-4 cursor-pointer"
                onClick={() => setShowComments(!showComments)}
              >
                <AddSvgComponent color={"#408dfb"} />
                <h6 className="header_text__16 color_primary">
                  Add Description
                </h6>
              </div>
            )}
          </div>
          <div className="step-border__1">
            <WorkflowDetailStep2 request={request} setRequest={setRequest} />
          </div>
          <div className="vertical-line"></div>
          <div className={`step-border__${allConditions ? "2" : "1"}`}>
            <WorkflowDetailStep3
              request={request}
              setRequest={setRequest}
              workflowConditions={workflowConditions}
              workflowOptions={workflowOptions}
              allConditions={allConditions}
              setAllConditions={setAllConditions}
              info={info}
            />
          </div>
          <div className="vertical-line"></div>
          <div className="step-border__1">
            <WorkflowDetailStep4
              request={request}
              setRequest={setRequest}
              //emails
              sendEmailRequest={sendEmailRequest}
              setSendEmailRequest={setSendEmailRequest}
              toggleSendEmail={toggleSendEmail}
              handleEditSendEmailPopup={handleEditSendEmailPopup}
              //updates
              updateRequest={updateRequest}
              setUpdateRequest={setUpdateRequest}
              toggleUpdates={toggleUpdates}
              handleEditUpdatesPopup={handleEditUpdatesPopup}
              //tasks
              tasksRequest={tasksRequest}
              setTasksRequest={setTasksRequest}
              toggleTasks={toggleTasks}
              handleEditTasksPopup={handleEditTasksPopup}
              //whatsapp
              whatsappRequest={whatsappRequest}
              setWhatsappRequest={setWhatsappRequest}
              toggleWhatsapp={toggleWhatsapp}
              handleEditWhatsappPopup={handleEditWhatsappPopup}
            />
          </div>
        </div>
      </div>
      <SendEmailPopup
        isOpen={isOpenSendEmail}
        toggle={toggleSendEmail}
        title={"Create Email Notification"}
        request={sendEmailRequest}
        setRequest={setSendEmailRequest}
        mainRequest={request}
        setMainRequest={setRequest}
        emailUsersList={emailUsersList}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        fromMails={fromMails}
        orgEmails={orgEmails}
        module={getValue(request, `moduleName`, "")}
      />
      <WorkflowUpdatesPopup
        isOpen={isOpenUpdates}
        toggle={toggleUpdates}
        title={"UpdateField"}
        request={updateRequest}
        setRequest={setUpdateRequest}
        mainRequest={request}
        setMainRequest={setRequest}
        fileds={fileds}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
      />
      <WorkflowTasksPopup
        isOpen={isOpenTasks}
        toggle={toggleTasks}
        title={"Add Workflow Task"}
        request={tasksRequest}
        setRequest={setTasksRequest}
        mainRequest={request}
        setMainRequest={setRequest}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
      />
      <WorkflowWhatsappPopup
        isOpen={isOpenWhatsapp}
        toggle={toggleWhatsapp}
        title={"Add Whatsapp Workflow"}
        request={whatsappRequest}
        setRequest={setWhatsappRequest}
        mainRequest={request}
        setMainRequest={setRequest}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
      />
    </>
  );
}

export default SettingsWorkflowDetail;
