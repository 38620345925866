import "./AddPopup.scss";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import AddExistingEmployee from "./AddExistingEmployee/AddExistingEmployee";
import React, { useState } from "react";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import { getListOfAllContacts } from "@services/contacts.service";
import { getListOfAllCompanies } from "@services/companies.service";
import { getListOfAllSales } from "@services/deals.service";
import FormBuilder from "@components/Builder/FormBuilder";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import ActivityLoader from "@components/common/Loader/activity-loader";

export default function AddPopup(props: any) {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSearchText("");
      setList([]);
    }
  };
  const [search_text, setSearchText] = useState("");
  const [listLoading, setListLoading] = React.useState(false);
  const [list, setList] = useState([]);
  const getListOfContacts = async (e: string) => {
    setSearchText(e);
    try {
      let payload = {
        search: e,
        page_no: 1,
        page_size: 30,
      };
      setListLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp;
      if (props.module === "contacts") {
        resp = await getListOfAllContacts(queryRequest);
      } else if (props.module === "companies") {
        resp = await getListOfAllCompanies(queryRequest);
      } else if (props.module === "sales" || props.module === "deals") {
        resp = await getListOfAllSales(queryRequest);
      }
      if (resp) {
        setList(getValue(resp, `data`, []));
        setListLoading(false);
      } else {
        setListLoading(false);
      }
    } catch (error) {
      setListLoading(false);
    }
  };
  return (
    <div className={"add-more-rolls-wrapper"}>
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Create New
            </NavLink>
          </NavItem>
          {!getValue(props, `hideList`, false) && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Add existing
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {/* <KnowledgeForm /> */}
            {props.formLoading ? (
              <ActivityLoader />
            ) : (
              <>
                <div className="form_wrapper_builder-pipeline">
                  {/* <SearchToolTip
                    placeholder={`Choose Pipeline`}
                    value={getValue(
                      props,
                      `staticFieldRequest.pipeline_id`,
                      ""
                    )}
                    onChange={(e: object) => {
                      props.setStaticFieldRequest(
                        (prevStaticFieldRequest: any) => ({
                          ...prevStaticFieldRequest,
                          pipeline_id: getValue(e, `id`, ""),
                        })
                      );
                      props.getSpecificPipelineInfo(getValue(e, `id`, ""));
                    }}
                    label={`label`}
                    validator={props.simpleValidator}
                    width={"460px"}
                    data={getValue(props, `pipelineList`, [])}
                    selectKey={"id"}
                    name={"Pipeline"}
                    required={true}
                  /> */}
                  <SearchToolTip
                    placeholder={`Choose Stage`}
                    label={`label`}
                    validator={props.simpleValidator}
                    // width={"480px"}
                    data={getValue(props, `stageList`, [])}
                    value={getValue(props, `staticFieldRequest.stage`, "")}
                    onChange={(e: object) =>
                      props.setStaticFieldRequest(
                        (prevStaticFieldRequest: any) => ({
                          ...prevStaticFieldRequest,
                          stage: getValue(e, `id`, ""),
                        })
                      )
                    }
                    selectKey={"id"}
                    name={"Stage"}
                    required={true}
                  />
                </div>
                <FormBuilder
                  fields={getValue(props, `form`, [])}
                  setFields={props.setForm}
                  simpleValidator={props.simpleValidator}
                  singular_label={props.singular_label}
                />
                <SubmitButtonContainer
                  handleBackButton={() => {
                    props.toggle();
                  }}
                  handleSubmit={() => {
                    props.handleSubmit(getValue(props, `module`, ""));
                  }}
                  submitLoading={props.formSubmitLoading}
                  first_title={"Cancel"}
                  second_title={"Create"}
                  hideNew
                  disabled={props.disabled}
                  disableValidation={props.disableValidation}
                />
              </>
            )}
          </TabPane>
          {!getValue(props, `hideList`, false) && (
            <TabPane tabId="2">
              <AddExistingEmployee
                selectedList={props.selectedList}
                list={list}
                listLoading={listLoading}
                search_text={search_text}
                handleAssociate={props.handleAssociate}
                handleDisAssociate={props.handleDisAssociate}
                getListOfContacts={getListOfContacts}
                module={props.module}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
}
