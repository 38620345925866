import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import ModulesEditFields from "../../../../../../pages/Private/Settings/ModuleAndField/ManageFields/Fields/EditFields";
import EditManageStages from "../../../../../../pages/Private/Settings/ModuleAndField/ManageFields/Stages/EditStages";
import ModulesEditInfo from "../../../../../../pages/Private/Settings/ModuleAndField/ManageFields/Info/EdiInfo";

export default function ManageFieldsDetails(props: any) {
  const params = useParams();

  const buttonArray = [
    {
      title: "Search",
      image: "search.svg",
    },
  ];

  const { activeTab, toggle } = props;
  return (
    <div className="d-flex">
      {/* <ManagefieldsLeft /> */}
      <div className={`${"manageFieldRight"} w-100`}>
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Stages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                Fields
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className={"module-tab-wrapper"}>
                <p className={"module-tab-wrapper__text"}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <ModulesEditInfo
                  request={props.request}
                  setRequest={props.setRequest}
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className={"module-tab-wrapper"}>
                <p className={"module-tab-wrapper__text"}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <EditManageStages
                  request={props.stageRequest}
                  setRequest={props.setStageRequest}
                />
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className={"module-tab-wrapper"}>
                <p className={"module-tab-wrapper__text"}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                {/* <div
                  className={`${"module-tab-wrapper__filter"]} d-flex justify-content-end`}
                >
                  {buttonArray.map((buttonObj, index) => {
                    return <IconWithText buttonObj={buttonObj} key={index} />;
                  })}
                </div> */}
                {/* <ManageTable /> */}
                <ModulesEditFields
                  usedFields={getValue(props, `selectedFields`, [])}
                  handleOnDragEnd={props.handleOnDragEnd}
                  unUsedFields={props.unUsedFields}
                />
              </div>
            </TabPane>
            {/* <div className="p-3">
                <div>
                  <h6 className={"status-title"]}>
                    Default status for Tasks
                  </h6>
                  <div
                    className={`${"status-container"]}
										
									 d-flex align-items-center`}
                  >
                    <img
                      src="/images/task/icons/header/drag-indicator.svg"
                      className="px-3"
                    />
                    <div
                      className={`${ClassNames["status-container__code"]} d-flex align-items-center justify-content-between w-100`}
                    >
                      <div className="d-flex align-items-center gap">
                        <div
                          className={`${ClassNames["box"]} ${
                            ClassNames[`box--yellow`]
                          } `}
                        ></div>
                        <span>Open</span>
                      </div>
                      <img
                        src="/images/icons/close-grey.svg"
                        className="image-fluid cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`${ClassNames["status-container"]}
										
									 d-flex align-items-center`}
                  >
                    <img
                      src="/images/task/icons/header/drag-indicator.svg"
                      className="px-3"
                    />
                    <div
                      className={`${ClassNames["status-container__code"]} d-flex align-items-center justify-content-between w-100`}
                    >
                      <div className="d-flex align-items-center gap">
                        <div
                          className={`${ClassNames["box"]} ${
                            ClassNames[`box--blue`]
                          } `}
                        ></div>
                        <span>Started</span>
                      </div>
                      <img
                        src="/images/icons/close-grey.svg"
                        className="image-fluid cursor-pointer"
                      />
                    </div>
                  </div>
                  <div
                    className={`${ClassNames["status-container"]}
										
									 d-flex align-items-center`}
                  >
                    <img
                      src="/images/task/icons/header/drag-indicator.svg"
                      className="px-3"
                    />
                    <div
                      className={`${ClassNames["status-container__code"]} d-flex align-items-center justify-content-between w-100`}
                    >
                      <div className="d-flex align-items-center gap">
                        <div
                          className={`${ClassNames["box"]} ${
                            ClassNames[`box--red`]
                          } `}
                        ></div>
                        <span>In Progress</span>
                      </div>
                      <img
                        src="/images/icons/close-grey.svg"
                        className="image-fluid cursor-pointer"
                      />
                    </div>
                  </div>
                  <div
                    className={`${ClassNames["status-container"]}
										
									 d-flex align-items-center`}
                  >
                    <img
                      src="/images/task/icons/header/drag-indicator.svg"
                      className="px-3"
                    />
                    <div
                      className={`${ClassNames["status-container__code"]} d-flex align-items-center justify-content-between w-100`}
                    >
                      <div className="d-flex align-items-center gap">
                        <div
                          className={`${ClassNames["box"]} ${
                            ClassNames[`box--cyan`]
                          } `}
                        ></div>
                        <span>For Review</span>
                      </div>
                      <img
                        src="/images/icons/close-grey.svg"
                        className="image-fluid cursor-pointer"
                      />
                    </div>
                  </div>
                  <div
                    className={`${ClassNames["status-container"]} d-flex align-items-center`}
                  >
                    <img
                      src="/images/task/icons/header/drag-indicator.svg"
                      className="px-3"
                    />
                    <div
                      className={`${ClassNames["status-container__code"]} d-flex align-items-center justify-content-between w-100`}
                    >
                      <div className="d-flex align-items-center gap">
                        <div
                          className={`${ClassNames["box"]} ${
                            ClassNames[`box--light-blue`]
                          } `}
                        ></div>
                        <span>On my Way</span>
                      </div>
                      <img
                        src="/images/icons/close-grey.svg"
                        className="image-fluid cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
          </TabContent>
        </div>
      </div>
    </div>
  );
}
