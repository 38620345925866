import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { getFutureDateFunctions } from "@common/Date-Helpers/future-date-helpers";
import { handleSelectChange } from "@common/handleChange";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import DraftJSEditor from "@components/custom/DraftJSEditor";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { motion } from "framer-motion";

function NotesEditor(props: any) {
  const {
    isTextarea,
    setNotesRequest,
    notesRequest,
    textareaRef,
    setIsTextarea,
    setEditId,
    handleSubmitNotes,
    submitNotesLoading,
    editId,
    handleInputClick,
    handleChangeEditor,
  } = props;
  const [types] = useState([
    { value: "EMAIL", label: "Email" },
    { value: "CALL", label: "Call" },
    { value: "TODO", label: "To-Do" },
  ]);

  return (
    <div>
      <div className="notes_container mt-3 mb-4">
        <div className="tasks_notes_container w-100">
          {isTextarea ? (
            <div>
              {/* <textarea
                className="tasks_notes-textarea"
                placeholder="Enter notes"
                name="content"
                onChange={(e) =>
                  setNotesRequest({
                    ...notesRequest,
                    content: e.target.value,
                  })
                }
                ref={textareaRef}
                autoFocus
                value={getValue(notesRequest, `content`, "")}
                rows={5}
              /> */}
              <DraftJSEditor
                toolbarHidden
                editorState={getValue(notesRequest, `content`, "")}
                handleChangeEditor={handleChangeEditor}
                name="content"
                placeholder={
                  props.placeholder ? props.placeholder : "Add a note..."
                }
                focus
                editorStyle={{
                  background: "white",
                  paddingLeft: "10px",
                  minHeight: "15vh",
                  border: "1px solid #efefef",
                }}
                toolbar={{
                  options: ["inline", "fontSize", "list"],
                }}
              />
            </div>
          ) : (
            <motion.div
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <input
                className="tasks_notes-textarea"
                type="text"
                onClick={handleInputClick}
                placeholder="Add a note..."
              />
            </motion.div>
          )}
          {/* <div
            className={` notes_select_container d-flex p-2 align-items-center`}
          >
            <div className="ms-3">
              <CustomCheckbox />
            </div>
            <div className="d-flex align-items-center">
              <p className="mx-2 small_text__14">Create a</p>
              <TextHeaderTooltip
                title={`Follow up type`}
                data={types}
                label={`label`}
                value={getValue(notesRequest, `follow_up_type`, "")}
                onChange={(e: any) =>
                  handleSelectChange(
                    e,
                    "follow_up_type",
                    notesRequest,
                    setNotesRequest
                  )
                }
                selectKey={"value"}
                width={"270px"}
                editInline
              />
              <p className="small_text__14">task to follow up</p>
              <TextHeaderTooltip
                title={`Follow up Date`}
                data={getFutureDateFunctions().filter(
                  (item: object) => getValue(item, `key`, "") !== "custom_date"
                )}
                label={`label`}
                value={getValue(notesRequest, `follow_up_datetime`, "")}
                onChange={(e: any) =>
                  handleSelectChange(
                    e,
                    "follow_up_datetime",
                    notesRequest,
                    setNotesRequest
                  )
                }
                selectKey={"value"}
                width={"270px"}
                editInline
              />
            </div>
          </div> */}
        </div>
        {getValue(notesRequest, `content`, "") && (
          <div className="tasks_notes_footer mt-2 d-flex justify-content-between">
            <div></div>
            <div>
              <button
                className="main_button__secondary"
                onClick={() => {
                  setNotesRequest({
                    ...notesRequest,
                    content: "",
                  });
                  setIsTextarea(false);
                  setEditId("");
                }}
              >
                Cancel
              </button>
              <button
                className="main_button__primary"
                onClick={handleSubmitNotes}
                disabled={
                  !getValue(notesRequest, `content`, "") ||
                  getValue(notesRequest, `content`, "") === "<p></p>\n"
                    ? true
                    : false
                }
              >
                {submitNotesLoading
                  ? "Please wait..."
                  : editId
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotesEditor;
