import { useRef, useState } from "react";
import Popup from "reactjs-popup";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import FormBuilder from "../FormBuilder";
import { motion } from "framer-motion";
import PenSvgComponent from "@assets/svg/pen";
import EditSvgComponent from "@assets/svg/edit";
import { Tooltip } from "react-tooltip";

const InlineEditTooltipComponent = (props) => {
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const POSITION_TYPES = [
    "right top",
    "top left",
    "top center",
    "top right",
    "right center",
    "right bottom",
    "bottom left",
    "bottom center",
    "bottom right",
    "left top",
    "left center",
    "left bottom",
    "center center",
  ];

  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const popupRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };
  return (
    <Popup
      ref={popupRef}
      trigger={(open) => (
        // <div
        //   className={`d-flex align-items-center cursor-pointer touchable-text`}
        // >
        //   <img
        //     src={"/images/icons/edit-blue.svg"}
        //     className={"edit_header-image"}
        //     onClick={handleOpen}
        //   />
        // </div>
        <div
          className="d-flex notes_action_container"

          // data-tooltip-place="bottom"
        >
          <div className="image_hover_background" onClick={handleOpen}>
            <div data-tooltip-id="edit_info" data-tooltip-content="Edit info">
              <EditSvgComponent />
            </div>
          </div>
          <Tooltip id={`edit_info`} className="tooltip" />
        </div>
      )}
      position={POSITION_TYPES}
      contentStyle={{ padding: "0", width: props.width }}
      nested
    >
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className={"inline_tooltip_edit_container"}>
          <FormBuilder
            fields={props.popupFields}
            setFields={props.setPopupFields}
            hideValidation
          />
          <div className="inline_toolto_footer_container d-flex mt-4 mx-4">
            <ButtonComponent
              buttonType={"scecondary"}
              buttonText={
                getValue(props, `first_title`, "")
                  ? getValue(props, `first_title`, "")
                  : "Cancel"
              }
              onClickHandler={handleClose}
            />
            <ButtonComponent
              buttonType={"primary"}
              buttonText={
                props.submitLoading
                  ? "Please wait..."
                  : getValue(props, `second_title`, "")
                  ? getValue(props, `second_title`, "")
                  : "Save"
              }
              onClickHandler={() => {
                props.handleSubmit();
                setTimeout(() => {
                  !props.submitLoading && handleClose();
                }, 500);
              }}
              disabled={props.submitLoading}
            />
          </div>
        </div>
      </motion.div>
    </Popup>
  );
};
export default InlineEditTooltipComponent;
