import { getValue } from "@utils/lodash";
import CommonAttachments from "./Attachments";
import TaskCompanies from "./TaskCompanies/TaskCompanies";
import TaskSales from "./TaskSales/TaskSales";
import TaskContacts from "./TaskContacts/TaskContacts";
export default function CommonModulesDetailRightPagePage(props: any) {
  return (
    <div className={"pendingtask-left_container"}>
      {getValue(props, `module`, "") === "contacts" ? (
        <>
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"contacts"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "companies" ? (
        <>
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"companies"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "sales" ||
      getValue(props, `module`, "") === "deals" ? (
        <>
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <CommonAttachments {...props} create_module={"deals"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "tasks" ? (
        <>
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"tasks"} />
        </>
      ) : null}
    </div>
  );
}
