import * as React from "react";
import { getValue } from "@utils/lodash";
import {
  addHour,
  convertCurrentDate,
  convertCurrentDateToTime,
  formatTime,
} from "@common/date-helpers";
import ArrowUpSvgComponent from "@assets/svg/arrow-up";
import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import { Bar, Line } from "react-chartjs-2";
import { numberToFixed } from "@common/text-helpers";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import UpSvgComponent from "@assets/svg/up";
import DownSvgComponent from "@assets/svg/down";

function DashboardContact(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { contactInfo } = props;
  const [todayRecords, setTodayRecords] = React.useState<any>([]);
  React.useEffect(() => {
    if (contactInfo) {
      //--------- today records ------------//
      setTodayRecords(
        getVolumes(getValue(contactInfo, `today_volume.records`, []))
      );
      //--------- Chart 1 ------------//
      let labels = getValue(contactInfo, `overview`, []).map((item: object) =>
        getValue(item, `title`, "")
      );
      let data = getValue(contactInfo, `overview`, []).map((item: object) =>
        getValue(item, `value`, "")
      );
      let previousData = getValue(
        contactInfo,
        `overview`,
        []
      ).map((item: object) => getValue(item, `previous_value`, 0));
      let obj: any = {
        labels: labels,
        datasets: [
          {
            label: "Current Value",
            data: data,
            backgroundColor: "#2376E5",
            borderWidth: 1,
            // barThickness: 30,
          },
          {
            label: "Previous Value",
            data: previousData,
            backgroundColor: "#8CB5F6",
            borderWidth: 1,
            // barThickness: 30,
          },
        ],
      };
      setData(obj);

      //--------- Chart 2 ------------//
      let labels1 = getValue(
        contactInfo,
        `performance_overview`,
        []
      ).map((item: object) => getValue(item, `name`, ""));
      let data1 = getValue(
        contactInfo,
        `performance_overview`,
        []
      ).map((item: object) => getValue(item, `OPEN`, ""));
      let data2 = getValue(
        contactInfo,
        `performance_overview`,
        []
      ).map((item: object) => getValue(item, `CLOSED_WON`, ""));
      let data3 = getValue(
        contactInfo,
        `performance_overview`,
        []
      ).map((item: object) => getValue(item, `CLOSED_LOST`, ""));

      let obj1: any = {
        labels: labels1,
        datasets: [
          {
            label: "Open",
            data: data1,
            backgroundColor: "#8CB5F6",
            borderWidth: 1,
            // barThickness: 30,
          },
          {
            label: "Closed Won",
            data: data2,
            backgroundColor: "#2376E5",
            borderWidth: 1,
            // barThickness: 30,
          },
          {
            label: "Closed Lost",
            data: data3,
            backgroundColor: "#FC541F",
            borderWidth: 1,
            // barThickness: 30,
          },
        ],
      };
      setData1(obj1);
    }
  }, [contactInfo]);

  const getVolumes = (contacts: any) => {
    const records: any = [];
    if (contacts && contacts.length) {
      for (let item of contacts) {
        const createdTime = formatTime(item.created_at);
        const updateOneHour = addHour(createdTime, 1);
        const foundIndex = records.findIndex(
          (x: any) => getValue(x, `from_time`, "") == createdTime
        );
        if (records[foundIndex]) {
          records[foundIndex] = {
            ...records[foundIndex],
            volume: records[foundIndex].volume + 1,
          };
        } else {
          records.push({
            from_time: createdTime,
            to_time: updateOneHour,
            volume: 1,
          });
        }
      }
    }
    return getValue(records, `length`, 0) > 0
      ? sortTimeSlotsByFromTime(records)
      : [];
  };

  function sortTimeSlotsByFromTime(slots: any) {
    // Custom sorting function to sort time slots based on "from_time"
    function compareTimeSlots(a: any, b: any) {
      // Extract the hour part of the time string and convert it to a number
      const hourA = parseInt(a.from_time.split(" ")[0]);
      const hourB = parseInt(b.from_time.split(" ")[0]);

      // Extract AM/PM part of the time string
      const partA = a.from_time.split(" ")[1];
      const partB = b.from_time.split(" ")[1];

      // Convert AM to 0 and PM to 1 for proper sorting
      const modifierA = partA === "AM" ? 0 : 1;
      const modifierB = partB === "AM" ? 0 : 1;

      // Compare hours first
      if (hourA !== hourB) {
        return hourA - hourB;
      }
      // If hours are same, compare AM/PM part
      else {
        return modifierA - modifierB;
      }
    }

    // Sort the array using the custom compare function
    slots.sort(compareTimeSlots);

    return slots;
  }

  const [data, setData] = React.useState({
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55],
        backgroundColor: "#8CB5F6",
        borderColor: "#2376E5",
        borderWidth: 1,
      },
    ],
  });

  const [data1, setData1] = React.useState({
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55],
        backgroundColor: "#8CB5F6",
        borderColor: "#2376E5",
        borderWidth: 1,
      },
    ],
  });
  const lineOptions: any = {
    responsive: true,
    bezierCurve: false,
    elements: {
      line: {
        // tension: 0.3,
      },
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "center",
        display: true,
        // anchor: 'center',
        // align: 'center',
        font: {
          size: 12,
        },
        color: "#000000",
      },
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  // Chart options
  const options: any = {
    // maintainAspectRatio: false, //width
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
        text: "Horizontal Bar Chart",
      },
      datalabels: {
        anchor: "end",
        align: "center",
        labels: {
          value: {
            color: "black",
          },
        },
        formatter: (val: any) => {
          return val ? val : "";
        },
      },
    },
    scales: {
      // to remove the labels
      // x: {
      //   ticks: {
      //     display: false,
      //   },

      //   // to remove the x-axis grid
      //   grid: {
      //     drawBorder: false,
      //     display: false,
      //   },
      // },
      // to remove the y-axis labels
      y: {
        ticks: {
          display: true,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };

  return (
    <div className="main_overview_container">
      <div className="d-flex align-items-start justify-content-center gap-3">
        <div className="today_volume_container">
          <h6 className="header_text__18 mb-3">Volume Today</h6>
          <div className="main">
            <div className="px-4 mt-3">
              <p className="small_text__14">Volume Today</p>
              <h6 className="header_text__30">
                {getValue(contactInfo, `today_volume.totalVolume`, "")}
              </h6>
            </div>
            <table className="overview_table w-100 p-3">
              <thead className="border-bottom">
                <th>
                  <h6>SLOT/CATEGORY</h6>
                </th>
                <th className="text-right">
                  <h6>VOLUME</h6>
                </th>
              </thead>
              <tbody>
                {
                  // getValue(contactInfo, `today_volume.records`, [])
                  getValue(todayRecords, `length`, 0) > 0 &&
                    todayRecords.map((item: object, index: number) => {
                      return (
                        <tr key={index} className="border-bottom">
                          <td>
                            <p className="d-flex w-50 justify-content-around align-items-center">
                              {getValue(item, `from_time`, "")}{" "}
                              <span className="mx-2">-</span>
                              {getValue(item, `to_time`, "")}
                            </p>
                          </td>
                          <td className="text-right">
                            <p>{getValue(item, `volume`, "")}</p>
                          </td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="volume_overview_container">
          <h6 className="header_text__18 mb-3">
            Volume Overview - Last 10 Days
          </h6>
          <div className="main">
            <div className="px-4 mt-3">
              <p className="small_text__14">Volume - Last 10 Days</p>
              <h6 className="header_text__30">
                {getValue(contactInfo, `volume_overview.totalVolume`, "")}
              </h6>
            </div>
            <table className="overview_table w-100">
              <thead className="border-bottom px-3">
                <th>
                  <h6>SLOT/CATEGORY</h6>
                </th>
                <th className="text-right">
                  <h6>VOLUME</h6>
                </th>
              </thead>
              <tbody>
                {getValue(contactInfo, `volume_overview.records`, []).map(
                  (item: object, index: number) => {
                    return (
                      <tr key={index} className="border-bottom">
                        <td>
                          <p>
                            {convertCurrentDate(getValue(item, `date`, ""))}
                          </p>
                        </td>
                        <td className="text-right">
                          <p>{getValue(item, `volume`, "")}</p>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="overview_container mt-4">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h6 className="header_text__18 mb-3">Overview - All Contacts</h6>
          <div className="w-25">
            <SearchToolTip
              label="label"
              selectKey={"id"}
              value={getValue(UrlParams, `owner_id`, "")}
              lookup_api="org_users"
              onChange={(e: any) => {
                props.handleChangeUser(getValue(e, `id`, ""));
              }}
            />
          </div>
        </div>
        <div className="main">
          <div className="d-flex align-items-center flex-wrap">
            {getValue(contactInfo, `overview`, []).map(
              (item: object, key: number) => {
                return !getValue(item, `value`, "") ? (
                  ""
                ) : (
                  <div className="d-flex">
                    <div className="p-4" key={key}>
                      <div className="d-flex justify-content-center">
                        <div className="">
                          <p className="small_text__14 text-center">
                            {getValue(item, `title`, "")}
                          </p>
                          <h6 className="header_text__30 text-center">
                            {getValue(item, `value`, "")}
                          </h6>
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="">
                              {getValue(item, `objective`, "") ===
                              "increase" ? (
                                <UpSvgComponent size={40} />
                              ) : (
                                <DownSvgComponent size={40} />
                              )}
                            </div>
                            <p
                              className="header_text__14"
                              style={{
                                color:
                                  getValue(item, `objective`, "") === "increase"
                                    ? "#23AE73"
                                    : "#DA4F4F",
                              }}
                            >
                              {getValue(item, `diff_percentage`, "")
                                ? numberToFixed(
                                    getValue(item, `diff_percentage`, ""),
                                    2
                                  )
                                : 0}{" "}
                              %
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="with-vertical-line"></div>
                  </div>
                );
              }
            )}
          </div>
          <div>
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
      <div className="performence_overview_container mt-4 ">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h6 className="header_text__18 mb-3">Performance Overview</h6>
          {/* <div className="w-25">
            <SearchToolTip
              label="label"
              selectKey={"value"}
              value={getValue(UrlParams, `owner_id`, "")}
              lookup_api="org_users"
              onChange={(e: any) =>
                props.handleChangeUser(getValue(e, `value`, ""))
              }
            />
          </div> */}
        </div>
        <div className="main">
          <Bar data={data1} options={options} />
        </div>
      </div>
    </div>
  );
}

export default DashboardContact;
