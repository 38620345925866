import { getValue } from "@utils/lodash";
import { config } from "env";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import OpenNewSvgComponent from "@assets/svg/open-new";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";

function WhatsappIconViewPage(props: any) {
  const { request } = props;

  const replacePlaceholders = (text: string) => {
    if (text)
      getValue(request, `customTags`, []).forEach((item: object) => {
        text =
          text &&
          text.replace(
            getValue(item, `placeholder`, ""),
            getValue(item, `sample`, "")
              ? getValue(item, `sample`, "")
              : getValue(item, `placeholder`, "")
          );
      });
    return text;
  };
  return (
    <div className="mobile-template">
      <div className="mobile-border">
        <div className="dark-green-header"></div>
        <div className="green-header"></div>
        <div className="mobile-screen">
          <div className="d-flex justify-content-center">
            <p className="meta-message">
              <img src="/images/icons/info.svg" /> This business uses a secure
              service from Meta to manage this chat. Tap to learn more
            </p>
          </div>
          <div className="message-box sdsd">
            {getValue(request, `header.type`, "") === "TEXT" && (
              <p className="message-title small_text__14">
                {getValue(request, `header.text`, "")}
              </p>
            )}

            {getValue(request, `header.type`, "") === "IMAGE" &&
              (getValue(request, `header.link.key`, "") ||
                getValue(request, `header.link`, "")) && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={
                      getValue(request, `header.link.key`, "")
                        ? `https://${config.URL}/${getValue(
                            request,
                            `header.link.key`,
                            ""
                          )}`
                        : `https://${config.URL}/${getValue(
                            request,
                            `header.link`,
                            ""
                          )}`
                    }
                    alt="Selected"
                    className="main_image"
                  />
                </div>
              )}
            {getValue(request, `header.type`, "") === "VIDEO" &&
              (getValue(request, `header.link.key`, "") ||
                getValue(request, `header.link`, "")) && (
                <div className="d-flex justify-content-center align-items-center">
                  <video controls style={{ height: "100px", width: "100%" }}>
                    <source
                      src={`https://${config.URL}/${
                        getValue(request, `header.link.key`, "")
                          ? getValue(request, `header.link.key`, "")
                          : getValue(request, `header.link`, "")
                      }`}
                      type="video/mp4"
                    />
                  </video>
                  {/* <img
                    src={
                      getValue(request, `header.link.key`, "")
                        ? `https://${config.URL}/${getValue(
                            request,
                            `header.link.key`,
                            ""
                          )}`
                        : `https://${config.URL}/${getValue(
                            request,
                            `header.link`,
                            ""
                          )}`
                    }
                    alt="Selected"
                    className="main_image"
                  /> */}
                </div>
              )}

            {getValue(request, `header.type`, "") === "DOCUMENT" &&
              (getValue(request, `header.link.key`, "") ||
                getValue(request, `header.link`, "")) && (
                <div className="d-flex justify-content-center align-items-center">
                  {/* <img
                                  src={`https://${config.URL}/${getValue(
                                    request,
                                    `header.link.key`,
                                    ""
                                  )}`}
                                  alt="Selected"
                                  className="main_image"
                                /> */}
                  <img
                    src="/images/icons/document-blue.svg"
                    className="dummy-image-icon"
                  />
                </div>
              )}

            <div className="mt-2">
              {/* <p
                              className="small_text__14 text-black"
                              contentEditable="true"
                              dangerouslySetInnerHTML={{
                                __html: replacePlaceholders(
                                  getValue(request, `body`, "")
                                ),
                              }}
                            ></p> */}
              <MarkdownPreview
                source={replacePlaceholders(getValue(request, `body`, ""))}
                // style={{ padding: 16 }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="small_text__12 ">
                {getValue(request, `footer`, "")}
              </p>
              <div
              // className="d-flex justify-content-end"
              >
                <span className="small_text__12 ">
                  {moment().format("hh:mm a")}
                </span>
              </div>
            </div>

            {/* {getValue(request, `buttonType`, "") === "CALL_TO_ACTION" && (
                <>
                  <div className=" w-100">
                    {getValue(request, `buttons[${0}].callAction`, []).map(
                      (item: any) => {
                        if (
                          getValue(item, `type`, "") === "url" &&
                          getValue(item, `text`, "")
                        )
                          return (
                            <div className="d-flex justify-content-center gap-1 align-items-center link-message-button">
                              <OpenNewSvgComponent color="#408dfb" size={14} />
                              <p className="small_text__12 color_primary">
                                {getValue(item, `text`, "")}
                              </p>
                            </div>
                          );
                        if (
                          getValue(item, `type`, "") === "call" &&
                          getValue(item, `text`, "")
                        )
                          return (
                            <div className="d-flex justify-content-center gap-1 align-items-center phone-message-button">
                              <CallSvgComponent color="#408dfb" size={14} />
                              <p className="small_text__12 color_primary">
                                {getValue(item, `text`, "")}
                              </p>
                            </div>
                          );
                      }
                    )}
                  </div>
                </>
              )} */}
          </div>
          <div className="grid-container ms-2" style={{ width: "83%" }}>
            {getValue(request, `buttons`, []).map(
              (item: object, index: number) => {
                return (
                  getValue(item, `text`, "") && (
                    <div
                      className="d-flex justify-content-center align-items-center template_button"
                      key={index}
                    >
                      {getValue(item, `action_type`, "") ===
                        "CALL_TO_ACTION" && (
                        <>
                          {getValue(item, `type`, "") === "call" ? (
                            <CallSvgComponent color="#408dfb" size={14} />
                          ) : (
                            <UrlSvgComponent color="#408dfb" size={14} />
                          )}
                        </>
                      )}
                      <p className="ms-2">{getValue(item, `text`, "")}</p>
                    </div>
                  )
                );
              }
            )}
          </div>

          <div className="action_div">
            {/* {getValue(request, `buttonType`, "") === "QUICK_REPLY" && (
                <div className="d-flex align-items-center flex-wrap">
                  {getValue(request, `buttons[${0}].quickReply`, []).map(
                    (item: any) => {
                      if (getValue(item, `text`, ""))
                        return (
                          <div className="d-flex justify-content-center gap-1 align-items-center template_button">
                            {getValue(item, `text`, "")}
                          </div>
                        );
                    }
                  )}
                </div>
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatsappIconViewPage;
