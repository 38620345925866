import PropertySettingsNavigation from "@layouts/PropertyLayout/PropertySettingsNavigation";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import React from "react";

const PropertySettingsLayout = ({ children, setActiveTab }: any) => {
  return (
    // <PropertyDetailsLayout setActiveTab={setActiveTab}>
    <div className="d-flex w-100">
      <PropertySettingsNavigation />
      <div className={"company-profile-wrapper__main-section-wrapper"}>
        <div className={`company-profile-wrapper__main-section px-4 pt-2`}>
          {children}
        </div>
      </div>
    </div>
    // </PropertyDetailsLayout>
  );
};

export default PropertySettingsLayout;
