import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import { getValue } from "@utils/lodash";
import { useState, useEffect } from "react";
import RenderBotForm from "../Forms";
const ModifyNodePopup = (props: any) => {
  const {
    request,
    setRequest,
    handleSubmit,
    selectedNode,
    submitLoading,
    isOpen,
    setIsOpen,
    handleModal,
    navigateBack,
  } = props;
  const [label, setLabel] = useState(getValue(selectedNode, `data.label`, ""));

  useEffect(() => {
    setLabel(getValue(selectedNode, `data.label`, ""));
  }, [selectedNode]);
  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        handleModal();
      }}
      title={"Settings"}
      width={"550px"}
      enableBack
      navigateBack={navigateBack}
    >
      <div className="px-4 py-3">
        <RenderBotForm request={request} setRequest={setRequest} />
      </div>
      <SubmitContainer
        handleBackButton={() => setIsOpen(!isOpen)}
        handleSubmit={() => handleSubmit()}
        submitLoading={submitLoading}
        first_title={"Cancel"}
        second_title={"Submit"}
      />
    </SlideFromRightPopup>
  );
};

export default ModifyNodePopup;
