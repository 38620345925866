import React from "react";
import "./button.scss";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import ButtonOnHover from "../Tooltip/button-on-hover";

function SubmitButtonContainer(props: any) {
  return (
    <div
      className={`submit-button__footer d-flex align-items-center justify-content-between`}
    >
      <div className={"submit-button__container"}>
        <div></div>
        <div className="d-flex align-items-center">
          <ButtonComponent
            buttonType={"scecondary"}
            buttonText={
              getValue(props, `first_title`, "")
                ? getValue(props, `first_title`, "")
                : "Cancel"
            }
            onClickHandler={() => props.handleBackButton()}
          />
          {getValue(props, `disableValidation`, false) ? (
            <ButtonOnHover
              header={
                <ButtonComponent
                  buttonType={props.submitLoading ? "scecondary" : "primary"}
                  buttonText={
                    getValue(props, `second_title`, "")
                      ? getValue(props, `second_title`, "")
                      : "Create"
                  }
                  onClickHandler={() => props.handleSubmit()}
                  disabled={props.submitLoading || props.disabled}
                />
              }
              text={"Please add all required information"}
              width={"200px"}
              height={"50px"}
              hover={props.disabled}
            />
          ) : (
            <ButtonComponent
              buttonType={props.submitLoading ? "scecondary" : "primary"}
              buttonText={
                getValue(props, `second_title`, "")
                  ? getValue(props, `second_title`, "")
                  : "Create"
              }
              // buttonText={
              //   props.submitLoading
              //     ? "Please wait..."
              //     : getValue(props, `second_title`, "")
              //     ? getValue(props, `second_title`, "")
              //     : "Create"
              // }
              onClickHandler={() => props.handleSubmit()}
              disabled={props.submitLoading || props.disabled}
            />
          )}

          {!getValue(props, `hideNew`, false) && (
            <ButtonComponent
              buttonType={props.submitLoading ? "scecondary" : "primary"}
              buttonText={"Create & Save New"}
              onClickHandler={() => props.handleSubmit("new")}
              disabled={props.submitLoading || props.disabled}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SubmitButtonContainer;
