import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { getValue } from "@utils/lodash";
import { concatString, formatString } from "@common/text-helpers";
import { useStateContext } from "context/profileProvider";

function NavViews(props: any) {
  const { userId } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let views = [
    {
      id: "",
      value: "",
      name: `All ${formatString(getValue(props, `plural_label`, ""))}`,
    },
    {
      id: userId,
      name: `My ${formatString(getValue(props, `plural_label`, ""))}`,
    },
    {
      id: "NO_OWNER",
      name: `Unassigned ${formatString(getValue(props, `plural_label`, ""))}`,
    },
  ];
  return (
    <div>
      <Nav tabs>
        {
        getValue(props, `plural_label`, "") && 
        getValue(views, `length`, 0) > 0
          ? views.map((item: object, index: number) => {
              return (
                <NavItem>
                  <NavLink
                    className={`${classnames({
                      active:
                        getValue(params, `filter_owner_id`, "") ===
                        getValue(item, `id`, ""),
                    })} d-flex justify-content-between align-items-center`}
                    onClick={() => {
                      props.handleNaviagteView(
                        "filter_owner_id",
                        getValue(item, `id`, "")
                      );
                    }}
                  >
                    <p>{concatString(getValue(item, `name`, ""),20)}</p>
                  </NavLink>
                </NavItem>
              );
            })
          : null}
      </Nav>
    </div>
  );
}

export default NavViews;
