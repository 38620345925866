import { getValue } from "@utils/lodash";

export const nodeRequestHelper = (
  list: any,
  handleDeleteNode: any,
  edges: any,
  addNodeByID: any
) => {
  return list.map((item: object, index: number) => {
    if (
      getValue(item, `component`, "") === "reply" ||
      getValue(item, `component`, "") === "visitor_name" ||
      getValue(item, `component`, "") === "visitor_email" ||
      getValue(item, `component`, "") === "visitor_phone" ||
      getValue(item, `component`, "") === "company_name"
    ) {
      return {
        id: getValue(item, `id`, ""),
        data: {
          label: getValue(item, `name`, ""),
          description: getValue(
            item,
            `rendering_config.replies[${0}].text`,
            ""
          ),
          id: getValue(item, `id`, ""),
          onDelete: handleDeleteNode,
          component: getValue(item, `component`, ""),
          edges: edges,
          addNodeByID: addNodeByID,
        },
        source_handle: getValue(item, `source_handle`, ""),
        flow_id: getValue(item, `flow_id`, ""),
        position: { x: index * 400, y: 50 },
        sourcePosition: "right",
        targetPosition: "left",
        type: "customNode",
      };
    } else if (getValue(item, `component`, "") === "suggestions") {
      return {
        id: getValue(item, `id`, ""),
        data: {
          label: getValue(item, `name`, ""),
          description: getValue(
            item,
            `rendering_config.replies[${0}].text`,
            ""
          ),
          id: getValue(item, `id`, ""),
          onDelete: handleDeleteNode,
          options: getValue(item, `rendering_config.suggestions`, []),
          component: getValue(item, `component`, ""),
          edges: edges,
          addNodeByID: addNodeByID,
        },
        source_handle: getValue(item, `source_handle`, ""),
        flow_id: getValue(item, `flow_id`, ""),
        position: { x: index * 400, y: 50 },
        sourcePosition: "right",
        targetPosition: "left",
        type: "customNode",
      };
    }
  });
};

export const findConnectedEdgeInfo = (data: any) => {
  let obj =
    getValue(data, `edges.length`, 0) > 0
      ? getValue(data, `edges`, []).find((item: object) =>
          getValue(item, `sourceHandle`, "")
            ? getValue(item, `sourceHandle`, "") === getValue(data, `id`, "")
            : getValue(item, `source`, "") === getValue(data, `id`, "")
        )
      : {};
  return getValue(obj, `sourceHandle`, "")
    ? getValue(obj, `sourceHandle`, "")
    : getValue(obj, `source`, "");
};
