import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

function BotVisitorNameComponent(props: any) {
  const { request, setRequest } = props;
  return (
    <div>
      <div className="mb-2">
        <InputRuleForm
          inputType="TEXT"
          value={getValue(request, `name`, "")}
          name="name"
          onChange={(e: any) =>
            setRequest({ ...request, name: e.target.value })
          }
          label="Name"
          placeholder="Enter Name"
          // required
        />
      </div>
      <div className="mb-2">
        <InputRuleForm
          inputType="TEXTAREA"
          value={getValue(request, `description`, "")}
          name="description"
          onChange={(e: any) =>
            setRequest({ ...request, description: e.target.value })
          }
          label="Message"
          placeholder="Enter Message"
          // mandatory
        />
      </div>
    </div>
  );
}

export default BotVisitorNameComponent;
