import React, { useEffect, useRef, useState } from "react";
import "./table.scss";
import { formatString, getFirstLetterOfWord } from "common/text-helpers";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import { convertCurrentDateWithTime, todayDate } from "common/date-helpers";
import Loader from "@components/common/Loader/loading";
import Nodata from "@components/common/NoData/NoData";
import CheckCircleSvgComponent from "assets/svg/check-circle";
import CustomAllCheckbox from "@components/Form/CheckBox/CustomAllCheckbox";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import HoverComponent from "../HoverComponent";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import CircleSvgComponent from "@assets/svg/circle";
import TasksNoDataPage from "@components/common/NoData/tasks-nodata";
import CallsNoDataPage from "@components/common/NoData/calls-nodata";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import SortTooltip from "../Dropdown/sortTooltip";
const CustomTaskDataTable = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               Navigation Section                           */
  /* -------------------------------------------------------------------------- */

  const navigate = useNavigate();
  const params = useParams();
  const handleNavigate = (route) => {
    !props.disableNavigation && navigate(`${route}?${mainQueryRequest}`);
  };
  const urlParamsFun = () => {
    let request = QueryRequestHelper({ ...UrlParams });
    return request;
  };

  const mainQueryRequest = React.useMemo(() => urlParamsFun(), [
    window.location.href,
  ]);
  /* -------------------------------------------------------------------------- */
  /*                               Drag and Drop Section                        */
  /* -------------------------------------------------------------------------- */
  const [isDropped, setIsDropped] = useState(false);
  const [dragOver, setDragOver] = useState("");
  const handleDragStart = (e) => {
    const { id } = e.target;
    if (id) {
      const idx = props.headers.indexOf(id);
      e.dataTransfer.setData("colIdx", idx);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDropped(false);
  };
  const handleDragEnter = (e) => {
    const { id } = e.target;
    if (id) {
      setDragOver(id);
    }
  };
  const handleOnDrop = (e) => {
    e.preventDefault();
    setIsDropped(true);
    const { id } = e.target;
    if (id) {
      const droppedColIdx = props.headers.indexOf(id);
      const draggedColIdx = e.dataTransfer.getData("colIdx");
      const tempCols = [...props.headers];
      // tempCols[draggedColIdx] = props.headers[droppedColIdx];
      // tempCols[droppedColIdx] = props.headers[draggedColIdx];

      // Remove the dragged column from the original position
      const draggedColumn = tempCols.splice(draggedColIdx, 1)[0];
      // Insert the dragged column at the target position
      tempCols.splice(droppedColIdx, 0, draggedColumn);
      props.setHeaders(tempCols);
      setDragOver("");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const { selectedIds, setSelectedIds } = props;
  const handleChangeCheckbox = (id) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const handleChangeAllCheckbox = (value) => {
    if (value === "all_checked") {
      setSelectedIds([]);
    } else {
      let IDs = getValue(props, `data`, []).map((item) => item.id);
      setSelectedIds(IDs);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    Display Section                         */
  /* -------------------------------------------------------------------------- */
  const handleDisplayHeaderItem = (col, row) => {
    switch (col) {
      case getValue(col, `api_name`, ""):
        return formatString("owner");
      default:
        return getValue(col, `label`, "");
    }
  };
  const handleDisplayItem = (col, row) => {
    switch (col) {
      case "created_at":
      case "updated_at":
      case "closing_date":
      case "due_datetime":
      case "reminder_datetime":
        return row[col] ? convertCurrentDateWithTime(row[col]) : null;
      case "owner_id":
        return getValue(row["owner"], `first_name`, "")
          ? getValue(row["owner"], `first_name`, "") +
              " " +
              getValue(row["owner"], `last_name`, "")
          : "-";
      case "updated_by":
        return getValue(row["updated_by"], `first_name`, "")
          ? getValue(row["updated_by"], `first_name`, "") +
              " " +
              getValue(row["updated_by"], `last_name`, "")
          : "-";

      case "created_by":
        return getValue(row["created_by"], `first_name`, "")
          ? getValue(row["created_by"], `first_name`, "") +
              " " +
              getValue(row["created_by"], `last_name`, "")
          : "-";
      default:
        return getValue(row, `[${col}]`, "")
          ? getValue(row, `[${col}]`, "")
          : "-";
    }
  };

  const getText = (row) => {
    switch (getValue(props, `module`, "")) {
      case "tasks":
        return `${getFirstLetterOfWord(getValue(row, `title`, ""))}`;
      default:
        return "S";
    }
  };

  const getDisplayText = (row) => {
    const firstTwoLetters = getText(row).substring(0, 2);
    return firstTwoLetters;
  };

  const fixedColumnRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = fixedColumnRef.current.scrollLeft;
      const header = fixedColumnRef.current.querySelector("th:nth-child(2)");
      const columns = fixedColumnRef.current.querySelectorAll(
        "td:nth-child(2)"
      );

      if (header) {
        if (scrollPosition > 0) {
          header.style.boxShadow = `5px 0 5px -2px rgba(0, 0, 0, 0.1)`;
        } else {
          header.style.boxShadow = "none";
        }
      }
      columns.forEach((column) => {
        if (scrollPosition > 0) {
          column.style.boxShadow = `5px 0 5px -2px rgba(0, 0, 0, 0.1)`;
        } else {
          column.style.boxShadow = "none";
        }
      });
    };
    const fixedColumnElement = fixedColumnRef.current;
    if (fixedColumnElement) {
      fixedColumnElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (fixedColumnElement) {
        fixedColumnElement.removeEventListener("scroll", handleScroll);
      }
    };
  });
  const getEmptyScreens = () => {
    switch (props.module) {
      case "tasks":
        return <TasksNoDataPage />;

      default:
        return <Nodata />;
    }
  };

  const sortOptions = [
    { value: "asc", label: "Asc" },
    { value: "desc", label: "Desc" },
  ];

  const [sort_by, setSortBy] = useState({
    field: "",
    value: "asc",
  });

  const handleSelectValue = (name, value) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const UrlParams = Object.fromEntries(urlSearchParams.entries());
    localStorage.setItem(
      `s:${getValue(props, `module`, "")}`,
      JSON.stringify({
        field: name,
        value: value,
      })
    );
    let payload = {
      ...UrlParams,
      page_no: 1,
      smart_filter: true,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const getFilterName = () => {
    let filter =
      getValue(localStorage, `s:${props.module}`, "") &&
      JSON.parse(getValue(localStorage, `s:${props.module}`, ""));
    return getValue(filter, `field`, "") ? getValue(filter, `field`, "") : "";
  };

  const getFilterValue = () => {
    let filter =
      getValue(localStorage, `s:${props.module}`, "") &&
      JSON.parse(getValue(localStorage, `s:${props.module}`, ""));
    return getValue(filter, `value`, "") ? getValue(filter, `value`, "") : "";
  };
  return (
    <div>
      {getValue(props, `isLoading`, false) &&
      !getValue(UrlParams, `search`, "") ? (
        <Loader />
      ) : (getValue(Object.keys(UrlParams), `length`, 0) &&
          getValue(props, `data.length`, 0) === 0) ||
        getValue(localStorage, `sf:companies`, "") ? (
        <FiltersNoDataPage module={props.module} />
      ) : getValue(props, `data.length`, 0) > 0 ? (
        <>
          <div
            className={"datatable_container"}
            style={{
              overflow: getValue(props, `isLoading`, false) ? "hidden" : "auto",
              borderBottom: getValue(props, `isLoading`, false) && "none",
            }}
            ref={fixedColumnRef}
          >
            {getValue(props, `isLoading`, false) ? (
              <Loader />
            ) : getValue(props, `data.length`, 0) > 0 ? (
              <table className="datatable_table">
                <thead className="datatable_table_thead">
                  <tr className="datatable_table_tr position-relative">
                    <th className="datatable_table_th text-center">Status</th>
                    <th className="datatable_table_th text-center">
                      {getValue(selectedIds, `length`, 0) ===
                      getValue(props, `data.length`, 0) ? (
                        <CustomCheckbox
                          checked={
                            getValue(selectedIds, `length`, 0) ===
                            getValue(props, `data.length`, 0)
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleChangeAllCheckbox(
                              getValue(selectedIds, `length`, 0) ===
                                getValue(props, `data.length`, 0)
                                ? "all_checked"
                                : "all_not_checked"
                            )
                          }
                          className={"datatable_table_th_checkbox"}
                        />
                      ) : (
                        <CustomAllCheckbox
                          checked={
                            getValue(selectedIds, `length`, 0) > 0
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleChangeAllCheckbox(
                              getValue(selectedIds, `length`, 0) > 0
                                ? "all_checked"
                                : "all_not_checked"
                            )
                          }
                          className={"datatable_table_th_checkbox"}
                        />
                      )}
                    </th>
                    {props.headers.map((col, index) => {
                      if (getValue(col, `is_selected`, false))
                        return (
                          <th className="datatable_table_th">
                            {/* {col === "name" &&
                              getValue(props, `singular_label`, "")}{" "}
                            {handleDisplayHeaderItem(col)} */}

                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="header_text__12">
                                {col === "name" &&
                                  getValue(props, `singular_label`, "")}{" "}
                                {handleDisplayHeaderItem(col)}
                              </h6>
                              {getValue(props, `module`, "") === "tasks" &&
                                getValue(col, `api_name`, "") !==
                                  "pipeline_stage" && (
                                  <SortTooltip
                                    title={`Sort`}
                                    value={
                                      getValue(col, `api_name`, "") ===
                                      getFilterName()
                                        ? getFilterValue()
                                        : ""
                                    }
                                    label={`label`}
                                    onChange={(e) => {
                                      handleSelectValue(
                                        getValue(col, `api_name`, ""),
                                        getValue(e, `value`, "")
                                      );
                                      setSortBy({
                                        ...sort_by,
                                        value: getValue(e, `value`, ""),
                                      });
                                    }}
                                    sortSelected={
                                      getValue(col, `api_name`, "") ===
                                      getFilterName()
                                        ? true
                                        : false
                                    }
                                    data={sortOptions}
                                    selectKey={"value"}
                                    editInline
                                  />
                                )}
                            </div>
                          </th>
                        );
                    })}
                  </tr>
                </thead>
                <tbody className="datatable_table_tbody">
                  {props.data.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={`datatable_table_tr ${
                          getValue(props, `selectedIds`, []).includes(
                            getValue(row, `id`, "")
                          )
                            ? "active_background"
                            : ""
                        }`}
                      >
                        <td
                          className="datatable_table_td d-flex justify-content-center"
                          style={{ width: "100px", height: "52px" }}
                        >
                          <TooltipOnHover
                            header={
                              <div
                                className="filter-image-container mx-3"
                                onClick={() =>
                                  props.handleUpdateStatus(
                                    getValue(row, `id`, ""),
                                    !getValue(row, `is_completed`, false)
                                  )
                                }
                              >
                                {getValue(row, `is_completed`, false) ? (
                                  <CheckCircleSvgComponent
                                    size={30}
                                    color={"#4cc793"}
                                  />
                                ) : (
                                  <CircleSvgComponent
                                    color={"lightgray"}
                                    size={30}
                                  />
                                )}
                              </div>
                            }
                            text={
                              getValue(row, `is_completed`, false)
                                ? "Mark as Open"
                                : "Mark as Completed"
                            }
                            show
                            height={"40px"}
                            width={"150px"}
                          />
                        </td>
                        <td className="datatable_table_td">
                          <HoverComponent
                            title={getDisplayText(row)}
                            checked={
                              getValue(props, `selectedIds`, []).includes(
                                getValue(row, `id`, "")
                              )
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleChangeCheckbox(getValue(row, `id`, ""))
                            }
                          />
                        </td>
                        {props.headers.map((item, idx) => {
                          let col = getValue(item, `api_name`, "");
                          if (getValue(item, `is_selected`, false))
                            return (
                              <td
                                className="datatable_table_td"
                                key={idx}
                                dragOver={props.headers[idx] === dragOver}
                                onClick={() =>
                                  getValue(props, `module`, "") &&
                                  handleNavigate(
                                    `/${getValue(
                                      params,
                                      `orgId`,
                                      ""
                                    )}/${getValue(
                                      props,
                                      `module`,
                                      ""
                                    )}/detail/${getValue(row, `id`, "")}`
                                  )
                                }
                              >
                                {handleDisplayItem(col, row)}
                              </td>
                            );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              getEmptyScreens()
            )}
          </div>
        </>
      ) : (
        getEmptyScreens()
      )}
    </div>
  );
};

export default CustomTaskDataTable;
