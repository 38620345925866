import { getValue } from "@utils/lodash";

export const edgeRequestHelper = (list: any, handleDeleteEdge: any) => {
  return list.map((item: object, index: number) => {
    // cly5lhvb100gyxs7bhukaos1l
    return {
      id: getValue(item, `id`, ""),
      source: getValue(item, `flow_id`, ""),
      target: getValue(item, `id`, ""),
      sourceHandle: getValue(item, `source_handle`, ""),
      targetHandle: getValue(item, `target_handle`, ""),
      // animated: true,
      type: "buttonedge",
      data: {
        label: getValue(item, `name`, ""),
        description: getValue(item, `rendering_config.replies[${0}].text`, ""),
        id: getValue(item, `id`, ""),
        onDelete: handleDeleteEdge,
      },
      markerEnd: {
        type: "arrowclosed",
        width: 20,
        height: 20,
        color: "#4285f4",
      },
      style: {
        strokeWidth: 1.5,
        stroke: "#4285f4",
      },
    };
  });
};
