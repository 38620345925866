import { getStartOfMonthDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Link, useParams } from "react-router-dom";

export default function Logo() {
  const params = useParams();
  let date_range = getStartOfMonthDate();
  return (
    <div>
      <Link
        to={
          getValue(params, `orgId`, "")
            ? `/${getValue(params, `orgId`, "")}/dashboard?date_range=${date_range}`
            : "#"
        }
        className=""
      >
        <img
          // src="/images/rapid-sales-logo.svg"
          src="/home_logo.svg"
          className="img-responsive cursor-pointer"
        />
      </Link>
    </div>
  );
}
