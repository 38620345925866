import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "../src/ErrorBoundary";
import { StateProvider } from "@context/profileProvider";
import { Toaster, toast } from "sonner";
import "bootstrap/dist/css/bootstrap.min.css";
import "@scss/common/base.scss";
import "@scss/layouts/layout-modifications.scss";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "reactjs-popup/dist/index.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-international-phone/style.css";
import "reactflow/dist/style.css";

// import { StateProvider } from "context/profileProvider";

/* -------------------------------------------------------------------------- */
/*                               UseState Section                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               React Query Section                          */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               API Section                                  */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               Onchange section                             */
/* -------------------------------------------------------------------------- */
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    {/* <React.StrictMode> */}
    <StateProvider>
      <Toaster richColors closeButton />
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </StateProvider>
    {/*</React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();