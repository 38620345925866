import { config } from "../env";
import { get, post, postFileUpload, postWithCode } from "./helpers/http-handler";

export const mediaUpload = (payload: any) =>
  postFileUpload(`${config.API_URL}/file-uploads/upload`, payload);

export const mediaUploadWithCode = (payload: any, code: string) =>
  postWithCode(`${config.API_URL}/file-uploads/upload`, payload, code);

export const dataImport = (payload: any) =>
  post(`${config.API_URL}/data-imports`, payload);

export const importPipelineSheet = (id: string) =>
  get(`${config.API_URL}/pipelines/${id}/import-sheet`);
