import LoginLayout from "layout/LoginLayout";
import SupportForm from "./supportForm";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { signInWithPassword } from "services/auth.service";
import { QueryRequestHelper } from "@common/query-request-helper";

export default function LoginPage() {
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    identifier_type: "EMAIL",
    identifier: "",
    password: "",
  });
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        let resp = await signInWithPassword(request);
        if (resp) {
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.access_token`, "")
          );
          if (getValue(UrlParams, `invite_token`, "")) {
            window.location.href = `/organisations/join?invite_token=${getValue(
              UrlParams,
              `invite_token`,
              ""
            )}`;
          } else {
            window.location.href = "/organisations";
          }
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const handleNavigate = () => {
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (getValue(UrlParams, `token`, "")) {
      return `/user-signup/invite?${queryRequest}`;
    } else {
      return `/signup?${queryRequest}`;
    }
  };
  return (
    <>
      <LoginLayout>
        <div className="d-flex justify-content-center login-mobile-responsive height-adjust">
          <div className="login_card">
            <div className={`support-login-wrapper__details`}>
              <div className="d-flex justify-content-center ">
                <Link to="https://supercrm.ai/">
                  <a className={``}>
                    <img
                      src="/logo.svg"
                      className={`login-header-wrapper__logo-image`}
                    />
                  </a>
                </Link>
              </div>
              <h4 className={"header_text__26 mb-3 mt-3"}>
                Sign in to access SuperCRM
              </h4>

              <SupportForm
                request={request}
                loading={false}
                handleSubmit={handleSubmit}
                setRequest={setRequest}
                submitLoading={submitLoading}
                simpleValidator={simpleValidator}
              />
              <h6 className={"header_text__16 d-flex align-items-center mb-3 mt-4"}>
                Don't have a SuperCRM account?
                <Link to={handleNavigate()} className="ms-1">
                  Sign Up Now
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </LoginLayout>
    </>
  );
}
