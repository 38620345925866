import React, { useRef, useState, useEffect, useMemo } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./tooltip.scss";
import "./index.scss";
import { getValue } from "@utils/lodash";
import { useStateContext } from "context/profileProvider";
import { concatString } from "@common/text-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getLookupAPIs } from "@components/helpers/lookup-apis";
import { motion } from "framer-motion";

const HeaderTooltip = (props) => {
  const { label, id, value, onChange, selectKey, data, isLoading } = props;

  const inputRef = useRef(null);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */
  const [dataInfo, setData] = useState([]);
  const handleClickOption = (option) => {
    onChange && onChange(option);
    setQuery("");
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const filter = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedValue = (id) => {
    let list =
      getValue(
        getValue(props, `lookup_api`, "") ? dataInfo : data,
        `length`,
        0
      ) > 0
        ? getValue(props, `lookup_api`, "")
          ? dataInfo.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
          : data.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(() => findSelectedValue(value), [
    value,
    getValue(getValue(props, `lookup_api`, "") ? dataInfo : data, `length`, 0) >
      0,
  ]);
  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const popupRef = useRef(null);
  const POSITION_TYPES = [
    "bottom center",
    "right top",
    "top left",
    "top center",
    "top right",
    "right center",
    "right bottom",
    "bottom left",
    "bottom right",
    "left top",
    "left center",
    "left bottom",
    "center center",
  ];
  const { userId } = useStateContext();
  return (
    <Popup
      trigger={(open) => (
        <div
          className={`d-flex align-items-center px-3 ${
            value && valueLabel && !props.editInline
              ? "inline_popup_active"
              : ""
          }`}
        >
          <p className="touchable-text header_text">
            {props.editInline
              ? concatString(valueLabel, 20)
              : concatString(getValue(props, `title`, ""), 20)}
          </p>
          <img
            src={
              open
                ? "/dropdown/arrow_up_filter.svg"
                : "/dropdown/arrow_down_filter.svg"
            }
            className="inline_dropdown_image"
          />
          {((!props.editInline && valueLabel) || props.closeInline) && value && (
            <div className="inline_close_image_container">
              <img
                src={"/dropdown/close.svg"}
                className="inline_close_image"
                onClick={(e) => handleClose(e)}
              />
            </div>
          )}
        </div>
      )}
      // position="bottom center"
      position={POSITION_TYPES}
      mouseEnterDelay={600} // Delay before showing the popup
      mouseLeaveDelay={300} // Delay before hiding the popup
      closeOnDocumentClick
      ref={popupRef}
      className="popup-content-first"
      contentStyle={{
        width: props.width,
        transition: "all 1s ease-in",
        padding: 0,
      }}
      nested={props.editInline ? true : false}
      arrow={true}
    >
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div>
          {
            <div className={"filter-select-form-inline"}>
              <div className="option_container">
                {isLoading ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <img src={`/dropdown/loader1.gif`} className="loader" />
                  </div>
                ) : filter(data).length > 0 ? (
                  filter(data).map((option, index) => {
                    if (getValue(option, `name`, ""))
                      return (
                        <div
                          onClick={() => {
                            handleClickOption(option);
                          }}
                          className={`option ${
                            value &&
                            getValue(
                              option,
                              `[${selectKey ? selectKey : "id"}]`,
                              ""
                            ) == value
                              ? "selected"
                              : ""
                          }`}
                          key={`${id}-${index}`}
                        >
                          <div className="selected_text hover-text">
                            {concatString(`${option[label]}`, 30)}
                            {getValue(option, `[${label}].length`, 0) > 30 && (
                              <span className="tooltip-text" id="top">
                                {option[label]}
                              </span>
                            )}
                            <p className="option_desc">
                              {getValue(option, `desc`, "")}
                            </p>
                          </div>
                          {value &&
                            getValue(
                              option,
                              `[${selectKey ? selectKey : "id"}]`,
                              ""
                            ) == value && (
                              <img
                                src="/dropdown/done.svg"
                                className="done_image"
                              />
                            )}
                        </div>
                      );
                  })
                ) : (
                  <div className="nodata">No Data Found</div>
                )}
              </div>
            </div>
          }
        </div>
      </motion.div>
    </Popup>
  );
};
export default HeaderTooltip;
