import EditSvgComponent from "@assets/svg/edit";
import { getValue } from "@utils/lodash";
import React from "react";

class FacebookLogin extends React.Component {
  componentDidMount() {
    // Load the Facebook SDK asynchronously
    this.loadFacebookSDK();
  }

  loadFacebookSDK() {
    // window.fbAsyncInit = function () {
    //   window.FB.init({
    //     appId: "1489430778292283",
    //     autoLogAppEvents: true,
    //     xfbml: true,
    //     version: "v18.0",
    //   });
    // };
    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) return;
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");
  }

  launchWhatsAppSignup = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = getValue(response, `authResponse.code`, "");
          // Use this token to call the debug_token API and get the shared WABA's ID
          this.props.handleSubmit(accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        // scopes: 'email,whatsapp_business_management',
        config_id: "986903789774528",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    );
  };

  render() {
    return (
      <>
        {
          this.props.edit ?
            <button
              style={{
                backgroundColor: "#1877F2",
                border: "0",
                borderRadius: "30px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "14px",
                fontWeight: "bold",
                // height: "40px",
                padding: "0 24px",
                padding: "10px",
                width:'100px'
              }}
              onClick={this.launchWhatsAppSignup}
            >
              Edit
            </button> :
            <button
              style={{
                backgroundColor: "#1877F2",
                border: "0",
                borderRadius: "4px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "14px",
                fontWeight: "bold",
                // height: "40px",
                padding: "0 24px",
                padding: "10px",
              }}
              onClick={this.launchWhatsAppSignup}
            >
              Connect Now
            </button>
        }
      </>

      // <>
      //   {this.props.edit ? (
      //     // <div onClick={this.launchWhatsAppSignup}>
      //     //   <EditSvgComponent color={"#1980d8"} />
      //     // </div>
      //     <button
      //       className="dashboard-button dashboard-button--primary px-4 mx-1  d-flex justify-content-center align-items-center"
      //       onClick={this.launchWhatsAppSignup}
      //     >
      //       Edit
      //     </button>
      //   ) : (
      //     <button
      //       className="dashboard-button dashboard-button--primary px-4 mx-1  d-flex justify-content-center align-items-center"
      //       onClick={this.launchWhatsAppSignup}
      //     >
      //       Integrate Whatsapp
      //     </button>
      //   )}
      // </>
    );
  }
}

export default FacebookLogin;
