import HomeHeader from "@components/common/Header/HomeHeader/Header";

export default function MainLayout({ children }: { children: any }) {
  return (
    <>
      <HomeHeader />
      {/* <SubHeader /> */}
      {/* <Tabs isNewUserVisible={true} /> */}
      <div className='flex-1'>{children}</div>
    </>
  );
}
