import InlineFormBuilder from "@components/Builder/InlineFormBuilder";
import TaskPendingLayout from "../../../components/TaskPendingLayout/TaskPendingLayout";
import { useState } from "react";
import { getValue } from "@utils/lodash";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { motion } from "framer-motion";
export default function PendingDealComponent(props: any) {
  const [showMore, setShowMore] = useState(false);
  return (
    <TaskPendingLayout>
      <div className={`pending-wrapper__main-left-section`}>
        {/* <PendingTaskCommonTitle
          commonTitle="About this deal"
          isTextVisible={true}
          handleToggle={() => {
            setIsActive1(!isActive1);
          }}
        /> */}
        {/* <div
          className={`
          
          ${isActive1 ? "showdiv" : "hidediv"} 
          
          accordian-wrapper pending-wrapper__pending-task-details
          `}
        > */}
        <div
          className={`accordian-wrapper pending-wrapper__pending-task-details`}
        >
          <div className={"pending-wrapper__main-section-form"}>
            <InlineFormBuilder
              fields={props.fields}
              setFields={props.setFields}
              module={props.module}
              width={"300px"}
              duplicateAPINames={props.duplicateAPINames}
              setDuplicateAPINames={props.setDuplicateAPINames}
              isChanged={props.isChanged}
            />
          </div>
          {showMore && (
            <motion.div
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              exit={{
                y: 50,
                opacity: 0,
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
            >
              <div className="view_more_container mt-3 mb-3">
                <div className="mt-3">
                  <label className={`form-label-inline`}>Created By</label>
                  <div className="d-flex align-items-center">
                    <p className="header_text__14">
                      {getValue(props, `data.created_by.first_name`, "")}{" "}
                      {getValue(props, `data.created_by.last_name`, "")}
                    </p>
                    <p className="small_text__14 mx-2">on</p>
                    <p className="header_text__14">
                      {convertCurrentDateWithTime(
                        getValue(props, `data.created_at`, "")
                      )}
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <label className={`form-label-inline`}>Updated By</label>
                  <div className="d-flex align-items-center">
                    <p className="header_text__14">
                      {getValue(props, `data.updated_by.first_name`, "")}{" "}
                      {getValue(props, `data.updated_by.last_name`, "")}
                    </p>
                    <p className="small_text__14 mx-2">on</p>
                    <p className="header_text__14">
                      {convertCurrentDateWithTime(
                        getValue(props, `data.updated_at`, "")
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
          <button
            className={"pending-wrapper__main-section-btn"}
            onClick={() => setShowMore(!showMore)}
          >
            View {showMore ? "Less" : "More"}
          </button>
        </div>
      </div>
    </TaskPendingLayout>
  );
}
