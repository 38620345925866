import React from "react";
import "./filter.scss";
import { getValue } from "@utils/lodash";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import ResendSvgComponent from "assets/svg/resend";
import AudioSvgComponent from "@assets/svg/audio";

function NotificationBar(props: any) {
  return (
    <div className={`filter-wrapper d-flex align-items-center `}>
      <div className={`filter-wrapper_text_container px-3`}>
        <p className={`filter-wrapper_text`}>
          {getValue(props, `selectedIds.length`, 0)}{" "}
          <span className="ms-1">{"Selected"}</span>
        </p>
        <img
          src={`/images/close-gray.svg`}
          className={`filter-wrapper_textimage ms-2`}
          onClick={() => props.setSelectedIds([])}
        />
      </div>
      {getValue(props, `selectedIds.length`, 0) === 1 && !props.hideDelete && (
        <div
          className={`filter-wrapper_header_container px-3`}
          onClick={() =>
            props.handleDeleteTable(getValue(props, `selectedIds[${0}]`, ""))
          }
        >
          <p className={`filter-wrapper_text`}>Delete</p>
          <img
            src={`/images/delete-white.svg`}
            className={`filter-wrapper_textimage ms-2`}
            onClick={() => props.setSelectedIds([])}
          />
        </div>
      )}
      {!props.hideOwner && (
        <div
          className={`filter-wrapper_header_container px-3`}
          onClick={() => props.handleOpenFilter("owner_id")}
        >
          <p className={`filter-wrapper_text`}>Change Owner</p>
          <img
            src={`/images/person-white.svg`}
            className={`filter-wrapper_textimage ms-2`}
            onClick={() => props.setSelectedIds([])}
          />
        </div>
      )}
      {!props.hideStage && (
        <div
          className={`filter-wrapper_header_container px-3`}
          onClick={() => props.handleOpenFilter("stage_id")}
        >
          <p className={`filter-wrapper_text`}>Change Stage</p>
          <img
            src={`/images/step-white.svg`}
            className={`filter-wrapper_textimage ms-2`}
            onClick={() => props.setSelectedIds([])}
          />
        </div>
      )}
      {getValue(props, `module`, "") === "contacts" &&
        getValue(props, `callsRecording`, false) &&
        getValue(props, `selectedIds.length`, 0) === 1 && (
          <div
            className={`filter-wrapper_header_container px-3`}
            onClick={() => props.handleOpenCallRecordings()}
          >
            <p className={`filter-wrapper_text`}>Call Recordings</p>
            <img
              src={`/images/play.svg`}
              className={`filter-wrapper_textimage ms-2`}
              onClick={() => props.setSelectedIds([])}
            />
            {/* <AudioSvgComponent size={16} color={"white"} /> */}
          </div>
        )}
      {getValue(props, `usersModule`, false) &&
        getValue(props, `selectedIds.length`, 0) === 1 && (
          <>
            {!getValue(props, `userInfo.is_email_verified`, false) && (
              <div
                className={`filter-wrapper_header_container px-3`}
                onClick={() =>
                  props.handleResendInvUser(
                    getValue(props, `selectedIds[${0}]`, "")
                  )
                }
              >
                <p className={`filter-wrapper_text`}>Resend Inviation</p>
                <div className="ms-2">
                  <ResendSvgComponent color={"white"} size={18} />
                </div>
              </div>
            )}
            {!getValue(props, `userInfo.is_email_verified`, false) && (
              <div
                className={`filter-wrapper_header_container px-3`}
                onClick={() =>
                  props.handleCancelInvUser(
                    getValue(props, `selectedIds[${0}]`, "")
                  )
                }
              >
                <p className={`filter-wrapper_text`}>Cancel Inviation</p>
                <div className="ms-2">
                  <RemoveCircleSvgComponent color={"white"} size={18} />
                </div>
              </div>
            )}
            {getValue(props, `userInfo.is_email_verified`, false) &&
              getValue(props, `userInfo.archived_at`, "") === null && (
                <div
                  className={`filter-wrapper_header_container px-3`}
                  onClick={() => props.handleDeactivateUser()}
                >
                  <p className={`filter-wrapper_text`}>Deactivate User</p>
                  <img
                    src={`/images/person-white.svg`}
                    className={`filter-wrapper_textimage ms-2`}
                    onClick={() => props.setSelectedIds([])}
                  />
                </div>
              )}

            {getValue(props, `userInfo.archived_at`, "") !== null && (
              <div
                className={`filter-wrapper_header_container px-3`}
                onClick={() => props.handleActivateUser()}
              >
                <p className={`filter-wrapper_text`}>Activate User</p>
                <img
                  src={`/images/person-white.svg`}
                  className={`filter-wrapper_textimage ms-2`}
                  onClick={() => props.setSelectedIds([])}
                />
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default NotificationBar;
