import DeleteSvgComponent from "@assets/svg/delete";
import { handleTextArrayChange } from "@common/handleChange";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  removeNullOrUndefinedProperties,
  removeNullValuesFromObjectArray,
} from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import {
  addAutoResponseInfo,
  getAutoResponseInfo,
  getWhatsappTemplate,
} from "@services/fb.service";
import { getValue, setValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import MessageOptOutPopup from "../components/optout-popup";
import { handleBooleanArrayChange } from "@common/handleChange";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { mediaUpload } from "@services/upload.service";

function AutoResponseMessages(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const initialState = {
    isEnable: true,
    data: [
      // {
      //   id: "string",
      //   keyword: "string",
      //   isAutoResponse: true,
      //   responseType: "template",
      //   autoResponseMsgType: "text",
      //   autoResponseMsgTxt: "string",
      //   templateId: "string",
      //   mediaId: "string",
      // },
    ],
  };
  const keywordObj = {
    id: "",
    keyword: "",
    isAutoResponse: true,
    responseType: "template",
    autoResponseMsgType: "text",
    autoResponseMsgTxt: "",
    templateId: "",
    mediaId: "",
  };

  let [request, setRequest] = useState<any>(initialState);
  const reset = () => {
    setRequest(initialState);
  };
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    getTemplateList();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAutoResponseInfo(queryRequest);
      if (resp) {
        console.log(resp);
        setRequest({
          ...request,
          isEnable: getValue(resp, `data.feature.auto_response`, false),
          data:
            getValue(resp, `data.items.length`, 0) > 0
              ? getValue(resp, `data.items`, []).map((item: object) => ({
                  ...item,
                  isAutoResponse: getValue(item, `auto_response`, ""),
                  responseType: getValue(item, `response_type`, ""),
                  autoResponseMsgType: getValue(item, `msg_type`, ""),
                  autoResponseMsgTxt: getValue(item, `msg_text`, ""),
                  templateId: getValue(item, `template_id`, ""),
                  mediaId: getValue(item, `msg_media`, ""),
                }))
              : [],
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitAutoResponse = async (value: boolean) => {
    let payload = JSON.parse(JSON.stringify(request));
    let list = getValue(payload, `data`, []).map((item: object) => ({
      ...item,
      mediaId: getValue(item, `mediaId.id`, ""),
    }));
    let data = removeNullValuesFromObjectArray(list);
    payload["isEnable"] = value;
    payload["data"] = data;
    setSubmitLoading(true);
    try {
      let resp = await addAutoResponseInfo(
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        toast.success("Saved successfully");
        setSubmitLoading(false);
        getData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  console.log(request);

  /* -------------------------------------------------------------------------- */
  /*                             Modal Section                                  */
  /* -------------------------------------------------------------------------- */
  const [selectedIndex, setSelectedIndex] = useState<Number>();
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const messageTypeEnum = [
    { value: "text", label: "Text" },
    { value: "image", label: "Image" },
    // { value: "audio", label: "Audio" },
    { value: "document", label: "Document" },
    { value: "video", label: "Video" },
  ];
  const [messageType, setMessageType] = useState("preapproved");
  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        status: ["APPROVED"],
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        // console.log(getValue(resp, `data.templates`, []));
        setTemplateList(
          getValue(resp, `data.templates`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  const handleSubmit = async () => {
    console.log(configureRequest);
    setValue(request, `data[${selectedIndex}]`, configureRequest);
    setRequest({ ...request });
    handleModal();
  };
  const [configureRequest, setConfigureRequest] = useState<any>({});
  const handleRemoveConfig = (index: number) => {
    let filtered = getValue(request, `data`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      data: filtered,
    });
  };

  console.log(getValue(request, `data`, []));

  const handleFileChange = async (e: any, type: string) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setConfigureRequest({
        ...configureRequest,
        msg_type: type,
        mediaId: getValue(resp, `data`, {}),
      });
    }
    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  return (
    <div className="optout_container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="optout-campaign_container">
            <div className="d-flex justify-content-between align-items-center px-4">
              <div>
                <h6 className="header_text__18">API Campaign Opt-out</h6>
                <p className="small_text__14 mt-3">
                  Enable this if you don't wish to send api campaign to
                  opted-out contacts
                </p>
              </div>
              <div>
                <label className={"switch"}>
                  <input
                    type="checkbox"
                    checked={getValue(request, `isEnable`, false)}
                    onChange={() => {
                      const currentValue = getValue(request, `isEnable`, false);
                      setRequest({
                        ...request,
                        isEnable: !currentValue,
                      });
                      // if (currentValue) {
                      handleSubmitAutoResponse(!currentValue);
                      // }
                    }}
                  />
                  <span className={`${"slider"} ${`round`} d-flex`}></span>
                </label>
              </div>
            </div>
          </div>

          <div className="optout-campaign_container">
            {getValue(request, `data`, []).map(
              (item: object, index: number) => {
                return (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="optout-campaign_container" key={index}>
                      <div className="d-flex justify-content-between align-items-start px-4 w-100">
                        <div className="w-50">
                          <h6 className="header_text__18">
                            Auto Response Keywords
                          </h6>
                          <p className="small_text__14 mt-3 w-75">
                            The user will have to type exactly one of these
                            messages on which they should be automatically
                            opted-out
                          </p>
                          <div className="keywords_container w-50 mt-4">
                            <InputRuleForm
                              inputType="TEXT"
                              placeholder="Enter keyword"
                              value={getValue(item, `keyword`, "")}
                              name={"keyword"}
                              onChange={(e: any) =>
                                handleTextArrayChange(
                                  index,
                                  e,
                                  "data",
                                  setRequest
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="w-50">
                          <div className="d-flex align-items-center">
                            <h6 className="header_text__18">Auto Response</h6>
                            <div className="ms-3 mt-2">
                              <label className={"switch"}>
                                <input
                                  type="checkbox"
                                  checked={getValue(
                                    item,
                                    `isAutoResponse`,
                                    false
                                  )}
                                  onChange={(e: any) =>
                                    handleBooleanArrayChange(
                                      index,
                                      !getValue(item, `isAutoResponse`, false),
                                      "isAutoResponse",
                                      "data",
                                      setRequest
                                    )
                                  }
                                />
                                <span
                                  className={`${"slider"} ${`round`} d-flex`}
                                ></span>
                              </label>
                            </div>
                            <div className="ms-2">
                              <ButtonComponent
                                onClickHandler={() => {
                                  handleModal();
                                  setSelectedIndex(index);
                                  setConfigureRequest(item);
                                }}
                                buttonType={"scecondary"}
                                buttonText="Configure"
                              />
                            </div>
                          </div>
                          <p className="small_text__14 mt-3">
                            Setup a response message for opt-out user keywords
                          </p>
                          {getValue(item, `autoResponseMsgTxt`, "") &&
                            getValue(item, `msg_type`, "") === "text" && (
                              <div className="border border-radius__5 p-3 mt-5">
                                <p className=" small_text__16">
                                  {getValue(item, `autoResponseMsgTxt`, "")}
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="ms-3"
                      onClick={() => handleRemoveConfig(index)}
                    >
                      <RemoveCircleSvgComponent />
                    </div>
                  </div>
                );
              }
            )}
            <div className="d-flex justify-content-between align-items-center w-100">
              <div></div>
              <h6
                className="color_primary header_text__16 cursor-pointer"
                onClick={() => {
                  request.data.push(keywordObj);
                  setRequest({
                    ...request,
                  });
                }}
              >
                Add Keyword
              </h6>
            </div>
          </div>
          <div className="mt-4">
            <ButtonComponent
              onClickHandler={() =>
                handleSubmitAutoResponse(getValue(request, `isEnable`, false))
              }
              buttonType={"primary"}
              buttonText={submitLoading ? "Please wait..." : "Save Settings"}
            />
          </div>
        </>
      )}
      <MessageOptOutPopup
        isOpen={isOpen}
        handleModal={handleModal}
        request={configureRequest}
        selectedIndex={selectedIndex}
        setRequest={setConfigureRequest}
        messageType={messageType}
        setMessageType={setMessageType}
        templateList={templateList}
        messageTypeEnum={messageTypeEnum}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        handleFileChange={handleFileChange}
      />
    </div>
  );
}

export default AutoResponseMessages;
