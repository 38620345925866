import "./Branding.scss";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useEffect, useState } from "react";
import { getSpecificOrganisations } from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { updateOrganisations } from "@services/organisations.service";
import { mediaUpload } from "@services/upload.service";
import { toast } from "sonner";
import { useStateContext } from "context/profileProvider";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import DeleteSvgComponent from "@assets/svg/delete";

export default function Branding(props: any) {
  const { handleChangeOrgainization } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    theme: "",
    logo: "",
    logo_id: "",
  });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  const themeColors = [
    { label: "Theme Green", value: "#3fd1a9" },
    { label: "Theme Red", value: "#E74C3C" },
    { label: "Theme Blue", value: "#276ef2" },
    { label: "Theme Orange", value: "#fd7445" },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    try {
      let resp = await getSpecificOrganisations();
      if (resp) {
        setRequest({
          ...request,
          theme: getValue(resp, `data.theme`, ""),
          logo: getValue(resp, `data.logo.url`, ""),
          logo_id: getValue(resp, `data.logo.id`, ""),
        });
      }
    } catch (error) {}
  };

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (event: any) => {
    try {
      const file = event.target.files[0];
      if (!file) {
        console.error("No file selected.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");

      setUploadLoading(true);

      const resp = await mediaUpload(formData);

      if (resp) {
        const response = await updateOrganisations({
          logo_id: getValue(resp, `data.id`, ""),
        });

        if (response) {
          toast.success("Imported successfully");
          getData();
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp = await updateOrganisations({
        theme: getValue(request, `theme`, ""),
        logo_id: getValue(request, `logo`, "")
          ? getValue(request, `logo_id`, "")
          : null,
      });
      if (resp) {
        setSubmitLoading(false);
        getData();
        handleChangeOrgainization();
        toast.success("Updated successfully");
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <>
      <HomeHeader />
      <CompanyProfileSubHeader
        isLoading={submitLoading}
        handleSubmit={handleSubmit}
        showSubmit
      />
      <CompanyProfileDetailsLayout>
        <div className={"branding-wrapper"}>
          <h6 className={"branding-wrapper__title"}>Your Logo</h6>
          <div className="d-flex align-items-center mb-4">
            {getValue(request, `logo`, "") ? (
              uploadLoading ? (
                <div className={"branding-input"}>
                  <p className="mt-4">Uploadlong...</p>
                </div>
              ) : (
                <div className="position-relative">
                  <label htmlFor="branding-logo" className="cursor-pointer">
                    <input
                      type="file"
                      className="d-none"
                      id="branding-logo"
                      onChange={handleUploadDocuments}
                    />
                    <div className={"branding-input"}>
                      <img
                        src={getValue(request, `logo`, "")}
                        className={`img-fluid cursor-pointer`}
                      />
                    </div>
                  </label>
                  <div
                    className="branding_delete_image"
                    onClick={() =>
                      setRequest({
                        ...request,
                        logo: "",
                      })
                    }
                  >
                    <DeleteSvgComponent />
                  </div>
                </div>
              )
            ) : uploadLoading ? (
              <div className={"branding-input"}>
                <p className="mt-4">Uploadlong...</p>
              </div>
            ) : (
              <label htmlFor="branding-logo" className="cursor-pointer">
                <input
                  type="file"
                  className="d-none"
                  id="branding-logo"
                  onChange={handleUploadDocuments}
                />
                <div className={"branding-input"}>
                  <img
                    src="/images/icons/camera.svg"
                    className={`${"camera-icon"} img-fluid cursor-pointer`}
                  />
                </div>
                <p className={`${"branding-wrapper__text"}`}>
                  Upload Brand Logo
                </p>
              </label>
            )}
            <div className={"branding-wrapper__main-section"}>
              <h6 className={"branding-wrapper__main-section-title"}>
                This Logo will appear on on the top-left corner of this Network.
              </h6>
              <p className={"branding-wrapper__main-section-text"}>
                Preferred Image Size: 240px x 48px @ 72 DPI Maximum size of 1MB.
              </p>
            </div>
          </div>
          <h6 className={"branding-wrapper__title"}>Themes</h6>
          <div className="d-flex align-items-center">
            <div>
              <UncontrolledDropdown className="color-dropdown">
                <DropdownToggle>
                  <div className={`${"branding-wrapper__dropdown"}`}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={`d-flex`}>
                        <div className="d-flex align-items-center">
                          <div className={"branding-wrapper__color-box"}></div>
                          <div
                            className={`${"branding-wrapper__color-box"} ${`branding-wrapper__color-box--color`}`}
                            style={{
                              background: getValue(request, `theme`, "")
                                ? getValue(request, `theme`, "")
                                : "white",
                            }}
                          ></div>
                        </div>
                        <p className={"branding-wrapper__color-box-text"}>
                          {getValue(request, `theme`, "")
                            ? getValue(request, `theme`, "")
                            : "Select"}
                        </p>
                      </div>
                      <img src="/images/icons/arrow-drop.svg" />
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="color-dropdown-menu">
                  {themeColors.map((item: object) => {
                    return (
                      <DropdownItem
                        onClick={() =>
                          setRequest({
                            ...request,
                            theme: getValue(item, `value`, ""),
                          })
                        }
                      >
                        <div
                          className={`${"branding-wrapper__dropdown-item"} d-flex`}
                        >
                          <div className="d-flex align-items-center">
                            <div
                              className={"branding-wrapper__color-box"}
                            ></div>
                            <div
                              className={`${"branding-wrapper__color-box"} ${`branding-wrapper__color-box--color`}`}
                              style={{
                                backgroundColor: getValue(item, `value`, ""),
                              }}
                            ></div>
                          </div>
                          <p className={"branding-wrapper__color-box-text"}>
                            {getValue(item, `label`, "")}
                          </p>
                        </div>
                      </DropdownItem>
                    );
                  })}

                  {/* <DropdownItem className="my-3">
                    <div
                      className={`${"branding-wrapper__dropdown-item"} d-flex`}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={"branding-wrapper__color-box"}
                        ></div>
                        <div
                          className={`${
                            "branding-wrapper__color-box"]
                          } ${
                            `branding-wrapper__color-box--color`]
                          }`}
                        ></div>
                      </div>
                      <p
                        className={
                          "branding-wrapper__color-box-text"]
                        }
                      >
                        Navy Blue
                      </p>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div
                      className={`${"branding-wrapper__dropdown-item"} d-flex`}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className={"branding-wrapper__color-box"}
                        ></div>
                        <div
                          className={`${
                            "branding-wrapper__color-box"]
                          } ${
                            `branding-wrapper__color-box--color`]
                          }`}
                        ></div>
                      </div>
                      <p
                        className={
                          "branding-wrapper__color-box-text"]
                        }
                      >
                        Navy Blue
                      </p>
                    </div>
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className={`${"branding-wrapper__main-section"} mb-0`}>
              <h6 className={"branding-wrapper__main-section-title"}>
                This theme will be applied to the portal and across all the
                products of the Superteam
              </h6>
            </div>
          </div>
        </div>
      </CompanyProfileDetailsLayout>
    </>
  );
}
