import "./SubHeader.scss";
import { Link } from "react-router-dom";
import { getValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { Tooltip } from "react-tooltip";

export default function PipelineSubHeader({
  subHeaderListTitle,
  isRightArrowVisible,
  subMenuArray,
  children,
  pipeline,
  options,
  handleOpenPopup,
  selectedPipeline,
  handleClickPipeline,
  isLoading,
}: any) {
  return (
    <div className={`sub-header-wrapper`}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <ul>
            <li className={`gap d-flex align-items-center cursor-pointer`}>
              <img src="/images/header/star.svg" className="img-fluid" />
              <h6 className={"submenu-name"}>{subHeaderListTitle}</h6>

              {isRightArrowVisible && (
                <img
                  src="/images/header/right-arrow.svg"
                  className="img-fluid"
                />
              )}
            </li>
          </ul>
          {subMenuArray?.length >= 0 && (
            <ul className={"header-wrapper__navigation-list"}>
              {subMenuArray.map((subMenuObj: any, index: any) => {
                return (
                  <li
                    className={"sub-header-wrapper__navigation-list-items"}
                    key={`subMenu-${index}`}
                  >
                    <Link
                      to={subMenuObj.linkUrl}
                      className={`sub-header-wrapper__sub-navigation-title ${
                        window.location.pathname === subMenuObj.linkUrl
                          ? `active`
                          : ""
                      }`}
                    >
                      {subMenuObj.linkText}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {pipeline && (
            <div
              data-tooltip-id="pipeline"
              data-tooltip-content="Create Pipeline"
              data-tooltip-place="bottom"
            >
              <SearchToolTip
                label={"label"}
                value={getValue(selectedPipeline, `id`, "")}
                onChange={handleClickPipeline}
                width={"280px"}
                data={options}
                button_title={"Create Pipeline"}
                handleOpenPopup={handleOpenPopup}
                isLoading={isLoading}
                add
              />
            </div>
          )}
        </div>
        {children}
      </div>
      <Tooltip id={`pipeline`} className="tooltip" />
    </div>
  );
}
