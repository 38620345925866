import StarSvgComponent from "@assets/svg/star";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";

const EditPropertyDetails = () => {
  return (
    <div className="subpipeline-wrapper px-5 py-4">
      <div className="grid-container  row-gap-3 column-gap-3  w-100">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value="Lorem ipsum"
          placeholder="Enter a property name"
          label="Property name"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter official website"
          label="Property official website"
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter Tax identification number "
          label="Tax Identification Number"
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="3"
          placeholder="Enter total no of rooms"
          label="Total room count"
          marginZero
        />
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value=""
          placeholder="Enter property contact name"
          label="Property contact name"
        />
        <div>
          <p className="form-label">Property currency</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="9999988888"
          placeholder="Enter contact number"
          label="Contact number"
          marginZero
        />
        <div>
          <p className="form-label">Time zone</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter a street name"
          label="City"
        />
        <div>
          <p className="form-label">State</p>
          <SearchToolTip />
        </div>
        <div>
          <p className="form-label">Country</p>
          <SearchToolTip />
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter the address"
          label="Address"
        />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          value="424244"
          placeholder="Enter the zip code"
          label="Zip code"
          marginZero
        />
        <div>
          <p className="form-label">Ratings</p>
          <div className="d-flex align-items-center gap-2 mt-2">
            <StarSvgComponent color="#aeaeae" />
            <StarSvgComponent color="#aeaeae" />
            <StarSvgComponent color="#aeaeae" />
            <StarSvgComponent color="#aeaeae" />
            <StarSvgComponent color="#aeaeae" />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="form-label">Upload logo</p>
        <div className="">
          <p
            // htmlFor="file-upload"
            className="custom-file-upload mb-2 requirement-message"
          >
            Choose a <span className="required-file-format">.jpg or .png</span>{" "}
            file
          </p>
          <input
            type="file"
            className="custom-upload-filename"
            accept={".jpg,.jpeg,.png"}
          />
        </div>
      </div>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="description"
          //   value={getValue(request, `description`, "")}
          placeholder="Enter a Description"
          label="Property description"
        />
      </div>
    </div>
  );
};

export default EditPropertyDetails;
