import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import { getValue } from "@utils/lodash";
import { useState, useEffect } from "react";
const AddNodePopup = (props: any) => {
  const { selectedNode, isOpen, setIsOpen, handleModal, addNode } = props;
  const [label, setLabel] = useState(getValue(selectedNode, `data.label`, ""));

  useEffect(() => {
    setLabel(getValue(selectedNode, `data.label`, ""));
  }, [selectedNode]);

  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        handleModal();
      }}
      title={"Add Fields"}
      width={"550px"}
    >
      <div className="px-4 py-3">
        <div className="flow_add_header">
          <p className="small_text__14">Response Cards</p>
        </div>
        <div className="nodes-panel">
          <div className="d-flex flex-wrap">
            <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("send_message")}
                className="chatbot_button"
              >
                Send Message
              </button>
            </div>
            {/* <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("share_link")}
                className="chatbot_button"
              >
                Share Link(s)
              </button>
            </div> */}
          </div>
        </div>
        <div className="flow_add_header">
          <p className="small_text__14">Input Cards</p>
        </div>
        <div className="nodes-panel">
          <div className="d-flex flex-wrap">
            <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("suggestions")}
                className="chatbot_button"
              >
                Buttons
              </button>
            </div>
          </div>
        </div>

        <div className="flow_add_header">
          <p className="small_text__14">Visitor Cards</p>
        </div>
        <div className="nodes-panel">
          <div className="d-flex flex-wrap">
            <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("visitor_name")}
                className="chatbot_button"
              >
                Name
              </button>
            </div>
            <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("visitor_email")}
                className="chatbot_button"
              >
                Email
              </button>
            </div>
            <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("visitor_phone")}
                className="chatbot_button"
              >
                Phone
              </button>
            </div>
            <div className="mx-1 mb-2">
              <button
                onClick={() => addNode("company_name")}
                className="chatbot_button"
              >
                Company
              </button>
            </div>
          </div>
        </div>
      </div>
    </SlideFromRightPopup>
  );
};

export default AddNodePopup;
