import { useState } from "react";
import PropertyDetailsLayout from "../../../../layout/PropertyLayout/property-details-layout";
import AddSvgComponent from "@assets/svg/add";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import ButtonComponent from "@components/Form/Button/Button";
import Popup from "reactjs-popup";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { param } from "lightgallery/plugins/video/lg-video-utils";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import BookingInformation from "../PropertySettings/components/booking-information";
import ReservationTab from "../PropertySettings/components/reservation-tab";
import PerformanceTab from "../PropertySettings/components/performance-tab";
import PropertySettingsDetails from "../PropertySettings";

const PropertyDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [activeTab, setActiveTab] = useState(1);

  const [openBookingInfo, setOpenBookignInfo] = useState(false);
  const toggleBookingInfo = () => {
    setOpenBookignInfo(!openBookingInfo);
  };

  const [openCheckin, setopenCheckin] = useState(false);
  const toggleCheckin = () => {
    setopenCheckin(!openCheckin);
  };

  const [openMagicLink, setOpenMagicLink] = useState(false);
  const toggleMagicLink = () => {
    setOpenMagicLink(!openMagicLink);
  };

  const [activeHeaderTab, setActiveHeaderTab] = useState("1");

  const views = [
    {
      id: "1",
      name: "Reservations",
    },
    {
      id: "2",
      name: "Performance",
    },
  ];

  const handleClick = () => {
    
  }

  return (
    <PropertyDetailsLayout setActiveTab={setActiveTab}>
      <div className="manageFieldRight w-100 ">
        {activeTab === 1 ? (
          <>
            <div className="position-sticky top-0 pb-3">
              <Nav tabs>
                {views.map((item, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={`d-flex justify-content-between align-items-center ${
                        activeHeaderTab == item.id ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveHeaderTab(item.id);
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
            <div className="px-5 pt-3 pb-5 property-container">
              <div className="row">
                <div className="col-9">
                  {activeHeaderTab === "1" && (
                    <ReservationTab
                      toggleCheckin={toggleCheckin}
                      toggleMagicLink={toggleMagicLink}
                    />
                  )}
                  {activeHeaderTab === "2" && <PerformanceTab />}
                </div>
                <div className="col-3">
                  <div className="border rounded-3 p-3">
                    <h3 className="header_text__16 text-center mb-3">
                      Availability Today
                    </h3>
                    <table className="w-100">
                      <tr className="small_text__14 ">
                        <td>Room category</td>
                        <td className="text-end">Inventory</td>
                        <td className="text-end">Base price</td>
                      </tr>
                      <tr>
                        <td className="p-1">Ac</td>
                        <td className="text-end p-1">0</td>
                        <td className="text-end p-1">₹ 1999</td>
                      </tr>
                    </table>
                  </div>
                  <div className="border rounded-3 p-3 mt-3 d-flex flex-column gap-2 align-items-center">
                    <h3 className="header_text__16 text-center ">
                      Get 20% more direct bookings
                    </h3>
                    <p className="small_text__14 text-center mb-1">
                      Your direct rates on google is low compared to OTAs. Click
                      below to enable 7% discount on direct bookings.
                    </p>
                    <ButtonComponent
                      buttonType="primary"
                      buttonText="Get more direct bookings"
                      onClickHandler={handleClick}
                    />
                  </div>
                  <div className="border rounded-3 p-3 mt-3 d-flex flex-column gap-2 align-items-center">
                    <h3 className="header_text__16 text-center ">
                      Reduce booking.com cancellations
                    </h3>
                    <p className="small_text__14 mb-1 text-center">
                      SuperCRM has launched an innovative payment automation
                      system. It holds bookings with invalid cards, collects
                      payments, and confirms bookings upon successful payment.
                    </p>
                    <ButtonComponent
                      buttonType="primary"
                      buttonText="Enable flex connect"
                      onClickHandler={handleClick}
                    />
                  </div>
                  <div className="border rounded-3 p-3 mt-3 d-flex flex-column gap-2 align-items-center">
                    <h3 className="header_text__16 text-center ">
                      Upgrade to SuperCRM Empower
                    </h3>
                    <p className="small_text__14 mb-1 text-center">
                      Revenue management service by SuperCRM to boost your
                      online bookings by 30%.
                    </p>
                    <ButtonComponent
                      buttonType="primary"
                      buttonText="Request demo"
                      onClickHandler={handleClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : activeTab === 2 ? (
          <>
            <div className="other-content subheader_filter_section open px-4">
              <div className="filter-wrapper d-flex align-items-center justify-content-between my-1 mx-4">
                <div className="d-flex gap-3 align-items-center w-100">
                  <label className={"switch"}>
                    <input type="checkbox" />
                    <span className={`${"slider"} ${`round`} d-flex`}></span>
                  </label>
                  <div className="w-25">
                    <SearchToolTip placeholder="Filters" />
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <button
                    className=" create-user-button create-user-button--department hover-text touchable-text-white ps-3 pe-4 py-2 gap-1 "
                    onClick={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/property/details/${getValue(
                          params,
                          `id`,
                          ""
                        )}/create/walk-in`
                      )
                    }
                  >
                    <AddSvgComponent color="#fff" size="18" />
                    Create
                  </button>
                </div>
                <div></div>
              </div>
            </div>
            <div className="p-5">
              <ButtonComponent
                buttonType={"primary"}
                buttonText={"Booking information"}
                onClickHandler={toggleBookingInfo}
              />
            </div>
          </>
        ) : (
          <PropertySettingsDetails />
        )}
      </div>
      <Modal isOpen={openCheckin} toggle={toggleCheckin}>
        <section className="add-popup-wrapper">
          <ModalHeader>
            <h6 className="header_text__16">
              The rooms is dirty, do you want to proceed for checkin?
            </h6>
          </ModalHeader>
          <ModalFooter>
            <div className="popup-submit-container">
              <ButtonComponent
                onClickHandler={toggleCheckin}
                buttonType={"scecondary"}
                buttonText="Cancel"
              />
              &nbsp;&nbsp;&nbsp;
              <ButtonComponent
                buttonType={"primary"}
                buttonText={"Continue"}
                onClickHandler={toggleCheckin}
              />
            </div>
          </ModalFooter>
        </section>
      </Modal>
      <Modal isOpen={openMagicLink} toggle={toggleMagicLink}>
        <section className="add-popup-wrapper">
          <ModalHeader>
            <h6 className="header_text__16">Send magic link</h6>
          </ModalHeader>
          <ModalFooter>
            <div className="popup-submit-container">
              <ButtonComponent
                onClickHandler={toggleMagicLink}
                buttonType={"scecondary"}
                buttonText="Cancel"
              />
              &nbsp;&nbsp;&nbsp;
              <ButtonComponent
                buttonType={"primary"}
                buttonText={"Continue"}
                onClickHandler={toggleMagicLink}
              />
            </div>
          </ModalFooter>
        </section>
      </Modal>
      <CommonRightPopup
        isPopupVisible={openBookingInfo}
        toggle={toggleBookingInfo}
        width="840px"
        title="Booking information"
        hideNew
        second_title="Save"
      >
        <BookingInformation />
      </CommonRightPopup>
    </PropertyDetailsLayout>
  );
};

export default PropertyDetails;
