import CheckCircleSvgComponent from "@assets/svg/check-circle";
import InfoSvgComponent from "@assets/svg/info";
import WarningSvgComponent from "@assets/svg/warning";
import {
  handleBooleanArrayChange,
  handleSelectArrayChange,
} from "@common/handleChange";
import { getImages } from "@common/image-helper";
import { formatCamelString, formatType } from "@common/text-helpers";
import { formatString } from "@common/text-helpers";
import IconOnHover from "@components/common/Tooltip/icon-on-hover";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import HeaderTooltip from "@components/custom/Dropdown/HeaderTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";

function ImportStep4(props: any) {
  const { info, setInfo } = props;
  const handleSelectChange = (
    i: number,
    e: any,
    key: string,
    setObject: any
  ) => {
    setObject((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            custom: getValue(e, `custom`, false),
            // field: getValue(e, `label`, ""),
            is_mapped: true,
            lookup_api_name: getValue(e, `lookup_api_name`, ""),
            mapped_field_label: getValue(e, `label`, ""),
            mapped_field_name: getValue(e, `name`, ""),
            mapped_field_type: getValue(e, `field_type`, ""),
            required: getValue(e, `required`, false),
          };
        }),
      };
    });
  };

  const handleSelectChangeOnRemove = (
    i: number,
    e: any,
    key: string,
    setObject: any
  ) => {
    setObject((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            custom: false,
            // field: getValue(e, `label`, ""),
            is_mapped: false,
            lookup_api_name: "",
            mapped_field_label: "",
            mapped_field_name: "",
            mapped_field_type: "",
            required: false,
          };
        }),
      };
    });
  };
  const [mappedFields, setMappedFields] = useState<any>([]);
  useEffect(() => {
    if (getValue(info, `column_mapping.length`, 0) > 0) {
      let list = getValue(info, `column_mapping`, []).filter((item: object) =>
        getValue(item, `mapped_field_name`, "")
      );
      let mappedFields =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `mapped_field_name`, ""))
          : [];
      setMappedFields(mappedFields);
    }
  }, [info]);
  return (
    <div>
      <div className="contact-type w-100 p-5">
        {/* <ProgressBar currentStep={currentStep} /> */}
        <h3>Review the mapping of your fields</h3>
        <p className="mapping-description">
          We've mapped the columns in your file to fields in SuperCRM—take a
          look
        </p>
        <table className="imported-file-details">
          <tr>
            <th className="text-center">Import</th>
            <th className="">
              {formatType(getValue(props, `uploadedFileInfo.extension`, ""))}{" "}
              Column
            </th>
            <th className="">Mapped</th>
            <th className="text-center">SuperCRM field</th>
            <th className="" style={{ paddingLeft: "3rem" }}>
              Field type
            </th>
          </tr>
          <tbody>
            {getValue(info, `column_mapping`, []).map(
              (item: object, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="email-checkbox mt-3 mb-3 justify-content-center">
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={getValue(item, `is_mapped`, false)}
                            onChange={() => {
                              handleSelectChangeOnRemove(
                                index,
                                null,
                                "column_mapping",
                                setInfo
                              );
                            }}
                            disabled={!getValue(item, `is_mapped`, false)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </td>
                    <td >
                      <p className="text-center imported-name-tag">
                      {getValue(item, `field`, "")}{" "}
                      {getValue(item, `required`, false) && (
                        <span className="color_danger small_text__16">*</span>
                      )}
                      </p>
                      <p className="imported-user-name text-left mt-2">
                        {getValue(item, `first_row_value`, "")}
                      </p>
                    </td>
                    <td>
                      {getValue(item, `is_mapped`, false) ? (
                        <IconOnHover
                          header={
                            <CheckCircleSvgComponent
                              size={22}
                              color={
                                getValue(item, `is_mapped`, false)
                                  ? "rgb(0, 168, 134)"
                                  : "lightgray"
                              }
                            />
                          }
                        />
                      ) : (
                        <TooltipOnHover
                          header={
                            <WarningSvgComponent
                              size={18}
                              color={
                                getValue(item, `is_mapped`, false)
                                  ? "#4cc793"
                                  : "lightgray"
                              }
                            />
                          }
                          text={
                            "This column is not mapped to a SuperCRM field; its data wont get imported"
                          }
                          show={
                            getValue(item, `is_mapped`, false) ? true : false
                          }
                          height={"40px"}
                          width={"250px"}
                        />
                      )}
                    </td>
                    <td>
                      <SearchToolTip
                        label="label"
                        data={
                          getValue(info, `system_fields.length`, 0) > 0
                            ? !getValue(item, `is_mapped`, false)
                              ? getValue(info, `system_fields`, []).filter(
                                  (item: object) =>
                                    !mappedFields.includes(
                                      getValue(item, `name`, "")
                                    )
                                )
                              : getValue(info, `system_fields`, [])
                            : []
                        }
                        selectKey={"value"}
                        value={getValue(item, `mapped_field_name`, "")}
                        onChange={(e: object) => {
                          getValue(e, `value`, "")
                            ? handleSelectChange(
                                index,
                                e,
                                "column_mapping",
                                setInfo
                              )
                            : handleSelectChangeOnRemove(
                                index,
                                e,
                                "column_mapping",
                                setInfo
                              );
                        }}
                      />
                    </td>
                    <td className="import-text-field">
                      <div
                        className=" d-flex align-items-center"
                        style={{ paddingLeft: "3rem" }}
                      >
                        {getImages({
                          name: getValue(item, `mapped_field_type`, ""),
                          size: 20,
                        })}
                        <p className="ms-2">
                          {formatString(
                            getValue(item, `mapped_field_type`, "")
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ImportStep4;
