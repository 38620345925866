import { useEffect, useMemo, useRef, useState } from "react";
import SettingsModuleSubHeader from "../common/SubHeader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import { toast } from "sonner";
import {
  allPipelines,
  updatePipelineFields,
  updatePipelineFormFields,
  updatePipelineInfo,
  updatePipelineStages,
  updatePositionPipelineFormFields,
} from "@services/pipeline.service";
import { getSpecificPipeline } from "@services/pipeline.service";
import SettingsEditInfoPopup from "../Info/EditInfoPopup";
import { getModuleFields } from "@services/module-fields.service";
import { formatString, sortJSONObjectArray } from "@common/text-helpers";
import { getSpecificModule, listAllModules } from "@services/modules.service";
// import CommonAddPipeline from "@components/common/Pipeline/AddPipeline";
import SimpleReactValidator from "simple-react-validator";
import { intialPipelineState } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import Header from "@components/common/Header/LoginHeader/header";
import CommonAddPipeline from "@components/Pages/Pipeline/AddPipeline";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useStateContext } from "@context/profileProvider";
import { getAllModuleFields } from "@services/module-fields.service";
import { removeDuplicatesById } from "@components/helpers/request-helper";
// import { intialPipelineState } from "@components/common/Pipeline/helpers/create-pipeline-helper";

const SettingModuleHOCComponent = (OriginalComponent: any) => {
  function NewComponent(props: any) {
    //render OriginalComponent and pass on its props.
    const mainParams = useParams();
    const navigate = useNavigate();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const UrlParams = Object.fromEntries(urlSearchParams.entries());
    const { selectedModuleId } = useStateContext();
    /* -------------------------------------------------------------------------- */
    /*                               UseEffect Section                            */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (Object.keys(params).length === 0) {
        if (
          getValue(mainParams, `code`, "") !== "tasks" &&
          getValue(mainParams, `code`, "") !== "calls" &&
          getValue(mainParams, `code`, "") !== "meetings"
        ) {
          getAllPipelines();
        } else {
          getTaskFields();
          setIsLoading(false);
          setOptionLoading(false);
        }
      }
      getModuleInfo();
    }, []);

    useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (Object.keys(params).length > 0) {
        if (
          getValue(mainParams, `code`, "") !== "tasks" &&
          getValue(mainParams, `code`, "") !== "calls" &&
          getValue(mainParams, `code`, "") !== "meetings"
        ) {
          getAllPipelines();
        } else {
          getTaskFields();
        }
      }
    }, [window.location.href]);
    useEffect(() => {
      if (
        getValue(moduleInfo, `name`, "") !== getValue(mainParams, `code`, "")
      ) {
        if (
          getValue(mainParams, `code`, "") !== "tasks" &&
          getValue(mainParams, `code`, "") !== "calls" &&
          getValue(mainParams, `code`, "") !== "meetings"
        ) {
          getAllPipelines();
        } else {
          getTaskFields();
        }
        getModuleInfo();
      }
    }, [getValue(mainParams, `code`, "")]);

    /* -------------------------------------------------------------------------- */
    /*                               API Section                                  */
    /* -------------------------------------------------------------------------- */

    const [moduleInfo, setModuleInfo] = useState({});
    const getModuleInfo = async () => {
      try {
        let resp = await getSpecificModule(getValue(mainParams, `code`, ""));
        if (resp) {
          setModuleInfo(getValue(resp, `data`, {}));
        }
      } catch (error) {}
    };
    const [selectedPipeline, setSelectedPipeline] = useState("");
    /**
     *
     * Get all Module pipelines
     * @requires
     *
     */

    const [isLoading, setIsLoading] = useState(true);
    const [pipelines, setAllPipelines] = useState([]);
    const [options, setOptions] = useState([]);
    const getAllPipelines = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      try {
        setIsLoading(true);
        let payload = {
          module_name: getValue(mainParams, `code`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        let module_list = await listAllModules("");
        let code =
          getValue(module_list, `data.length`, 0) > 0
            ? getValue(module_list, `data`, []).find(
                (item: object) =>
                  getValue(item, `api_name`, "") ==
                  getValue(mainParams, `code`, "")
              )
            : [];
        let resp = await allPipelines(getValue(code, `id`, ""), queryRequest);
        if (getValue(resp, `data.length`, 0) > 0) {
          let list = getValue(resp, `data`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
          }));
          setOptions(list);
          if (getValue(params, `pipeline`, "")) {
            setSelectedPipeline(getValue(params, `pipeline`, ""));
            getData(getValue(params, `pipeline`, ""));
          } else {
            setSelectedPipeline(getValue(list, `[${0}].id`, {}));
            getData(getValue(list, `[${0}].id`, {}));
          }
          setAllPipelines(getValue(resp, `data`, []));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    /**
     *
     * Get selected pipeline
     * @requires id
     *
     */
    const [optionLoading, setOptionLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState({});
    const getData = async (id: string) => {
      try {
        setOptionLoading(true);
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let selectedFields = getValue(
            resp,
            `data.form_fields`,
            []
          ).filter((item: any) =>
            getValue(item, `module_field.form_default`, false)
          );

          let list = selectedFields.map((item: object) => ({
            ...item,
            ...getValue(item, `module_field`, {}),
          }));
          // getPredefinedModuleFields(selectedFields);
          getPredefinedModuleFields(getValue(resp, `data.form_fields`, []));
          setSelectedOption(getValue(resp, `data`, {}));
          setRequest({
            ...request,
            name: getValue(resp, `data.api_name`, ""),
            label: getValue(resp, `data.label`, ""),
          });

          let openStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => getValue(item, `type`, "") === "OPEN"
          );
          let closedWonStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
          );
          let closedLostStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
          );
          // setValue(stageRequest, `openStages`, openStages);
          // setValue(stageRequest, `closedWonStages`, closedWonStages);
          // setValue(stageRequest, `closedLostStages`, closedLostStages);
          setStageRequest({
            ...stageRequest,
            openStages: openStages,
            closedWonStages: closedWonStages,
            closedLostStages: closedLostStages,
          });
          setStageRequestDirty({
            ...stageRequestDirty,
            openStages: openStages,
            closedWonStages: closedWonStages,
            closedLostStages: closedLostStages,
          });
          setPipelineRequest({
            ...pipelineRequest,
            fields: removeDuplicatesById(list),
          });
          setOptionLoading(false);
        } else {
          setOptionLoading(false);
        }
      } catch (error) {
        setOptionLoading(false);
      }
    };
    const getDataWithNoLoading = async (id: string) => {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let selectedFields = getValue(resp, `data.form_fields`, []).map(
            (item: any) => item.name
          );
          // getPredefinedModuleFields(selectedFields);
          getPredefinedModuleFields(getValue(resp, `data.form_fields`, []));
          setSelectedOption(getValue(resp, `data`, {}));
          setRequest({
            ...request,
            name: getValue(resp, `data.name`, ""),
            label: getValue(resp, `data.label`, ""),
          });

          let openStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => getValue(item, `type`, "") === "OPEN"
          );
          let closedWonStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
          );
          let closedLostStages = getValue(resp, `data.stages`, []).filter(
            (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
          );
          // setValue(stageRequest, `openStages`, openStages);
          // setValue(stageRequest, `closedWonStages`, closedWonStages);
          // setValue(stageRequest, `closedLostStages`, closedLostStages);
          setStageRequest({
            ...stageRequest,
            openStages: openStages,
            closedWonStages: closedWonStages,
            closedLostStages: closedLostStages,
          });
          setStageRequestDirty({
            ...stageRequestDirty,
            openStages: openStages,
            closedWonStages: closedWonStages,
            closedLostStages: closedLostStages,
          });
          setOptionLoading(false);
        } else {
          setOptionLoading(false);
        }
      } catch (error) {
        setOptionLoading(false);
      }
    };
    const [stageRequest, setStageRequest] = useState({
      openStages: [
        {
          name: "",
          type: "OPEN",
        },
      ],
      closedWonStages: [
        {
          name: "",
          type: "CLOSED_WON",
        },
      ],
      closedLostStages: [
        {
          name: "",
          type: "CLOSED_LOST",
        },
      ],
    });
    const [stageRequestDirty, setStageRequestDirty] = useState({
      openStages: [
        {
          name: "",
          type: "OPEN",
        },
      ],
      closedWonStages: [
        {
          name: "",
          type: "CLOSED_WON",
        },
      ],
      closedLostStages: [
        {
          name: "",
          type: "CLOSED_LOST",
        },
      ],
    });

    const [request, setRequest] = useState({
      name: "",
      label: "",
    });

    const [selectedFields, setSelectedFields] = useState<any>([]);
    const [unUsedFields, setUnUsedFields] = useState<any>([]);
    const [allFields, setAllFields] = useState([]);
    const getPredefinedModuleFields = async (selectedFields: any) => {
      try {
        let payload = {
          // module_name: getValue(mainParams, `code`, ""),
          module_id: getValue(mainParams, `id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getModuleFields(getValue(mainParams, `id`, ""));
        if (resp) {
          //form fields

          const arr = getValue(resp, `data.module_fields`, [])
            .map((item2: any) => {
              const matchingItem = selectedFields.find(
                (item1: any) =>
                  getValue(item1, `module_field.api_name`, "") ===
                  item2.api_name
              );
              if (matchingItem) {
                return {
                  ...item2,
                  form_field_id: getValue(matchingItem, `id`, ""),
                  required: getValue(matchingItem, "required", false),
                  seq_num: getValue(matchingItem, "seq_num", false),
                };
              }
              return null; // Return null for non-matching items
            })
            .filter((item: any) => item !== null); // Filter out null items
          // module fields
          const moduleFields = getValue(resp, `data.module_fields`, []);
          const matchedItems: any = [];
          const nonMatchedItems: any = [];

          moduleFields.forEach((item2: any) => {
            const matchingItem = selectedFields.find(
              (item1: any) => item1.api_name === item2.api_name
            );
            if (matchingItem) {
              matchedItems.push({
                ...item2,
                required: getValue(matchingItem, "required", false),
                seq_num: getValue(matchingItem, "seq_num", false),
                form_field: true,
                // label: getValue(item2, `label`, ""),
              });
            } else {
              nonMatchedItems.push(item2);
            }
          });
          // Sort both arrays alphabetically by name
          matchedItems.sort((a: any, b: any) =>
            a.api_name.localeCompare(b.api_name)
          );
          nonMatchedItems.sort((a: any, b: any) =>
            a.api_name.localeCompare(b.api_name)
          );

          // Concatenate the two arrays with matched items at the top
          const sortedModuleFields = matchedItems.concat(nonMatchedItems);
          setAllFields(sortedModuleFields);
          setSelectedFields(sortJSONObjectArray(arr, "seq_num"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getTaskFields = async () => {
      try {
        let module_list = await listAllModules("");
        let code =
          getValue(module_list, `data.length`, 0) > 0
            ? getValue(module_list, `data`, []).find(
                (item: object) =>
                  getValue(item, `api_name`, "") ==
                  getValue(mainParams, `code`, "")
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        getPredefinedModuleFields(getValue(resp, `data`, []));
      } catch (error) {}
    };
    /* -------------------------------------------------------------------------- */
    /*                        Pipeline Helper section                             */
    /* -------------------------------------------------------------------------- */
    const findSelectedPipeline = (id: string) => {
      let list =
        getValue(options, `length`, 0) > 0
          ? options.filter((item: object) => getValue(item, `id`, "") === id)
          : [];
      return getValue(list, `length`, 0) > 0
        ? getValue(list, `[${0}]`, "")
        : {};
    };
    const getSelectedPipeline = useMemo(
      () => findSelectedPipeline(selectedPipeline),
      [selectedPipeline]
    );
    const handleChangePipeline = (e: any) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      delete params.pipeline;
      let payload = {
        ...params,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    };
    const handleClickPipeline = (option: any) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let payload = {
        ...params,
        pipeline: getValue(option, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    };
    const handleNavigate = (value: string) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const urlParams = Object.fromEntries(urlSearchParams.entries());
      let payload = {
        ...urlParams,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(
        `/${getValue(
          mainParams,
          `orgId`,
          ""
        )}/settings/module-and-field/manage-fields/${value}/${getValue(
          mainParams,
          `id`,
          ""
        )}/${getValue(mainParams, `code`, "")}?${queryRequest}`
      );
    };
    const swapObjects = (
      indexA: number,
      indexB: number,
      data: any,
      setData: any
    ) => {
      const newData = [...data];
      [newData[indexA], newData[indexB]] = [newData[indexB], newData[indexA]];
      setData(newData);
    };
    //for one array
    const handleOnDragEnd = async (result: any) => {
      if (!result.destination) return;
      const { source, destination } = result;
      const sourceArrayCopy = [...selectedFields];
      // Moving from same area in top to botomm direction

      if (source.droppableId === destination.droppableId) {
        if (source.droppableId === "source") {
          // swapObjects(
          //   source.index,
          //   destination.index,
          //   selectedFields,
          //   setSelectedFields
          // );
          const [removed] = sourceArrayCopy.splice(source.index, 1);
          sourceArrayCopy.splice(destination.index, 0, removed);
          setSelectedFields(sourceArrayCopy);
          let payload = {
            form_fields:
              getValue(sourceArrayCopy, `length`, 0) > 0
                ? sourceArrayCopy.map((item: any, index: number) => ({
                    id: getValue(item, `form_field_id`, ""),
                    // api_name: getValue(item, `api_name`, ""),
                    seq_num: index + 1,
                    required: getValue(item, `required`, false),
                  }))
                : [],
          };
          let resp = await updatePositionPipelineFormFields(
            getValue(selectedOption, `id`, ""),
            getValue(selectedOption, `module_id`, ""),
            payload
          );
          if (resp) {
            toast.success("Position Change Successful");
          }
        }
      }
    };

    /**
     *
     * Update pipelines
     * @requires
     *
     */

    const [submitLoading, setSubmitLoading] = useState(false);
    const handleSubmit = async () => {
      let main = window.location.pathname.split("/");
      let tab = main[main.length - 3];
      let resp;
      let payload = {};
      try {
        if (tab == "info") {
          setSubmitLoading(true);
          payload = {
            // name: request.name,
            label: request.label,
          };
          resp = await updatePipelineInfo(
            getValue(selectedOption, `module_id`, ""),
            getValue(selectedOption, `id`, ""),
            payload
          );
          if (resp) {
            toast.success("Updated successfully");
            getAllPipelines();
            setSubmitLoading(false);
          } else {
            setSubmitLoading(false);
          }
        }
        if (tab == "stages") {
          setSubmitLoading(true);
          let stages = [].concat.apply(
              [],
              [
                getValue(stageRequest, `openStages`, []).map(
                  (item: object, index: number) => ({
                    ...item,
                    seq_num: index + 1,
                    name: getValue(item, `name`, ""),
                    // required: false,
                  })
                ),
                getValue(stageRequest, `closedLostStages`, []).map(
                  (item: object, index: number) => ({
                    ...item,
                    seq_num: index + 1,
                    name: getValue(item, `name`, ""),
                    // required: false,
                  })
                ),
                getValue(stageRequest, `closedWonStages`, []).map(
                  (item: object, index: number) => ({
                    ...item,
                    seq_num: index + 1,
                    name: getValue(item, `name`, ""),

                    // required: false,
                  })
                ),
              ]
            ),
            payload = stages;
          resp = await updatePipelineStages(
            getValue(selectedOption, `module_id`, ""),
            selectedPipeline,
            getValue(selectedOption, `id`, ""),
            payload
          );
          if (resp) {
            toast.success("Updated successfully");
            getAllPipelines();
            setSubmitLoading(false);
          } else {
            setSubmitLoading(false);
          }
        }
        if (tab == "fields") {
          setSubmitLoading(true);
          const removeDuplicates = () => {
            const filteredData = selectedFields.filter(
              (item: any, index: number) => {
                return (
                  selectedFields.findIndex(
                    (obj: any) => obj.name === item.name
                  ) === index
                );
              }
            );

            return filteredData ? filteredData : [];
          };
          payload = {
            form_fields:
              getValue(removeDuplicates(), `length`, 0) > 0
                ? removeDuplicates().map((item: any, index: number) => ({
                    id: getValue(item, `id`, ""),
                    seq_num: index + 1,
                    required: getValue(item, `required`, false),
                  }))
                : [],
          };
          resp = await updatePipelineFields(
            getValue(selectedOption, `module_id`, ""),
            payload
          );
          if (resp) {
            toast.success("Updated successfully");
            getAllPipelines();
            setSubmitLoading(false);
          } else {
            setSubmitLoading(false);
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    };

    const [infoLoading, setInfoLoading] = useState(false);
    const handleSubmitInfo = async () => {
      let resp;
      let payload = {};
      try {
        setInfoLoading(true);
        payload = {
          // name: request.name,
          label: request.label,
        };
        resp = await updatePipelineInfo(
          getValue(selectedOption, `module_id`, ""),
          getValue(selectedOption, `id`, ""),
          payload
        );
        if (resp) {
          toast.success("Updated successfully");
          setInfoLoading(false);
          setIsOpen(false);
          getAllPipelines();
        } else {
          setInfoLoading(false);
        }
      } catch (error) {
        setInfoLoading(false);
      }
    };

    const handleCancel = () => {};

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
      setIsOpen(!isOpen);
    };
    /* -------------------------------------------------------------------------- */
    /*                          Create Pipline Section                            */
    /* -------------------------------------------------------------------------- */
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState(0);
    const [isOpenPipeline, setIsOpenPipeline] = useState(false);
    const togglePipeline = () => {
      setIsOpenPipeline(!isOpenPipeline);
    };
    const [pipelineRequest, setPipelineRequest] = useState<any>(intialPipelineState);
    return (
      <div>
        <HomeHeader />
        <SettingsModuleSubHeader
          subHeaderTitle={`Manage ${formatString(
            getValue(mainParams, `code`, "")
          )} Fields`}
          linkAddress={`/${getValue(
            mainParams,
            `orgId`,
            ""
          )}/settings/module-and-field`}
          pipeline={
            getValue(mainParams, `code`, "") !== "tasks" &&
            getValue(mainParams, `code`, "") !== "calls" &&
            getValue(mainParams, `code`, "") !== "meetings"
              ? true
              : false
          }
          options={options}
          optionLoading={optionLoading}
          selectedOption={selectedOption}
          selectedPipeline={getSelectedPipeline}
          handleChangePipeline={handleChangePipeline}
          handleClickPipeline={handleClickPipeline}
          hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
          //submit section
          isSubmitVisible={false}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitLoading={submitLoading}
          moduleInfo={moduleInfo}
          togglePipeline={togglePipeline}
          toggle={toggle}
          {...mainParams}
        />
        <div>
          <OriginalComponent
            isLoading={optionLoading}
            loading={isLoading}
            //navigate
            handleNavigate={handleNavigate}
            //info module
            request={request}
            setRequest={setRequest}
            params={mainParams}
            //stage
            stageRequest={stageRequest}
            stageRequestDirty={stageRequestDirty}
            setStageRequest={setStageRequest}
            //fields
            allFields={allFields}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            unUsedFields={unUsedFields}
            handleOnDragEnd={handleOnDragEnd}
            getData={getDataWithNoLoading}
            selectedPipeline={selectedPipeline}
            selectedOption={selectedOption}
            handleSubmit={handleSubmit}
            getTaskFields={getTaskFields}
          />
        </div>
        <SettingsEditInfoPopup
          isOpen={isOpen}
          toggle={toggle}
          request={request}
          setRequest={setRequest}
          handleSubmit={handleSubmitInfo}
          isLoading={infoLoading}
        />

        {/* ------------------------- Creating Pipelines --------------------------------- */}
        <CommonAddPipeline
          isOpen={isOpenPipeline}
          toggle={togglePipeline}
          title={`Create ${formatString(
            getValue(mainParams, `code`, "")
          )} Pipeline`}
          simpleValidator={simpleValidator}
          forceUpdate={forceUpdate}
          request={pipelineRequest}
          setRequest={setPipelineRequest}
          module_id={getValue(selectedOption, `module_id`, "")}
          module={getValue(mainParams, `code`, "")}
        />
      </div>
    );
  }
  return NewComponent;
};
export default SettingModuleHOCComponent;
