import { getValue } from "@utils/lodash";
import InlineEditTooltipComponent from "@components/Builder/InlineFormBuilder/EditFields";
import { CopyToClipboard, concatString } from "@common/text-helpers";
import CopySvgComponents from "@assets/svg/copy";
// import InlineEditTooltipComponent from "@components/Custom/InlineComponents/Tooltip/EditFields";

function PendingLoanComponentContacts(props: any) {
  const { submitLoading, handleSubmit, popupFields, setPopupFields } = props;
  return (
    <div className={"form-container"}>
      <div className="d-flex align-items-center justify-content-between detail_header_container pt-2 pb-2">
        <div className="">
          {!getValue(props, `request.first_name`, "") &&
            !getValue(props, `request.last_name`, "") && (
              <h6 className={"form-container_name-text"}>Unknown</h6>
            )}
          <h6 className={"form-container_name-text"}>
            {getValue(props, `request.first_name`, "")}{" "}
            {getValue(props, `request.last_name`, "")}
          </h6>
          {getValue(props, `request.email`, "") && (
            <p className="text-left small_text__14 mb-2 d-flex align-items-center mt-1">
              <span className="">
                {concatString(getValue(props, `request.email`, ""), 30)}
              </span>
              <span
                className="ms-2 cursor-pointer"
                onClick={() =>
                  CopyToClipboard(getValue(props, `request.email`, ""))
                }
              >
                <CopySvgComponents size={18} color={"gray"} />
              </span>
            </p>
          )}
        </div>
        <div
        // className={"pending-wrapper-hover_edit_section"}
        >
          <InlineEditTooltipComponent
            position={"right top"}
            popupFields={popupFields}
            setPopupFields={setPopupFields}
            width={"450px"}
            submitLoading={submitLoading}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default PendingLoanComponentContacts;
