import { getValue } from "@utils/lodash";
import { Link, useLocation, useParams } from "react-router-dom";
import CompanyProfileSvgComponent from "assets/svg/company-profile";
import BrandingSvgComponent from "assets/svg/branding";
import AppBrandingSvgComponent from "assets/svg/app-branding";
import ModulesAndFieldsSvgComponent from "assets/svg/modules-fields";
import PersonSvgComponent from "assets/svg/person";
import EmailSvgComponent from "assets/svg/email";
import { useStateContext } from "context/profileProvider";
import DataAdministrationSvgComponent from "@assets/svg/data";
import ChatSvgComponent from "@assets/svg/chat";
import BroadcastSvgComponent from "@assets/svg/broadcast";
import AdsSvgCompnent from "@assets/svg/ads";
import FileSvgComponent from "@assets/svg/file";
import ChatbotSvgComponent from "@assets/svg/chatbot";
export default function CompanyProfileNavigation() {
  const params = useParams();
  const location = useLocation();
  let selectedURL = location.pathname.split("/").pop();
  const CompanyNavigationListArray = [
    {
      title: "General",
      linkArray: [
        {
          linkTitle: "Company Profile",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/company-profile`,
          path: "company-profile",
          image_url: "/images/company_profile.svg",
        },
        {
          linkTitle: "Branding",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/branding`,
          path: "branding",
          image_url: "/images/branding.svg",
        },
        // {
        //   linkTitle: "App Branding",
        //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/app-branding`,
        //   path: "app-branding",
        //   image_url: "/images/app_branding.svg",
        // },
      ],
    },
    {
      title: "Customization",
      linkArray: [
        {
          linkTitle: "Modules and Fields",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/settings/module-and-field`,
          path: "module-and-field",
          image_url: "/images/modules_fields.svg",
        },
        // {
        //   linkTitle: "Email Templates",
        //   linkUrl: `/${getValue(
        //     params,
        //     `orgId`,
        //     ""
        //   )}/settings/email-templates/service`,
        //   path: "service",
        // },
        // {
        //   linkTitle: "Defaults",
        //   linkUrl: "#",
        // },
      ],
    },

    // {
    //   title: "Accounts and Billing",
    //   linkArray: [
    //     {
    //       linkTitle: "Company Profile",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/company-profile`,
    //     },
    //     {
    //       linkTitle: "Branding",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/branding`,
    //     },
    //     {
    //       linkTitle: "App Branding",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/app-branding`,
    //     },
    //   ],
    // },
    {
      title: "Organisation",
      linkArray: [
        {
          linkTitle: "Users",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/users`,
          path: "users",
          image_url: "/images/users.svg",
        },
      ],
    },
    {
      title: "Settings",
      linkArray: [
        {
          linkTitle: "Emails",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/emails/config`,
          path: "config",
          image_url: "/images/emails.svg",
        },
        {
          linkTitle: "Workflows",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/workflows`,
          path: "workflows",
          image_url: "/images/emails.svg",
        },
        {
          linkTitle: "Data Administration",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/settings/data-administration-exports`,
          path: "data-administration-exports",
          image_url: "/images/emails.svg",
        },

        {
          linkTitle: "Forms",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/forms`,
          path: "forms",
          image_url: "/images/forms.svg",
        },
      ],
    },
    {
      title: "Marketing",
      linkArray: [
        {
          linkTitle: "Ads",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/ads`,
          path: "ads",
          image_url: "/images/ads.svg",
        },
        {
          linkTitle: "Messages",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/messages`,
          path: "messages",
          image_url: "/images/emails.svg",
        },
        // {
        //   linkTitle: "Chatbot",
        //   linkUrl: `/${getValue(params, `orgId`, "")}/chatbot`,
        //   path: "chatbot",
        //   image_url: "/images/emails.svg",
        // },
      ],
    },
    // {
    //   title: "Customer Portal",
    //   linkArray: [
    //     {
    //       linkTitle: "Company Profile",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/company-profile`,
    //     },
    //     {
    //       linkTitle: "Branding",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/branding`,
    //     },
    //     {
    //       linkTitle: "App Branding",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/app-branding`,
    //     },
    //   ],
    // },
    // {
    //   title: "Apps",
    //   linkArray: [
    //     {
    //       linkTitle: "Enable Apps",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/company-profile`,
    //     },
    //   ],
    // },
    // {
    //   title: "Notifications",
    //   linkArray: [
    //     {
    //       linkTitle: "Email",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/company-profile`,
    //     },
    //     {
    //       linkTitle: "Desktop",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/branding`,
    //     },
    //     {
    //       linkTitle: "Other Apps",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/app-branding`,
    //     },
    //     {
    //       linkTitle: "Mobile App",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/app-branding`,
    //     },
    //   ],
    // },
  ];
  const getImages = (name: string, color: string) => {
    switch (name) {
      case "company-profile":
        return <CompanyProfileSvgComponent color={color} />;
      case "branding":
        return <BrandingSvgComponent color={color} />;
      case "app-branding":
        return <AppBrandingSvgComponent color={color} />;
      case "module-and-field":
        return <ModulesAndFieldsSvgComponent color={color} />;
      case "users":
        return <PersonSvgComponent color={color} />;
      case "data-administration-exports":
        return <DataAdministrationSvgComponent color={color} />;
      case "config":
      case "emails":
        return <EmailSvgComponent color={color} />;
      case "forms":
        return <FileSvgComponent color={color} />;
      case "messages":
        return <ChatSvgComponent color={color} />;
      case "broadcast":
        return <BroadcastSvgComponent color={color} />;
      case "chatbot":
        return <ChatbotSvgComponent color={color} />;
      case "ads":
        return <AdsSvgCompnent color={color} />;
      default:
        return <CompanyProfileSvgComponent color={color} />;
    }
  };
  const { orgInfo } = useStateContext();
  return (
    <div className={"company-profile-details-wrapper__navigation"}>
      {CompanyNavigationListArray.map((navObj, index) => {
        return (
          <div
            key={`Company-nav-${index}`}
            className={"company-profile-details-wrapper__navigation-container"}
          >
            <div
              className={`d-flex align-items-center justify-content-between`}
            >
              <h6
                className={`company-profile-details-wrapper__navigation-title px-4`}
              >
                {navObj.title}
              </h6>
              <img
                src={`/images/icons/arrow-down.svg`}
                alt="arrow-drop"
                className="cursor-pointer img-fluid px-4"
              />
            </div>
            <ul>
              {navObj.linkArray.map((linkObj, index) => {
                return (
                  <li
                    key={`nav-linkObj-${index}`}
                    className={
                      selectedURL === getValue(linkObj, `path`, "")
                        ? `company-profile-details-wrapper__navigation-list_active mt-1`
                        : `company-profile-details-wrapper__navigation-list mt-1`
                    }
                  >
                    <Link
                      to={linkObj.linkUrl}
                      className={`company-profile-details-wrapper__navigation-list-link d-flex align-items-center gap`}
                    >
                      {/* <img
                        src={getValue(
                          linkObj,
                          `image_url`,
                          "/images/icons/arrow-down.svg"
                        )}
                        alt="arrow-drop"
                        className="cursor-pointer img-fluid"
                      /> */}
                      {getImages(
                        getValue(linkObj, `path`, ""),
                        selectedURL !== getValue(linkObj, `path`, "")
                          ? "#414141"
                          : getValue(orgInfo, `theme`, "")
                          ? getValue(orgInfo, `theme`, "")
                          : "#D7242E"
                      )}
                      <h6
                        className={`company-profile-details-wrapper__navigation-link-title`}
                      >
                        {linkObj.linkTitle}
                      </h6>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
