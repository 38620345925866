import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const propertyList = [
  {
    name: "Wild Valley Nandi Hills",
    image: "/property/1.png",
  },
  {
    name: "Aclat Meadows",
    image: "/property/2.png",
  },
  {
    name: "Pratham Resorts",
    image: "/property/3.png",
  },
  {
    name: "Mountain Bear",
    image: "/property/4.png",
  },
  {
    name: "Kstamusu",
    image: "/property/5.jpg",
  },
];

const PropertiesList = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className="property-list-wrapper p-5">
      {propertyList.map((property, index) => (
        <button
          key={index}
          className="property-card"
          onClick={() =>
            navigate(`/${getValue(params, "orgId", "")}/property/details/1234`)
          }
        >
          <img src={property.image} className="property-image" />
          <div className="p-3">
            <p className="header_text__20">{property.name}</p>
          </div>
        </button>
      ))}
    </div>
  );
};

export default PropertiesList;
