import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import React from "react";

const CreatePropertyStepFive = () => {
  return (
    <div>
      <p className="header_text__20">Add your cancellation policy</p>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          required
          placeholder="Enter your policy name"
          className="mb-2"
          label="Policy name"
        />
      </div>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="description"
          //   value={getValue(request, `description`, "")}
          placeholder="Enter a Description"
          label="Describe the booking cancellation policy below and tick mark the check box if you want this policy to reflect everywhere"
          required
        />
      </div>
      <p className="header_text__20 mt-4">Add a policy rule</p>
      <div className="">
        <span>If the guest cancels</span>
        <div className="policy-time-width mx-2">
          <InputRuleForm
            inputType="NUMBER"
            name="number"
            //   value={getValue(request, `name`, "")}
            placeholder="24"
            value="24"
          />
        </div>
        <span>
          hours before check-in date or post checkin, the guest will be charged
        </span>
        <div className="display-inline tooltip-width mx-2">
          <SearchToolTip placeholder="Choose property type" className=" mb-0" />
        </div>
        <div className="policy-time-width mx-2">
          <InputRuleForm
            inputType="NUMBER"
            name="number"
            //   value={getValue(request, `name`, "")}
            placeholder="50"
            value="50"
          />
        </div>
        <span>of the total booking amount.</span>
        <button className="dashboard-button dashboard-button--primary px-4 mx-1  d-flex justify-content-center align-items-center">
          Add rule
        </button>
      </div>
      <div className="mt-2">
        <span>
          If the guest cancels anytime after the booking is made and before the
        </span>
        <div className="policy-time-width mx-2">
          <InputRuleForm
            inputType="NUMBER"
            name="number"
            //   value={getValue(request, `name`, "")}
            placeholder="24"
            value="24"
            disabled
          />
        </div>
        <span>of check-in date. The guest will be charged</span>
        <div className="display-inline tooltip-width mx-2">
          <SearchToolTip placeholder="Choose property type" className=" mb-0" />
        </div>
        <div className="policy-time-width mx-2">
          <InputRuleForm
            inputType="NUMBER"
            name="number"
            //   value={getValue(request, `name`, "")}
            placeholder="0"
            value="0"
          />
        </div>
        <span>of the total booking amount.</span>
      </div>
    </div>
  );
};

export default CreatePropertyStepFive;
