import { getColorCode, getTextColor } from "@common/colors";
import {
  getFirstLetterOfWord,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";

function ProfileCircle(props: any) {
  const { item, name, value, value2 } = props;
  return (
    <div>
      {(value || (value ? value : getValue(item, `[${name}].email`, ""))) && (
        <div
          className="profile_circle"
          style={{
            background: getColorCode(
              getOnlyFirstLetterOfWord(
                value ? value : getValue(item, `[${name}].email`, "")
              )
            ),
            // border: "1px solid green",
          }}
        >
          <h5
            className="title"
            style={{
              color: getTextColor(
                getOnlyFirstLetterOfWord(
                  value ? value : getValue(item, `[${name}].email`, "")
                )
              ),
            }}
          >
            {getOnlyFirstLetterOfWord(
              value ? value : getValue(item, `[${name}].email`, "")
            )}
            {value2 && getOnlyFirstLetterOfWord(value2)}
          </h5>
        </div>
      )}
    </div>
  );
}

export default ProfileCircle;
