import { camelToSnake, sortJSONObjectArray } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

export const intialPipelineState = {
  name: "",
  singular_label: "",
  plural_label: "",
  openStages: [
    {
      name: "",
      label: "",
      type: "OPEN",
      color_code: "#4cc793",
    },
  ],
  closedWonStages: [
    {
      name: "",
      label: "",
      type: "CLOSED_WON",
      color_code: "#f27914",
    },
  ],
  closedLostStages: [
    {
      name: "",
      label: "",
      type: "CLOSED_LOST",
      color_code: "#f27476",
    },
  ],
  fields: [],
};

export const generatePipelinePayload = (request: any, module: string) => {
  return {
    // module_name: module,
    api_name: camelToSnake(request.name),
    label: request.name,
    // singular_label: request.singular_label,
    // plural_label: request.plural_label,
    stages: [].concat.apply(
      [],
      [
        getValue(request, `openStages`, []).map(
          (item: object, index: number) => ({
            api_name: camelToSnake(getValue(item, `label`, "")),
            seq_num: index + 1,
            color_code: getValue(item, `color_code`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
          })
        ),
        getValue(request, `closedLostStages`, []).map(
          (item: object, index: number) => ({
            api_name: camelToSnake(getValue(item, `label`, "")),
            seq_num: index + 1,
            color_code: getValue(item, `color_code`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
          })
        ),
        getValue(request, `closedWonStages`, []).map(
          (item: object, index: number) => ({
            api_name: camelToSnake(getValue(item, `label`, "")),
            seq_num: index + 1,
            color_code: getValue(item, `color_code`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
          })
        ),
      ]
    ),
    form_fields: getValue(request, `fields`, []).map(
      (item: any, index: number) => ({
        module_field_id: getValue(item, `id`, ""),
        seq_num: index + 1,
        required: false,
      })
    ),
  };
};

export const handleNavigatePipeline = (
  request: any,
  activeStep: any,
  setActiveStep: any,
  type: string,
  simpleValidator: any,
  forceUpdate: any
) => {
  if (type === "back") {
    setActiveStep(activeStep);
  } else {
    if (
      activeStep < 3 &&
      request.name &&
      request.singular_label &&
      request.plural_label
    ) {
      setActiveStep(activeStep);
    } else {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        if (activeStep === 3) {
          setActiveStep(activeStep);
          // handleSubmit();
        } else {
        }
      }
    }
  }
};

export const createStageObj = (picklist_options: any) => {
  let options =
    getValue(picklist_options, `length`, 0) > 0
      ? picklist_options.map((item: object) => ({
          ...item,
          label: getValue(item, `name`, ""),
          display_text: getValue(item, `name`, ""),
          value: getValue(item, `id`, ""),
        }))
      : [];

  let openStages = options.filter(
    (item: object) => getValue(item, `type`, "") === "OPEN"
  );
  let closedWonStages = options.filter(
    (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
  );
  let closedLostStages = options.filter(
    (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
  );

  let stageObj = {
    name: "stage_id",
    label: "Stage",
    mandatory: true,
    required: true,
    picklist_options: [].concat(openStages, closedWonStages, closedLostStages),
    sub_type: "single_select",
    input_type: "picklist",
    value: getValue(openStages, `[${0}].value`, ""),
  };
  // setStage([stageObj]);
  return [stageObj];
};

export const sortPipelineFieldList = (fields: any) => {
  let fieldList =
    getValue(fields, `length`, 0) > 0
      ? sortJSONObjectArray(fields, `seq_num`).map((item: object) => ({
          ...getValue(item, `module_field`, {}),
          value: "",
          name: getValue(item, `name`, ""),
          required: getValue(item, `required`, ""),
          seq_num: getValue(item, `seq_num`, ""),
          picklist_options:
            getValue(item, `picklist_options.length`, 0) > 0
              ? getValue(item, `picklist_options`, []).map((option: any) => ({
                  ...option,
                  label: getValue(option, `display_text`, ""),
                }))
              : [],
        }))
      : [];
  return fieldList;
};

export const sortTaskFieldList = (fields: any) => {
  let fieldList =
    getValue(fields, `length`, 0) > 0
      ? sortJSONObjectArray(fields, `seq_num`).map((item: any) => ({
          ...item,
          ...item.module_field,
          value: "",
          name: getValue(item, `name`, ""),
          required: getValue(item, `required`, ""),
          seq_num: getValue(item, `seq_num`, ""),
          picklist_options:
            getValue(item, `picklist_options.length`, 0) > 0
              ? getValue(item, `picklist_options`, []).map((option: any) => ({
                  ...option,
                  label: getValue(option, `display_text`, ""),
                }))
              : [],
        }))
      : [];
  return fieldList;
};

export const intitalFilterViewState = {
  name: "",
  description: "",
  is_private: false,
  filter_name: "",
  filter_value: "",
  filter_obj: {},
};
