import AddSvgComponent from "@assets/svg/add";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropertiesList from "./PropertiesList";

const Property = () => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle="Property"
        isRightArrowVisible={false}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
      />
      <div className="manageFieldRight w-100 add-overflow-y">
        <div className="other-content subheader_filter_section open">
          <div className="filter-wrapper d-flex align-items-center justify-content-between my-1 mx-4">
            <div className="d-flex gap-3 align-items-center">
              <p>
                Properties <span className="form-label">(5)</span>
              </p>
            </div>
            <div className="d-flex gap-3">
              <button
                className=" create-user-button create-user-button--department hover-text touchable-text-white ps-3 pe-4 py-2 gap-1 "
                onClick={() =>
                  navigate(`/${getValue(params, `orgId`, "")}/property/create`)
                }
              >
                <AddSvgComponent color="#fff" size="18" />
                Add
              </button>
            </div>
          </div>
        </div>
        {isLoading ? <Loader /> : <PropertiesList />}
      </div>
    </div>
  );
};

export default Property;
