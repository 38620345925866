import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import React, { useState } from "react";
import { Modal } from "reactstrap";
import { SketchPicker } from "react-color";

function SettingModuleEditPopup(props: any) {
  const { request, setRequest } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")} toggle={props.toggle}>
      <CommonPopupHeader
        height={"60px"}
        title={`${props.editId ? "Update" : "Add"} Stage`}
        handleBackButton={props.toggle}
      />
      <div
        className={`${"popup-container_create_stage"} px-5 mb-3 mt-1`}
        style={{ minHeight: "20vh" }}
      >
        <InputRuleForm
          inputType="TEXTAREA"
          label={"Name"}
          placeholder="Enter"
          name={"label"}
          value={getValue(request, `label`, "")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          rows={3}
          // validator={simpleValidator}
          // validLeft
          // mandatory
        />
        <div
          onClick={() => setDisplayColorPicker(!displayColorPicker)}
          className="cursor-pointer"
        >
          <InputRuleForm
            inputType="TEXT"
            label={"Color Code"}
            placeholder="Enter"
            name={"color_code"}
            value={getValue(request, `color_code`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            rows={3}
            // validator={simpleValidator}
            // validLeft
            // mandatory
          />
        </div>
        {displayColorPicker && (
          <SketchPicker
            color={getValue(request, `color_code`, "")}
            onChangeComplete={(e) =>
              setRequest({ ...request, color_code: e.hex })
            }
          />
        )}

        <div className={"popup-submit-container"}>
          <ButtonComponent
            buttonType={"scecondary"}
            buttonText="Cancel"
            onClickHandler={() => {
              props.handleCloseModal();
              setDisplayColorPicker(false);
            }}
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={
              props.isLoading
                ? "Please wait..."
                : `${props.editId ? "Update" : "Save"}`
            }
            onClickHandler={() => {
              props.handleSubmit();
              setDisplayColorPicker(false);
            }}
            disabled={props.isLoading}
          />
        </div>
      </div>
    </Modal>
  );
}

export default SettingModuleEditPopup;
