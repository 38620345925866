import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Loader from "@components/common/Loader/loading";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BotTableData from "./components/botTableData";
import { Pagination } from "reactstrap";
import "./createbot.scss";
import { getValue } from "@utils/lodash";
import { deleteChatbot, getAllChatbots } from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { toast } from "sonner";

const BotsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        console.log(resp);
        setList(getValue(resp, `data.botConfigs`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteBot = async () => {
    try {
      let resp = await deleteChatbot(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle="Bots"
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
      />
      <div className="d-flex">
        <CompanyProfileNavigation />
        <div className="w-100">
          <div className="other-content subheader_filter_section open">
            <div className="filter-wrapper d-flex align-items-center justify-content-between my-1 mx-4">
              <div className="d-flex gap-3 align-items-center">
                <p>
                  Bots <span className="form-label">(1)</span>
                </p>
              </div>
              <div className="d-flex gap-3">
                <button
                  className=" create-user-button create-user-button--department hover-text touchable-text-white px-4 py-2"
                  onClick={() =>
                    navigate(
                      `/${getValue(params, `orgId`, "")}/chatbot/builder`
                    )
                  }
                  // onClick={handleModal}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <BotTableData list={list} handleDelete={handleDeleteView} />
          )}
          <Pagination
          // totalCount={totalCount}
          // limit={limit}
          // page_no={page_no}
          // handleChangePagination={handleChangePagination}
          // handleChangeLimit={handleChangeLimit}
          />
        </div>
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDeleteBot}
          deleteValue={deleteValue}
        />
      </div>
    </div>
  );
};

export default BotsPage;
