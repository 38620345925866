import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllChatbots = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot?${queryRequest}`);

export const getSpecificChatbot = (id: string) =>
  get(`${config.API_URL}/chatbot/${id}`);

export const createChatbot = (payload: object) =>
  post(`${config.API_URL}/chatbot`, payload);

export const updateChatbot = (id: string, payload: object) =>
  patch(`${config.API_URL}/chatbot/${id}`, payload);

export const deleteChatbot = (id: string) =>
  Delete(`${config.API_URL}/chatbot/${id}`);

export const createChatbotSetup = (payload: object) =>
  post(`${config.API_URL}/chatbot/setup`, payload);

export const getAllChatbotSetup = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/setup?${queryRequest}`);

export const deleteChatbotSetup = (id: string) =>
  Delete(`${config.API_URL}/chatbot/setup/${id}`);
