import DeleteSvgComponent from "@assets/svg/delete";
import { getValue } from "@utils/lodash";
import React from "react";
import { Handle, Position } from "reactflow";
import { findConnectedEdgeInfo } from "../../helpers/node-helper";
import AddCircleSvgComponent from "@assets/svg/add-circle";

function BotVisitorPhoneOption(props: any) {
  const { data, handleDelete, handleStyle } = props;
  return (
    <div>
      {findConnectedEdgeInfo(data) !== getValue(data, `id`, "") && (
        <div
          style={{
            position: "absolute",
            right: "-12px",
            top: "50%",
            background: "white",
          }}
          onClick={(e) => {
            e.stopPropagation();
            data.addNodeByID(getValue(data, `id`, ""));
          }}
        >
          <AddCircleSvgComponent color={"black"} size={28} />
        </div>
      )}
      <div className="custom-node-header">
        <p className="small_text__14 color_white">
          {getValue(data, `label`, "")}
        </p>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(getValue(data, `id`, ""));
          }}
        >
          <DeleteSvgComponent color={"#fff"} size={22} />
        </div>
      </div>
      <div className="custom-node-body">
        <p className="small_text__14">{getValue(data, `description`, "")}</p>
      </div>
      <div className="visitor_section">
        <h6 className="header_text__14 text-center">Phone</h6>
      </div>
      <Handle type="target" position={Position.Left} style={handleStyle} />
      <Handle type="source" position={Position.Right} style={handleStyle} />
    </div>
  );
}

export default BotVisitorPhoneOption;
