import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React, { useState } from "react";
import BotBuilderHeader from "../components/bot-builder-header";
import BotBuilderStepOne from "../components/bot-builder-step-1";
import BotBuilderStepTwo from "../components/bot-builder-step-2";
import BotBuilderStepThree from "../components/bot-builder-step-3";
import BotBuilderStepFour from "../ReactFlowBuilder";
import { createChatbot } from "@services/chatbot.service";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";

const BotBuilder = () => {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const initialState = {
    name: "",
    platform: "CODE_LESS_BOT",
    description: "",
    department: "ALL",
    channels: [],
    supported_languages: [],
    default_language: "",
    audience: "",
    initiate_chat_on_site_land: true,
    initiate_chat_on_click_chat_widget: true,
    initiate_chat_on_custom_action: true,
    response_interval: 0,
    idle_chat_time: 0,
    idle_chat_response: "",
  };
  const [request, setRequest] = useState(initialState);
  const resetRequest = () => {
    setRequest(initialState);
  };

  const [activeTab, setActiveTab] = useState(1);
  const [selectedOption, setSelectedOption] = useState("Codeless");
  const [selectedBrand, setSelectedBrand] = useState("Randoo");
  const [selectedVisitor, setSelectedVisitor] = useState("All visitors");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [createLoading, setCreateLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setCreateLoading(true);
      let resp = await createChatbot(request);
      if (resp) {
        toast.success("Created Successfully");
        navigate(
          `/${getValue(params, `orgId`, "")}/chatbot/builder/create-flow/${getValue(
            resp,
            `data.id`,
            ""
          )}`
        );
        setCreateLoading(false);
        console.log(resp);
      } else {
        setCreateLoading(false);
      }
    } catch (error) {
      setCreateLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      {/* <HomeHeader /> */}
      <BotBuilderHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSubmit={handleSubmit}
        isLoading={createLoading}
      />
      <div
        className={` w-100 d-flex justify-content-start add-overflow-y  ${
          activeTab !== 4 && "p-5 mb-5 manageFieldRight"
        }`}
      >
        {activeTab === 1 && (
          <BotBuilderStepOne
            selectedOption={selectedOption}
            request={request}
            setRequest={setRequest}
          />
        )}
        {activeTab === 2 && (
          <BotBuilderStepTwo
            selectedBrand={selectedBrand}
            request={request}
            setRequest={setRequest}
          />
        )}
        {activeTab === 3 && (
          <BotBuilderStepThree
            selectedVisitor={selectedVisitor}
            request={request}
            setRequest={setRequest}
          />
        )}
        {/* {activeTab === 4 && <BotBuilderStepFour />} */}
      </div>
    </div>
  );
};

export default BotBuilder;
