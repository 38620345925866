import { config } from "../env";
import {
  patch,
  post,
  postWithMethod,
} from "./helpers/http-handler";

//sign-up
export const signUpInitiate = (payload: object) =>
  post(`${config.API_URL}/auth/signup/otp-flow/initiate`, payload);

export const signUpInviteInitiate = (payload: object) =>
  post(`${config.API_URL}/auth/signup/invite-flow`, payload);

export const signUpComplete = (payload: object) =>
  post(`${config.API_URL}/auth/signup/otp-flow/complete`, payload);

export const resendVerificationCode = (id: string) =>
postWithMethod(`${config.API_URL}/auth/verification-codes/${id}/resend`);

//sign-in
export const signInWithPassword = (payload: object) =>
  post(`${config.API_URL}/auth/login/password-flow`, payload);

//sign-in with OTP
export const signInWithOTPInitiate = (payload: object) =>
  post(`${config.API_URL}/auth/login/otp-flow/initiate`, payload);

export const signInWithOTPComplete = (payload: object) =>
  post(`${config.API_URL}/auth/login/otp-flow/complete`, payload);

  //forgot password with OTP
export const ForgotPassowrdOTPInitiate = (payload: object) =>
post(`${config.API_URL}/auth/forgot-password/otp-flow/initiate`, payload);

export const ForgotPassowrdOTPComplete = (payload: object) =>
post(`${config.API_URL}/auth/forgot-password/otp-flow/complete`, payload);
