import { useState } from "react";
import "./PendingTask.scss";
import { getValue } from "@utils/lodash";
import {
  convertCurrentDateWithTime,
} from "common/date-helpers";
import { concatString } from "@common/text-helpers";
import { useNavigate, useParams } from "react-router-dom";
// import InlineStaticTooltip from "@components/Custom/InlineComponents/Tooltip/InlineStatic";
enum Status {
  overdue = "Overdue",
}
enum Type {
  task = "Task",
  note = "Note",
}
type Props = {
  taskObj: {
    author: string;
    meta: string;
    status?: Status;
    dateAndTime: string;
  };
  isIconVisible: boolean;
  type: Type;
};

const getTypeInfo = (type: Type) => {
  if (type === Type.task) {
    return "assigned to";
  } else if (type === Type.note) {
    return "by";
  }
};

export default function PendingTask(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [isActive, setActive] = useState(true);
  const handleToggle = () => {
    setActive(!isActive);
  };
  const [expandMore, setExpandMore] = useState("");
  return (
    <div
      className={`task-assigned cursor-pointer mb-1 border-none`}
      onClick={() =>
        navigate(
          `/${getValue(params, `orgId`, "")}/tasks/detail/${getValue(
            props,
            `taskObj.id`,
            ""
          )}`
        )
      }
    >
      <div
        className={`task-assigned__details d-flex align-items-center justify-content-between`}
      >
        <div className="d-flex align-items-center">
          <h6 className={"pending-wrapper__main-body-title"}>
            <span className={"pending-wrapper__main-body-title-bold"}>
              {props.type}
            </span>
            <span className="px-1">{getTypeInfo(props.type)}</span>
            <span className="header_blue_text">
              {getValue(props, `taskObj.created_by.first_name`, "")}{" "}
              {getValue(props, `taskObj.created_by.last_name`, "")}
            </span>
          </h6>
        </div>
        <div className="d-flex align-items-center">
          <p
            className={`pending-wrapper__main-body-status
         pending-wrapper__main-body-status--overdue
          `}
          >
            Due Date :{" "}
            {convertCurrentDateWithTime(
              getValue(props, `taskObj.due_datetime`, "")
            )}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h6 className="header_text__14">
            {getValue(props, `taskObj.is_completed`, false) ? (
              <span className="color_success">Completed</span>
            ) : (
              <span className="color_danger">Not Completed</span>
            )}
          </h6>
        </div>
        <div className="d-flex align-items-center mt-2">
          {!getValue(props, `taskObj.is_completed`, false) && (
            <div>
              <button
                className="main_button__primary "
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleTaskUpdate(
                    getValue(props, `taskObj.id`, ""),
                    !getValue(props, `taskObj.is_completed`, false)
                  );
                }}
              >
                Mark as Completed
              </button>
            </div>
          )}
          {getValue(props, `taskObj.is_completed`, false) && (
            <div>
              <button
                className=" main_button__secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleTaskUpdate(
                    getValue(props, `taskObj.id`, ""),
                    !getValue(props, `taskObj.is_completed`, false)
                  );
                }}
              >
                Reopen task
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          isActive ? "showdiv" : "hidediv"
        } d-flex align-items-start ${"pending-wrapper__main-body-description-section"}`}
      >
        <h6 className={`header_text__16`}>
          {getValue(props, `taskObj.title`, "")}
        </h6>
      </div>
      {getValue(props, `taskObj.description`, "") && (
        <p className={`small_text__15`}>
          {expandMore === getValue(props, `taskObj.id`, "")
            ? getValue(props, `taskObj.description`, "")
            : concatString(getValue(props, `taskObj.description`, ""), 130)}
          {!expandMore &&
          getValue(props, `taskObj.description.length`, 0) > 120 ? (
            <span
              className="color_primary cursor-pointer text-decoration-underline"
              onClick={(e: any) => {
                e.stopPropagation();
                setExpandMore(getValue(props, `taskObj.id`, ""));
              }}
            >
              more
            </span>
          ) : getValue(props, `taskObj.description.length`, 0) > 120 ? (
            <span
              className="color_primary cursor-pointer text-decoration-underline ms-2"
              onClick={(e: any) => {
                e.stopPropagation();
                setExpandMore("");
              }}
            >
              show less
            </span>
          ) : null}
        </p>
      )}
    </div>
  );
}
