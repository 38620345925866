import React from "react";
import Popup from "reactjs-popup";

function ConfirmImportPopup({ open, onClose, onConfirm }: any) {
  return (
    <Popup
      open={open}
      closeOnDocumentClick={false}
      onClose={onClose}
      className="confirmation-message-wrapper"
    >
      <div className="confirmation-message-container">
        <h6>Go back?</h6>
        <p>
          Your field mapping will be discarded and you'll need to upload your
          file again. Sure you want to go back?
        </p>
      </div>
      <div className="button-wrapper">
        <button onClick={onClose} className="cancel-button">
          Stay on this page
        </button>
        <button onClick={onConfirm} className="next-button">
          Go back
        </button>
      </div>
    </Popup>
  );
}

export default ConfirmImportPopup;
