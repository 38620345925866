import AddSvgComponent from "@assets/svg/add";
import ResendSvgComponent from "@assets/svg/resend";
import SmileSvgComponent from "@assets/svg/smile";
import { emojisList } from "@common/emojis";
import WhatsappAddHover from "@components/common/Tooltip/whats-app-add";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import EmojiPicker from "emoji-picker-react";
import React, { useState } from "react";

function ChatFooter(props: any) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    toggleEmojiPicker,
    message,
    setMessage,
    reference,
    handleSendMessages,
    handleUploadDocument,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessages();
    }
  };

  const handleChangeEditor = (name: any, value: any) => {
    setMessage(value);
  };

  return (
    <div>
      <div className="message-bottom-section p-2">
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            left: "60px",
          }}
        >
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
        </div>
        <div className="d-flex align-items-center">
          {/* <div
            className="d-flex align-items-center mx-2 cursor-pointer"
            onClick={toggleEmojiPicker}
            // ref={props.emojiPickerRef}
          >
            <SmileSvgComponent color={"#54656f"} size={32} />
          </div> */}
          <div className="cursor-pointer mx-2">
            <WhatsappAddHover
              header={<AddSvgComponent color={"#54656f"} size={32} />}
              text={"Please add all required information"}
              width={"200px"}
              height={"200px"}
              hover
              handleUploadDocument={handleUploadDocument}
            />
          </div>

          {/* <input
            type="text"
            placeholder="Type a message"
            className="message-editor"
            value={message}
            ref={reference}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          /> */}
          <div className="w-100">
            <DraftJSRefEditor
              editorState={message}
              handleChangeEditor={handleChangeEditor}
              name="content"
              placeholder="Type a message"
              editorStyle={{
                background: "white",
                paddingLeft: "20px",
                paddingTop: "20px",
                // height: "10vh",
                // width: `calc(100vh - 50px)`,
              }}
              toolbar={{
                options: ["inline", "emoji"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                emoji: {
                  emojis: emojisList,
                },
              }}
              focusColor={"#efefef"}
              focus
              autoFocus
              floatToolbar
              submitOnEnter
              handleSubmit={handleSendMessages}
            />
          </div>
          <div
            onClick={
              !message || message === "<p></p>\n" ? null : handleSendMessages
            }
            className="cursor-pointer mx-2 ms-3"
          >
            <ResendSvgComponent
              color={
                !message || message === "<p></p>\n" ? "#8696a0" : "#408dfb"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatFooter;
