import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import Input from "@components/custom/Forms/Input";
import { getValue } from "@utils/lodash";
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function NewEmailPopup(props: any) {
  const {
    handleModal,
    isLoading,
    handleSubmit,
    request,
    setRequest,
    domains,
    validator,
    onBlur
  } = props;
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")} toggle={handleModal}>
      <ModalHeader>Add Organization Email</ModalHeader>
      <ModalBody className="m-2">
        <div>
          <Input
            type="email"
            name={"email"}
            label={"Email"}
            value={getValue(request, `email`, "")}
            mandatory
            onChange={(e: any) =>
              setRequest({ ...request, email: e.target.value })
            }
            placeholder={"Enter Email"}
            validator={validator}
            disabled={false}
            maxLength={100}
            hideLabel={false}
            validatoryType={""}
            removeBottom={undefined}
            inline={false}
            onBlur={onBlur}
          />
        </div>
        <div className="form_builder_field">
          <SearchToolTip
            placeholder={"Choose Domain"}
            name={"Domain"}
            required
            data={domains}
            label={"label"}
            value={getValue(request, `domainId`, "")}
            onChange={(e: any) =>
              setRequest({ ...request, domainId: getValue(e, `id`, "") })
            }
            validator={validator}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="popup-submit-container">
          <ButtonComponent
            buttonText={"Cancel"}
            buttonType="secondary"
            onClickHandler={handleModal}
          />
          <ButtonComponent
            buttonText={isLoading ? "Creating..." : "Create"}
            buttonType="primary"
            onClickHandler={handleSubmit}
            disabled={isLoading}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default NewEmailPopup;
