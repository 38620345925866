import ButtonComponent from "@components/Form/Button/Button";
import React, { useState } from "react";

const steps = [
  {
    label: "Configure room",
    step: 1,
  },
  {
    label: "Traveller details",
    step: 2,
  },
  {
    label: "Room amenities",
    step: 3,
  },
  {
    label: "Photoes",
    step: 4,
  },
];

const ProgressSteps = ({ activeStep, nextStep, prevStep }: any) => {
  const totalSteps = steps.length;
  const progressWidth = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <div className="progress-bar-wrapper">
      <div
        className="step-container"
        style={{ "--progress-width": progressWidth } as any}
      >
        {steps.map(({ step, label }) => (
          <div className="step-wrapper" key={step}>
            <div
              className={`step-style ${activeStep >= step ? "completed" : ""}`}
            >
              {activeStep > step ? (
                <div className="check-mark">L</div>
              ) : (
                <span className="step-count">{step}</span>
              )}
            </div>
            <div className="steps-label-container">
              <span className="small_text__14 text-nowrap">{label}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="buttons-container">
        <button
          className="button-style"
          onClick={prevStep}
          disabled={activeStep === 1}
        >
          Previous
        </button>
        <button
          className="button-style"
          onClick={nextStep}
          disabled={activeStep === totalSteps}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProgressSteps;
