import React from "react";
// import "../../PendingTaskMainBody.scss";
import { getValue } from "@utils/lodash";

function MainBodyNavViews(props: any) {
  const { pendingLinkArray, handleTab, urlParams } = props;
  return (
    <ul className="d-flex align-items-center justify-content-center">
      {pendingLinkArray.map((linkObj: object, index: number) => {
        return (
          <li
            key={`pending-${index}`}
            className={`position-relative ${
              index + 1 == getValue(urlParams, `activeTab`, "1")
                ? "pending-wrapper__main-body-list_active"
                : "pending-wrapper__main-body-list"
            }`}
            onClick={() => handleTab(index + 1)}
          >
            <a
              className={"pending-wrapper__main-body-link"}
              // onClick={toggleCreateNote}
            >
              {getValue(linkObj, `title`, "")}
            </a>
            {getValue(linkObj, `title`, "") === "Tasks" &&
              getValue(props, `taskCount`, 0) > 0 && (
                <p className="unique_task_count">
                  {getValue(props, `taskCount`, 0)}
                </p>
              )}
          </li>
        );
      })}
    </ul>
  );
}

export default MainBodyNavViews;
