import AddSvgComponent from "@assets/svg/add";
import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import ArrowStraightDownSvgComponent from "@assets/svg/arrow-straight-down";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import BackSvgComponent from "@assets/svg/back-link";
import CalenderPlusSvgComponent from "@assets/svg/calender-plus";
import DashboardSvgComponent from "@assets/svg/dashboard";
import MoreVerticalSvgComponent from "@assets/svg/more-vertical";
import PrinterSvgComponent from "@assets/svg/printer";
import SettingsSvgComponents from "@assets/svg/settings";
import ButtonComponent from "@components/Form/Button/Button";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import InputRuleForm from "@components/InputRuleForm/form";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { motion } from "framer-motion";
import { useState } from "react";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";

const bookingDetails = [
  {
    heading: "Address",
    info: "Not available",
  },
  {
    heading: "Booking made on",
    info: "Jun 18, 2024 11:52 PM",
  },
  {
    heading: "Email",
    info: "ravi288691718772751@example.com",
  },
  {
    heading: "Checkin",
    info: "Jun 23, 2024 05:00 AM",
  },
  {
    heading: "Customer identification",
    info: "NA",
  },
  {
    heading: "Checkout",
    info: "Jun 24, 2024 01:00 PM",
  },
  {
    heading: "Booking reference id",
    info: "28869_12357",
  },
  {
    heading: "Room ID",
    info: "254, 2345",
  },
  {
    heading: "Room type",
    info: "AC",
  },
  {
    heading: "Nights",
    info: "1",
  },
  {
    heading: "Rate plans",
    info: "Standard Plan",
  },
  {
    heading: "Adults / Children / Infant",
    info: "1,1/0,0/0,0",
  },
  {
    heading: "Access code",
    info: "254 - NA , 2345 - NA",
  },
  {
    heading: "Customer Notes",
    info: "NA",
  },
  {
    heading: "Booking Notes",
    info: "NA",
  },
];

const Bookingfolio = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [openIndex, setOpenIndex] = useState(null);
  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const items = [
    {
      tooltipId: "more",
      tooltipContent: "More",
      SvgComponent: MoreVerticalSvgComponent,
      svgProps: { color: "#1a73e8", size: 24 },
    },
    {
      tooltipId: "printer",
      tooltipContent: "Print",
      SvgComponent: PrinterSvgComponent,
      svgProps: { color: "#444f60", size: 22 },
    },
  ];

  const accordionItems = [
    {
      title: (
        <div className="d-flex align-items-center gap-2 w-100">
          <h6 className={`header_text__16 text-black`}>Bill to: Ravi</h6>
        </div>
      ),
      content: (
        <>
          <div className="d-flex align-items-center gap-2 w-100 mb-3">
            <div className="w-25">
              <SearchToolTip placeholder="Search guest list" />
            </div>
            <AddSvgComponent color="#3c4043" size="20" />
            <div className="w-25">
              <SearchToolTip placeholder="Change booking segment" />
            </div>
            <div className="w-25">
              <SearchToolTip placeholder="Change booking source" />
            </div>
          </div>
          <div className="grid-container row-gap-3 column-gap-4">
            {bookingDetails.map((details, index) => (
              <div
                key={index}
                className="d-flex align-items-center gap-2 justify-content-between"
              >
                <p className="small_text__14">{details.heading}:</p>
                <p className="small_text__16 text-black">{details.info}</p>
              </div>
            ))}
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/property/details/1234`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">Testing</p>
          </div>
          <div className="d-flex gap-4 align-items-center">
            <p className="small_text__15">Folio#: SBOOKING_28869_12357</p>
            {items.map((item, index) => (
              <div
                key={index}
                className="cursor-pointer"
                data-tooltip-id={item.tooltipId}
                data-tooltip-content={item.tooltipContent}
              >
                <item.SvgComponent {...item.svgProps} />
                <Tooltip id={item.tooltipId} className="tooltip" place="left" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="manageFieldRight w-100 overflow-y-auto  p-4">
        <div className="row">
          <div className="col-9">
            <div>
              {accordionItems.map((item, index) => (
                <div
                  className="accordion-item mb-3 border-bottom pb-2"
                  key={index}
                >
                  <div
                    className="accordion-title d-flex justify-content-between cursor-pointer"
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.title}
                    <img
                      src="/images/icons/arrow-down.svg"
                      width={18}
                      height={18}
                      className={`${
                        openIndex === index ? "accordion-rotate" : ""
                      } accordion-arrow`}
                      alt="Toggle icon"
                    />
                  </div>
                  {openIndex === index && (
                    <motion.div
                      initial={{ y: 0, opacity: 0 }}
                      animate={{ y: "auto", opacity: 1 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                      <div className=" mt-2 px-2 py-1">{item.content}</div>
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
            <table className="datatable_table w-100 mt-4">
              <thead className="datatable_table_thead position-relative">
                <tr className="datatable_table_tr">
                  <th className="datatable_table_th ">
                    <CustomCheckbox />
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">DATE</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">DESCRIPTION</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">TYPE</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">SUBTOTAL (INR)</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">CUMULATIVE TAX %</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">TAX (INR)</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">TOTAL (INR)</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="datatable_table_tbody">
                <tr className="datatable_table_tr">
                  <td
                    className="datatable_table_td "
                    style={{ display: "table-cell" }}
                  >
                    <div className="d-flex justify-content-center">
                      <CustomCheckbox />
                    </div>
                  </td>
                  <td
                    className="datatable_table_td "
                    style={{ display: "table-cell" }}
                  >
                    Jun 25th 2024
                  </td>
                  <td className="datatable_table_td ">Booking Price</td>
                  <td className="datatable_table_td ">DEBIT</td>
                  <td className="datatable_table_td ">
                    <InputRuleForm
                      inputType="NUMBER"
                      name="number"
                      value="648.67"
                      placeholder="Enter total with tax"
                      marginZero
                      hideFormLabel
                    />
                  </td>
                  <td className="datatable_table_td ">3.00</td>
                  <td className="datatable_table_td ">18.00</td>
                  <td className="datatable_table_td ">
                    <InputRuleForm
                      inputType="NUMBER"
                      name="number"
                      value="666.67"
                      placeholder="Enter total with tax"
                      marginZero
                      hideFormLabel
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-3 position-sticky top-0">
            <div className="room-box-shadow rounded-3  border">
              <div className="add-more-rolls-wrapper__header d-flex align-items-center justify-content-between rounded-top-3">
                <h3 className="header_text__18 text-center text-white">
                  Folio summary
                </h3>
              </div>
              <div className="p-3">
                <div className="border-bottom">
                  <p className="text-center header_text__16">Fee Breakdown</p>
                  <table className="w-100 mt-3 mb-3 small_text__16">
                    <tr>
                      <td className="text-start p-1">Total without taxes</td>
                      <td className="text-end p-1">₹ 648.67</td>
                    </tr>
                    <tr>
                      <td className="text-start p-1"> Total tax amount</td>
                      <td className="text-end p-1">₹ 18.00</td>
                    </tr>
                    <tr>
                      <td className="text-start p-1">
                        {" "}
                        Total with taxes and fees
                      </td>
                      <td className="text-end p-1">₹ 666.67</td>
                    </tr>
                  </table>
                </div>
                <div className="border-bottom mt-3">
                  <p className="text-center header_text__16">
                    Room Taxes Breakdown
                  </p>
                  <table className="w-100 mt-3 mb-3 small_text__16">
                    <tr>
                      <td className="text-start p-1">custom tax 1</td>
                      <td className="text-end p-1">₹ 18.00</td>
                    </tr>
                  </table>
                </div>
                <div className="border-bottom mt-3">
                  <table className="w-100 mt-3 mb-3 small_text__16">
                    <tr>
                      <td className="text-start p-1"> Service taxes</td>
                      <td className="text-end p-1">₹ 0.00</td>
                    </tr>
                  </table>
                </div>
                <div className="border-bottom mt-3">
                  <table className="w-100 mt-3 mb-3 small_text__16 text-black">
                    <tr>
                      <td className="text-start p-1"> Payment made</td>
                      <td className="text-end p-1">₹ 0.00</td>
                    </tr>
                    <tr>
                      <td className="text-start p-1"> Balance due</td>
                      <td className="text-end p-1">₹ 666.67</td>
                    </tr>
                  </table>
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <ButtonComponent
                    buttonType="secondary"
                    buttonText="Settle dues"
                  />
                  <ButtonComponent buttonType="primary" buttonText="check in" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookingfolio;
