import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import React from "react";

const CreatePropertyStepOne = () => {
  return (
    <div>
      <InputRuleForm
        inputType="TEXT"
        name="name"
        //   value={getValue(request, `name`, "")}
        placeholder="Enter a Name"
        required
        className="mb-2"
        label="Property name"
      />
      {/* <PhoneInputComponent
        placeholder="Enter phone number (+91...)"
        //   value={getValue(
        //     item,
        //     "phone",
        //     ""
        //   )}
        //   onChange={(e: any) => {
        //     handlePhoneNestedRequestArrayChange(
        //       index,
        //       aIndex,
        //       e,
        //       "carousel",
        //       "buttons",
        //       "phone",
        //       setRequest
        //     );
        //   }}
        label={"Phone Number"} */}
      {/* /> */}
      <div className="mt-3">
        <p className="form-label">Property type</p>
        <SearchToolTip placeholder="Choose property type" className="pt-3" />
        <InputRuleForm
          inputType="NUMBER"
          name="number"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter no of rooms"
          required
          className="mb-2"
          label="Rooms across categories"
        />
      </div>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          //   value={getValue(request, `name`, "")}
          placeholder="Enter a street name"
          className="mb-2"
          label="Street address"
        />
      </div>
      <div className="mt-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="description"
          //   value={getValue(request, `description`, "")}
          placeholder="Enter a Description"
          label="Property description"
        />
      </div>
    </div>
  );
};

export default CreatePropertyStepOne;
