import { getValue } from "@utils/lodash";
import { handleSelectChange } from "common/handleChange";
import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./edit-popup.scss";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import ButtonComponent from "@components/Form/Button/Button";

function CommonBulkEditPopup(props: any) {
  const {
    request,
    setRequest,
    stageList,
    selectedEditOption,
    setSelectedEditOption,
  } = props;
  const [editOptions] = useState([
    { value: "owner_id", label: "Owner" },
    { value: "stage_id", label: "Stage" },
  ]);
  return (
    <div>
      <Modal
        isOpen={getValue(props, `isOpen`, "")}
        toggle={getValue(props, `isOpen`, "")}
      >
        <CommonPopupHeader
          title={"Edit"}
          handleBackButton={props.handleModal}
        />
        <section className="edit-popup-wrapper">
          <div className="">
            {/* <SearchToolTip
              placeholder={`Select`}
              onChange={(e: object) =>
                setSelectedEditOption(getValue(e, `value`, ""))
              }
              label={`label`}
              width={"440px"}
              data={editOptions}
              value={selectedEditOption}
              selectKey={"value"}
              name={"Select"}
            /> */}
            {selectedEditOption === "owner_id" && (
              <SearchToolTip
                placeholder={`Enter Owner`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  // handleSelectChange(e, "owner_id", request, setRequest)
                  setRequest({
                    ...request,
                    owner_id: getValue(e, `id`, ""),
                  })
                }
                label={`label`}
                // width={"440px"}
                lookup_api={"org_users"}
                value={getValue(request, `owner_id`, "")}
                selectKey={"id"}
                name={"Owner"}
              />
            )}
            {selectedEditOption === "stage_id" && (
              <SearchToolTip
                placeholder={`Enter Stage`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  // handleSelectChange(e, "stage_id", request, setRequest)
                  setRequest({
                    ...request,
                    stage_id: getValue(e, `id`, ""),
                  })
                }
                label={`label`}
                // width={"440px"}
                data={stageList}
                value={getValue(request, `stage_id`, "")}
                selectKey={"id"}
                name={"Stage"}
              />
            )}

            <div className="edit-footer-wrapper">
              <ButtonComponent
                onClickHandler={props.handleModal}
                buttonType={"scecondary"}
                buttonText="Cancel"
              />
              <ButtonComponent
                buttonType={"primary"}
                buttonText={props.isLoading ? "Please wait..." : "Update"}
                disable={props.isLoading}
                onClickHandler={() => props.handleSubmit()}
              />
            </div>
          </div>
        </section>
      </Modal>
    </div>
  );
}

export default CommonBulkEditPopup;
