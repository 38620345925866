import CheckCircleSvgComponent from "@assets/svg/check-circle";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import React, { useState } from "react";
import InfoSvgComponent from "@assets/svg/info";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import PrinterSvgComponent from "@assets/svg/printer";
import RecieptSvgComponent from "@assets/svg/reciept";
import MoneySvgComponent from "@assets/svg/money";
import AdsSvgCompnent from "@assets/svg/ads";
import TimerSvgComponent from "@assets/svg/timer";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import CloseMainSvgComponent from "@assets/svg/close-main";
import LockSvgComponent from "@assets/svg/lock";
import CalenderPlusSvgComponent from "@assets/svg/calender-plus";
import CreditCardSvgComponent from "@assets/svg/credit-card";
import CalendarSvgComponent from "@assets/svg/calender";
import MappingSvgComponent from "@assets/svg/mapping";

const allOptions = [
  {
    icon: <CheckCircleSvgComponent />,
    name: "Check in ",
  },
  {
    icon: <PrinterSvgComponent />,
    name: "Print reg card",
  },
  {
    icon: <RecieptSvgComponent color="#aeaeae" />,
    name: "View folio",
  },
  {
    icon: <MoneySvgComponent color="#3C4043" />,
    name: "Settle dues",
  },
  {
    icon: <AdsSvgCompnent />,
    name: "Send magic link",
  },
  {
    icon: <TimerSvgComponent />,
    name: "Hold booking",
  },
  {
    icon: <RemoveCircleSvgComponent />,
    name: "Cancel booking",
  },
  {
    icon: <CloseMainSvgComponent />,
    name: "Set to no show",
  },
  {
    icon: <LockSvgComponent />,
    name: "Lock booking",
  },
  {
    icon: <PrinterSvgComponent />,
    name: "Unassign room",
  },
  {
    icon: <CalenderPlusSvgComponent />,
    name: "Modify checkin",
  },
  {
    icon: <CreditCardSvgComponent />,
    name: "Modify checkout",
  },
  {
    icon: <CalendarSvgComponent />,
    name: "Edit reservation",
  },
  {
    icon: <MappingSvgComponent />,
    name: "Move room",
  },
];

const MoreBookingDetails = () => {
  const [openCheckin, setOpenCheckin] = useState(false);
  const toggleCheckin = () => {
    setOpenCheckin(!openCheckin);
  };

  return (
    <div className="mt-5">
      <div className="more-options-container gap-3 ">
        {allOptions.map((options, index) => (
          <div
            className="more-options-card touchable-text"
            key={index}
            onClick={toggleCheckin}
          >
            {options.icon}
            {options.name}
          </div>
        ))}
      </div>
      <CommonRightPopup
        isPopupVisible={openCheckin}
        toggle={toggleCheckin}
        width="500px"
        title="Check-in room"
        hideNew
        second_title="Update"
        addZIndex
      >
        <div className="pt-4 px-5">
          <div className="d-flex justify-content-center">
            <div className="information-container">
              <InfoSvgComponent color="#1a73e8" size="18" />
              You are about to check-in Room <b>254</b>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-4">
            <CustomCheckbox />
            <p className="form-label">
              Check-in all the rooms in booking SFBOOKING_28869_12357
            </p>
          </div>
          <div className="mt-4">
            <p className="form-label">Guest list</p>
            <SearchToolTip />
          </div>
        </div>
      </CommonRightPopup>
    </div>
  );
};

export default MoreBookingDetails;
